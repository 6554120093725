import { Component, OnInit, ViewChild } from '@angular/core';

interface ItemData {
  id: number;
  startdate:string;
  enddate: string;
  filedate: string;
  amountdue: string;
  status:string;
  statusColor:string;
  action:string;
}

@Component({
  selector: 'app-tax-module',
  templateUrl: './tax-module.component.html',
  styleUrls: ['./tax-module.component.scss']
})
export class TaxModuleComponent implements OnInit {
  

  offsetTop = 0;
  nzOffsetBottom = 0;

  setOffsetTop(): void {
    this.offsetTop += 10;
  }

  setOffsetBottom(): void {
    this.nzOffsetBottom += 10;
  }
  
  dataList=[{id:1, startdate:'07 Jan 2021', enddate:'07 Jan 2021',  filedate:'07 Jan 2021', amountdue:'$2500.00', status:'Open',statusColor:'badge-green-background',  action:'Prepare Return'}, {id:2, startdate:'07 Jan 2021', enddate:'07 Jan 2021',  filedate:'07 Jan 2021', amountdue:'$2500.00', status:'Open',statusColor:'badge-green-background',  action:'Prepare Return'}, {id:3, startdate:'07 Jan 2021', enddate:'07 Jan 2021',  filedate:'07 Jan 2021', amountdue:'$2500.00', status:'Open',statusColor:'badge-green-background',  action:'Prepare Return'}, {id:4, startdate:'07 Jan 2021', enddate:'07 Jan 2021',  filedate:'07 Jan 2021', amountdue:'$2500.00', status:'Open',statusColor:'badge-green-background',  action:'Prepare Return'}, {id:5, startdate:'07 Jan 2021', enddate:'07 Jan 2021',  filedate:'07 Jan 2021', amountdue:'$2500.00', status:'Open',statusColor:'badge-green-background',  action:'Prepare Return'}, {id:6, startdate:'07 Jan 2021', enddate:'07 Jan 2021',  filedate:'07 Jan 2021', amountdue:'$2500.00', status:'Open',statusColor:'badge-green-background',  action:'Prepare Return'} ]  
  checked = false;
  indeterminate = false;
  listOfCurrentPageData: ReadonlyArray<ItemData> = [];
  listOfData: ReadonlyArray<ItemData> = [];
  setOfCheckedId = new Set<number>();
  
  updateCheckedSet(id: number, checked: boolean): void {
    if (checked) {
      this.setOfCheckedId.add(id);
    } else {
      this.setOfCheckedId.delete(id);
    }
  }
  
  onItemChecked(id: number, checked: boolean): void {
    this.updateCheckedSet(id, checked);
    this.refreshCheckedStatus();
  }
  
  onAllChecked(value: boolean): void {
    this.listOfCurrentPageData.forEach(item => this.updateCheckedSet(item.id, value));
    this.refreshCheckedStatus();
  }
  
  onCurrentPageDataChange($event: ReadonlyArray<ItemData>): void {
    this.listOfCurrentPageData = $event;
    this.refreshCheckedStatus();
  }
  
  refreshCheckedStatus(): void {
    this.checked = this.listOfCurrentPageData.every(item => this.setOfCheckedId.has(item.id));
    this.indeterminate = this.listOfCurrentPageData.some(item => this.setOfCheckedId.has(item.id)) && !this.checked;
  }
  
  ngOnInit(): void {
    // this.listOfData = new Array(9).fill(0).map((_, index) => {
  
    //   return {
    //     id: index,
    //     name: `Edward King ${index}`,
    //     age: 32,
    //     address: `London, Park Lane no. ${index}`
    //   };
    // });
  }
  
  
}
