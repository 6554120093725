import { Component, OnInit } from '@angular/core';
// import * as jsPDF from 'jspdf';
import { AccountsService } from 'src/app/services/accounts.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { SettingsService } from 'src/app/services/settings.service';
import { NzMessageService } from 'ng-zorro-antd';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-generate-payslip',
  templateUrl: './generate-payslip.component.html',
  styleUrls: ['./generate-payslip.component.scss']
})
export class GeneratePayslipComponent implements OnInit {
  selectedValue;
  date_payslip;
  datepay;
  settings;
  dataChanged($event){
    this.datepay = moment($event).format('MMMM, yyyy')
    console.log(this.datepay)
  }

  monthFormat = 'MMM yyyy';
  public errorMsg;
  listOfSelection = [
    {
      text: 'Select All Row',
      onSelect: () => {
        this.checkAll(true);
      }
    },
    {
      text: 'Select Odd Row',
      onSelect: () => {
        this.listOfDisplayData.forEach((data, index) => (this.mapOfCheckedId[data.id] = index % 2 !== 0));
        this.refreshStatus();
      }
    },
    {
      text: 'Select Even Row',
      onSelect: () => {
        this.listOfDisplayData.forEach((data, index) => (this.mapOfCheckedId[data.id] = index % 2 === 0));
        this.refreshStatus();
      }
    }
  ];
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfDisplayData: Payslip[];
  listOfEmployees;
  listOfAllData: Payslip[];
  mapOfCheckedId: { [key: string]: boolean } = {};
  currentPageDataChange($event: Payslip[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }
  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item.id]);
    this.isIndeterminate =
      this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;
  }
  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus();
  }
  visible = false;
  departments;
  designations;
  payment_month ;
  netSalary;
  overtimeSalary;
  tax;
  providentFund;
  loan;
  totalSalary;
  slip: Payslip = {
    id: null,
    employee_code: null,
    employee_name: null,
    department: null,
    designation: null,
    payment_type: null,
    payment_month: null,
    payment_date: null,
    net_salary: null,
    tax: null,
    provident_fund: null,
    loan: null,
    overtime_salary: null,
    total_salary: null,
    payment_method: null,
    address: "Office #305, Blossom Trade Center, University Road, Gulzar-e-Hijri, Karachi, Pakistan",
    basic_salary: null
  };
  searchForm = new FormGroup({
    date: new FormControl(null, Validators.required),
    employee: new FormControl(null),
    department: new FormControl(null),
    designation: new FormControl(null),
    
  });
   
  constructor(
    private settingsService: SettingsService,
    private _AccountsService: AccountsService, 
    private _EmployeesService: EmployeesService, private _SettingsService: SettingsService,  
    private msg: NzMessageService) { }

  ngOnInit() {
    this._SettingsService.getDepartments().subscribe(data => this.departments = data);
    this._SettingsService.getDesignations().subscribe( data => this.designations = data );
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfEmployees = data;
    },
    error => console.log(error.message));


    this.settingsService.getSettings().subscribe(
      data => {
        this.settings = data;
      }
    );
  }

  changeDepartment(ev): void{
    if(ev){
      this._SettingsService.getDesignationsByDepartmentId(ev).subscribe(
        data => { 
          this.designations = data;
        }
      );
      this._EmployeesService.getEmployeesByDepartmentId(ev).subscribe(data=> { 
        this.listOfEmployees = data;
      },
      error => console.log(error.message));
    }
  }
  changeDesignation(ev): void{
    if(ev){
      this._EmployeesService.getEmployeesByDesignationId(ev).subscribe(data=> { 
        this.listOfEmployees = data;
      },
      error => console.log(error.message));
    }
  }

  searchEmployees(): void{
    for (const i in this.searchForm.controls) {
      this.searchForm.controls[i].markAsDirty();
      this.searchForm.controls[i].updateValueAndValidity();
    }
    this._AccountsService.searchEmployees(this.searchForm.value).subscribe(data => {
      this.listOfAllData = data;
      this.listOfDisplayData = this.listOfAllData;
      console.log(data);

    });
  }

  open(id): void {
    this.visible = true;
    this.slip = this.listOfAllData.find(data => data.id == id);
    
  }

  close(): void {
    this.visible = false;
  }

  printSlip(): void{
    window.print();
  }
  resetSearch(): void{
    this.searchForm.reset();
     
  }
}
 

