import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { SettingsService } from 'src/app/services/settings.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Component({
  selector: 'app-employee-awards',
  templateUrl: './employee-awards.component.html',
  styleUrls: ['./employee-awards.component.scss']
})
export class EmployeeAwardsComponent implements OnInit {
  selectedValue;
  loggedInUser = this.storage.get('loggedInUser');
  
    //Copid from employee type component ts
    public errorMsg;
    dateFormat = 'MMM yyyy';
    listOfEmployeesData;
    employeeDataLoading = true;


    listOfDisplayData;
    listOfAllData;
    //
    visible = false;
    visibleEdit = false;
    visibleDelete = false;
    awardToDeleteId = 0;
    isOkLoading = false;

    monthNames = ['January', 'Febraury', 'March', 'April', 'May', 'June',
                  'July', 'August', 'September', 'October', 'November', 'December'];

    awards;

    selectedEmployee;
    selectedAward;

  constructor(
    private _SettingsService: SettingsService, 
    private _EmployeesService: EmployeesService
    , @Inject(LOCAL_STORAGE) private storage: StorageService) { }

  ngOnInit() {
    this._EmployeesService.getEmployeeAwards().subscribe(data => {
      this.listOfAllData = data;
      this.listOfDisplayData = this.listOfAllData;
      
      this._EmployeesService.getEmployees().subscribe(data=> { 
        console.log(data)
        this.listOfEmployeesData = data;
        this.employeeDataLoading = false;
      },
      error => console.log(error.message));  
  });


  this._SettingsService.getAwards().subscribe(data => this.awards = data);

  }

  employeeAwardForm = new FormGroup({
    award: new FormControl(null, Validators.required),
    emp_id: new FormControl(null, Validators.required),
    gift: new FormControl(null),
    cash: new FormControl(null),
    month: new FormControl(null, Validators.required),
    year: new FormControl(null)
  });

  employeeAwardEditForm = new FormGroup({
    id: new FormControl(),
    award: new FormControl(null, Validators.required),
    emp_id: new FormControl(null, Validators.required),
    gift: new FormControl(null),
    cash: new FormControl(null),
    month: new FormControl(null, Validators.required),
    year: new FormControl(null)
  });

  open(): void{
  this.visible = true;
  }

  close(): void{
  this.visible = false;
  }

  openEdit(id): void {
    this.visibleEdit = true;

    let data = this.listOfAllData.find(data => data.id == id);

    this.selectedAward = data.award;
    this.selectedEmployee = data.emp_id;

    this.employeeAwardEditForm.patchValue({
      id: id,
      gift: data.gift,
      cash: data.cash,
      month: new Date(data.year + '-' + data.month)
    });
  }
  closeEdit(): void {
  this.visibleEdit = false;
  }

  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.awardToDeleteId = id;
  }

  closeDeleteModal(): void{
    this.visibleDelete = false;
  }

  addEmployeeAward(): void {
    for (const i in this.employeeAwardForm.controls) {
      this.employeeAwardForm.controls[i].markAsDirty();
      this.employeeAwardForm.controls[i].updateValueAndValidity();
    }
    this.employeeAwardForm.value.month = moment(this.employeeAwardForm.value.month).format('MM');
    this.employeeAwardForm.value.year = moment(this.employeeAwardForm.value.month).format('YYYY');
    if(this.employeeAwardForm.valid){
      this._EmployeesService.createEmployeeAward(this.employeeAwardForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            alert('Already Exisits');
          }else if(responseObj.message == 'failed'){
            alert('Faild');
          }else if(responseObj.message == 'success'){
            this._EmployeesService.getEmployeeAwards().subscribe(data => this.listOfAllData = data,
              error => this.errorMsg = error);
              this.listOfDisplayData = this.listOfAllData;
              this.visible = false;
          }else{
            alert(JSON.stringify(response));
          }
        }
      );
    }
  }

  editEmployeeAward(): void {
    for (const i in this.employeeAwardEditForm.controls) {
      this.employeeAwardEditForm.controls[i].markAsDirty();
      this.employeeAwardEditForm.controls[i].updateValueAndValidity();
    }
    if(this.employeeAwardEditForm.valid){
      this.employeeAwardEditForm.value.month = moment(this.employeeAwardEditForm.value.month).format('MM');
      this.employeeAwardEditForm.value.year = moment(this.employeeAwardEditForm.value.month).format('YYYY');
      this._EmployeesService.editEmployeeAward(this.employeeAwardEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success'){
            this._EmployeesService.getEmployeeAwards().subscribe(data => this.listOfAllData = data,
              error => this.errorMsg = error);
            this.listOfDisplayData = this.listOfAllData;

            this.visibleEdit = false;
          }else if(responseObj.message == 'failed'){
            alert('Faild');
          }else{
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }

  deleteEmployeeAward(id): void {
    this.isOkLoading = true;
    this._EmployeesService.deleteEmployeeAward(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success'){
        this._EmployeesService.getEmployeeAwards().subscribe(data => this.listOfAllData = data,
          error => this.errorMsg = error);
          this.listOfDisplayData = this.listOfAllData;
      }else if(responseObj.message == 'failed'){
        alert('Faild');
      }else{
        alert(JSON.stringify(response));
      }
    }
  );
  this.isOkLoading = false;
  this.visibleDelete = false;
  }
  currentPageDataChange($event){
    
  }

}
