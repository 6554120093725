import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(private authService: AuthService,
    private router: Router) { }
    canActivate(): boolean {
      if(this.authService.isLoggedIn()) {
        return true;
      }else{
        console.log('authServices start')
        this.router.navigate(['/login'])
      return false;
      }
    }
}
