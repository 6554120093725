import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { DOCUMENT } from '@angular/common';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd/notification';

import { SettingsService } from 'src/app/services/settings.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { SlackService } from 'src/app/services/slack.service';

@Component({
  selector: 'app-check-in-timer',
  templateUrl: './check-in-timer.component.html',
  styleUrls: ['./check-in-timer.component.scss']
})
export class CheckInTimerComponent implements OnInit {
  currentLoggedInInterval;
  CurrentDate = moment().format("HH:mm:ss A");
  officeTime="9:00:00";
  totalHoursforOffice = moment.duration(this.officeTime).asMinutes();
  loggedInUser = this.storage.get('loggedInUser');
  checkInStart;
  loggedinTime= "0:00:00";
  remainingTime;
  totalRemainingTime;
  currenttotalRemaingTimeInterval;
  showPlayIcon = true;
  showStopIcon = false;
  isPaused = false;
  totalLoggedInTime;
  isClockedOut = false;
  punchInLoading = true;
  punchOutLoading = false;
  switchValue : boolean = false;
  workFromHomeToggle :boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private settingsService: SettingsService,
    private _EmployeesService: EmployeesService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private notification: NzNotificationService,
    private _slackService: SlackService
    ) { }


  ngOnInit() {
    this.currentLoggedInInterval = setInterval(() => {
      this.CurrentDate = moment().format("H:mm:ss A");
    }, 1000);
    // get office time
    this.settingsService.getOfficeTime().subscribe(
      data => {
        let timeIn = moment(data['officeInTime'], ["h:mm:ss A"]).format("DD/MM/YYYY HH:mm:ss");
        let timeOut = moment(data['officeOutTime'], ["h:mm:ss A"]).format("DD/MM/YYYY HH:mm:ss");
        this.officeTime = moment.utc(moment(timeOut,"DD/MM/YYYY HH:mm:ss").diff(moment(timeIn,"DD/MM/YYYY HH:mm:ss"))).format("H:mm:ss");        
        this.totalHoursforOffice = moment.duration(this.officeTime).asMinutes();
      }
    );
    // get dashboard stats
    this._EmployeesService.checkAttendance(this.loggedInUser.id).subscribe(
      data => {                 
        if(!data['error']){       
          let timeNow;
          if(!data['clock_out']){            
            timeNow = moment().format("DD/MM/YYYY HH:mm:ss");
          }else{
            timeNow = moment(data['clock_out'], ["h:mm:ss A"]).format("DD/MM/YYYY HH:mm:ss");
          }
  
          let clockIn = moment(data['clock_in'], ["h:mm:ss A"]).format("DD/MM/YYYY HH:mm:ss");
          this.checkInStart = moment(data['clock_in'], ["h:mm:ss A"]).format("HH:mm:ss");
          this.loggedinTime = moment.utc(moment(timeNow,"DD/MM/YYYY HH:mm:ss").diff(moment(clockIn,"DD/MM/YYYY HH:mm:ss"))).format("H:mm:ss");
  
  
          // calculate total Remaining time
          let calculateloggedInTime = moment(this.loggedinTime, ['H:mm:ss']);
          let calculateremainingTime = moment(this.officeTime, ['H:mm:ss']);
          this.remainingTime = moment.utc(moment(calculateremainingTime,"DD/MM/YYYY HH:mm:ss").diff(moment(calculateloggedInTime,"DD/MM/YYYY HH:mm:ss"))).format("H:mm:ss");
          this.totalRemainingTime = this.remainingTime; // remove this after fixing <--
            if(!data['clock_out']){
              // set interval for loggedIn timer & remaining Timer
              let loggedInTimer = moment(this.loggedinTime, ['H:mm:ss']);
              let remainingTimer = moment(this.remainingTime, ['H:mm:ss']);
              if(this.currentLoggedInInterval) { // clear interval
                clearInterval(this.currentLoggedInInterval);
              }
              if(this.currenttotalRemaingTimeInterval) { // clear interval
                clearInterval(this.currenttotalRemaingTimeInterval);
              }
              this.showPlayIcon = false;
              this.showStopIcon = true;  
              this._EmployeesService.getallpauselogs(this.loggedInUser.id, moment().format("YYYY-MM-DD")).subscribe((
                dt => {
                  this._EmployeesService.checkPaused(this.loggedInUser.id).subscribe(data=>{
                    if(data['success']){
                      this.isPaused = true;        
                      loggedInTimer = loggedInTimer.subtract(dt, 'seconds');  // see the cloning?
                      remainingTimer = remainingTimer.add(dt, 'seconds');  // see the cloning?
                      this.totalLoggedInTime = loggedInTimer.format('H:mm:ss');
                      this.totalRemainingTime = remainingTimer.format('H:mm:ss');
                    }else{
                      loggedInTimer = loggedInTimer.subtract(dt, 'seconds');  // see the cloning?
                      remainingTimer = remainingTimer.add(dt, 'seconds');  // see the cloning?
                      this.currentLoggedInInterval = setInterval(() => {
                        loggedInTimer.add(1, 'second');
                      this.totalLoggedInTime = loggedInTimer.format('H:mm:ss');
                      }, 1000);
                      this.currenttotalRemaingTimeInterval = setInterval(() => {
                        remainingTimer.subtract(1, 'second');
                        this.totalRemainingTime = remainingTimer.format('H:mm:ss');
                      }, 1000);
                    }
                  });
                }
              ),error=>{ console.log(error)});
  
            }else{
              this.isClockedOut = true;
              this.totalLoggedInTime = this.loggedinTime;
              this._EmployeesService.getallpauselogs(this.loggedInUser.id, moment().format("YYYY-MM-DD")).subscribe((
                data => {
                  let timee = moment(this.totalLoggedInTime, ['H:mm:ss']);
                  var totalLoggedInTime = moment(timee).subtract(data, 'seconds');  // see the cloning?
                  this.totalLoggedInTime = totalLoggedInTime.format('H:mm:ss');
  
                  let timee2 = moment(this.totalRemainingTime, ['H:mm:ss']);
                  var totalRemainingTime = moment(timee2).add(data, 'seconds');  // see the cloning?
                  this.totalRemainingTime = totalRemainingTime.format('H:mm:ss');
                }
              ),error=>{ console.log(error)});
  
            }
          }
          else{
            this.totalRemainingTime = this.officeTime; // no attendance found / add complete office hours as remaining time
          }
  
          this.punchInLoading = false;
      }
    );
  }
  onCheckedChange(isChecked: boolean) {
    this.workFromHomeToggle = isChecked;
    console.log(this.workFromHomeToggle)
    let currentDate = moment().format("YYYY-MM-DD");
    let currentDateTime = new Date();
    let currentTime = moment(currentDateTime).format("HH:mm");
    let workFromHomePayload = {
      clock_in: currentTime,
      clock_out: "",
      date: currentDate,
      emp_id: this.loggedInUser.id,
      id: this.loggedInUser.id,
      work_from_home: true,
      work_from_home_total:540
 }

   this._EmployeesService.editAttendance(workFromHomePayload).subscribe((res) => {
     console.log("Hlellooooooooooooooo",res)
   })
    // let workFromHomePayload = {
    //   emp_id: this.loggedInUser.id,
    //   date: moment().format("MM/DD/YYYY"),
    //   clock_in: moment().format("HH:mm:ss"),
    //   clock_out: moment().format("HH:mm:ss"),
    //   work_from_home: "Yes",
    //   work_from_home_total: null,
    // }
    // this._EmployeesService.editAttendance(workFromHomePayload).subscribe((data) => {
    //   console.log(data)
    // })
  }
  punchIn(){  
 
    this.punchInLoading = false;
    this._EmployeesService.punchIn(this.loggedInUser.id, moment().format("HH:mm:ss")).subscribe(
      data => {
        if(!data['error']){
          console.log(data)
          var m = moment().utcOffset(0);
          m.set({hour:0,minute:0,second:0,millisecond:0});
          let loggedInTime = moment(m, ['HH:mm:ss']);
          this.currentLoggedInInterval = setInterval(() => {
            loggedInTime.add(1, 'second');
            this.totalLoggedInTime = loggedInTime.format('HH:mm:ss');
          }, 1000);
          let remainingTime = moment(this.totalRemainingTime, ["HH:mm:ss"]);
          this.currenttotalRemaingTimeInterval = setInterval(() => {
            remainingTime.subtract(1, 'second');
            this.totalRemainingTime = remainingTime.format('HH:mm:ss');
          }, 1000);
          this.checkInStart = moment().format("HH:mm:ss");
          if(this.showPlayIcon == true)
          {
          this.notification.create(
            'success',
            'Punch-In',
            'Successfully Punched-In at ' + moment().format("DD/MM/YYYY HH:mm:ss")
            );
          }
          this.showPlayIcon = false;
          this.showStopIcon = true;
          this.punchInLoading = true;  
        }else{
          this.notification.create(
            'error',
            'Punch-In',
            'You have already Check-Out for today. '
            );        
            this.punchInLoading = false;  
        }
      }
    );
  }
  punchOut(){
    this.punchOutLoading = true;
    this.punchInLoading = true;
    this._EmployeesService.punchOut(this.loggedInUser.id, moment().format("HH:mm:ss")).subscribe(
      data => {
        if(!data['error']){ 
          if(this.currentLoggedInInterval) { // clear interval
            clearInterval(this.currentLoggedInInterval);
          }
          if(this.currenttotalRemaingTimeInterval) { // clear interval
            clearInterval(this.currenttotalRemaingTimeInterval);
          }
          if(this.showStopIcon == true)
          {
          this.notification.create(
            'success',
            'Punch-Out',
            'Successfully Punched-Out at ' + moment().format("DD/MM/YYYY HH:mm:ss")
            );
            this.punchOutLoading = false;
          }
          this.showPlayIcon = true;
          this.showStopIcon = false;          
          this.punchInLoading = false;
        }
      },
      error => 
      {
        this.notification.create(
        'error',
        'Punch-Out',
        'Cant Punch-Out Somethings Wrong'
        )
        this.punchOutLoading = false;
      }
    );
  }
  pause(){
    this.isPaused = true;
    this._EmployeesService.pause(this.loggedInUser.id, moment().format("HH:mm:ss")).subscribe(data =>{
      clearInterval(this.currentLoggedInInterval);
      clearInterval(this.currenttotalRemaingTimeInterval);
      this.notification.create(
        'success',
        'Paused',
        'Successfully Paused at ' + moment().format("DD/MM/YYYY HH:mm:ss")
        );
      });
  }
  resume(){
    this.isPaused = false;
    this._EmployeesService.pause(this.loggedInUser.id, moment().format("HH:mm:ss")).subscribe(data =>{        
          this._EmployeesService.checkAttendance(this.loggedInUser.id).subscribe(
            data => {        
              if(!data['error']){
                if(!data['clock_out']){
                    let loggedInTimer = moment(this.totalLoggedInTime, ['H:mm:ss']);
                    let remainingTimer = moment(this.totalRemainingTime, ['H:mm:ss']);
                    this.showPlayIcon = false;
                    this.showStopIcon = true;  
                    this._EmployeesService.checkPaused(this.loggedInUser.id).subscribe(data=>{
                      if(data['success']){
                        this.isPaused = true;        
                      }else{
                        this.currentLoggedInInterval = setInterval(() => {
                          loggedInTimer.add(1, 'second');
                        this.totalLoggedInTime = loggedInTimer.format('H:mm:ss');
                        }, 1000);
                        this.currenttotalRemaingTimeInterval = setInterval(() => {
                          remainingTimer.subtract(1, 'second');
                          this.totalRemainingTime = remainingTimer.format('H:mm:ss');
                        }, 1000);
                      }
                    });
                }
              }
              this.punchInLoading = false;
            }
          );
        this.notification.create(
          'success',
          'Resume',
          'Successfully Resumed at ' + moment().format("DD/MM/YYYY HH:mm:ss")
        );
    });
  }


}

