import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from 'moment'
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-edit-increments',
  templateUrl: './edit-increments.component.html',
  styleUrls: ['./edit-increments.component.scss']
})
export class EditIncrementsComponent implements OnInit {
  load = false;
  public errorMsg;
  listOfDisplayData;
  listOfAllData;
  visibleEdit = false;
  selectedPaymentType= 'Monthly';
  constructor(private _EmployeesService: EmployeesService, private msg: NzMessageService,
    private router: Router,
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService) { }

  ngOnInit() {
    let id:any = this.route.snapshot.paramMap.get('id');
    this._EmployeesService.getIncrements().subscribe(dt => {
      this.listOfAllData = dt;
      this.load = true;
      this.listOfDisplayData = this.listOfAllData;
      let data = this.listOfDisplayData.find(data => data.id == id);
      
      this.incrementEditForm.patchValue({
        id: id,
        payment_type : data.payment_type,
        basic_salary_increment : data.basic_salary_increment,
        overtime_salary_increment : data.overtime_salary_increment,
        working_hourly_increment_rate : data.working_hourly_increment_rate,
        overtime_hourly_increment_rate : data.overtime_hourly_increment_rate
      });
    });
  }

onPaymentTypeChange(): void{
  if(this.selectedPaymentType == 'Monthly'){
    this.incrementEditForm.get('basic_salary_increment').setValidators([Validators.required]);
    this.incrementEditForm.get('overtime_salary_increment').setValidators([Validators.required]);
    this.incrementEditForm.get('working_hourly_increment_rate').setValidators([Validators.nullValidator]);
    this.incrementEditForm.get('overtime_hourly_increment_rate').setValidators([Validators.nullValidator]);
  }else{
    this.incrementEditForm.get('working_hourly_increment_rate').setValidators([Validators.required]);
    this.incrementEditForm.get('overtime_hourly_increment_rate').setValidators([Validators.required]);
    this.incrementEditForm.get('basic_salary_increment').setValidators([Validators.nullValidator]);
    this.incrementEditForm.get('overtime_salary_increment').setValidators([Validators.nullValidator]);
  }
  this.incrementEditForm.get('working_hourly_increment_rate').updateValueAndValidity();
  this.incrementEditForm.get('overtime_hourly_increment_rate').updateValueAndValidity();
  this.incrementEditForm.get('basic_salary_increment').updateValueAndValidity();
  this.incrementEditForm.get('overtime_salary_increment').updateValueAndValidity();
}

incrementEditForm = new FormGroup({
  id: new FormControl(),
  payment_type: new FormControl(null, Validators.required),
  basic_salary_increment: new FormControl(null),
  overtime_salary_increment: new FormControl(null),
  working_hourly_increment_rate: new FormControl(null),
  overtime_hourly_increment_rate: new FormControl(null),
});

  openEdit(id): void {
    this.visibleEdit = true;
    let data = this.listOfAllData.find(data => data.id == id);
    this.selectedPaymentType = data.payment_type;
    this.incrementEditForm.patchValue({
      id: data.id,
      payment_type: data.payment_type,
      basic_salary_increment: data.basic_salary_increment,
      overtime_salary_increment: data.overtime_salary_increment,
      working_hourly_increment_rate: data.working_hourly_increment_rate,
      overtime_hourly_increment_rate: data.overtime_hourly_increment_rate
    });
  }
  closeEdit(): void {
    this.visibleEdit = false;
  }

  editIncrement(): void {
    for (const i in this.incrementEditForm.controls) {
      this.incrementEditForm.controls[i].markAsDirty();
      this.incrementEditForm.controls[i].updateValueAndValidity();
    }
    if(this.incrementEditForm.valid){
      this._EmployeesService.editIncrement(this.incrementEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
              if(responseObj.message == 'already-exists'){
                this.notification.create(
                  'error',
                  'Edit Increment',
                  'Already Exists'
                  );
              }else if(responseObj.message == 'failed'){
                this.notification.create(
                  'error',
                  'Edit Increment',
                  'Unable to save changes'
                  );
              }else if(responseObj.message == 'success'){
              this.router.navigate(['/increment'])
                this.notification.create(
                  'success',
                  'Edit Increment',
                  'Successfully updated'
                  );
              }else{
                this.router.navigate(['/increment'])
                this.notification.create(
                  'success',
                  'Edit Increment',
                  'Successfully updated'
                  );
              }

        }
      )
    }
  }

}
