import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { SupportticketsService } from 'src/app/services/supporttickets.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-support-ticket',
  templateUrl: './add-support-ticket.component.html',
  styleUrls: ['./add-support-ticket.component.scss']
})
export class AddSupportTicketComponent implements OnInit {
  
  listOfSupportTicketData;
  supportTicketLoading = true;
  jobs;
  dateFormat = 'MMM dd, yyyy';

  drawerVisible = false;
  
  fileToUpload: File;
  validUpload = false;
  loggedInUser = this.storage.get('loggedInUser');
  
  loading = true;
  
  supportTicketForm = this.fb.group({
    
    name: new FormControl(null),
    email: new FormControl(null),
    date: new FormControl(null),
    subject: new FormControl(null),
    message: new FormControl(null),
    status: new FormControl(null) 
  });
 
   
  
  constructor(private _supportTicketsService: SupportticketsService, 
    private router: Router,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    @Inject(LOCAL_STORAGE) private storage: StorageService, private fb: FormBuilder) { }
  public errorMsg;

  ngOnInit(): void {
    this._supportTicketsService.getSupportTicket().subscribe(
      data=> { 
        this.listOfSupportTicketData = data;
        this.supportTicketLoading = false;
      },
      error => this.errorMsg = error);
      
  }

  visible = false;
  open(): void {
    this.visible = true;
  }
  close(): void {
    this.visible = false;
  }  

  removeAttachment(tag, element){
    this.fileToUpload = null;
    element.value = "";
  }

  onUpload(files: FileList): void{
    this.validUpload = false;
    if(files.length > 0){
      this.fileToUpload = files.item(0);

      var acceptedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.docx|\.doc)$/i;

      if(!acceptedExtensions.exec(this.fileToUpload.name)){
        // this.msg.error(`Supported Formats: .JPG, .JPEG, .PDF, .DOC, DOCX`);
        this.validUpload = false;
      }
      else{
        this.validUpload = true;
      }
    }
  }

  addSupportTicket(): void {
    for (const i in this.supportTicketForm.controls) {
      this.supportTicketForm.controls[i].markAsDirty();
      this.supportTicketForm.controls[i].updateValueAndValidity();
    }
    if(this.supportTicketForm.valid){
      this._supportTicketsService.createSupportTicket(this.supportTicketForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            this.notification.create(
              'error',
              'Support Ticket',
              'Already Exists'
              );
          }else if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Support Ticket',
              'Unable to save changes'
              );
          }else if(responseObj.message == 'success'){
            this.router.navigate(['/support-tickets'])
            this.notification.create(
              'success',
              'Support Ticket',
              'Successfully published'
              );
          }else{
            this.router.navigate(['/support-tickets'])
            this.notification.create(
              'success',
              'Support Ticket',
              'Successfully published'
              );
          }
        }
      )
    }
  }

 
  
  handleChange(info ): void {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      this.msg.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`);
    }
  }

  

}
