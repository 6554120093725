import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-timesheet-settings',
  templateUrl: './timesheet-settings.component.html',
  styleUrls: ['./timesheet-settings.component.scss']
})
export class TimesheetSettingsComponent implements OnInit {

  constructor() { }
  designationEditForm = new FormGroup({
    allow_saturday: new FormControl(null, Validators.required),
  });
  ngOnInit() {
  }
  timesheetSetting(){}
}
