import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AtsServiceService } from 'src/app/services/ats-service.service';
import { JobsService } from 'src/app/services/jobs.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-applicants',
  templateUrl: './applicants.component.html',
  styleUrls: ['./applicants.component.scss']
})
export class ApplicantsComponent implements OnInit {
  selectedValue;
  stroreEnableDisable;
  listOfStoreData;
   listOfInternalApplicantsData; 
   listOfExternalApplicantsData;
  listOfApplicantsData: Applicants[];
  applicantsLoading = true;
  jobs;
  dateFormat = 'MMM dd, yyyy';
  listOfDisplayData: Applicants[];
  ApplicantData: Applicants = {
    id: null,
    job_id: null,
    fname: null,
    lname: null,
    email: null,
    phone: null,
    phone2: null,
    job_type: null,
    close_date: null,
    status: null
  };
 
  loggedInUser = this.storage.get('loggedInUser');
  
  loading = true;
  
  

  
  constructor(
    private _jobsService: JobsService, 
    private _atsService : AtsServiceService,
    private _storeService: StoreService,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService, private fb: FormBuilder) { }
  public errorMsg;

  ngOnInit(): void {
    let id:any = this.route.snapshot.paramMap.get('id');


 this._storeService.getStoreItemView(4).subscribe(
      data=> {  
        this.listOfStoreData = data;
        this.stroreEnableDisable = this.listOfStoreData.enable;
        console.log(this.stroreEnableDisable);
    this._jobsService.getApplicants(id).subscribe(
      data=> { 
        this.listOfInternalApplicantsData = data;
         this._atsService.getApplicantByJobId(id).subscribe(
           data=> {
             console.log(data);
            this.listOfExternalApplicantsData = data;
            if(this.stroreEnableDisable == 0){
              this.listOfApplicantsData = this.listOfInternalApplicantsData;
              // console.log(this.listOfApplicantsData);
            }else{
              this.listOfApplicantsData = this.listOfExternalApplicantsData;
              // console.log(this.listOfApplicantsData);
            }

           },
           error => this.errorMsg = error);
      },
      error => this.errorMsg = error);
    },
    error => this.errorMsg = error);
  }

  
   
   

 
  

   
}
