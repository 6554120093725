import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import * as moment from 'moment';
import { NzNotificationService  } from 'ng-zorro-antd';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { Router } from "@angular/router";
@Component({
  selector: 'app-add-loan',
  templateUrl: './add-loan.component.html',
  styleUrls: ['./add-loan.component.scss']
})
export class AddLoanComponent implements OnInit {

  loggedInUser = this.storage.get('loggedInUser');

  public errorMsg;
  dateFormat = 'MMM dd, yyyy';
  listOfDisplayData;
  listOfAllData;
  //
  load = false;
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  loanToDeleteId = 0;
  isOkLoading = false;


  selectedEmployee;
  enablePayslip;
  selectedStatus;

  defaultEnablePayslip;
  defaultStatus;
  listOfEmployees;

  constructor(

private notification: NzNotificationService,

private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _EmployeesService: EmployeesService) { }

  ngOnInit() {
    this.defaultEnablePayslip = 'yes';
    this.defaultStatus = 'pending';

    if(this.loggedInUser.role_id !== 2){
      this._EmployeesService.getLoans().subscribe(data => {
        console.log(data);
        this.listOfAllData = data;
        this.listOfDisplayData = this.listOfAllData;
        this.load = true;
      });
    }else{
      this._EmployeesService.getLoansByEmployeeId(this.loggedInUser.id).subscribe(data => {
        this.listOfAllData = data;
        this.listOfDisplayData = this.listOfAllData;
        this.load = true;
      });
    }
    
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfEmployees = data;
    },
    error => console.log(error.message));

    this.loanForm.patchValue({
      emp_id : this.loggedInUser.id,
      status : 'Pending'
    });
  }

  loanForm = new FormGroup({
    emp_id: new FormControl(null, Validators.required),
    title: new FormControl(null, Validators.required),
    loan_date: new FormControl(null, Validators.required),
    amount: new FormControl(null, Validators.required),
    enable_payslip: new FormControl('yes'),
    repayment_amount: new FormControl(null, Validators.required),
    repayment_start_date: new FormControl(null, Validators.required),
    status: new FormControl('pending'),
    description: new FormControl(null)
  });

  loanEditForm = new FormGroup({
    id: new FormControl(null),
    emp_id: new FormControl(null, Validators.required),
    title: new FormControl(null, Validators.required),
    loan_date: new FormControl(null, Validators.required),
    amount: new FormControl(null, Validators.required),
    enable_payslip: new FormControl('yes'),
    repayment_amount: new FormControl(0),
    repayment_start_date: new FormControl(null),
    status: new FormControl('pending'),
    description: new FormControl(null)
  });

  open(): void{
    this.visible = true;
  }

  close(): void{
    this.visible = false;
  }

  openEdit(id): void {
    this.visibleEdit = true;
    let data = this.listOfAllData.find(data => data.id == id);
    this.selectedEmployee = data.emp_id;
    this.enablePayslip = data.enable_payslip;
    this.selectedStatus = data.status;
    this.loanEditForm.patchValue({
      id: id,
      title: data.title,
      loan_date: data.loan_date,
      amount: data.amount,
      repayment_amount: data.repayment_amount,
      repayment_start_date: data.repayment_start_date,
      description: data.description
    });
  }

  closeEdit(): void {
    this.visibleEdit = false;
  }

  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.loanToDeleteId = id;
  }

  closeDeleteModal(): void{
    this.visibleDelete = false;
  }

  addLoan(): void {
    for (const i in this.loanForm.controls) {
      this.loanForm.controls[i].markAsDirty();
      this.loanForm.controls[i].updateValueAndValidity();
    }
    if(this.loanForm.valid)
    {
      this.loanForm.value.loan_date = moment(this.loanForm.value.loan_date).format('YYYY-MM-DD');
      if(this.loanForm.value.repayment_start_date !== null)
      this.loanForm.value.repayment_start_date = moment(this.loanForm.value.repayment_start_date).format('YYYY-MM-DD'); 


      this._EmployeesService.createLoan(this.loanForm.value).subscribe(
        response => {
      
    console.log('request create');
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists')
          {
            alert('Already Exisits');
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else if(responseObj.message == 'success')
          {
            
          this.notification.create(
            'success',
            'Loan',
            'Successfully updated'
            );
            this.router.navigate(['/loan'])
          }
          else
          {
            
            this.notification.create(
              'success',
              'Loan',
              'Successfully updated'
              );
              this.router.navigate(['/loan'])
          }
        }
      )
    }
  }

  editLoan(): void {
    if(this.loanEditForm.valid)
    {
      

      this.loanEditForm.value.loan_date = moment(this.loanForm.value.loan_date).format('YYYY-MM-DD');
      if(this.loanForm.value.repayment_start_date !== null)
      this.loanEditForm.value.repayment_start_date = moment(this.loanForm.value.repayment_start_date).format('YYYY-MM-DD'); 

      this._EmployeesService.editLoan(this.loanEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success')
          {
            if(this.loggedInUser.role_id !== 2){
              this._EmployeesService.getLoans().subscribe(data => {
                console.log(data);
                this.listOfAllData = data;
                this.listOfDisplayData = this.listOfAllData;
              });
            }else{
              this._EmployeesService.getLoansByEmployeeId(this.loggedInUser.id).subscribe(data => {
                this.listOfAllData = data;
                this.listOfDisplayData = this.listOfAllData;
              });
            }
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else
          {
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }

  deleteLoan(id): void {
    this.isOkLoading = true;
    this._EmployeesService.deleteLoan(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success')
      {
          if(this.loggedInUser.role_id !== 2){
            this._EmployeesService.getLoans().subscribe(data => {
              console.log(data);
              this.listOfAllData = data;
              this.listOfDisplayData = this.listOfAllData;
            });
          }else{
            this._EmployeesService.getLoansByEmployeeId(this.loggedInUser.id).subscribe(data => {
              this.listOfAllData = data;
              this.listOfDisplayData = this.listOfAllData;
            });
          }
      }
      else if(responseObj.message == 'failed')
      {
        alert('Faild');
      }
      else
      {
        alert(JSON.stringify(response));
      }
    }
  );

  this.isOkLoading = false;
  this.visibleDelete = false;
  }

}
