import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgZorroAntdModule, NzSliderModule } from 'ng-zorro-antd';
 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzInputModule } from 'ng-zorro-antd/input';
import { ReactiveFormsModule } from '@angular/forms';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NgApexchartsModule } from "ng-apexcharts";
import { NzListModule } from 'ng-zorro-antd/list';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';

import { TopBarComponent } from './components/global-components/top-bar/top-bar.component';
import { CheckInTimerComponent } from './components/global-components/check-in-timer/check-in-timer.component';
import { SlackSidebarComponent } from './components/global-components/slack-sidebar/slack-sidebar.component';
import { NotificationSidebarComponent } from './components/global-components/notification-sidebar/notification-sidebar.component';

import { PublicComponent } from './layouts/public/public.component';
import { PrivateComponent } from './layouts/private/private.component';
import { DonutcharGapComponent } from './components/global-components/donutchar-gap/donutchar-gap.component';
import { BarChartComponent } from './components/global-components/bar-chart/bar-chart.component';
import { DoubleBarChartComponent } from './components/global-components/double-bar-chart/double-bar-chart.component';
import { AreaChartComponent } from './components/global-components/area-chart/area-chart.component';
import { DoubleBarChartSmallComponent } from './components/global-components/double-bar-chart-small/double-bar-chart-small.component';
import { HorizontalDoubleBarChartComponent } from './components/global-components/horizontal-double-bar-chart/horizontal-double-bar-chart.component';
import { LineChartComponent } from './components/global-components/line-chart/line-chart.component';
import { RadarChartComponent } from './components/global-components/radar-chart/radar-chart.component';
import { SortLabelComponent } from './components/global-components/sort-label/sort-label.component';

import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordEmailComponent } from './components/login/forgot-password-email/forgot-password-email.component';
import { SetForgotPasswordComponent } from './components/login/set-forgot-password/set-forgot-password.component';

import { SlackComponent } from './components/slack/slack.component';
import { SlackChannelComponent } from './components/slack/slack-channel/slack-channel.component';


// HRMS
import { DashboardComponent } from './components/HRMS/dashboard/dashboard.component';
import { EmployeeAwardsComponent } from './components/HRMS/employee-awards/employee-awards.component';
import { AddAwardsComponent } from './components/HRMS/employee-awards/add-awards/add-awards/add-awards.component';
import { MakePaymentComponent } from './components/HRMS/make-payment/make-payment.component';
import { CreatePaymentComponent } from './components/HRMS/make-payment/create-payment/create-payment/create-payment.component';
import { MakePaymentSlipComponent } from './components/HRMS/make-payment/make-payment-slip/make-payment-slip.component';
import { GeneratePayslipComponent } from './components/HRMS/generate-payslip/generate-payslip.component';
import { TrainingGoalsComponent } from './components/training-goals/training-goals.component';
import { ViewPayslipComponent } from './components/HRMS/generate-payslip/view-payslip/view-payslip.component';
import { ViewPaymentSlipComponent } from './components/HRMS/make-payment/view-payment-slip/view-payment-slip.component';
import { NotificationsComponent } from './components/HRMS/notifications/notifications.component';
// import { HrmsDashboardComponent } from './components/hrms/hrms-dashboard/hrms-dashboard.component';

// Jobs
import { JobsComponent } from './components/HRMS/jobs/jobs.component';
import { AddJobComponent } from './components/HRMS/jobs/add-job/add-job/add-job.component';
import { JobDashboardComponent } from './components/HRMS/jobs/job-dashboard/job-dashboard.component';
import { EditJobComponent } from './components/HRMS/jobs/edit-job/edit-job.component';
import { JobsGridComponent } from './components/HRMS/jobs/jobs-grid/jobs-grid.component';
import { ApplicantsComponent } from './components/HRMS/jobs/applicants/applicants.component';

// Projects
import { ProjectsComponent } from './components/projects/projects.component';
import { AddProjectComponent } from './components/projects/add-project/add-project/add-project.component';
import { EditProjectComponent } from './components/projects/edit-project/edit-project/edit-project.component';
import { SingleProjectComponent } from './components/projects/single-project/single-project.component';
import { AddTaskComponent } from './components/projects/single-project/add-task/add-task/add-task.component';
import { SingleTaskComponent } from './components/projects/single-task/single-task.component';
import { EditTaskComponent } from './components/projects/single-task/edit-task/edit-task.component';
import { ProjectDashboardComponent } from './components/projects/project-dashboard/project-dashboard.component';
// HRMS Modules
import { EmployeesComponent } from './components/HRMS/employees/employees.component';
// import { DepartmentsComponent } from './components/settings/departments/departments.component';
// import { DesignationsComponent } from './components/settings/designations/designations.component';
// import { JobsComponent } from './components/HRMS/jobs/jobs.component';
// import { AddJobComponent } from './components/HRMS/jobs/add-job/add-job/add-job.component';
import { OnboardingComponent } from './components/HRMS/employees/onboarding/onboarding.component';
import { AddOnboardingComponent } from './components/HRMS/employees/onboarding/add-onboarding/add-onboarding/add-onboarding.component';
import { AttendanceComponent } from './components/HRMS/attendance/attendance.component';
import { AddAttendanceComponent } from './components/HRMS/attendance/add-attendance/add-attendance/add-attendance.component';
import { EditAttendanceComponent } from './components/HRMS/attendance/edit-attendance/edit-attendance/edit-attendance.component';
import { HolidayComponent } from './components/HRMS/holiday/holiday.component';
import { AddHolidayComponent } from './components/HRMS/holiday/add-holiday/add-holiday/add-holiday.component';
import { EditHolidayComponent } from './components/HRMS/holiday/edit-holiday/edit-holiday/edit-holiday.component';
import { NoticeBoardComponent } from './components/HRMS/notice-board/notice-board.component';
import { AddNoticeBoardComponent } from './components/HRMS/notice-board/add-notice-board/add-notice-board/add-notice-board.component';
import { EditNoticeBoardComponent } from './components/HRMS/notice-board/edit-notice-board/edit-notice-board/edit-notice-board.component';
// import { ProjectsComponent } from './components/projects/projects.component';
// import { AddProjectComponent } from './components/projects/add-project/add-project/add-project.component';
// import { EditProjectComponent } from './components/projects/edit-project/edit-project/edit-project.component';
// import { SupportTicketsComponent } from './components/support-tickets/support-tickets.component';
// import { SettingsComponent } from './components/settings/settings.component';
// import { GeneralComponent } from './components/settings/general/general.component';
// import { LeaveTypeComponent } from './components/settings/leave-type/leave-type.component';
// import { ExpenseTypeComponent } from './components/settings/expense-type/expense-type.component';
import { EmployeeLeaveComponent } from './components/HRMS/employee-leave/employee-leave.component';
import { AddLeaveComponent } from './components/HRMS/employee-leave/add-leave/add-leave/add-leave.component';
import { EditLeaveComponent } from './components/HRMS/employee-leave/edit-leave/edit-leave/edit-leave.component';
// import { EmployeeAwardsComponent } from './components/HRMS/employee-awards/employee-awards.component';
// import { AddAwardsComponent } from './components/HRMS/employee-awards/add-awards/add-awards/add-awards.component';
import { ExpensesComponent } from './components/HRMS/expenses/expenses.component';
import { AddExpensesComponent } from './components/HRMS/expenses/add-expenses/add-expenses/add-expenses.component';
import { EditExpensesComponent } from './components/HRMS/expenses/edit-expenses/edit-expenses/edit-expenses.component';
import { SalaryListComponent } from './components/HRMS/salary-list/salary-list.component';
import { EditSalaryComponent } from './components/HRMS/salary-list/edit-salary/edit-salary/edit-salary.component';
import { IncrementsComponent } from './components/HRMS/increments/increments.component';
import { EditIncrementsComponent } from './components/HRMS/increments/edit-increments/edit-increments/edit-increments.component';
import { BonusesComponent } from './components/HRMS/bonuses/bonuses.component';
import { EditBonusComponent } from './components/HRMS/bonuses/edit-bonus/edit-bonus/edit-bonus.component';
// import { MakePaymentComponent } from './components/HRMS/make-payment/make-payment.component';
// import { CreatePaymentComponent } from './components/HRMS/make-payment/create-payment/create-payment/create-payment.component';
// import { MakePaymentSlipComponent } from './components/HRMS/make-payment/make-payment-slip/make-payment-slip.component';
// import { GeneratePayslipComponent } from './components/HRMS/generate-payslip/generate-payslip.component';
import { ProvidentFundComponent } from './components/HRMS/provident-fund/provident-fund.component';
import { AddFundComponent } from './components/HRMS/provident-fund/add-fund/add-fund/add-fund.component';
import { EditFundComponent } from './components/HRMS/provident-fund/edit-fund/edit-fund/edit-fund.component';
import { LoanComponent } from './components/HRMS/loan/loan.component';
import { AddLoanComponent } from './components/HRMS/loan/add-loan/add-loan/add-loan.component';
import { EditLoanComponent } from './components/HRMS/loan/edit-loan/edit-loan/edit-loan.component';
import { IncrementLogsComponent } from './components/HRMS/increments/increment-logs/increment-logs.component';
import { BonusLogsComponent } from './components/HRMS/bonuses/bonus-logs/bonus-logs.component';
// import { TrainingGoalsComponent } from './components/training-goals/training-goals.component';
import { TimesheetComponent } from './components/HRMS/timesheet/timesheet.component';
import { AddTimesheetComponent } from './components/HRMS/timesheet/add-timesheet/add-timesheet/add-timesheet.component';
import { EditTimesheetComponent } from './components/HRMS/timesheet/edit-timesheet/edit-timesheet/edit-timesheet.component';
import { ProfileComponent } from './components/HRMS/employees/profile/profile.component';
// import { AwardComponent } from './components/settings/award/award.component';
// import { SingleProjectComponent } from './components/projects/single-project/single-project.component';
// import { AddTaskComponent } from './components/projects/single-project/add-task/add-task/add-task.component';
import { AddTaskByTimesheetComponent } from './components/HRMS/timesheet/add-task-by-timesheet/add-task-by-timesheet.component';
// import { SingleTaskComponent } from './components/projects/single-task/single-task.component';
// import { EditTaskComponent } from './components/projects/single-task/edit-task/edit-task.component';
// import { StoreComponent } from './components/store/store.component';
// import { StoreDashboardSidebarComponent } from './components/store/store-dashboard-sidebar/store-dashboard-sidebar.component';
// import { DetailComponent } from './components/store/detail/detail.component';
// import { ConfigureComponent } from './components/store/configure/configure.component';
import { NewEmployeeComponent } from './components/HRMS/employees/add-employee/new-employee/new-employee.component';
import { EmployeeRolesComponent } from './components/HRMS/employees/employee-roles/employee-roles.component';
import { AddRoleComponent } from './components/HRMS/employees/employee-roles/add-role/add-role/add-role.component';
import { EditRoleComponent } from './components/HRMS/employees/employee-roles/edit-role/edit-role/edit-role.component';
import { SetPermissionsComponent } from './components/HRMS/employees/employee-roles/set-permissions/set-permissions.component';
// import { UserMappingComponent } from './components/store/configure/user-mapping/user-mapping.component';
// import { ItemComponent } from './components/inventory/item/item.component';
// import { ItemGroupComponent } from './components/inventory/item-group/item-group.component';
// import { CompositeItemComponent } from './components/inventory/composite-item/composite-item.component';
// import { InvAdjustmentsComponent } from './components/inventory/inv-adjustments/inv-adjustments.component';
// import { CustomerComponent } from './components/inventory/customer/customer.component';
// import { RetainerInvoicesComponent } from './components/inventory/retainer-invoices/retainer-invoices.component';
// import { SalesOrderComponent } from './components/inventory/sales-order/sales-order.component';
// import { ProjectDashboardComponent } from './components/projects/project-dashboard/project-dashboard.component';
// import { CrmDashboardComponent } from './components/CRM/crm-dashboard/crm-dashboard.component';
// // import { FinanceDashboardComponent } from './components/accounts/finance-dashboard/finance-dashboard.component';
// import { FileManagerDashboardComponent } from './components/document-manager/file-manager-dashboard/file-manager-dashboard.component';
// import { InventoryDashboardComponent } from './components/inventory/inventory-dashboard/inventory-dashboard.component';
// import { InvoicesComponent } from './components/inventory/invoices/invoices.component';
// import { PaymentReceivedComponent } from './components/inventory/payment-received/payment-received.component';
// import { SalesReturnsComponent } from './components/inventory/sales-returns/sales-returns.component';
// import { CreditNotesComponent } from './components/inventory/credit-notes/credit-notes.component';
// import { VendorComponent } from './components/inventory/vendor/vendor.component';
// import { CreditNoteViewComponent } from './components/inventory/credit-notes/credit-note-view/credit-note-view.component';
// import { PaymentReceivedViewComponent } from './components/inventory/payment-received/payment-received-view/payment-received-view.component';
// import { RetainerInvoiceViewComponent } from './components/inventory/retainer-invoices/retainer-invoice-view/retainer-invoice-view.component';
// import { PurchaseOrderComponent } from './components/inventory/purchase-order/purchase-order.component';
// import { BillsComponent } from './components/inventory/bills/bills.component';
// import { PaymentMadeComponent } from './components/inventory/payment-made/payment-made.component';
// import { PaymentMadeViewComponent } from './components/inventory/payment-made/payment-made-view/payment-made-view.component';
// import { VendorCreditsComponent } from './components/inventory/vendor-credits/vendor-credits.component';
// import { VendorCreditsViewComponent } from './components/inventory/vendor-credits/vendor-credits-view/vendor-credits-view.component';
// import { MannualJournalComponent } from './components/accounts/mannual-journal/mannual-journal.component';
// import { ChartOfAccountComponent } from './components/accounts/chart-of-account/chart-of-account.component';
// import { BudgetsComponent } from './components/accounts/budgets/budgets.component';
// import { AddBudgetComponent } from './components/accounts/budgets/add-budget/add-budget.component';
// import { ViewBudgetComponent } from './components/accounts/budgets/view-budget/view-budget.component';
// import { FilesListComponent } from './components/document-manager/files-list/files-list.component';
// import { FilesListViewComponent } from './components/document-manager/files-list/files-list-view/files-list-view.component';
// import { TagsComponent } from './components/document-manager/tags/tags.component';
// import { CustomFieldsComponent } from './components/document-manager/custom-fields/custom-fields.component';
// import { FileTypeComponent } from './components/document-manager/file-type/file-type.component';
// import { DocumentDetailsComponent } from './components/document-manager/document-details/document-details.component';
// import { FilesSettingComponent } from './components/document-manager/files-setting/files-setting.component';

// import { PackagesComponent } from './components/inventory/packages/packages.component';
// import { LeadsComponent } from './components/CRM/leads/leads.component';
// import { ContactsComponent } from './components/CRM/contacts/contacts.component';
// import { AccountsComponent } from './components/CRM/accounts/accounts.component';
// import { DealsComponent } from './components/CRM/deals/deals.component';
// import { SalesOrderViewComponent } from './components/inventory/sales-order/sales-order-view/sales-order-view.component';
// import { PurchaseOrderViewComponent } from './components/inventory/purchase-order/purchase-order-view/purchase-order-view.component';
// import { BillViewComponent } from './components/inventory/bills/bill-view/bill-view.component';
// import { InvoiceViewComponent } from './components/inventory/invoices/invoice-view/invoice-view.component';
// import { CustomerViewComponent } from './components/inventory/customer/customer-view/customer-view.component';
// import { SalesReportsComponent } from './components/reports/sales-reports/sales-reports.component';
// import { InventoryReportsComponent } from './components/reports/inventory-reports/inventory-reports.component';
// import { ReceivablesReportsComponent } from './components/reports/receivables-reports/receivables-reports.component';
// import { PaymentReceivedReportComponent } from './components/reports/payment-received-report/payment-received-report.component';
// import { PayablesReportsComponent } from './components/reports/payables-reports/payables-reports.component';
// import { PurchasesExpensesReportsComponent } from './components/reports/purchases-expenses-reports/purchases-expenses-reports.component';
// import { InventorySummaryComponent } from './components/reports/inventory-reports/inventory-summary/inventory-summary.component';
// import { ProjectReportsComponent } from './components/reports/project-reports/project-reports.component';
// import { ProjectDetailsReportsComponent } from './components/reports/project-reports/project-details-reports/project-details-reports.component';
// import { SalesByCustomerComponent } from './components/reports/sales-reports/sales-by-customer/sales-by-customer.component';
// import { TaxModuleComponent } from './components/tax-module/tax-module.component';
// import { TaxDashboardSidebarComponent } from './components/tax-module/tax-dashboard-sidebar/tax-dashboard-sidebar.component';
// import { TaxViewComponent } from './components/tax-module/tax-view/tax-view.component';
import { OnBoardingComponent } from './components/on-boarding/on-boarding.component';
// import { CustomerAnalysisComponent } from './components/reports/customer-analysis/customer-analysis.component';
// import { InventoryAnalysisComponent } from './components/reports/inventory-analysis/inventory-analysis.component';
// import { InvAdjustmentsViewComponent } from './components/inventory/inv-adjustments/inv-adjustments-view/inv-adjustments-view.component';
// import { PriceListComponent } from './components/inventory/price-list/price-list.component';
// import { SalesReturnsViewComponent } from './components/inventory/sales-returns/sales-returns-view/sales-returns-view.component';
// import { VendorViewComponent } from './components/inventory/vendor/vendor-view/vendor-view.component';
// import { ItemViewComponent } from './components/inventory/item/item-view/item-view.component';
// import { ItemGroupViewComponent } from './components/inventory/item-group/item-group-view/item-group-view.component';
// import { PackageViewComponent } from './components/inventory/packages/package-view/package-view.component';
// import { CompositeItemViewComponent } from './components/inventory/composite-item/composite-item-view/composite-item-view.component';
// import { EmailTemplateComponent } from './components/email-template/email-template.component';
// import { ManualJournalViewComponent } from './components/accounts/mannual-journal/manual-journal-view/manual-journal-view.component';
// import { RecurringJournalsComponent } from './components/accounts/recurring-journals/recurring-journals.component';
// import { RecurringJournalsViewComponent } from './components/accounts/recurring-journals/recurring-journals-view/recurring-journals-view.component';
// import { LeadViewComponent } from './components/CRM/leads/lead-view/lead-view.component';
// import { AccountViewComponent } from './components/CRM/accounts/account-view/account-view.component';
// import { DealsViewComponent } from './components/CRM/deals/deals-view/deals-view.component';
// import { ContactsViewComponent } from './components/CRM/contacts/contacts-view/contacts-view.component';

// Support Tickets
import { SupportTicketsComponent } from './components/support-tickets/support-tickets.component';
import { AddSupportTicketComponent } from './components/support-tickets/add-support-ticket/add-support-ticket.component';
import { ViewSupportTicketComponent } from './components/support-tickets/view-support-ticket/view-support-ticket.component';
import { DepartmentComponent } from './components/support-tickets/department/department/department.component';
import { AddDepartmentComponent } from './components/support-tickets/department/add-department/add-department.component';
import { EditDepartmentComponent } from './components/support-tickets/department/edit-department/edit-department.component';

// Tax
import { TaxModuleComponent } from './components/tax-module/tax-module.component';
import { TaxDashboardSidebarComponent } from './components/tax-module/tax-dashboard-sidebar/tax-dashboard-sidebar.component';
import { TaxViewComponent } from './components/tax-module/tax-view/tax-view.component';
import { TaxRuleComponent } from './components//tax-module/tax-rule/tax-rule.component';
import { AddTaxRuleComponent } from './components//tax-module/tax-rule/add-tax-rule/add-tax-rule.component';
import { SetRuleComponent } from './components//tax-module/tax-rule/set-rule/set-rule.component';
import { EditTaxRuleComponent } from './components//tax-module/tax-rule/edit-tax-rule/edit-tax-rule.component';
import { FinanceDashboardSidebarComponent } from './components/accounts/finance-dashboard/finance-dashboard-sidebar/finance-dashboard-sidebar.component';

// Store
import { StoreComponent } from './components/store/store.component';
import { StoreDashboardSidebarComponent } from './components/store/store-dashboard-sidebar/store-dashboard-sidebar.component';
import { DetailComponent } from './components/store/detail/detail.component';
import { ConfigureComponent } from './components/store/configure/configure.component';
import { UserMappingComponent } from './components/store/configure/user-mapping/user-mapping.component';

// Organization
import { OrganizationHierarchyComponent } from './components/organization-hierarchy/organization-hierarchy.component';
import { AddOrganizationHierarchyComponent } from './components/organization-hierarchy/add-organization-hierarchy/add-organization-hierarchy.component';
import { EditOrganizationHierarchyComponent } from './components/organization-hierarchy/edit-organization-hierarchy/edit-organization-hierarchy.component'; 

// Settings
import { DepartmentsComponent } from './components/settings/departments/departments.component';
import { DesignationsComponent } from './components/settings/designations/designations.component';
import { SettingsComponent } from './components/settings/settings.component';
import { GeneralComponent } from './components/settings/general/general.component';
import { LeaveTypeComponent } from './components/settings/leave-type/leave-type.component';
import { ExpenseTypeComponent } from './components/settings/expense-type/expense-type.component';
import { AwardComponent } from './components/settings/award/award.component';
import { IntegrationsListComponent } from './components/settings/integrations-list/integrations-list.component';
import { CurrencyComponent } from './components/settings/currency/currency.component';
import { AccountTypesComponent } from './components/settings/account-types/account-types.component';
// import {InvoiceGeneratorComponent} from './components/inventory/invoices/invoice-generator/invoice-generator.component';
// import { AddAccountComponent } from './components/CRM/accounts/add-account/add-account.component';
// import { AddContactComponent } from './components/CRM/contacts/add-contact/add-contact.component';
 
// import { AddLeadComponent } from './components/CRM/leads/add-lead/add-lead.component';
 
// import { AddDealComponent } from './components/CRM/deals/add-deal/add-deal.component';
// import { EditLeadComponent } from './components/CRM/leads/edit-lead/edit-lead.component';
// import { EditContactComponent } from './components/CRM/contacts/edit-contact/edit-contact.component';
// import { EditAccountComponent } from './components/CRM/accounts/edit-account/edit-account.component';
// import { EditDealComponent } from './components/CRM/deals/edit-deal/edit-deal.component';
// import { AddItemComponent } from './components/inventory/item/add-item/add-item.component';
// import { EditItemComponent } from './components/inventory/item/edit-item/edit-item.component';
// import { AddItemGroupComponent } from './components/inventory/item-group/add-item-group/add-item-group.component';
// import { EditItemGroupComponent } from './components/inventory/item-group/edit-item-group/edit-item-group.component';
// import { EditCompositeItemComponent } from './components/inventory/composite-item/edit-composite-item/edit-composite-item.component';
// import { AddCompositeItemComponent } from './components/inventory/composite-item/add-composite-item/add-composite-item.component';
// import { AddPriceComponent } from './components/inventory/price-list/add-price/add-price.component';
// import { EditPriceComponent } from './components/inventory/price-list/edit-price/edit-price.component';
// import { TaxRuleComponent } from './components//tax-module/tax-rule/tax-rule.component';
// import { AddTaxRuleComponent } from './components//tax-module/tax-rule/add-tax-rule/add-tax-rule.component';
// import { SetRuleComponent } from './components//tax-module/tax-rule/set-rule/set-rule.component';
// import { EditTaxRuleComponent } from './components//tax-module/tax-rule/edit-tax-rule/edit-tax-rule.component';
// import { AddInvAdjustmentComponent } from './components/inventory/inv-adjustments/add-inv-adjustment/add-inv-adjustment.component';
// import { EditInvAdjustmentComponent } from './components/inventory/inv-adjustments/edit-inv-adjustment/edit-inv-adjustment.component';
// import { EditJobComponent } from './components/HRMS/jobs/edit-job/edit-job.component';
// import { AddSupportTicketComponent } from './components/support-tickets/add-support-ticket/add-support-ticket.component';
// import { ViewSupportTicketComponent } from './components/support-tickets/view-support-ticket/view-support-ticket.component';
// import { ViewPayslipComponent } from './components/HRMS/generate-payslip/view-payslip/view-payslip.component';
// import { ViewPaymentSlipComponent } from './components/HRMS/make-payment/view-payment-slip/view-payment-slip.component';
// import { AddCustomerComponent } from './components/inventory/customer/add-customer/add-customer.component';
// import { EditCustomerComponent } from './components/inventory/customer/edit-customer/edit-customer.component';
// import { AddRetainerInvoicesComponent } from './components/inventory/retainer-invoices/add-retainer-invoices/add-retainer-invoices.component';
// import { EditRetainerInvoicesComponent } from './components/inventory/retainer-invoices/edit-retainer-invoices/edit-retainer-invoices.component';
// import { AddSalesOrderComponent } from './components/inventory/sales-order/add-sales-order/add-sales-order.component';
// import { EditSalesOrderComponent } from './components/inventory/sales-order/edit-sales-order/edit-sales-order.component';
// import { AddPaymentsReceivedComponent } from './components/inventory/payment-received/add-payments-received/add-payments-received.component';
// import { EditPaymentReceivedComponent } from './components/inventory/payment-received/edit-payment-received/edit-payment-received.component';
// import { AddVendorComponent } from './components/inventory/vendor/add-vendor/add-vendor.component';
// import { EditVendorComponent } from './components/inventory/vendor/edit-vendor/edit-vendor.component';
// import { AddPurchaseOrderComponent } from './components/inventory/purchase-order/add-purchase-order/add-purchase-order.component';
// import { EditPurchaseOrderComponent } from './components/inventory/purchase-order/edit-purchase-order/edit-purchase-order.component';
// import { AddBillComponent } from './components/inventory/bills/add-bill/add-bill.component';
// import { EditBillComponent } from './components/inventory/bills/edit-bill/edit-bill.component';
// import { EditPaymentMadeComponent } from './components/inventory/payment-made/edit-payment-made/edit-payment-made.component';
// import { AddPaymentMadeComponent } from './components/inventory/payment-made/add-payment-made/add-payment-made.component';
// import { AddVendorCreditsComponent } from './components/inventory/vendor-credits/add-vendor-credits/add-vendor-credits.component';
// import { EditVendorCreditsComponent } from './components/inventory/vendor-credits/edit-vendor-credits/edit-vendor-credits.component';
// import { AddCreditNotesComponent } from './components/inventory/credit-notes/add-credit-notes/add-credit-notes.component';
// import { EditCreditNotesComponent } from './components/inventory/credit-notes/edit-credit-notes/edit-credit-notes.component';
// import { ViewPriceListComponent } from './components/inventory/price-list/view-price-list/view-price-list.component';
// import { JobsGridComponent } from './components/HRMS/jobs/jobs-grid/jobs-grid.component';
// import { ApplicantsComponent } from './components/HRMS/jobs/applicants/applicants.component';
// import { DepartmentComponent } from './components/support-tickets/department/department/department.component';
// import { AddDepartmentComponent } from './components/support-tickets/department/add-department/add-department.component';
// import { EditDepartmentComponent } from './components/support-tickets/department/edit-department/edit-department.component';
// import { OrganizationHierarchyComponent } from './components/organization-hierarchy/organization-hierarchy.component';
// import { AddOrganizationHierarchyComponent } from './components/organization-hierarchy/add-organization-hierarchy/add-organization-hierarchy.component';
// import { EditOrganizationHierarchyComponent } from './components/organization-hierarchy/edit-organization-hierarchy/edit-organization-hierarchy.component';
import { ConfirmTimesheetComponent } from './components/HRMS/timesheet/confirm-timesheet/confirm-timesheet.component';
 
import { TimesheetSettingsComponent } from './components/settings/timesheet-settings/timesheet-settings.component';
import { OrganizationComponent } from './components/organization-hierarchy/organization/organization.component';
import { AllTasksComponent } from './components/HRMS/timesheet/all-tasks/all-tasks.component';
 
 
// import { NotificationsComponent } from './components/HRMS/notifications/notifications.component';
 
 

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    CheckInTimerComponent,
    SlackSidebarComponent,
    NotificationSidebarComponent,
      PublicComponent,
      PrivateComponent,
      DonutcharGapComponent,
      BarChartComponent,
      DoubleBarChartComponent,
      AreaChartComponent,
      DoubleBarChartSmallComponent,
      HorizontalDoubleBarChartComponent,
      LineChartComponent,
      RadarChartComponent,
      SortLabelComponent,
      
      LoginComponent,
      ForgotPasswordEmailComponent,
      SetForgotPasswordComponent,
      
      SlackComponent,
      SlackChannelComponent,

      // Timesheet
      TimesheetComponent,
      AddTimesheetComponent,
      EditTimesheetComponent,
      AddTaskByTimesheetComponent,
      ConfirmTimesheetComponent,

      // HRMS
      DashboardComponent,
      EmployeesComponent,
      OnboardingComponent,
      AddOnboardingComponent,
      AttendanceComponent,
      AddAttendanceComponent,
      EditAttendanceComponent,
      HolidayComponent,
      AddHolidayComponent,
      EditHolidayComponent,
      NoticeBoardComponent,
      AddNoticeBoardComponent,
      EditNoticeBoardComponent,
      EmployeeLeaveComponent,
      AddLeaveComponent,
      EditLeaveComponent,
      EmployeeAwardsComponent,
      AddAwardsComponent,
      ExpensesComponent,
      AddExpensesComponent,
      EditExpensesComponent,
      SalaryListComponent,
      EditSalaryComponent,
      IncrementsComponent,
      EditIncrementsComponent,
      BonusesComponent,
      EditBonusComponent,
      MakePaymentComponent,
      CreatePaymentComponent,
      MakePaymentSlipComponent,
      GeneratePayslipComponent,
      ProvidentFundComponent,
      AddFundComponent,
      EditFundComponent,
      LoanComponent,
      AddLoanComponent,
      EditLoanComponent,
      IncrementLogsComponent,
      BonusLogsComponent,
      TrainingGoalsComponent,
      ProfileComponent,
      NewEmployeeComponent,
      EmployeeRolesComponent,
      AddRoleComponent,
      EditRoleComponent,
      SetPermissionsComponent,
      ViewPayslipComponent,
      ViewPaymentSlipComponent,
      NotificationsComponent,

      // Jobs
      JobsComponent,
      AddJobComponent,
      JobDashboardComponent,
      EditJobComponent,
      JobsGridComponent,
      ApplicantsComponent,

      // Projects
      ProjectsComponent,
      AddProjectComponent,
      EditProjectComponent,
      SingleProjectComponent,
      AddTaskComponent,
      SingleTaskComponent,
      EditTaskComponent,
      ProjectDashboardComponent,

      // Support Tickets
      SupportTicketsComponent,
      AddSupportTicketComponent,
      ViewSupportTicketComponent,
      DepartmentComponent,
      AddDepartmentComponent,
      EditDepartmentComponent,

      // Tax
      TaxModuleComponent,
      TaxDashboardSidebarComponent,
      TaxViewComponent,
      TaxRuleComponent,
      AddTaxRuleComponent,
      SetRuleComponent,
      EditTaxRuleComponent,
      FinanceDashboardSidebarComponent,

      // Store
      StoreComponent,
      StoreDashboardSidebarComponent,
      DetailComponent,
      ConfigureComponent,
      UserMappingComponent,
//     GeneralComponent,
//     TaxDashboardSidebarComponent, 
//     SettingsComponent,
//     DesignationsComponent,
//     DepartmentsComponent,
//     LeaveTypeComponent,
//     AwardComponent,
    
//     // Hrms Module,
//             //HRMS Dashboard
//           //HRMS Employee
    NewEmployeeComponent,//
    EmployeeRolesComponent,//
    AddRoleComponent,//
    EditRoleComponent,//
    SetPermissionsComponent,//
    OnboardingComponent,
    AddOnboardingComponent,//
    ProfileComponent,//
    EmployeesComponent,//
//           //HRMS Employee
//           //HRMS Attendance
    AttendanceComponent,//
    AddAttendanceComponent,//
    EditAttendanceComponent,//       
//           //HRMS Attendance
//           //HRMS Job
//     JobsComponent,//
//     AddJobComponent,//
//     ApplicantsComponent,//
//     EditJobComponent,//
//     JobDashboardComponent,//
//     JobsGridComponent,//
//           //HRMS Job
//           //HRMS Holiday
    HolidayComponent,//
    AddHolidayComponent,//
    EditHolidayComponent,//
//           //HRMS Holiday
//           //HRMS NoticeBoard
    NoticeBoardComponent,//
    AddNoticeBoardComponent,//
    EditNoticeBoardComponent,//
//           //HRMS NoticeBoard
//           //HRMS Support Ticket
//     SupportTicketsComponent,//
//     AddSupportTicketComponent,//
//     DepartmentComponent,//
//     AddDepartmentComponent,//
//     EditDepartmentComponent,//
//     ViewSupportTicketComponent,//      
//           //HRMS Support Ticket
//           //HRMS Leave
    EmployeeLeaveComponent,//
    AddLeaveComponent,//
    EditLeaveComponent,//
//           //HRMS Leave
//           //HRMS Award
//     EmployeeAwardsComponent,
//     AddAwardsComponent, 
//           //HRMS Award   
//           //HRMS Expence
    ExpensesComponent,//
    AddExpensesComponent,//
    EditExpensesComponent,//
//           //HRMS Expence
//           //HRMS Salary
    SalaryListComponent,//
    EditSalaryComponent,//
//           //HRMS Salary
//           //HRMS Increment
    IncrementsComponent,//
    EditIncrementsComponent,//
    IncrementLogsComponent,//
//           //HRMS Increment
//           //HRMS Bonus
    BonusesComponent,//
    EditBonusComponent,//
    BonusLogsComponent,//
//           //HRMS Bonus
//           //HRMS Make Payment
//     MakePaymentComponent,//
//     CreatePaymentComponent,//
//     MakePaymentSlipComponent,//
//     ViewPaymentSlipComponent,//
//           //HRMS Make Payment
//           //HRMS Generate PaySlip
//     GeneratePayslipComponent,//
//     ViewPayslipComponent,//
//           //HRMS Generate PaySlip
//           //HRMS Providient Fund
    ProvidentFundComponent,//
    AddFundComponent,//
    EditFundComponent,//
//           //HRMS Providient Fund
//           //HRMS Loan
    LoanComponent,//
    AddLoanComponent,//
    EditLoanComponent,//
//           //HRMS Loan
//           // HRMS Timesheet
    TimesheetComponent,//
    AddTaskByTimesheetComponent,//
    AddTimesheetComponent,//
    EditTimesheetComponent,//
    ConfirmTimesheetComponent,//
//           // HRMS Timesheet
//           //HRMS Notification
//     NotificationsComponent,
//           //HRMS Notification
//           //HRMS Organization Hirarchy
//     OrganizationHierarchyComponent,//
//     AddOrganizationHierarchyComponent,//     
//     EditOrganizationHierarchyComponent,//
    // Hrms Module
    
//     SingleProjectComponent,
//     AddTaskComponent,
//     ExpenseTypeComponent,
//     ProjectsComponent,
//     AddProjectComponent,

      // Organization
      OrganizationHierarchyComponent,
      AddOrganizationHierarchyComponent,
      EditOrganizationHierarchyComponent,

      // Settings
      DepartmentsComponent,
      DesignationsComponent,
      SettingsComponent,
      GeneralComponent,
      LeaveTypeComponent,
      ExpenseTypeComponent,
      AwardComponent,
      IntegrationsListComponent,
      CurrencyComponent,
      AccountTypesComponent,
     
      TimesheetSettingsComponent,
     
      OrganizationComponent,
     
      AllTasksComponent,

  ],
  imports: [
      CommonModule,
    NgZorroAntdModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    NzAffixModule,
    NzGridModule,
    NzIconModule,
    NzSelectModule,
    NzCardModule,
    NzDividerModule,
    NzBadgeModule,
    NzFormModule,
    NzTableModule,
    NzProgressModule,
    NzInputModule,
    ReactiveFormsModule,
    NzTypographyModule,
    NgApexchartsModule,
    NzListModule,
    NzDropDownModule,
    NzTimelineModule,
    NzSliderModule,
    NgxMaterialTimepickerModule,    
     
  ],
  providers: [{ provide: NZ_I18N, useValue: en_US }, AuthGuardService, Title, AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
