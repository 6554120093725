import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from 'moment'
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-edit-bonus',
  templateUrl: './edit-bonus.component.html',
  styleUrls: ['./edit-bonus.component.scss']
})
export class EditBonusComponent implements OnInit {

  load = false;
  public errorMsg;

  listOfDisplayData;
  listOfAllData;
  //
  visibleEdit = false;
  selectedPaymentType = 'Monthly';
  constructor(private _EmployeesService: EmployeesService, private msg: NzMessageService,
    private router: Router,
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService) { }

  ngOnInit() {

    let id:any = this.route.snapshot.paramMap.get('id');
    this._EmployeesService.getBonus().subscribe(dt => {
      this.listOfAllData = dt;
      this.load = true;
      this.listOfDisplayData = this.listOfAllData;

      let data = this.listOfDisplayData.find(data => data.id == id);

      this.bonusEditForm.patchValue({
        id: id,
        payment_type : data.payment_type,
        basic_salary_bonus : data.basic_salary_bonus,
        overtime_salary_bonus : data.overtime_salary_bonus,
        working_hourly_bonus : data.working_hourly_bonus,
        overtime_hourly_bonus : data.overtime_hourly_bonus
      });
    });

}

  onPaymentTypeChange(): void{
    if(this.selectedPaymentType == 'Monthly')
    {

    }
    else
    {

    }
  }

  bonusEditForm = new FormGroup({
    id: new FormControl(),
    payment_type: new FormControl(null, Validators.required),
    basic_salary_bonus: new FormControl(null),
    overtime_salary_bonus: new FormControl(null),
    working_hourly_bonus: new FormControl(null),
    overtime_hourly_bonus: new FormControl(null),
  });


  openEdit(id): void {
    this.visibleEdit = true;

    let data = this.listOfAllData.find(data => data.id == id);

    this.selectedPaymentType = data.payment_type;

    this.bonusEditForm.patchValue({
      id: data.id,
      payment_type: data.payment_type,
      basic_salary_bonus: data.basic_salary_bonus,
      overtime_salary_bonus: data.overtime_salary_bonus,
      working_hourly_bonus: data.working_hourly_bonus,
      overtime_hourly_bonus: data.overtime_hourly_bonus
    });
  }
  closeEdit(): void {
    this.visibleEdit = false;
  }

  editBonus(): void {
    for (const i in this.bonusEditForm.controls) {
      this.bonusEditForm.controls[i].markAsDirty();
      this.bonusEditForm.controls[i].updateValueAndValidity();
    }
    if(this.bonusEditForm.valid)
    {
      this._EmployeesService.editBonus(this.bonusEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
              if(responseObj.message == 'already-exists'){
                this.notification.create(
                  'error',
                  'Edit Bonus',
                  'Already Exists'
                  );
              }else if(responseObj.message == 'failed'){
                this.notification.create(
                  'error',
                  'Edit Bonus',
                  'Unable to save changes'
                  );
              }else if(responseObj.message == 'success'){
              this.router.navigate(['/bonus'])
                this.notification.create(
                  'success',
                  'Edit Bonus',
                  'Successfully updated'
                  );
              }else{
                this.router.navigate(['/increment'])
                this.notification.create(
                  'success',
                  'Edit Bonus',
                  'Successfully updated'
                  );
              }
        }
      )
    }
  }

}
