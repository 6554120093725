import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-bonus-logs',
  templateUrl: './bonus-logs.component.html',
  styleUrls: ['./bonus-logs.component.scss']
})
export class BonusLogsComponent implements OnInit {
  selectedValue;
  public errorMsg;

  listOfAllData;
  load = false;
  constructor(private _EmployeesService: EmployeesService) { }

    ngOnInit() {
      this._EmployeesService.getLogs(1).subscribe(data => {
        this.listOfAllData = data;
        this.load = true;
      });
  }
  currentPageDataChange($event){}

}
