import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ActivatedRoute, Router } from '@angular/router';

import { ProjectsService } from 'src/app/services/projects.service';
import * as moment from 'moment';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { EmployeesService } from 'src/app/services/employees.service';
@Component({
  selector: 'app-edit-task',
  templateUrl: './edit-task.component.html',
  styleUrls: ['./edit-task.component.scss']
})
export class EditTaskComponent implements OnInit {

  visibleEditTask = false;
  selectedStatus;
  projectId;
  load = false;
  selectedEmployees = [];
   
  filteredTasks;
  filteredTimesheetByTaskId;
  total=6;
  taskId;
  errorMsg;
  msg;
  isOkLoading;
  project;
  listOfDisplayData;
    
  btnFileUpload = true;
  employedata: number[] | { x: any; y: any; fillColor?: string; strokeColor?: string; }[] | [number, number][] | [number, number[]][];
  viewFileUploader(): void{
    if(this.btnFileUpload == true)
      this.btnFileUpload = false;
    else
      this.btnFileUpload = true;
  }
  
  tab1 = true;
  activeTab1(){ this.tab1 = true; this.tab2 = false; this.tab3 = false; this.tab4 = false; }
  tab2 = false;
  activeTab2(){ this.tab1 = false; this.tab2 = true; this.tab3 = false; this.tab4 = false; }
  tab3 = false;
  activeTab3(){ this.tab1 = false; this.tab2 = false; this.tab3 = true; this.tab4 = false; }
  tab4 = false;
  activeTab4(){ this.tab1 = false; this.tab2 = false; this.tab3 = false; this.tab4 = true; }
  
  visibleCommentDelete = false;
  visibleFileDelete = false;
  commentToDeleteId = 0;
  fileToDeleteId = 0;
  comments: TrainingGoalComment[];
  files: TrainingGoalFile[];
  fileToUpload: File;
  validUpload = false;
  task;
  taskStatus:string;
  listOfAllEmployees;
  progress = 0;
  loggedInUser = this.storage.get('loggedInUser');
  constructor( private route: ActivatedRoute,
    private router: Router,
    public _EmployeesService: EmployeesService
    ,private _ProjectsService: ProjectsService
    , @Inject(LOCAL_STORAGE) private storage: StorageService
    ,private notification: NzNotificationService) { }


  ngOnInit() {

    this.task = {
      id: null,
      project_id: null,
      integration_id: null,
      ref_id: null,
      task: null,
      start_date: null,
      due_date: null,
      estimated_hour: null,
      description: null,
      progress: null,
      status: null,
      employee_ids: null
    };
    this.route.paramMap.subscribe(params => {
      this.taskId =this.route.snapshot.params.id;
    });

    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfAllEmployees = data;
    },
    error => console.log(error.message));    

    this._ProjectsService.getTask(this.taskId).subscribe(
      data => {
        
        this.load = true;
        this.task = data;
        this.selectedStatus = data.status;
        this.progress = parseInt(data.progress);
        console.log(this.task);
        // console.log(data.employee_ids);
        this.selectedEmployees = data.employee_ids;
        this.projectId = data.project_id;
        this.taskEditForm.patchValue({
          id: data.id,
          project_id: data.project_id,
          task: data.task,
          estimated_hour: data.estimated_hour,
          progress: data.progress,
          description: data.description,

          status: data.status
        });
        if(data.start_date !== null){
          this.taskEditForm.patchValue({
            start_date: new Date(data.start_date),
          });
        }
        if(data.due_date !== null){
          this.taskEditForm.patchValue({
            due_date: new Date(data.due_date),   
          });
        }
        this.filteredTimesheetByTaskId = this.listOfDisplayData.filter(data1=> data1.task_id == data.id);
      }
    );
    this._ProjectsService.getComments(this.taskId).subscribe(data => this.comments = data);
    this._ProjectsService.getFiles(this.taskId).subscribe(data => this.files = data);
    
    this.commentForm.patchValue({
      task_id: this.taskId,
      emp_id: this.loggedInUser.id
    });
    this.fileForm.patchValue({
      task_id: this.taskId,
      emp_id: this.loggedInUser.id
    });
    this.taskStatusForm.patchValue({
      id: this.taskId
    });
    this.taskStatus = this.task.status;


  }
  progresspercent;
progressError = false;
changeStatus(ev){
  this.progresspercent = ev ;
  console.log(this.progresspercent);
  if(this.progresspercent <= 0){
    this.progressError = false;
    this.taskEditForm.patchValue({
      status: "pending",
       
    });
     
  }else if(this.progresspercent > 0 && this.progresspercent < 99 ){
    this.progressError = false;
    this.taskEditForm.patchValue({
      status: "started",
       
    });
     
  }else if(this.progresspercent > 99 && this.progresspercent < 101){
    this.progressError = false;
  this.taskEditForm.patchValue({
    status: "completed",
     
  });
   
}else if(this.progresspercent > 100 ){
  this.progressError = true;
  this.taskEditForm.patchValue({
    status: " ",
     
  });
  
}else{
  this.progressError = true;
  this.taskEditForm.patchValue({
    status: "",
     
  });
  
}

}
  commentForm = new FormGroup({
    task_id: new FormControl(null),
    emp_id: new FormControl(null),
    comment: new FormControl(null, Validators.required)
  });
  fileForm = new FormGroup({
    task_id: new FormControl(null),
    emp_id: new FormControl(null),
    file_title: new FormControl(null, Validators.required)
  });
  updateTaskStatus(): void{
    if(this.taskStatusForm.valid){
      this._ProjectsService.updateTaskStatus(this.taskStatusForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success'){
            this.notification.create(
              'success',
              'Update Task',
              'Successfully Updated Task'
              );
          }else{
            this.notification.create(
              'error',
              'Update Task',
              'Cant Update Task, Somethings Wrong'
              );            
          }
        }
      );
    }
  }
  taskStatusForm = new FormGroup({
    id: new FormControl(null),
    status: new FormControl(null, Validators.required)
  });
  showDeleteCommentModal(id): void{
    this.visibleCommentDelete = true;
    this.commentToDeleteId = id;
  }
  closeDeleteCommentModal(): void{
    this.visibleCommentDelete = false;
  }
  showDeleteFileModal(id): void{
    this.visibleFileDelete = true;
    this.fileToDeleteId = id;
  }
  closeDeleteFileModal(): void{
    this.visibleFileDelete = false;
  }
  fileUpload(files: FileList): void{
    this.fileToUpload = files.item(0);
    var acceptedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.DOC|\.DOCX)$/i;
    if(!acceptedExtensions.exec(this.fileToUpload.name)){
      this.msg.error(`Supported Formats: .JPG, .JPEG, .PDF, .DOC, DOCX`);
      this.validUpload = false;
    }
    else{
      this.validUpload = true;
    }
  }
  addComment(): void {
    if(this.commentForm.valid){
      this._ProjectsService.createComment(this.commentForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'failed'){
            alert('Faild');
          }
          else if(responseObj.message == 'success'){
            this._ProjectsService.getComments(this.task.id).subscribe(data => this.comments = data,
              error => this.errorMsg = error);
              this.commentForm.patchValue({
                comment: null
              });
              this.commentForm.get('comment').markAsUntouched();
              this.commentForm.get('comment').markAsPristine();
              this.commentForm.get('comment').updateValueAndValidity();
          }
          else{
            alert(JSON.stringify(response));
          }
        }
      );
    }
  }
  deleteComment(id): void {
    this.isOkLoading = true;
    this._ProjectsService.deleteComment(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success'){
        this._ProjectsService.getComments(this.task.id).subscribe(data => this.comments = data,
          error => this.errorMsg = error);
          this.notification.create(
            'success',
            'Delete Comment',
            'Successfully Deleted Comment'
            );
      }
      else if(responseObj.message == 'failed'){
        this.notification.create(
          'error',
          'Delete Comment',
          'Successfully Deleted Comment'
          );        
      }
      else{
        this.notification.create(
          'error',
          'Delete Comment',
          'Somethings Wrong'
          );
      }
    }
  );
  this.isOkLoading = false;
  this.visibleCommentDelete = false;
  }
  addFile(): void {
    if(!this.validUpload){
      this.msg.error(`Please upload a valid file.`);
      return;
    }
    if(this.fileForm.valid){
      const formData: FormData = new FormData();
      formData.append('file_title', this.fileForm.value.file_title);
      formData.append('file_size', this.fileToUpload.size.toString());
      formData.append('task_id', this.fileForm.value.task_id);
      formData.append('emp_id', '17');      
      formData.append('file_to_upload', this.fileToUpload, this.fileToUpload.name);
      console.log('checkingEmpId',this.fileForm.value.emp_id);
      this._ProjectsService.createFile(formData).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Add File',
              'Cant Add File'
              );            
          }
          else if(responseObj.message == 'success'){
            this.fileForm.reset();
            this.btnFileUpload = true;
            this._ProjectsService.getFiles(this.task.id).subscribe(data => this.files = data,
              error => this.errorMsg = error);
              this.notification.create(
                'success',
                'Add File',
                'Successfully Added File'
                );
          }
          else{
            this.notification.create(
            'error',
            'Add File',
            'Somethings Wrong'
            );            
          }
        }
      )
    }
  }
  deleteFile(id): void {
    this.isOkLoading = true;
    this._ProjectsService.deleteFile(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success'){
        this._ProjectsService.getFiles(this.task.id).subscribe(data => this.files = data,
          error => this.errorMsg = error);
          this.notification.create(
            'success',
            'Delete File',
            'Successfully Deleted File'
            );
      }
      else if(responseObj.message == 'failed'){
        this.notification.create(
          'error',
          'Delete File',
          'Cant Delete File'
          );        
      }
      else{
        this.notification.create(
          'error',
          'Delete File',
          'Somethings Wrong'
          );        
      }
    }
  );
  this.isOkLoading = false;
  this.visibleFileDelete = false;
  }
  downloadFile(id): void{
    window.location.href = environment.stepApiUrl + "/api/task/download-file/" + id;
  }

  visibleDelete = false;
  timesheetToDeleteId = 0;
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.timesheetToDeleteId = id;
  }
  closeDeleteModal(): void{
    this.visibleDelete = false;
  }

  taskEditForm = new FormGroup({
    id: new FormControl(null),
    project_id: new FormControl(null),
    task: new FormControl(null, Validators.required),
    employee_ids: new FormControl(null, Validators.required),
    start_date: new FormControl(null, Validators.required),
    due_date: new FormControl(null),
    estimated_hour: new FormControl(null),
    progress: new FormControl(null),
    description: new FormControl(null),
    status: new FormControl(null, Validators.required)
  });

  openEditTaskDrawer(id): void {
    this.visibleEditTask = true;
    let data = this.task.find(data => data.id == id);
    this.selectedStatus = data.status;
    this.selectedEmployees = data.employee_ids;
    this.taskEditForm.patchValue({
      project_id: this.project.id
    });
    this.taskEditForm.patchValue({
      id: id,
      task: data.task,
      start_date: new Date(data.start_date),
      due_date: new Date(data.due_date),
      estimated_hour: data.estimated_hour,
      progress: data.progress,
      description: data.description,
    });
  }
  closeEditTaskDrawer(): void {
    this.visibleEditTask = false;
  }
  editTask(): void {
    console.log("editTask");
    if(this.taskEditForm.valid){
      this.taskEditForm.value.start_date = moment(this.taskEditForm.value.start_date).format('YYYY-MM-DD');
      this.taskEditForm.value.due_date = moment(this.taskEditForm.value.due_date).format('YYYY-MM-DD');
      this._ProjectsService.editTask(this.taskEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            this.notification.create(
              'error',
              'Edit Task',
              'Task Already Exists'
              );             
          }else if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Edit Task',
              'Cant Edit Task'
              );             
          }else if(responseObj.message == 'success'){            
            this.router.navigate(['/projects', this.projectId])
            this.notification.create(
              'success',
              'Edit Task',
              'Successfully Edited Task'
              ); 
          }else{
            this.notification.create(
              'success',
              'Add Task',
              'Successfully Added Task'
              );     

            this.router.navigate(['/projects', this.projectId])
          }
        }
      );
    }
  }

}
