import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { JobsService } from 'src/app/services/jobs.service';

@Component({
  selector: 'app-jobs-grid',
  templateUrl: './jobs-grid.component.html',
  styleUrls: ['./jobs-grid.component.scss']
})
export class JobsGridComponent implements OnInit {

  listOfJobsData;
  jobsLoading = true;
  jobs;
  dateFormat = 'MMM dd, yyyy';

  drawerVisible = false;
  
  fileToUpload: File;
  validUpload = false;
  loggedInUser = this.storage.get('loggedInUser');
  
  loading = true;
  
  jobForm = this.fb.group({
    company_id: new FormControl(null),
    job_title: new FormControl(null),
    industry: new FormControl(null),
    no_position: new FormControl(null),
    job_location: new FormControl(null),
    tax_terms: new FormControl(null),
    visa_status: new FormControl(null),
    contract_duration: new FormControl(null),
    total_experience: new FormControl(null),
    related_experience: new FormControl(null),
    remote_job: new FormControl(null),
    job_type: new FormControl(null),
    job_level: new FormControl(null),
    skills: new FormControl(null),
    additional_skills: new FormControl(null),
    age: new FormControl(null),
    min_salary: new FormControl(null),
    max_salary: new FormControl(null),
    description: new FormControl(null),
    post_date: new FormControl(null),
    apply_date: new FormControl(null),
    close_date: new FormControl(null),
    status: new FormControl(null),
    reponsibilities: new FormControl(null)
  });

  
  constructor(private _jobsService: JobsService, 
    @Inject(LOCAL_STORAGE) private storage: StorageService, private fb: FormBuilder) { }
  public errorMsg;

  ngOnInit(): void {
    this._jobsService.getJobs().subscribe(
      data=> { 
        this.listOfJobsData = data;
        this.jobsLoading = false;
      },
      error => this.errorMsg = error);
      
  }

  visible = false;
  open(): void {
    this.visible = true;
  }
  close(): void {
    this.visible = false;
  }  

  removeAttachment(tag, element){
    this.fileToUpload = null;
    element.value = "";
  }

  //Add Job Below.
  openAddJobDrawer(): void {
    this.jobForm.reset();
    this.drawerVisible = true;
  }

  closeDrawer(): void {
    this.drawerVisible = false;
  }

  onUpload(files: FileList): void{
    this.validUpload = false;
    if(files.length > 0){
      this.fileToUpload = files.item(0);

      var acceptedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.docx|\.doc)$/i;

      if(!acceptedExtensions.exec(this.fileToUpload.name)){
        // this.msg.error(`Supported Formats: .JPG, .JPEG, .PDF, .DOC, DOCX`);
        this.validUpload = false;
      }
      else{
        this.validUpload = true;
      }
    }
  }

  addJob(): void{
    for (const i in this.jobForm.controls) {
      this.jobForm.controls[i].markAsDirty();
      this.jobForm.controls[i].updateValueAndValidity();
    }
      this.jobForm.value.post_date    = moment(this.jobForm.value.post_date).format('YYYY-MM-DD');
      this.jobForm.value.apply_date   = moment(this.jobForm.value.post_date).format('YYYY-MM-DD');
      this.jobForm.value.close_date   = moment(this.jobForm.value.post_date).format('YYYY-MM-DD');

      if(this.jobForm.valid){
        
        this._jobsService.createJob(this.jobForm.value).subscribe(
          response => {
            let responseObj = JSON.parse(JSON.stringify(response));
            if(responseObj.message == 'already-exists'){
              alert('Already Exisits');
            }else if(responseObj.message == 'failed'){
              alert('Faild');
            }else if(responseObj.message == 'success'){
    
              this._jobsService.getJobs().subscribe(
                data=> { 
                  this.listOfJobsData = data;
                  this.jobsLoading = false;
                },
                error => this.errorMsg = error);          
 
              this.visible = false;
            }else{
              alert(JSON.stringify(response));
            }
          }
        )
      }
    }
}
