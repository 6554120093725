import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from "@angular/router";


@Component({
  selector: 'app-tax-rule',
  templateUrl: './tax-rule.component.html',
  styleUrls: ['./tax-rule.component.scss']
})
export class TaxRuleComponent implements OnInit {
  isOkLoading = false;
  selectedValue;
  loading = true;
  visibleDelete = false;
  taxId;
  listOfAllData;

  constructor(private _SettingsService: SettingsService,
    private notification: NzNotificationService) { }
  public errorMsg;
  ngOnInit(): void {
    this._SettingsService.getTaxes().subscribe(data=> this.listOfAllData = data,
      error => this.errorMsg = error);
  }
  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.taxId = id;
  }  
  deleteTax(id): void {
    this.isOkLoading = true;
    this._SettingsService.deleteTax(id).subscribe(
      response => {
        let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'success')
        {
          
          this._SettingsService.getTaxes().subscribe(data => this.listOfAllData = data,
            error => this.errorMsg = error);
            this.visibleDelete = false;

            this.notification.create(
              'success',
              'Tax Rule',
              'Deleted Successfully'
              );
        }
        else if(responseObj.message == 'failed')
        {
          this.notification.create(
            'error',
            'Tax Rule',
            'Unabled to save changes'
            );
        }
        else
        {
          this.notification.create(
            'success',
            'Tax Rule',
            'Deleted Successfully'
            );
        }
      }
    )
    this.isOkLoading = false;
  } 
  closeDeleteModal(): void{
    this.visibleDelete = false;
  }
}
