import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
//for catch:
import { catchError } from 'rxjs/operators';
//for throw:
import { Observable, throwError } from 'rxjs';

import {Router} from "@angular/router";
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = environment.stepApiUrl + '/api';
  user:any;
  perms:any;
  Id:any;  
  constructor(private http : HttpClient, 
    private router: Router, 
    @Inject(LOCAL_STORAGE) private storage: StorageService) {
      this.user = this.storage.get('loggedInUser');
  }
  isLoggedIn(): boolean {
    return this.user != null; 
    // && this.perms != null;
  }
  getClaims(token:any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json'
    })
    return this.http.get<any>( this.apiUrl + '/user' ,{ headers: headers })
    .pipe(catchError(this.erroHandler));
  }
  login(form:any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json'
    })
    return this.http.post<any>( this.apiUrl + '/login', JSON.stringify(form),{ headers: headers })
    .pipe(catchError(this.erroHandler));
  }
  forgot(form:any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Accept': 'application/json'
    })
    return this.http.post<any>( this.apiUrl + '/forgot-password', JSON.stringify(form),{ headers: headers })
    .pipe(catchError(this.erroHandler));
  }
  getPermissionsList() : Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.storage.get('auth-token').accessToken,
      'Access-Control-Allow-Origin': '*'
    })
    return this.http.get<any>( this.apiUrl + '/permissions-list' ,{ headers: headers })
    .pipe(catchError(this.erroHandler));
  }
  getPermissions(roleId: number) : Observable<any>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.storage.get('auth-token').accessToken,
      'Access-Control-Allow-Origin': '*'
    })
    return this.http.get<any>( this.apiUrl + '/get-permissions/'+roleId ,{ headers: headers })
    .pipe(catchError(this.erroHandler));
  }
  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }
}
