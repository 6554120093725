import { Component, Inject, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { SettingsService } from 'src/app/services/settings.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-provident-fund',
  templateUrl: './provident-fund.component.html',
  styleUrls: ['./provident-fund.component.scss']
})
export class ProvidentFundComponent implements OnInit {
  loggedInUser = this.storage.get('loggedInUser');
  selectedValue;
  public errorMsg;
  load = false;
  listOfDisplayData;
  listOfAllData;
  //
  departments;
  designations;
  listOfEmployeesData;
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  providentFundToDeleteId = 0;
  isOkLoading = false;

  providentFund: ProvidentFund[];

  selectedEmployee;
  selectedProvidentFundType;
  employeeShare;
  organizationShare;

  selectedEditProvidentFundType;
  employeeShareEdit;
  organizationShareEdit;

  employeeShareAmountEdit;
  organizationShareAmountEdit;
  listOfEmployees;

  constructor(
    private _EmployeesService: EmployeesService,
    private _SettingsService: SettingsService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private nzError: NzMessageService) { }

  ngOnInit() {
    this.selectedProvidentFundType = 'Fixed Amount';
    this.employeeShare = 0;
    this.organizationShare = 0;

    this._EmployeesService.getProvidentFund().subscribe(data => {
      this.load = true;
      this.listOfAllData = data;
      this.listOfDisplayData = this.listOfAllData;
      console.log(this.listOfAllData)
     
    });
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfEmployees = data;
     
    },
    error => console.log(error.message));
    this._SettingsService.getDepartments().subscribe(data => this.departments = data);
    this._SettingsService.getDesignations().subscribe(data => this.designations = data);
    this._EmployeesService.getEmployees().subscribe(data=>  this.listOfEmployeesData = data);
  }
  changeDepartment(ev): void{
    if(ev){
      this._SettingsService.getDesignationsByDepartmentId(ev).subscribe(
        data => { 
          this.designations = data;
        }
      );
      this._EmployeesService.getEmployeesByDepartmentId(ev).subscribe(data=> { 
        this.listOfEmployeesData = data;
      },
      error => console.log(error.message));
    }
  }
  changeDesignation(ev): void{
    if(ev){
      this._EmployeesService.getEmployeesByDesignationId(ev).subscribe(data=> { 
        this.listOfEmployeesData = data;
      },
      error => console.log(error.message));
    }
  }
// filters
selectedPayment;
listOfAllPreData;

paymentSelected(ev){
  this.listOfAllPreData=[];
      console.log(ev);
    if(ev == 1){
      this.listOfAllData=null;
      this._EmployeesService.getProvidentFund().subscribe(data => {
        data.map((fund) => {
          if (fund.provident_fund_type == "Percentage of Basic Salary" ) {
            this.listOfAllPreData.push(fund);
            // console.log(salary);
          }
        });
        this.listOfAllData = this.listOfAllPreData;
        // console.log(this.listOfAllData);
      });
     
    }else if (ev == 2 ){
      this.listOfAllData=null;
      this._EmployeesService.getProvidentFund().subscribe(data => {

        data.map((fund) => {
          if (fund.provident_fund_type == "Fixed Amount" ) {
            this.listOfAllPreData.push(fund);
            // console.log(salary);
          }
        });
         this.listOfAllData = this.listOfAllPreData;
      //  console.log(this.listOfAllData);
      });
    }else if (ev == 3 ){
      this.listOfAllData=null;
      this._EmployeesService.getProvidentFund().subscribe(data => {
        this.listOfAllData = data;
   
      });
    }
  }
  searchForm = new FormGroup({
    emp_id: new FormControl(null),
 
    department: new FormControl(null),
    designation: new FormControl(null)
  });
  searchFund(): void{
    // this.leavesLoading = true;
    for (const i in this.searchForm.controls) {
      this.searchForm.controls[i].markAsDirty();
      this.searchForm.controls[i].updateValueAndValidity();
    }
    if(this.searchForm.valid){    
       
      this._EmployeesService.searchEmployeeProvidentFund(this.searchForm.value).subscribe(data => {
        this.listOfAllData = data;
        // this.leavesLoading = false;
      });
    }
  }
  resetSearch(): void{
    this.searchForm.reset();
    this._EmployeesService.getProvidentFund().subscribe(data => {
      this.listOfAllData = data;
    });
  }
  providentFundForm = new FormGroup({
    emp_id: new FormControl(null, Validators.required),
    provident_fund_type: new FormControl(null, Validators.required),
    employee_share: new FormControl(null),
    organization_share: new FormControl(null),
    description: new FormControl(null),
  });

  providentFundEditForm = new FormGroup({
    id: new FormControl(null),
    emp_id: new FormControl(null, Validators.required),
    provident_fund_type: new FormControl(null, Validators.required),
    employee_share: new FormControl(null),
    organization_share: new FormControl(null),
    description: new FormControl(null),
  });

  open(): void{
  this.visible = true;
  }

  close(): void{
  this.visible = false;
  }

  openEdit(id): void {
    this.visibleEdit = true;
    let data = this.listOfAllData.find(data => data.id == id);
    this.selectedEmployee = data.emp_id;
    this.selectedEditProvidentFundType = data.provident_fund_type;
    this.employeeShareEdit = 0;
    this.organizationShareEdit = 0;
    this.employeeShareAmountEdit = 0;
    this.organizationShareAmountEdit = 0;
    if(data.provident_fund_type == 'Fixed Amount'){
      this.employeeShareAmountEdit = data.employee_share;
      this.organizationShareAmountEdit = data.organization_share;
    }else{
      this.employeeShareEdit = data.employee_share;
      this.organizationShareEdit = data.organization_share;
    }
    this.providentFundEditForm.patchValue({
      id: id,
      description: data.description,
      employee_share: data.employee_share,
      organization_share: data.organization_share 
    });
  }

  closeEdit(): void {
  this.visibleEdit = false;
  }
  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.providentFundToDeleteId = id;
  }

  closeDeleteModal(): void{
    this.visibleDelete = false;
  }

  addProvidentFund(): void {
    if(this.providentFundForm.valid)
    {
      this._EmployeesService.createProvidentFund(this.providentFundForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists')
          {
            alert('Already Exisits');
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else if(responseObj.message == 'success')
          {
            this._EmployeesService.getProvidentFund().subscribe(data => this.listOfAllData = data,
              error => this.errorMsg = error);
              this.listOfDisplayData = this.listOfAllData;
          }
          else
          {
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }
  currentPageDataChange($event){}
  editProvidentFund(): void {
    if(this.providentFundEditForm.valid){
      this._EmployeesService.editProvidentFund(this.providentFundEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success')
          {
            this._EmployeesService.getProvidentFund().subscribe(data => this.listOfAllData = data,
              error => this.errorMsg = error);
            this.listOfDisplayData = this.listOfAllData;
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else
          {
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }

  deleteProvidentFund(id): void {
    this.isOkLoading = true;
    this._EmployeesService.deleteProvidentFund(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success')
      {
        this._EmployeesService.getProvidentFund().subscribe(data => this.listOfAllData = data,
          error => this.errorMsg = error);
          this.listOfDisplayData = this.listOfAllData;
      }
      else if(responseObj.message == 'failed')
      {
        alert('Faild');
      }
      else
      {
        alert(JSON.stringify(response));
      }
    }
  );

  this.isOkLoading = false;
  this.visibleDelete = false;
  }

}
