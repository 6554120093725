import { Component, OnInit,AfterViewChecked, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ActivatedRoute } from '@angular/router';
import { EmployeesService } from 'src/app/services/employees.service';
import { ProjectsService } from 'src/app/services/projects.service';
import * as moment from 'moment';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexLegend,
  ApexFill,
  ApexGrid
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
  fill: ApexFill;
  colors: string[];
  grid: ApexGrid;
};


@Component({
  selector: 'app-single-task',
  templateUrl: './single-task.component.html',
  styleUrls: ['./single-task.component.scss']
})
export class SingleTaskComponent implements OnInit {
  load = false;
  @ViewChild("chart",{static:true}) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  visibleEditTask = false;
  selectedStatus;
  projectId;

  listOfAllEmployees;
  selectedEmployees;
  filteredTasks;
  filteredTimesheetByTaskId;
  total=6;
  taskId;
  errorMsg;
  msg;
  isOkLoading;
  project;
  listOfDisplayData;
  assignedTo=[];
    
  btnFileUpload = true;
  employedata: number[] | { x: any; y: any; fillColor?: string; strokeColor?: string; }[] | [number, number][] | [number, number[]][];
  viewFileUploader(): void{
    if(this.btnFileUpload == true)
      this.btnFileUpload = false;
    else
      this.btnFileUpload = true;
  }
  
  tab1 = true;
  activeTab1(){ this.tab1 = true; this.tab2 = false; this.tab3 = false; this.tab4 = false; }
  tab2 = false;
  activeTab2(){ this.tab1 = false; this.tab2 = true; this.tab3 = false; this.tab4 = false; }
  tab3 = false;
  activeTab3(){ this.tab1 = false; this.tab2 = false; this.tab3 = true; this.tab4 = false; }
  tab4 = false;
  activeTab4(){ this.tab1 = false; this.tab2 = false; this.tab3 = false; this.tab4 = true; }
  
  visibleCommentDelete = false;
  visibleFileDelete = false;
  commentToDeleteId = 0;
  fileToDeleteId = 0;
  comments: TrainingGoalComment[];
  files: TrainingGoalFile[];
  fileToUpload: File;
  validUpload = false;
  task;
  taskStatus:string;

  loggedInUser = this.storage.get('loggedInUser');
  constructor( private route: ActivatedRoute
    ,public _EmployeesService: EmployeesService
    ,private _ProjectsService: ProjectsService
    , @Inject(LOCAL_STORAGE) private storage: StorageService
    ,private notification: NzNotificationService) { }

@ViewChild('scrollMe', { static:false }) private myScrollContainer: ElementRef;
  scrollToBottom(): void {
      try {
          this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
      } catch(err) { }                 
  }
  ngOnInit() {
    this.linechart();
    this.task = {
      id: null,
      project_id: null,
      integration_id: null,
      ref_id: null,
      task: null,
      start_date: null,
      due_date: null,
      estimated_hour: null,
      description: null,
      progress: null,
      status: null,
      employee_ids: null
    };
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfAllEmployees = data;
      this.load = true;
      this.selectedEmployees = this.listOfAllEmployees.find(o => o.id == 1);
        this.route.paramMap.subscribe(params => {
          this.taskId =this.route.snapshot.params.id;
        });
        this._ProjectsService.getTask(this.taskId).subscribe(
          data => {
            this.task = data
           
              data.employee_ids.forEach(element => {
              const emp = this.listOfAllEmployees.find(o => o.id == element);
              this.assignedTo.push(
                { name: emp.fname, img: emp.avatar });
              });

            this.filteredTimesheetByTaskId = this.listOfDisplayData.filter(data1=> data1.task_id == data.id);
           
          }
        );
        this._ProjectsService.getComments(this.taskId).subscribe(data => this.comments = data);
        this._ProjectsService.getFiles(this.taskId).subscribe(data => this.files = data);
        
        this.commentForm.patchValue({
          task_id: this.taskId,
          emp_id: this.loggedInUser.id
        });
        this.fileForm.patchValue({
          task_id: this.taskId,
          emp_id: this.loggedInUser.id
        });
        this.taskStatusForm.patchValue({
          id: this.taskId
        });
        this.taskStatus = this.task.status;
        this.scrollToBottom();
    },
    error => console.log(error.message));
  }
  commentForm = new FormGroup({
    task_id: new FormControl(null),
    emp_id: new FormControl(null),
    comment: new FormControl(null, Validators.required)
  });
  fileForm = new FormGroup({
    task_id: new FormControl(null),
    emp_id: new FormControl(null),
    file_title: new FormControl(null, Validators.required)
  });
  updateTaskStatus(): void{
    if(this.taskStatusForm.valid){
      this._ProjectsService.updateTaskStatus(this.taskStatusForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success'){
            this.notification.create(
              'success',
              'Update Task',
              'Successfully Updated Task'
              );
          }else{
            this.notification.create(
              'error',
              'Update Task',
              'Cant Update Task, Somethings Wrong'
              );            
          }
        }
      );
    }
  }
  taskStatusForm = new FormGroup({
    id: new FormControl(null),
    status: new FormControl(null, Validators.required)
  });
  showDeleteCommentModal(id): void{
    this.visibleCommentDelete = true;
    this.commentToDeleteId = id;
  }
  closeDeleteCommentModal(): void{
    this.visibleCommentDelete = false;
  }
  showDeleteFileModal(id): void{
    this.visibleFileDelete = true;
    this.fileToDeleteId = id;
  }
  closeDeleteFileModal(): void{
    this.visibleFileDelete = false;
  }
  fileUpload(files: FileList): void{
    this.fileToUpload = files.item(0);
    var acceptedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.DOC|\.DOCX)$/i;
    if(!acceptedExtensions.exec(this.fileToUpload.name)){
      this.msg.error(`Supported Formats: .JPG, .JPEG, .PDF, .DOC, DOCX`);
      this.validUpload = false;
    }
    else{
      this.validUpload = true;
    }
  }
  addComment(): void {
    if(this.commentForm.valid){
      this._ProjectsService.createComment(this.commentForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'failed'){
            alert('Faild');
          }
          else if(responseObj.message == 'success'){
            this._ProjectsService.getComments(this.task.id).subscribe(data => this.comments = data,
              error => this.errorMsg = error);
              this.commentForm.patchValue({
                comment: null
              });
              this.commentForm.get('comment').markAsUntouched();
              this.commentForm.get('comment').markAsPristine();
              this.commentForm.get('comment').updateValueAndValidity();
              this.scrollToBottom();   
          }
          else{
            alert(JSON.stringify(response));
          }
        }
      );
    }
  }
  deleteComment(id): void {
    this.isOkLoading = true;
    this._ProjectsService.deleteComment(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success'){
        this._ProjectsService.getComments(this.task.id).subscribe(data => this.comments = data,
          error => this.errorMsg = error);
          this.notification.create(
            'success',
            'Delete Comment',
            'Successfully Deleted Comment'
            );
      }
      else if(responseObj.message == 'failed'){
        this.notification.create(
          'error',
          'Delete Comment',
          'Successfully Deleted Comment'
          );        
      }
      else{
        this.notification.create(
          'error',
          'Delete Comment',
          'Somethings Wrong'
          );
      }
    }
  );
  this.isOkLoading = false;
  this.visibleCommentDelete = false;
  }
  addFile(): void {
    if(!this.validUpload){
      this.msg.error(`Please upload a valid file.`);
      return;
    }
    if(this.fileForm.valid){
      const formData: FormData = new FormData();
      formData.append('file_title', this.fileForm.value.file_title);
      formData.append('file_size', this.fileToUpload.size.toString());
      formData.append('task_id', this.fileForm.value.task_id);
      formData.append('emp_id', '17');      
      formData.append('file_to_upload', this.fileToUpload, this.fileToUpload.name);
      console.log('checkingEmpId',this.fileForm.value.emp_id);
      this._ProjectsService.createFile(formData).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Add File',
              'Cant Add File'
              );            
          }
          else if(responseObj.message == 'success'){
            this.fileForm.reset();
            this.btnFileUpload = true;
            this._ProjectsService.getFiles(this.task.id).subscribe(data => this.files = data,
              error => this.errorMsg = error);
              this.notification.create(
                'success',
                'Add File',
                'Successfully Added File'
                );
          }
          else{
            this.notification.create(
            'error',
            'Add File',
            'Somethings Wrong'
            );            
          }
        }
      )
    }
  }
  deleteFile(id): void {
    this.isOkLoading = true;
    this._ProjectsService.deleteFile(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success'){
        this._ProjectsService.getFiles(this.task.id).subscribe(data => this.files = data,
          error => this.errorMsg = error);
          this.notification.create(
            'success',
            'Delete File',
            'Successfully Deleted File'
            );
      }
      else if(responseObj.message == 'failed'){
        this.notification.create(
          'error',
          'Delete File',
          'Cant Delete File'
          );        
      }
      else{
        this.notification.create(
          'error',
          'Delete File',
          'Somethings Wrong'
          );        
      }
    }
  );
  this.isOkLoading = false;
  this.visibleFileDelete = false;
  }
  downloadFile(id): void{
    window.location.href = environment.stepApiUrl + "/api/task/download-file/" + id;
  }

  visibleDelete = false;
  timesheetToDeleteId = 0;
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.timesheetToDeleteId = id;
  }
  closeDeleteModal(): void{
    this.visibleDelete = false;
  }
  linechart(){
    // this.getUserDashboardDetails();
    this.employedata = [0,14,30,10,45,55,38,75,78];
    this.chartOptions = {
      series: [
        {
          name: "Attendance",
          data: this.employedata
        }
      ],
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight",
         colors:['#131d50','#131d50', '#131d50'],
          
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8
        }
      },
      title: {
        text: "",
        //align: "left"
      },
      subtitle: {
        text: "",
        //align: "left"
      },
      xaxis: {
        
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'],
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        },
        labels: {
          show: false
        },
      },  
      yaxis: {
        opposite: true,
        floating: true,
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        },
        labels: {
          show: false
        },
      },
      legend: {
        horizontalAlign: "right"
      },
      colors:["#131d50"],
      grid: {
        row: {
          colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0
        },
      },
    };
  }
  taskEditForm = new FormGroup({
    id: new FormControl(null),
    project_id: new FormControl(null),
    task: new FormControl(null, Validators.required),
    employee_ids: new FormControl(null, Validators.required),
    start_date: new FormControl(null, Validators.required),
    due_date: new FormControl(null, Validators.required),
    estimated_hour: new FormControl(null, Validators.required),
    progress: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    status: new FormControl(null, Validators.required)
  });

  openEditTaskDrawer(id): void {
    this.visibleEditTask = true;
    let data = this.task.find(data => data.id == id);
    this.selectedStatus = data.status;
    this.selectedEmployees = data.employee_ids;
    this.taskEditForm.patchValue({
      project_id: this.project.id
    });
    this.taskEditForm.patchValue({
      id: id,
      task: data.task,
      start_date: new Date(data.start_date),
      due_date: new Date(data.due_date),
      estimated_hour: data.estimated_hour,
      progress: data.progress,
      description: data.description,
    });
  }
  closeEditTaskDrawer(): void {
    this.visibleEditTask = false;
  }
  editTask(): void {
    if(this.taskEditForm.valid){
      this.taskEditForm.value.start_date = moment(this.taskEditForm.value.start_date).format('YYYY-MM-DD');
      this.taskEditForm.value.due_date = moment(this.taskEditForm.value.due_date).format('YYYY-MM-DD');
      this._ProjectsService.editTask(this.taskEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            this.notification.create(
              'error',
              'Edit Task',
              'Task Already Exists'
              );             
          }else if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Edit Task',
              'Cant Edit Task'
              );             
          }else if(responseObj.message == 'success'){
            this._ProjectsService.getTasks(this.projectId).subscribe(data => {
              this.task = data;
              console.log('thisIsTaks',this.task);
              this.filteredTasks = this.task;
            });
            this.notification.create(
              'success',
              'Edit Task',
              'Successfully Edited Task'
              ); 
          }else{
            this.notification.create(
              'error',
              'Edit Task',
              'Somethings Wrong'
              );             
          }
        }
      );
    }
  }
}

