import { Component, Inject, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NzNotificationService } from "ng-zorro-antd";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { EmployeesService } from "src/app/services/employees.service";
import { OrgData } from "angular-org-chart/src/app/modules/org-chart/orgData";
import { TeamsService } from "src/app/services/teams.service";

@Component({
  selector: "app-add-organization-hierarchy",
  templateUrl: "./add-organization-hierarchy.component.html",
  styleUrls: ["./add-organization-hierarchy.component.scss"],
})
export class AddOrganizationHierarchyComponent implements OnInit {
  listOfEmployeesToRemove = [];
  teamList = [];
  checked = false;
  listOfAllData;
  listOfNames = [];
  listOfEmployees;
  listOfManagers = [];
  loanForm = new FormGroup({
    manager_id: new FormControl(null),
    employees: new FormArray([]),
  });

  constructor(
    private notification: NzNotificationService,

    private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _TeamsService: TeamsService,
    private _EmployeesService: EmployeesService
  ) {}

  ngOnInit() {
    this._EmployeesService.getEmployees().subscribe(
      (data) => {
        data.map((employee) => {
          if (employee.role_id != 2) {
            this.listOfManagers.push(employee);
            console.log(employee);
          }
        });
        // console.log(this.listOfManagers);
      },
      (error) => console.log(error.message)
    );
    this._TeamsService.getTeams().subscribe((dt) => {
      this.teamList = dt;
    });
    this._EmployeesService.getEmployees().subscribe(
      (data) => {
        this.listOfAllData = data;
        this.listOfAllData.map(
          (item, index) => {
            // teamsList = []
            this.listOfNames.push(item.fname);
            
            // this.listOfNames = this.listOfAllData.fname
            
      this.teamList.map((items) => {
        let employees = items.employees.split(",");
        var isIndexExist = employees.indexOf((item.id).toString());
        if ( isIndexExist != -1 ) {
          this.listOfEmployeesToRemove.push(item);
        }
        if(index == this.listOfAllData.length - 1) {
          this.listOfEmployeesToRemove.map(item => {
            var index = this.listOfAllData.indexOf(item);
            this.listOfAllData.splice(index, 1);
          })
        }
      });
          },
          (error) => console.log(error.message)
        );
      },
      (error) => console.log(error.message)
    );
    
  }

  updateChkbxArray(id, isChecked, key) {
    const chkArray = <FormArray>this.loanForm.get(key);
    if (isChecked) {
      chkArray.push(new FormControl(id));
    } else {
      let idx = chkArray.controls.findIndex((x) => x.value == id);
      chkArray.removeAt(idx);
    }
    console.log(chkArray);
  }

  addLoan() {
    console.log(this.loanForm.value);
    if (this.loanForm.valid) {
      this._TeamsService
        .createtems(this.loanForm.value)
        .subscribe((response) => {
          console.log("request create");
          let responseObj = JSON.parse(JSON.stringify(response));
          if (responseObj.message == "already-exists") {
            alert("Already Exisits");
          } else if (responseObj.message == "failed") {
            alert("Faild");
          } else if (responseObj.message == "success") {
            this.notification.create(
              "success",
              "Organization Hierarchy",
              "Successfully updated"
            );
            this.router.navigate(["/organization-hierarchy"]);
          } else {
            this.notification.create(
              "success",
              "Organization Hierarchy",
              "Successfully updated"
            );
            this.router.navigate(["/organization-hierarchy"]);
          }
        });
    }
  }
}
