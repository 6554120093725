import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { AccountsService } from 'src/app/services/accounts.service';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { EmployeesService } from 'src/app/services/employees.service';
import { SettingsService } from 'src/app/services/settings.service';
import * as moment from 'moment';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss']
})
export class MakePaymentComponent implements OnInit {
  selectedValue;
  listOfEmployees;
selected=null;
  monthFormat = 'MMM yyyy';
  public errorMsg;
  settings;
  
  listOfSelection = [
    {
      text: 'Select All Row',
      onSelect: () => {
        this.checkAll(true);
      }
    },
    {
      text: 'Select Odd Row',
      onSelect: () => {
        this.listOfDisplayData.forEach((data, index) => (this.mapOfCheckedId[data.id] = index % 2 !== 0));
        this.refreshStatus();
      }
    },
    {
      text: 'Select Even Row',
      onSelect: () => {
        this.listOfDisplayData.forEach((data, index) => (this.mapOfCheckedId[data.id] = index % 2 === 0));
        this.refreshStatus();
      }
    }
  ];

  isAtleastOneChecked = false;
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfDisplayData: Payroll[];
  listOfAllData: Payroll[];
  mapOfCheckedId: { [key: string]: boolean } = {};

  currentPageDataChange($event: Payroll[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }
  refreshStatus():void {
    let checkedAbles = this.listOfDisplayData.filter(item => item.status == "Pay");

    for(let i = 0; i < checkedAbles.length; i++)
    {
      if(this.mapOfCheckedId[checkedAbles[i].id])
      {
        this.isAllDisplayDataChecked = true;
      }
      else
      {
        this.isAllDisplayDataChecked = false;
        break;
      }
    }

    this.isIndeterminate =
      this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;

    if(Object.values(this.mapOfCheckedId).includes(true))
    {
      this.isAtleastOneChecked = true;
    }
    else
    {
      this.isAtleastOneChecked = false;
    }
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => {
      if(item.status == 'Pay')
        this.mapOfCheckedId[item.id] = value;
    });
    this.refreshStatus();
  }

  visible = false;
  visibleSlipDrawer = false;

  departments;
  designations;

  payment_month;

  selectedEmployee;
  selectedDepartment;
  selectedDesignation;

  netSalary;
  overtimeSalary;
  tax;
  providentFund;
  loan;
  totalSalary;
  selectedPaymentType;

  payments;

  i = 0;

  slip: Payslip = {
    id: null,
    employee_code: null,
    employee_name: null,
    department: null,
    designation: null,
    payment_type: null,
    payment_month: null,
    payment_date: null,
    net_salary: null,
    tax: null,
    provident_fund: null,
    loan: null,
    overtime_salary: null,
    total_salary: null,
    payment_method: null,
    address: "Office #305, Blossom Trade Center, University Road, Gulzar-e-Hijri, Karachi, Pakistan",
    basic_salary: null
  };

  searchFormIsSubmitted = false;
  PaymentFormIsSubmitted = false;

  searchForm = new FormGroup({
    date: new FormControl(null, Validators.required),
    employee: new FormControl(null),
    department: new FormControl(null),
    designation: new FormControl(null),
  });
  paymentForm = new FormGroup({
    emp_id: new FormControl(null),
    net_salary: new FormControl(null, Validators.required),
    overtime_salary: new FormControl(null, Validators.required),
    tax: new FormControl(null, Validators.required),
    provident_fund: new FormControl(null),
    loan: new FormControl(null),
    total_salary: new FormControl({disabled: true}, Validators.required),
    payment_month: new FormControl(null),
    payment_type: new FormControl(null, Validators.required),
  });

  constructor(
    public _EmployeesService: EmployeesService,
    private _AccountsService: AccountsService, private _SettingsService: SettingsService) { }

    ngOnInit() {
    
    this.selectedPaymentType = 'Bank Payment';
    this._SettingsService.getDepartments().subscribe(data => this.departments = data);
    this._SettingsService.getDesignations().subscribe( data => this.designations = data );
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfEmployees = data;
    },
    error => console.log(error.message));


    this._SettingsService.getSettings().subscribe(
      data => {
        this.settings = data;
      }
    );
  }

  changeDepartment(ev): void{
    if(ev){
      this._SettingsService.getDesignationsByDepartmentId(ev).subscribe(
        data => { 
          this.designations = data;
        }
      );
      this._EmployeesService.getEmployeesByDepartmentId(ev).subscribe(data=> { 
        this.listOfEmployees = data;
      },
      error => console.log(error.message));
    }
  }
  changeDesignation(ev): void{
    if(ev){
      this._EmployeesService.getEmployeesByDesignationId(ev).subscribe(data=> { 
        this.listOfEmployees = data;
      },
      error => console.log(error.message));
    }
  }

  get date() { return this.searchForm.get('date'); }

  searchEmployees(): void{
    for (const i in this.searchForm.controls) {
      this.searchForm.controls[i].markAsDirty();
      this.searchForm.controls[i].updateValueAndValidity();
    }

    console.log(this.selected);
    this.searchFormIsSubmitted = true;
    if(this.searchForm.valid)
    {
      let newData= [];
      this._AccountsService.searchUnpaidEmployees(this.searchForm.value).subscribe(data => {
        this.listOfAllData = data;
        this.listOfDisplayData = this.listOfAllData;
          this.listOfEmployees.forEach(element => {
            let r = data.find(data => data.id == element.id);
            if(r){ 
              if(element.payment_type == 'Monthly' && element.basic_salary < 1){
                element.status = 'Salary Not Set';
                console.log(element.status);
              }
              else if(element.payment_type == 'Hourly' && element.working_hourly_rate < 1){
                element.status = 'Salary Not Set';
                console.log(element.status);
              }
              else{
                element.status = 'Already Paid';
                console.log(element.status);
              }
            }else{
              element.status = 'Pay'
              console.log(element.status);
            }
            element.payment_month = moment(this.searchForm.value.date).format('MMMM, YYYY');
            newData.push(element)
          });
          console.log(newData);
          this.listOfAllData = newData;
          this.listOfDisplayData = newData;
      });

      this.searchFormIsSubmitted = false;
    }
  }

  resetSearch(): void{
    this.searchForm.reset();
  }

  closeSlipDrawer(): void{
    this.visibleSlipDrawer = false;
  }

  onAmountChange(): void{
    this.totalSalary = (this.netSalary + this.overtimeSalary) - this.tax - this.providentFund - this.loan;
  }

  onBulkAmountChange(): void{
    console.clear();
    for(let i = 0; i < this.payments.length; i++)
    {
      this.payments[i].total_salary = (this.payments[i].net_salary + this.payments[i].overtime_salary) - this.payments[i].tax - this.payments[i].provident_deducted - this.payments[i].loan_deducted;
    }
  }


  printSlip(): void{
    window.print();
  }
}
