import { listLazyRoutes } from '@angular/compiler/src/aot/lazy_routes';
import { Component,Inject,OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
 
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexGrid
} from "ng-apexcharts";
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { EmployeesService } from 'src/app/services/employees.service';
import { transpileModule } from 'typescript';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  grid: ApexGrid;
};

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss']
})
export class AreaChartComponent implements OnInit {
  firstDayThisWeek;
  lastDayThisWeek;
  presentDaysList;
  countPresentDays;
  total=[] ;
  total1 =0;
  total2=0;
  total3=0;
  total4=0;
  total5=0;
  
  formatOne = (percent: number): string => `${Math.round(percent / 4.54545) } Days`;
  loggedInUser = this.storage.get('loggedInUser');
  thisWeekSearchFormEmployee = new FormGroup({
    emp_id: new FormControl(null, Validators.required),
    dateFrom: new FormControl(null, Validators.required),
    dateTo: new FormControl(null , Validators.required),
     
  });
loadChart= false;
 
  presentInCurrentMonth(){
    this.firstDayThisWeek = moment( ).startOf('week').subtract(6, 'd').format("YYYY-MM-DD");
        this.lastDayThisWeek = moment( ).endOf('week').subtract(7, 'd').format("YYYY-MM-DD");
    console.log(this.firstDayThisWeek);
    console.log(this.lastDayThisWeek);
    this.thisWeekSearchFormEmployee.value.emp_id = this.loggedInUser.id;
    this.thisWeekSearchFormEmployee.value.dateFrom = this.firstDayThisWeek;
    this.thisWeekSearchFormEmployee.value.dateTo = this.lastDayThisWeek;
    console.log(this.thisWeekSearchFormEmployee.value);
    this._EmployeesService.searchAttendance(this.thisWeekSearchFormEmployee.value).subscribe(data => {
       
      this.presentDaysList = data
      this.countPresentDays = this.presentDaysList;
      this.total = data.map(function (val) {

        return val.total / 60;

        
    });
    
      while(this.total.length < 5){
        this.total.push(0.00);
      }
      this.total1 = Math.round(this.total[0]);
      this.total2 = Math.round(this.total[1]);
      this.total3 = Math.round(this.total[2]);
      this.total4 = Math.round(this.total[3]);
      this.total5 = Math.round(this.total[4]);
    if(this.total1 >= 1){
      this.chartOptions = {
        series: [
          {
            name: "Attendance",
            data: [this.total5, this.total4, this.total3, this.total2, this.total1]
            
          } 
        ],
        chart: {
          height: 190,
          fontFamily: 'Roboto, sans-serif',
          redrawOnParentResize: true,
          type: "area",
          toolbar: {
            show: false
           }
        },
        colors:["#9476f0"],
        xaxis: {
          labels:{
            style:{
              fontWeight: 200,
              fontSize: '12px',
              colors:['#a0a0a0','#a0a0a0','#a0a0a0','#a0a0a0','#a0a0a0']
            }
          },
          axisBorder: {
            show: false
          },
          categories: ["M", "T",  "W",  "T",  "F" ]
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
            formatter: function(val: string) {
              return val + "%";
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            borderRadius: 5,
            endingShape: "rounded"
            
          }
        },
        dataLabels: {
          enabled: false
        },
  
  
        stroke: {
          curve: 'smooth',
          width: 1,
           colors:['#9476f0', '#9476f0', '#9476f0'],
            
        },
        fill: {
          opacity: 0.5,
          color:["#9476f0"],
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          }
        },
   
        grid: {
          show: true,       
          xaxis: {
            lines: {
              show: false  
             }
           },  
          yaxis: {
            lines: { 
              show: false   
             }
           }   
        },
       
        legend: {
          show: false
        },
        tooltip: {
          y: {
            formatter: function(val: string) {
              return  val + ":00" + " Hours";
            }
          }
        }
      };
      this.loadChart = true;
    }
    


      console.log(this.presentDaysList);
      console.log(this.countPresentDays);
      console.log(this.total);
      console.log(this.total1);
      console.log(this.total2);
      console.log(this.total3);
      console.log(this.total4);
      console.log(this.total5);
      
    });
    

  }
  
  @ViewChild("chart1", null)
  chart: ChartComponent = new ChartComponent;
  public chartOptions: Partial<any>;


  constructor( public _EmployeesService: EmployeesService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,) { 

   
  }


  ngOnInit(): void {
    this.presentInCurrentMonth();


    
  }

}
