import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AzureService {
  private personalAccessToken = "e3tljfhtawjnmix3ve2gah7gttsnatlwtyzzea45dlv7jme2v4ta";
  private username = "shakeel.moosa@stratesfy.net";

  // private apiUrl = environment.stepApiUrl + '/api';
  constructor(private http: HttpClient,@Inject(LOCAL_STORAGE) private storage: StorageService) { }
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.storage.get('auth-token').accessToken,
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json'
  });

  getAccessToken() {
    return this.http.get(
      'https://dev.azure.com/e-merge/_apis/projects?api-version=2.0'
      ,{ headers: new HttpHeaders({
        'Authorization': 'Basic '+ btoa( this.username + ':' + this.personalAccessToken )
      }) })
    .pipe(catchError(this.erroHandler));
  }

  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  } 
}
