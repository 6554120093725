import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { NzMessageService } from 'ng-zorro-antd';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import * as moment from 'moment';
import { Router } from "@angular/router";
import { DOCUMENT } from '@angular/common';
import { NzNotificationService  } from 'ng-zorro-antd';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@Component({
  selector: 'app-add-attendance',
  templateUrl: './add-attendance.component.html',
  styleUrls: ['./add-attendance.component.scss']
})
export class AddAttendanceComponent implements OnInit {
  load = false;
  disabledDate;
  workfromhometrigger = true;
  attendanceDataByUserId;
  listOfattendanceData;
  listOfEmployeesData;
  employeeDataLoading = true;
  listOfAllEmployees;
  attendanceDataLoading = true;
  clockIn= null;
  clockOut= null;
  error = false;
  loggedInUser = this.storage.get('loggedInUser');
  public errorMsg;
  //
  today = new Date();
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  attendanceToDeleteId = 0;
  isOkLoading = false;
  departments;
  designations;
  selectedEmployee;
  workFromHome = false;
  employeeName = "";
  attendanceForm = new FormGroup({
    emp_id: new FormControl(null, Validators.required),
    date: new FormControl(null, Validators.required),
    clock_in: new FormControl(null, Validators.required),
    clock_out: new FormControl(null),
    work_from_home: new FormControl(null),
    work_from_home_total: new FormControl(null)
  });
  constructor(
    private notification: NzNotificationService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
                private _EmployeesService: EmployeesService,
                private _SettingsService: SettingsService, private msg: NzMessageService
                , @Inject(LOCAL_STORAGE) private storage: StorageService) { }
  ngOnInit() {
    // get employees list
     
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfAllEmployees = data;
      this.selectedEmployee = this.listOfAllEmployees.find(o => o.id == 1);
      this.listOfEmployeesData = this.listOfAllEmployees;
      this.employeeDataLoading = false;
      this.load = true;
    },
    error => console.log(error.message));
    this.disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;
  
  }
  open(): void{
    this.attendanceForm.reset();
    this.visible = true;
  }
  close(): void{
  this.visible = false;
  }


  openWorkFromHome(event){
    if(event.target.checked == true){
      this.workfromhometrigger = false;
    }else{
      this.workfromhometrigger = true;
    }
  }
errorRequiredcheckout =false;
errorRequiredcheckin = false;
  addAttendance(): void {
    // if (this.clockOut == null ){
    //   this.errorRequiredcheckout = true;
    //   console.log("checkout true");
    // }else{
    // this.errorRequiredcheckout = false;
    // console.log("checkout false");}
    if (this.clockIn == null ){
      this.errorRequiredcheckin = true;
      console.log("checkin true");
    }else{
    this.errorRequiredcheckin = false;
    console.log("checkin false");
    }
    for (const i in this.attendanceForm.controls) {
      this.attendanceForm.controls[i].markAsDirty();
      this.attendanceForm.controls[i].updateValueAndValidity();
    }    
    // let selectedDate = new Date(this.attendanceForm.value.date);
    // this.attendanceForm.value.clock_in = moment(this.attendanceForm.value.clock_in).format('H:mm');
    // this.attendanceForm.value.clock_out = moment(this.attendanceForm.value.clock_out).format('H:mm');
    // this.attendanceForm.patchValue({
    //     date: moment(selectedDate).format('YYYY-MM-DD'),
    //    clock_in: this.clockIn,
    //    clock_out: this.clockOut
    // });
    this.attendanceForm.patchValue({
      clock_in: this.clockIn,
      clock_out: this.clockOut
   });
   
    console.log(this.attendanceForm.value);

    if(this.attendanceForm.valid){
      this.attendanceForm.value.date = moment(this.attendanceForm.value.date).format('YYYY-MM-DD');
    
    console.log(this.attendanceForm.value);
      this._EmployeesService.createAttendance(this.attendanceForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            alert('Already Exisits');
          }else if(responseObj.message == 'failed'){
            alert('Faild');
          }else if(responseObj.message == 'success'){
            this.notification.create(
              'success',
              'Attendance',
              'Successfully updated'
              );
            this.router.navigate(['/attendance'])
            // this.document.location.href = '/attendance';
          }else{
            this.notification.create(
              'success',
              'Attendance',
              'Successfully updated'
              );
            this.router.navigate(['/attendance'])
            // this.document.location.href = '/attendance';
          }
        }
      );
   
    }
  }
 

  onTimeChange(ev){
    if(ev !== null && this.clockOut != null && this.clockIn != null){
      const clockIn = moment.duration(this.clockIn).asMinutes();
      const clockOut = moment.duration(this.clockOut).asMinutes();
      if(clockOut <= clockIn){
        this.error = true
      }else{
        this.error = false;

        
      }
    }
  }
}
