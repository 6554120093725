import { Component,OnInit, ViewChild } from '@angular/core';
 
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexGrid,
  ApexMarkers
} from "ng-apexcharts";
import { transpileModule } from 'typescript';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  grid: ApexGrid;
  markers: ApexMarkers;

};

@Component({
  selector: 'app-horizontal-double-bar-chart',
  templateUrl: './horizontal-double-bar-chart.component.html',
  styleUrls: ['./horizontal-double-bar-chart.component.scss']
})
export class HorizontalDoubleBarChartComponent implements OnInit {

  @ViewChild("chart4", null)
  chart: ChartComponent = new ChartComponent;
  public chartOptions: Partial<any>;  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "Net Profit",
          data: [44, 37, 73, 63, 54]
        },
        {
          name: "Revenue",
          data: [40, 33, 62, 58, 50]
        }
      ],
      chart: {
        height: 250,
        fontFamily: 'Roboto, sans-serif',
        redrawOnParentResize: true,
        type: "bar",
        toolbar: { tools: { download: false } }
      },
      
      xaxis: {
        labels:{
          style:{
            fontWeight: 600,
            colors:['#000000','#000000','#000000','#000000','#000000','#000000']
          }
        },
        axisBorder: {
          show: false
        },
        categories: ["One", "Two",  "Three",  "Four",  "Five" ]
      },
      yaxis: {
        labels:{
          style:{
            fontWeight: 600,
            colors:['#d5d8db','#d5d8db','#d5d8db','#d5d8db','#d5d8db']
          },
          formatter: function (value: string) {
            return  value ;
          }
        },
        axisBorder: {
          show: false
        },
         
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: "55%",
          borderRadius: 5,
          endingShape: "rounded"
          
        }
      },dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 0.5,
        colors: ["transparent"]
      }, 
      fill: {
        colors: ['#5078fc', '#9d3dbe']
      },
      grid: {
        show: true,      // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: true //or just here to disable only x axis grids
           }
         },  
        yaxis: {
          lines: { 
            show: false  //or just here to disable only y axis
           }
         }   
      },
     
      legend: {
        show: false
      },
      tooltip: {
        y: {
          formatter: function(val: string) {
            return "$ " + val + " thousands";
          }
        }
      }
    };

  }


  ngOnInit(): void {
  }

}
