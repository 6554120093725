import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss']
})
export class DepartmentsComponent implements OnInit {
  isOkLoading;
  loading = true;
  constructor(private _SettingsService: SettingsService) { }
  public errorMsg;

  listOfDisplayData;
  listOfAllData;
  ngOnInit(): void {
    this._SettingsService.getDepartments().subscribe(data=> {
      this.listOfAllData = data
      console.log(this.listOfAllData);
    },
      error => this.errorMsg = error);
    this.listOfDisplayData = this.listOfAllData;
    
  }

  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  departmentToDeleteId = 0;

  departmentForm = new FormGroup({
    department: new FormControl('')
  });

  departmentEditForm = new FormGroup({
    id: new FormControl(null),
    department: new FormControl('')
  });

  open(): void {
    this.visible = true;
  }
  close(): void {
    this.visible = false;
  }

  openEdit(id): void {
    this.visibleEdit = true;

    let data = this.listOfAllData.find(data => data.id == id);

    this.departmentEditForm.patchValue({
      id : id,
      department: data.department
    });
  }
  closeEdit(): void {
    this.visibleEdit = false;
  }

  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.departmentToDeleteId = id;
  }

  closeDeleteModal(): void{
    this.visibleDelete = false;
  }


  AddDepartment(){
    this._SettingsService.createDepartment(this.departmentForm.value).subscribe(
      data=> {
        this._SettingsService.getDepartments().subscribe(data=> this.listOfAllData = data,
          error => this.errorMsg = error);
        this.listOfDisplayData = this.listOfAllData;
      },
      error => this.errorMsg = error
    );
  }

  editDepartment(){
    this._SettingsService.updateDepartment(this.departmentEditForm.value).subscribe(
      response => {
        let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'success')
        {
          this._SettingsService.getDepartments().subscribe(data=> this.listOfAllData = data,
            error => this.errorMsg = error);
          this.listOfDisplayData = this.listOfAllData;
        }
        else if(responseObj.message == 'failed')
        {
          alert('Faild');
        }
        else
        {
          alert(JSON.stringify(response));
        }
      }
    );
  }

  deleteDepartment(id){
    this._SettingsService.deleteDepartment(id).subscribe(
      data=> {
        this._SettingsService.getDepartments().subscribe(data=> this.listOfAllData = data,
          error => this.errorMsg = error);
        this.visibleDelete = false;
        this.listOfDisplayData = this.listOfAllData;
      },
      error => this.errorMsg = error
    );
  }
}
