import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service'; 
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { Inject } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Router } from "@angular/router";
import { DOCUMENT } from '@angular/common';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-new-employee',
  templateUrl: './new-employee.component.html',
  styleUrls: ['./new-employee.component.scss']
})
export class NewEmployeeComponent implements OnInit {
  load = false;
  department;
  designation;
role;
  confirm;
  errorMsg;
  startValue: Date | null = null;
  endValue: Date | null = null;
  endOpen = false;
  visibleOnboarding = false;
  listOfEmployees;
  filteredEmployee;
  employeeDataLoading = true;
  listOfEmployeesData;
  listOfAllEmployees;
  selectedEmployee;
  listOfDepartments;
  listOfDesignations;
  listOfRoles;

  employeeForm = new FormGroup({
    fname: new FormControl(null, Validators.required),
    lname: new FormControl(null, Validators.required),
    role_id: new FormControl(null, Validators.required),
    username: new FormControl(null, Validators.required),
    password: new FormControl(null, Validators.required),
  
    email: new FormControl(null, Validators.required),
    phone: new FormControl(null, Validators.required),
    phone2: new FormControl(null, Validators.required),
    IM: new FormControl(null, Validators.required),
    employee_code: new FormControl(null, Validators.required),
    department: new FormControl(null, Validators.required),
    designation: new FormControl(null, Validators.required),
  });
  constructor(private router: Router,
    @Inject(DOCUMENT) private document: Document,public _EmployeesService: EmployeesService, @Inject(LOCAL_STORAGE) private storage: StorageService,private notification: NzNotificationService,
  private _SettingsService: SettingsService) { }

  ngOnInit() {

    this._EmployeesService.getEmployeeRoles().subscribe(data=> { 
      this.load = true;
      this.listOfRoles = data;
    });    

    this._SettingsService.getDepartments().subscribe(data=> this.listOfDepartments = data,
      error => this.errorMsg = error);
    this._SettingsService.getDesignations().subscribe(data=> this.listOfDesignations = data,
      error => this.errorMsg = error);
  }

  addEmployee() :void{
    for (const i in this.employeeForm.controls) {
      this.employeeForm.controls[i].markAsDirty();
      this.employeeForm.controls[i].updateValueAndValidity();
    }
    if(this.employeeForm.valid){
      if(this.employeeForm.value.password == this.confirm){
        this._EmployeesService.createEmployee(this.employeeForm.value).subscribe(
          response => {
            let responseObj = JSON.parse(JSON.stringify(response));
            if(responseObj.message == 'success'){

              this.router.navigate(['/employees'])
              // this.document.location.href = '/employees';
              
            }
            else if(responseObj.message == 'username-exists')
            {
              this.notification.create(
                'error',
                'Username Already Exists',
                'Unable to save changes'
                );            
            }else if(responseObj.message == 'email-exists')
            {
              
              this.router.navigate(['/employees'])
              // this.document.location.href = '/employees';
                
                this.notification.create(
                  'error',
                  'Username Already Exists',
                  'Unable to save changes'
                  );            
            }
            else if(responseObj.message == 'password-mismatch')
            {
              this.notification.create(
                'error',
                'Password Mismatch',
                'Unable to save changes'
                );            
            }
            else
            {
              this.notification.create(
                'error',
                'Employee Created but Email Sending Failed',
                'somethings went wrong'
                );
            }
          }
        )
      }else{

        this.notification.create(
          'error',
          'Error!',
          'Confirm password mismatched'
          );            
      }
    }
    // console.log(this.confirm);
}
}
