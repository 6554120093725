import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-finance-dashboard-sidebar',
  templateUrl: './finance-dashboard-sidebar.component.html',
  styleUrls: ['./finance-dashboard-sidebar.component.scss']
})
export class FinanceDashboardSidebarComponent implements OnInit {
  selectedValue = '6 Months';
  constructor() { }

  ngOnInit(): void {
  }

}
