import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit {
  isOkLoading = false;
  loading = true;
  visibleDelete = false;
  awardId;
  
  constructor(private _SettingsService: SettingsService) { }
  public errorMsg;
  listOfAllData;
  ngOnInit(): void {
    this._SettingsService.getCurrency().subscribe(data=> this.listOfAllData = data,
      error => this.errorMsg = error);
  }

  
  
  currencyForm = new FormGroup({
    name: new FormControl(null, Validators.required),
    conversion_rate: new FormControl(0.00, Validators.required)
  });

  currencyEditForm = new FormGroup({
    id: new FormControl(),
    name: new FormControl(null, Validators.required),
    conversion_rate: new FormControl(0.00, Validators.required)
  });

  visible = false;
  open(): void {
    this.visible = true;
  }
  close(): void {
    this.visible = false;
  }

  visibleEdit = false;
  openEdit(id): void {
    this.visibleEdit = true;

    let data = this.listOfAllData.find(data => data.id == id);

    this.currencyEditForm.patchValue({
      id : id,
      name: data.name,
      conversion_rate: data.conversion_rate
    });
  }
  closeEdit(): void {
    this.visibleEdit = false;
  }

  addCurrency(): void {
    if(this.currencyForm.valid)
    {
      this._SettingsService.createCurrency(this.currencyForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists')
          {
            alert('Already Exisits');
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else if(responseObj.message == 'success')
          {
            this._SettingsService.getCurrency().subscribe(data => this.listOfAllData = data,
              error => this.errorMsg = error);
          }
          else
          {
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }

  editCurrency(): void {
    if(this.currencyEditForm.valid)
    {
      this._SettingsService.editCurrency(this.currencyEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success')
          {
            this._SettingsService.getCurrency().subscribe(data => this.listOfAllData = data,
              error => this.errorMsg = error);
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else
          {
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }
  
  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.awardId = id;
  }
  //Delete Modal
  closeDeleteModal(id): void{
    this.visibleDelete = false;
  }

  deleteCurrency(id): void {
    this.isOkLoading = true;
    this._SettingsService.deleteCurrency(id).subscribe(
      response => {
        let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'success')
        {
          this._SettingsService.getCurrency().subscribe(data => this.listOfAllData = data,
            error => this.errorMsg = error);
            this.visibleDelete = false;
        }
        else if(responseObj.message == 'failed')
        {
          alert('Faild');
        }
        else
        {
          alert(JSON.stringify(response));
        }
      }
    )
    this.isOkLoading = false;
  }


}
