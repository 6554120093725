import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { SettingsService } from 'src/app/services/settings.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import * as moment from 'moment'
import { Router } from "@angular/router";
import { DOCUMENT } from '@angular/common';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-add-leave',
  templateUrl: './add-leave.component.html',
  styleUrls: ['./add-leave.component.scss']
})
export class AddLeaveComponent implements OnInit {
  selected= null;
  load = false;
  applyLeaveForm = false;
  dateFrom;
  date;
  dateFormat = 'MMM dd, yyyy';
  leaveTypes;
  employeeLeaveData;
  leavesLoading = true;
  holidayLoading = true;
  holidayData;
  dateIn= null;
  dateOut= null;
  error = false;

  applyLeaveFormOpen(select: Date):void{
    this.applyLeaveForm = true;
    this.dateFrom = select
  }
  applyLeaveFormClose() : void{
    this.applyLeaveForm = false;
  }
  loggedInUser = this.storage.get('loggedInUser');
  casualLeavesAvailed = 0;
  
  sickLeaves = 0;
  casualLeaves = 0;
  sickLeaveTypeId;
  casualLeavesRemaining = 0;
  sickLeavesRemaining = 0;
  thisMonthSickLeavesAvailed = 0;
  thisMonthVacationsLeavesAvailed = 0;
  thisMonthHalfdayLeavesAvailed = 0;
  
  //Copid from employee type component ts
  public errorMsg;

  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  leaveToDeleteId = 0;
  isOkLoading = false;

  selectedEmployee;
  selectedLeaveType;
  selectedStatus;
  selectedValue;
  inputValue;
  listOfEmployeesData;
  employeeDataLoading = true;

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private _EmployeesService: EmployeesService
    , @Inject(LOCAL_STORAGE) private storage: StorageService
    ,private notification: NzNotificationService
    ,private _SettingsService: SettingsService) { }

  ngOnInit() {
    this._EmployeesService.getEmployees().subscribe(data=> { 
      console.log(data)
      this.listOfEmployeesData = data;
      this.employeeDataLoading = false;
    },
    error => console.log(error.message));  

    // get leaves list
    this._SettingsService.getLeaves().subscribe(data => {
      this.leaveTypes = data;
      console.log(this.leaveTypes);
     
    });  

      if(this.loggedInUser.role_id !== 2){
        this._EmployeesService.getEmployeeLeave().subscribe(data => {
          this.employeeLeaveData = data;
          this.load = true;
          this.leavesLoading = false;
        });
      }else{
        this._EmployeesService.getEmployeeLeavesById(this.loggedInUser.id).subscribe(data => {
          this.employeeLeaveData = data;
          this.leavesLoading = false;
          this.load = true;
        });
      }

      this._SettingsService.getHolidays().subscribe(data => {
        this.holidayData = data;
        this.holidayLoading = false;
      });

      this.employeeLeaveFormForAdmin.patchValue({
        emp_id : this.loggedInUser.id,
        status : 'pending'
      });

  }

  employeeLeaveFormForAdmin = new FormGroup({
    emp_id: new FormControl(null, Validators.required),
    ltype_id: new FormControl(null, Validators.required),
    leave_from: new FormControl(null, Validators.required),
    leave_to: new FormControl(null, Validators.required),
    leave_reason: new FormControl(null, Validators.required),
    status: new FormControl(null, Validators.required)
  });

  open(): void{
    this.visible = true;
  }

  close(): void{
    this.visible = false;
  }

  addLeave(): void {
    for (const i in this.employeeLeaveFormForAdmin.controls){
      this.employeeLeaveFormForAdmin.controls[i].markAsDirty();
      this.employeeLeaveFormForAdmin.controls[i].updateValueAndValidity();
    }
    this.employeeLeaveFormForAdmin.patchValue({
      // date: moment(selectedDate).format('YYYY-MM-DD'),
      leave_from: this.dateIn,
      leave_to: this.dateOut

  });
  console.log('here' , (this.employeeLeaveFormForAdmin.value));
    if(this.employeeLeaveFormForAdmin.valid){
    this.employeeLeaveFormForAdmin.value.leave_from = moment(this.employeeLeaveFormForAdmin.value.leave_from).format('YYYY-MM-DD');
    this.employeeLeaveFormForAdmin.value.leave_to = moment(this.employeeLeaveFormForAdmin.value.leave_to).format('YYYY-MM-DD');
      this._EmployeesService.createEmployeeLeave(this.employeeLeaveFormForAdmin.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          console.log('response :', responseObj)
          if(responseObj.message == 'already-exists')
          {
            this.notification.create(
              'error',
              'Add Leave',
              'Leave Already Exists'
              );            
          }
          else if(responseObj.message == 'failed')
          {
            this.notification.create(
              'error',
              'Add Leave',
              'Cant Add Leave'
              );            
          }
          else if(responseObj.message == 'success')
          {
            this.visible = false;
            this.notification.create(
              'success',
              'Add Leave',
              'Successfully Added Leave'
              );
              // update data
              

            this.router.navigate(['/leave'])
            // this.document.location.href = '/leave';
          }
          else{
            this.notification.create(
              'error',
              'Add Leave',
              'Somethings Wrong'
              );            

            this.router.navigate(['/leave'])
            // this.document.location.href = '/leave';
          }
        }
      );
    }
  }

 
  onDateChange(ev){
    if(ev !== null && this.dateOut != null && this.dateIn != null){
      const dateIn = moment.duration(this.dateIn).asDays();
      const dateOut = moment.duration(this.dateOut).asDays();
      console.log(dateIn);
      console.log(dateOut);
      if(dateOut <= dateIn){
        this.error = true
        console.log(this.dateIn);
        console.log(this.dateOut);
      }else{
        this.error = false;
        console.log(this.dateIn);
        console.log(this.dateOut);
      
      }
    }
  }
}
