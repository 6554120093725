import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-designations',
  templateUrl: './designations.component.html',
  styleUrls: ['./designations.component.scss']
})
export class DesignationsComponent implements OnInit {
  isOkLoading;
  loading = true;

  departments;
  constructor( 
    private _SettingsService: SettingsService) { }
  public errorMsg;

  listOfDisplayData;
  listOfAllData;
  ngOnInit(): void {
    this._SettingsService.getDesignations().subscribe(data=> this.listOfAllData = data,
      error => this.errorMsg = error);
    this.listOfDisplayData = this.listOfAllData;

    this._SettingsService.getDepartments().subscribe(data=> this.departments = data,
      error => this.errorMsg = error);
  }

  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  designationToDeleteId = 0;
  selectedDepartment;

  designationForm = new FormGroup({
    department_id: new FormControl(null, Validators.required),
    designation: new FormControl(null, Validators.required)
  });

  designationEditForm = new FormGroup({
    id: new FormControl(null),
    department_id: new FormControl(null, Validators.required),
    designation: new FormControl(null, Validators.required)
  });

  open(): void {
    this._SettingsService.getDepartments().subscribe(data=> {
      this.departments = data;
      this.visible = true;
    },
      error => this.errorMsg = error);
  }
  close(): void {
    this.visible = false;
  }

  openEdit(id): void {
    this.visibleEdit = true;

    let data = this.listOfAllData.find(data => data.id == id);
    this.selectedDepartment = data.department_id;

    this.designationEditForm.patchValue({
      id : id,
      designation: data.designation
    });
  }
  closeEdit(): void {
    this.visibleEdit = false;
  }

  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.designationToDeleteId = id;
  }

  closeDeleteModal(): void{
    this.visibleDelete = false;
  }

  AddDesignation(){
    this._SettingsService.createDesignation(this.designationForm.value).subscribe(
      data=> {
        this._SettingsService.getDesignations().subscribe(data=> this.listOfAllData = data,
          error => this.errorMsg = error);
        this.listOfDisplayData = this.listOfAllData;
      },
      error => this.errorMsg = error
    );
  }

  editDesignation(){
    this._SettingsService.updateDesignation(this.designationEditForm.value).subscribe(
      response => {
        let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'success')
        {
          this._SettingsService.getDesignations().subscribe(data=> this.listOfAllData = data,
            error => this.errorMsg = error);
          this.listOfDisplayData = this.listOfAllData;
        }
        else if(responseObj.message == 'failed')
        {
          alert('Faild');
        }
        else
        {
          alert(JSON.stringify(response));
        }
      }
    );
  }

  deleteDesignation(id){
    this._SettingsService.deleteDesignation(id).subscribe(
      data=> {
        this._SettingsService.getDesignations().subscribe(data=> this.listOfAllData = data,
          error => this.errorMsg = error);
        this.visibleDelete = false;
        this.listOfDisplayData = this.listOfAllData;
      },
      error => this.errorMsg = error
    );
  }

}
