import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { SlackService } from 'src/app/services/slack.service';
import * as moment from 'moment';
import { Subscriber } from 'rxjs';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Component({
  selector: 'app-slack-channel',
  templateUrl: './slack-channel.component.html',
  styleUrls: ['./slack-channel.component.scss']
})

 
export class SlackChannelComponent implements OnInit {
   

  loading = false;
  slackChannels =[];
  messages = [];
  newMessages: any[];
  Nemessages:[];
  channelId;
  token;
  messageTime;
  loginForm = new FormGroup({
    message: new FormControl(null, Validators.required)
  });

  loggedInUser = this.storage.get('loggedInUser');
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _slackService: SlackService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
    ) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => {
        // do your task for before route
        return false;
      }
      this.newMessages= [];
     }
chanel = [];
singleChanel=[];
  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
     this.channelId=  id;
   
    if(id){
      this._slackService.getSavedAccessToken(this.loggedInUser.id).subscribe((data:any)=>{
        if(data.length == 0){
        }else{
          let startTime = data[0].created_at;
          let end = moment();
          
          var duration = moment.duration(end.diff(startTime));
          var seconds = duration.asSeconds();

            if(seconds < data[0].expiry){
            this.token = data[0].access_token;

            this._slackService.getChannels(data[0].access_token).subscribe(async (channels:any)=>{
              this.chanel = channels.channels;
               
              this.singleChanel = this.chanel.find(data => data.id == id);
              console.log(this.chanel);
              console.log(this.singleChanel);
        })

              this._slackService.getChannelMessages(data[0].access_token , id).subscribe((channelmsg:any)=>{
                if(channelmsg.ok){
                  console.log(channelmsg);
                   this.messages = channelmsg.messages;
                  this.messages.sort((b, a) => (a.ts) - (b.ts));
                  console.log(this.messages);
                   this.messages.map((msgs)=>{ 
                     this.newMessages.push({ 
                      bot_id: msgs.bot_id, bot_profile: msgs.bot_profile, team: msgs.team, text: msgs.text, type: msgs.type, user: msgs.user, userName: msgs.userName, ts: moment.utc(msgs.ts*1000).local().format('DD-MM-YYYY HH:mm:ss'),
       
                     })
                     
                   })
                   console.log(this.newMessages);
                   this.newMessages.map((msg)=>{
                    this._slackService.getUserProfile(data[0].access_token, msg.user).subscribe((user:any)=>{
                      msg.userName = user.profile.real_name;
                      this.newMessages.push(msg);
                     
                      
                    });
                   })
                   this.finalMessage = this.newMessages;
                }
              }, error => console.log(error))

            }

          }
      }, error => console.log(error));
    }
    
  }
  emojisList=[];
//   onclick(){
//     this._slackService.emojiList(this.token).subscribe((data:any)=>{
// this.emojisList = data;
// console.log(data);
//     });
//   }
  
  finalMessage: any[];
  onSubmit() {
    for (const i in this.loginForm.controls) {
      this.loginForm.controls[i].markAsDirty();
      this.loginForm.controls[i].updateValueAndValidity();
    }
    if(this.loginForm.valid){
        this._slackService.createMessage(this.token, this.channelId, this.loginForm.value.message).subscribe(
          (response:any) => {
            if(response.ok){
              this.messages = [];
              this.newMessages=[];
              this.loginForm.reset();
              this._slackService.getChannelMessages(this.token , this.channelId).subscribe((channelmsg:any)=>{
                if(channelmsg.ok){
                  console.log(channelmsg);
                  this.messages = channelmsg.messages;
                  this.messages.sort((b, a) => (a.ts) - (b.ts));
                  console.log(this.messages);
                  this.messages.map((msgs)=>{ 
                    this.newMessages.push({ 
                     bot_id: msgs.bot_id, bot_profile: msgs.bot_profile, team: msgs.team, text: msgs.text, type: msgs.type, user: msgs.user, userName: msgs.userName, ts: moment.utc(msgs.ts*1000).local().format('DD-MM-YYYY HH:mm:ss'),
      
                    })
                    
                  })
                  this.newMessages.map((msg)=>{
                    this._slackService.getUserProfile(this.token, msg.user).subscribe((user:any)=>{
                      msg.userName = user.profile.real_name;
                      this.newMessages=[];
                      this.newMessages.push(msg);
                     
                      
                    });
                   })
                   this.finalMessage = this.newMessages;
                }
              }, error => console.log(error))
              
            }
          }
        )
    }
  }

}
