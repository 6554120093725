import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Router } from "@angular/router";

@Component({
  selector: 'app-add-tax-rule',
  templateUrl: './add-tax-rule.component.html',
  styleUrls: ['./add-tax-rule.component.scss']
})
export class AddTaxRuleComponent implements OnInit {
  taxForm = new FormGroup({
    tax_name: new FormControl(null),
    status: new FormControl(null)
  });

  constructor(private router: Router,
  private notification: NzNotificationService,
  private _SettingsService: SettingsService) { }


  ngOnInit() {
  }


  addTaxRule(): void {
    for (const i in this.taxForm.controls) {
      this.taxForm.controls[i].markAsDirty();
      this.taxForm.controls[i].updateValueAndValidity();
    }

    if(this.taxForm.valid)
    {
      this._SettingsService.createTax(this.taxForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            this.notification.create(
              'error',
              'Tax Rules',
              'Already Exists'
              );
          }else if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Tax Rules',
              'Unable to save changes'
              );
          }else if(responseObj.message == 'success'){
            this.router.navigate(['/tax-rule'])
            this.notification.create(
              'success',
              'Tax Rules',
              'Successfully published'
              );
          }else{
            this.router.navigate(['/tax-rule'])
            this.notification.create(
              'success',
              'Tax Rules',
              'Successfully published'
              );
          }
        }
      )
    }
  }
  

}
