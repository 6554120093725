import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training-goals',
  templateUrl: './training-goals.component.html',
  styleUrls: ['./training-goals.component.scss']
})
export class TrainingGoalsComponent implements OnInit {

  constructor(
) { }

  ngOnInit() {
  }
}
