import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-integrations-list',
  templateUrl: './integrations-list.component.html',
  styleUrls: ['./integrations-list.component.scss']
})
export class IntegrationsListComponent implements OnInit {
  isOkLoading = false;
  constructor(private _SettingsService: SettingsService) { }
  public errorMsg;
  listOfAllData;
  visibleDelete= false;
  moduleToUninstallId;

  ngOnInit() {
    this._SettingsService.getEnabledStoreItems().subscribe(data=> 
      { this.listOfAllData = data;
      console.log(data) }
      ,
      error => this.errorMsg = error);
      this.isOkLoading = false;
  }

  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.moduleToUninstallId = id;
  }

  closeDeleteModal(): void{
    this.visibleDelete = false;
  }
  
  uninstall(id){
    this._SettingsService.uninstallStoreItemById(id).subscribe(
      data=> {
        this.visibleDelete = false;
        this._SettingsService.getEnabledStoreItems().subscribe(data=> 
          { this.listOfAllData = data;
          console.log(data) }
          ,
          error => this.errorMsg = error);
        
      },
      error => this.errorMsg = error
    );
  }
}
