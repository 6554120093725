import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UploadChangeParam } from 'ng-zorro-antd/upload';
import { JobsService } from 'src/app/services/jobs.service'; 

@Component({
  selector: 'app-job-dashboard',
  templateUrl: './job-dashboard.component.html',
  styleUrls: ['./job-dashboard.component.scss']
})
export class JobDashboardComponent implements OnInit {
  isOkLoading = false;
  loggedInUser = this.storage.get('loggedInUser');

  textValue: string | null = null;
  visible = false;
  visibleAdd = false;
  visibleEdit = false;
  visibleEditCustomer = false;
  visibleLink = false;
  visibleMerge = false;
  visibleClone = false
  visibleEditAddress = false;
  visibleEditAddress02= false;
  visibleRecord = false;
  
  listOfAllData;
  data;
  contactId;
  visibleDelete = false;
  errorMsg;

  contactForm = new FormGroup({
    customer_id: new FormControl(this.route.snapshot.paramMap.get('id')),
    title: new FormControl(null),
    fname: new FormControl(null, Validators.required),
    lname: new FormControl(null),
    company: new FormControl(null),
    email: new FormControl(null, Validators.required),
    phone: new FormControl(null),
    mobile: new FormControl(null),
    skype: new FormControl(null),
    designation: new FormControl(null),
    department: new FormControl(null),
  });
  

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService, 
    private route: ActivatedRoute,
    private router: Router,
    private msg: NzMessageService,
    private _jobsService: JobsService
    ) { }
  
  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');

    this._jobsService.getJobs().subscribe(
      data=> { 
        this.listOfAllData = data;
        this.data = this.listOfAllData.find(o => o.id == id);
      },
      error => this.errorMsg = error);

      this.isOkLoading =  false;
  }

  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.contactId = id;
  }

  openMerge(): void{
    this.visibleMerge = true;
  }
  closeMerge(): void{
    this.visibleMerge = false;
  }

  openClone(): void{
    this.visibleClone = true;
  }
  closeClone(): void{
    this.visibleClone = false;
  }
  closeDeleteModal(): void{
    this.visibleDelete = false;
  }
  deleteContact(contactId){}

  openEditCustomer(): void{
    this.visibleEditCustomer = true;
  }
  closeEditCustomer(): void{
    this.visibleEditCustomer = false;
  }

  open(): void{
    this.visible = true;
  }
  close(): void{
    this.visible = false;
  }

  openLink(): void{
    this.visibleLink = true;
  }
  closeLink(): void{
    this.visibleLink = false;
  }

  openAdd(): void{
    this.visibleAdd = true;
  }
  closeAdd(): void{
    this.visibleAdd = false;
  }

  openEdit(): void{
    this.visibleEdit = true;
  }
  closeEdit(): void{
    this.visibleEdit = false;
  }

  openEditAddress(): void{
    this.visibleEditAddress = true;
  }
  closeEditAddress(): void{
    this.visibleEditAddress = false;
  }

  openEditAddress02(): void{
    this.visibleEditAddress02 = true;
  }
  closeEditAddress02(): void{
    this.visibleEditAddress02 = false;
  }
  openRecord(): void{
    this.visibleRecord = true;
  }

  closeRecord(): void{
    this.visibleRecord = false;
  }
  handleChange(info: UploadChangeParam): void {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      this.msg.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`);
    }
  }
}
