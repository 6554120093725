import { Component,OnInit, ViewChild } from '@angular/core';
 
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexGrid
} from "ng-apexcharts";
import { transpileModule } from 'typescript';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  grid: ApexGrid;
};

@Component({
  selector: 'app-double-bar-chart-small',
  templateUrl: './double-bar-chart-small.component.html',
  styleUrls: ['./double-bar-chart-small.component.scss']
})
export class DoubleBarChartSmallComponent implements OnInit {

  
  @ViewChild("chart7", null)
  chart: ChartComponent = new ChartComponent;
  public chartOptions: Partial<any>;
  constructor() { 
    this.chartOptions = {
      series: [
        {
          name: "Total Salary",
          data: [44, 37, 79, 63, 54, 71 ]
        },
        {
          name: "Allowance",
          data: [40, 33, 28, 58, 50, 62]
        }
      ],
      chart: {
        height: 190,
        fontFamily: 'Roboto, sans-serif',
        redrawOnParentResize: true,
        type: "bar",
        toolbar: { tools: { download: false } }
      },
      
      
      xaxis: {
        labels:{
          style:{
            fontWeight: 200,
            colors:['#8A8A8A','#8A8A8A','#8A8A8A','#8A8A8A','#8A8A8A','#8A8A8A']
          }
        },
        axisBorder: {
          show: false
        },
        categories: ["Jan", "Feb",  "Mar",  "Apr",  "May",  "Jun" ]
      },
      yaxis: {
        labels:{
          style:{
            fontWeight: 200,
            colors:['#8A8A8A','#8A8A8A','#8A8A8A','#8A8A8A','#8A8A8A','#8A8A8A']
          },
          formatter: function (value: string) {
            return "$" + value + "k";
          }
        },
        axisBorder: {
          show: false
        },
         
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "35%",
          borderRadius: 2,
          endingShape: "rounded"
          
        }
      },dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 0.8,
        colors: ["transparent"]
      }, 
      fill: {
        colors: ['#8B85BF', '#FAAD51']
      },
      grid: {
        show: true,      // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: false //or just here to disable only x axis grids
           }
         },  
        yaxis: {
          lines: { 
            show: false  //or just here to disable only y axis
           }
         }   
      },
     
      legend: {
        show: false
      },
      tooltip: {
        y: {
          formatter: function(val: string) {
            return "$ " + val + " thousands";
          }
        }
      }
    };
  }


  ngOnInit(): void {
  }

}
