import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { SettingsService } from 'src/app/services/settings.service';
import { SupportticketsService } from 'src/app/services/supporttickets.service';

@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.scss']
})
export class AddDepartmentComponent implements OnInit {
  listOfSupportTicketData;
  supportTicketLoading = true;
  jobs;
  dateFormat = 'MMM dd, yyyy';

  drawerVisible = false;
  
  fileToUpload: File;
  validUpload = false;
  loggedInUser = this.storage.get('loggedInUser');
  
  loading = true;
  
  departmentForm = this.fb.group({
    
    name: new FormControl(null),
     
  });
 
   
  
  constructor(private _supportTicketsService: SupportticketsService, 
    private router: Router,
    private notification: NzNotificationService,
    private msg: NzMessageService,
    @Inject(LOCAL_STORAGE) private storage: StorageService, private fb: FormBuilder) { }
  public errorMsg;

  ngOnInit(): void {
    this._supportTicketsService.getDepartment().subscribe(
      data=> { 
        this.listOfSupportTicketData = data;
        this.supportTicketLoading = false;
      },
      error => this.errorMsg = error);
      
  }

 
 

  addDepartment(): void {
    for (const i in this.departmentForm.controls) {
      this.departmentForm.controls[i].markAsDirty();
      this.departmentForm.controls[i].updateValueAndValidity();
    }
    if(this.departmentForm.valid){
      this._supportTicketsService.createDepartment(this.departmentForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            this.notification.create(
              'error',
              'Department',
              'Already Exists'
              );
          }else if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Department',
              'Unable to save changes'
              );
          }else if(responseObj.message == 'success'){
            this.router.navigate(['/department'])
            this.notification.create(
              'success',
              'Department',
              'Successfully published'
              );
          }else{
            this.router.navigate(['/department'])
            this.notification.create(
              'success',
              'Department',
              'Successfully published'
              );
          }
        }
      )
    }
  }

 
  
 
  

 

}
