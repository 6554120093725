import { Component, OnInit, ViewChild, OnChanges } from "@angular/core";
import { EmployeesService } from "src/app/services/employees.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SettingsService } from "src/app/services/settings.service";
import { NzMessageService } from "ng-zorro-antd";
import { Inject } from "@angular/core";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import * as moment from "moment";
import { NzNotificationService } from "ng-zorro-antd";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { ExcelService } from "src/app/services/excel.service";
import { differenceBy } from "lodash";
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexGrid,
} from "ng-apexcharts";
import { transpileModule } from "typescript";
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  grid: ApexGrid;
};
@Component({
  selector: "app-attendance",
  templateUrl: "./attendance.component.html",
  styleUrls: ["./attendance.component.scss"],
})
export class AttendanceComponent implements OnInit {
  totals = [];
  employee_id;
  load = false;
  changeTable = false;
  syncAttendanceLoading: boolean = false;
  selectedValue;
  @ViewChild("chart", null)
  chart: ChartComponent = new ChartComponent();
  public chartOptions: Partial<any>;
  todayDate = moment().format("YYYY-MM-DD");
  // chart
  visisbleId;
  sortName: string | null = null;
  sortValue: string | null = null;
  searchAddress: string;
  listOfName = [];
  listOfAddress = [];
  listOfSearchName: string[] = [];
  listOfData: Attendance[] = [];
  listOfDisplayData: Attendance[] = [...this.listOfData];
  listOfAllData: Attendance[];
  formattedListOfAttendance: any[] = [];
  sort(sort: { key: string; value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.search();
  }
  filter(listOfSearchName: string[], searchAddress: string): void {
    this.listOfSearchName = listOfSearchName;
    this.searchAddress = searchAddress;
    this.search();
  }
  search(): void {
    /** filter data **/
    const filterFunc = (item: Attendance) =>
      (this.listOfSearchName.length ? this.listOfSearchName.some(name => item.fname.indexOf(name) !== -1) : true);
    const data = this.listOfData.filter(item => filterFunc(item));
    /** sort data **/
    if (this.sortName && this.sortValue) {
      this.listOfDisplayData = data.sort((a, b) =>
        this.sortValue === 'ascend'
          ? a[this.sortName!] > b[this.sortName!]
            ? 1
            : -1
          : b[this.sortName!] > a[this.sortName!]
          ? 1
          : -1
      );
    } else {
      this.listOfDisplayData = data;
      console.log(this.listOfDisplayData,"hbere is is")
    }
  }
  firstDay;
  lastDay;
  timeinToday;
  timeoutToday;
  listOfAttendanceDataForToday;
  yearSelectedFromYearPicker;
  attendanceDataByUserId;
  listOfattendanceData;
  listOfEmployeesData;
  employeeDataLoading = true;
  listOfAllEmployees;
  leave_emp;
  attendanceDataLoading = true;
  totalHours;
  loggedInUser = this.storage.get("loggedInUser");
  public errorMsg;
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  attendanceToDeleteId = 0;
  isOkLoading = false;
  departments;
  designations;
  selectedEmployee;
  workFromHome = false;
  employeeName = "";
  searchForm = new FormGroup({
    emp_id: new FormControl(null),
    dateFrom: new FormControl(null, Validators.required),
    dateTo: new FormControl(null, Validators.required),
    department: new FormControl(null),
    designation: new FormControl(null),
  });
  attendanceForm = new FormGroup({
    emp_id: new FormControl(null, Validators.required),
    date: new FormControl(null, Validators.required),
    clock_in: new FormControl(null, Validators.required),
    clock_out: new FormControl(null, Validators.required),
    work_from_home: new FormControl(null),
    work_from_home_total: new FormControl(null),
  });
  attendanceEditForm = new FormGroup({
    id: new FormControl(),
    emp_id: new FormControl(null),
    date: new FormControl(null, Validators.required),
    clock_in: new FormControl(null, Validators.required),
    clock_out: new FormControl(null, Validators.required),
    work_from_home: new FormControl(null),
    work_from_home_total: new FormControl(null),
  });
  selectedYear;
  employedata = [];
  yearSelected(date: Date): void {
    // this.totalLeavesLeft = (this.sickLeaves + this.OtherLeaves) - (this.sickLeaveAvailed + this.OtherLeaveAvailed);
    let date1 = moment(date).year();
    this.selectedYear = date1;
    this.plotYearlyAttendanceChart(this.listOfData);
  }
  plotYearlyAttendanceChart(dataInput) {
    this.employedata = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    dataInput.forEach((data) => {
      let dataYear = moment(data.date).year();
      let dataMonth = moment(data.date).month();
      if (this.selectedYear == dataYear) {
        this.employedata[dataMonth]++;
        console.log("emplData", this.employedata);
        console.log("selectedYear", this.selectedYear);
      }
    });
    this.chartOptions.series = [
      {
        name: "Attendance",
        data: this.employedata,
      },
    ];
  }
  linechart() {
    // this.getUserDashboardDetails();
    this.employedata = [10, 14, 8, 9, 6, 4, 5, 10, 15, 20, 33, 10];
    this.chartOptions = {
      series: [
        {
          name: "Attendance",
          data: this.employedata,
        },
      ],
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",

        // colors:['#ef4c77','#E91E63', '#9C27B0'],
      },
      fill: {
        type: "gradient",
        gradient: {
          opacityFrom: 0.6,
          opacityTo: 0.8,
        },
      },
      title: {
        text: "Attendance of The Year",
        //align: "left"
      },
      subtitle: {
        text: "Attendance By Month",
        //align: "left"
      },
      labels: this.employedata,
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        opposite: true,
      },
      legend: {
        horizontalAlign: "right",
      },
      colors: ["#ef4c77"],
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
    };
  }
  constructor(
    private excelService: ExcelService,
    private notification: NzNotificationService,
    private _EmployeesService: EmployeesService,
    private _SettingsService: SettingsService,
    private msg: NzMessageService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {
    // chart
    this.chartOptions = {
      colors: ["#52459d", "#84e8f5"],
      series: [
        {
          name: "Desktops",
          data: [62, 50, 68, 51, 49, 62, 69, 91, 148, 62, 69, 91],
        },
        // {
        //   name: "Desktops",
        //   data: [58, 54, 65, 51, 49, 63, 69, 9, 148 , 41, 5, 51]
        // }
      ],
      chart: {
        height: 180,
        redrawOnParentResize: true,

        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: { tools: { download: false } },
      },
      fill: {
        colors: ["#52459d", "#84e8f5"],
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: "2",
      },
      yaxis: {
        show: false,
      },
      legend: {
        show: false,
      },
      grid: {
        show: true, // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: true, //or just here to disable only x axis grids
          },
        },
        yaxis: {
          lines: {
            show: false, //or just here to disable only y axis
          },
        },
      },
      xaxis: {
        labels: {
          style: {
            fontWeight: 600,
            colors: [
              "#d5d8db",
              "#d5d8db",
              "#d5d8db",
              "#d5d8db",
              "#d5d8db",
              "#d5d8db",
              "#d5d8db",
              "#d5d8db",
              "#d5d8db",
              "#d5d8db",
              "#d5d8db",
              "#d5d8db",
            ],
          },
        },
        axisBorder: {
          show: false,
        },
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    };
    // chart
  }
  totalWorkingDaysThisMonth = 0;
  totalAttendanceThisMonth = 0;
  ngOnInit() {
    this._EmployeesService.getHrDashboardStats().subscribe((res) => {
      console.log("Abesent", res);
    });
    this.firstDay = moment().format("YYYY-MM-01");
    this.lastDay = moment().format("YYYY-MM-") + moment().daysInMonth();
    for (
      var m = moment(this.firstDay);
      m.isBefore(this.lastDay);
      m.add(1, "days")
    ) {
      this._EmployeesService
        .getAttendanceByDateAndEmployeeId(m.format("YYYY-MM-DD"))
        .subscribe(
          (data) => {
            if (data) {
              this.totalAttendanceThisMonth += 1;
            }
          },
          (error) => console.log(error)
        );
      if (m.isoWeekday() !== 6 && m.isoWeekday() !== 7) {
        this._EmployeesService
          .getHolidayByDate(m.format("YYYY-MM-DD"))
          .subscribe(
            (response) => {
              let responseObj = JSON.parse(JSON.stringify(response));
              if (responseObj.message == "failed") {
                this.totalWorkingDaysThisMonth += 1;
              }
            },
            (error) => console.log(error)
          );
      }
    }
    this._EmployeesService
      .getAttendanceByDateAndEmployeeId(moment().format("YYYY-MM-DD"))
      .subscribe(
        (data) => {
          this.listOfAttendanceDataForToday = data;
          this.timeinToday = this.listOfAttendanceDataForToday.clock_in;
          this.timeoutToday = this.listOfAttendanceDataForToday.clock_out;
        },
        (error) => console.log(error)
      );
    // get employees list
    this._EmployeesService.getEmployees().subscribe(
      (data) => {
        this.listOfAllEmployees = data;
        console.log(
          this.listOfAllEmployees,
          "sorted employess: ",
          this.listOfAllEmployees.sort(function (a, b) {
            if (a.fname < b.fname) {
              return -1;
            }
            if (a.fname > b.fname) {
              return 1;
            }
            return 0;
          })
        );
        // this.selectedEmployee = this.listOfAllEmployees.find(o => o.id == 1);
        this.listOfEmployeesData = this.listOfAllEmployees.sort(function (
          a,
          b
        ) {
          if (a.fname < b.fname) {
            return -1;
          }
          if (a.fname > b.fname) {
            return 1;
          }
          return 0;
        });
        this.employeeDataLoading = false;
      },
      (error) => console.log(error.message)
    );
    if (this.loggedInUser.role_id !== 2) {
      this._EmployeesService.getEmployeeLeave().subscribe((data) => {
        this.leave_emp = data;
        console.log("get leave : ", this.leave_emp);
      });
      // get employees list
      this._EmployeesService.getAttendance().subscribe((data) => {
        this.listOfattendanceData = data;
        this.listOfData = data;
        this.load = true;
        data.map(function (entry) {
          entry.date = moment(entry.date).format("DD-MM-YYYY");
          entry.clock_in = moment(entry.clock_in, ["HH:mm:ss"]).format(
            "hh:mm"
          );
          if (entry.clock_out != null) {
            entry.clock_out = moment(entry.clock_out, ["HH:mm:ss"]).format(
              "hh:mm"
            );
          } else {
            entry.clock_out = "Active";
          }
        });
        this.listOfDisplayData = data;

        this.attendanceDataLoading = false;
      });
    } else {
      this._EmployeesService
        .getAttendanceById(this.loggedInUser.id)
        .subscribe((data) => {
          this.listOfattendanceData = data;
          this.listOfData = data;
          this.load = true;
          data.map(function (entry) {
            entry.date = moment(entry.date).format("DD-MM-YYYY");
            entry.clock_in = moment(entry.clock_in, ["HH:mm:ss"]).format(
              "hh:mm"
            );
            if (entry.clock_out != null) {
              entry.clock_out = moment(entry.clock_out, ["HH:mm:ss"]).format(
                "hh:mm"
              );
            } else {
              entry.clock_out = "Active";
            }
          });
          this.listOfDisplayData = data;
          console.log(this.listOfDisplayData);
          this.listOfAttendanceDataForToday = this.listOfattendanceData;
          this.attendanceDataLoading = false;
        });
    }

    this._SettingsService
      .getDepartments()
      .subscribe((data) => (this.departments = data));
    this._SettingsService
      .getDesignations()
      .subscribe((data) => (this.designations = data));

    // build chart
    // this.yearSelectedFromYearPicker = 'Fri Jan 01 2021 05:00:00 GMT+0500 (Pakistan Standard Time)'
    // this.selectedYear = 2021;
    // this.plotYearlyAttendanceChart(this.listOfAllData)
    // build chart
  }
  download() {
    let now_date;
    this.listOfDisplayData.map((e) => {
      if (e.work_from_home == undefined) e.work_from_home = "Not Found";
    });
    const results = this.listOfAllEmployees.filter(
      ({ id: id1 }) =>
        !this.listOfDisplayData.some(({ emp_id: id2 }) => id2 === id1)
    );
    const result_leave = this.leave_emp.filter(({ emp_id: id2 }) =>
    results.some(({ id: id1 }) => id2 === id1)
  );
    const results_absent = results.filter(
      ({ id: id1 }) =>
        !result_leave.some(({ emp_id: id2 }) => id2 === id1)
    );

    let newSheet = [];
    var count = 0;
    var count_two = 0;
    this.listOfDisplayData.map((dt, index) => {
      now_date = dt.date;
      console.log("check WOrk from home", dt);
      count += 1;
      newSheet.push({
        "S.No": index + 1,
        "First Name": dt.fname,
        "Last Name": dt.lname,
        Date: dt.date,
        Day: moment(dt.date, "YYYY-MM-DD").format("dddd"),
        "Clock In": dt.clock_in,
        "Clock Out": dt.clock_out,
        Late: dt.late > 0 ? "Yes" : "No",
        Total: this.getTimeFromMins(dt.total),
        "Work From Home": dt.status === "Work From Home" ? "Yes" : "No",
        Notes: "",
      });
    });
    count++;
    
   
    console.log("result leave:", result_leave);
    result_leave.map((result, index) => {
      console.log('from',result.leave_from)
      count_two +=1;
      if (now_date >= result.leave_from && now_date <= result.leave_to) {
        console.log('here',result);
        if(result.status === 'approved'){
      newSheet.push({
        "S.No": count + index,
        "First Name": result.fname,
        "Last Name": result.lname,
        Date: "Leave",
      });
      }
      else{
        newSheet.push({
          "S.No": count + index,
          "First Name": result.fname,
          "Last Name": result.lname,
          Date: "Absent",
        });
      }
    }
    else{
      
      newSheet.push({
        "S.No": count + index,
        "First Name": result.fname,
        "Last Name": result.lname,
        Date: "Absent",
      });
    }
    });
    console.log('count two: ',count_two)
    results_absent.map((result, index) => {
      
      newSheet.push({
        "S.No": count + index+count_two,
        "First Name": result.fname,
        "Last Name": result.lname,
        Date: "Absent",
      });
    });
    console.log("now_date ", now_date);
    debugger;
    console.log("leave", this.leave_emp);
    this.excelService.exportAsExcelFile(newSheet, "attendance");
  }

  downloadTotal() {
    let displayDataJSON = JSON.stringify(this.listOfDisplayData);
    let displayData = JSON.parse(displayDataJSON);

    var count = 0;
    let totals = [];

    displayData.map((x) => {
      const obj = totals.find(
        (o) =>
          o.emp_id === x.emp_id && o.date.slice(3, 5) === x.date.slice(3, 5)
      );
      if (obj) {
        obj.total = obj.total + x.total;
        obj.date = obj.date + "," + x.date;

        console.log("helllo", obj);
      } else {
        totals.push(x);
      }
    });
    const results = this.listOfAllEmployees.filter(
      ({ id: id1 }) => !displayData.some(({ emp_id: id2 }) => id2 === id1)
    );
    let newSheet = [];
    console.log("total: ", totals);
    totals.map((dt, index) => {
      let countWeek = 0;
      if (dt.status === "Work From Home") {
        countWeek++;
      }
      const islamabad =
        (dt.fname == "Bilal" && dt.lname == "Khan") ||
        (dt.fname == "Asfa" && dt.lname == "Muazzam") ||
        (dt.fname == "Jahanzaib" && dt.lname == "Khan") ||
        (dt.fname == "Hashir" && dt.lname == "Nauman") ||
        (dt.fname == "Zain" && dt.lname == "Jahangir")
          ? "Islamabad"
          : "Karachi";
      newSheet.push({
        "S.No": index + 1,
        "First Name": dt.fname,
        "Last Name": dt.lname,
        Day: this.countDays(dt.date),
        Total: this.getTimeFromMins(dt.total),
        location: islamabad,
        "Work from home": countWeek,
      });
    });
    count++;
    results.map((result, index) => {
      console.log(result);
      newSheet.push({
        "S.No": count + index,
        "First Name": result.fname,
        "Last Name": result.lname,
      });
    });
    this.excelService.exportAsExcelFile(newSheet, "attendance_log");
  }
  countDays(datesString) {
    const dates = [];

    const dateStrings = datesString.split(",");

    for (const dateString of dateStrings) {
      dates.push(dateString);
    }
    return dates.length;
  }

  changeDepartment(ev): void {
    if (ev) {
      this._SettingsService
        .getDesignationsByDepartmentId(ev)
        .subscribe((data) => {
          this.designations = data;
        });
      this._EmployeesService.getEmployeesByDepartmentId(ev).subscribe(
        (data) => {
          this.listOfEmployeesData = data;
        },
        (error) => console.log(error.message)
      );
    }
  }
  changeDesignation(ev): void {
    if (ev) {
      this._EmployeesService.getEmployeesByDesignationId(ev).subscribe(
        (data) => {
          this.listOfEmployeesData = data;
        },
        (error) => console.log(error.message)
      );
    }
  }
  searchAttendance(): void {
    for (const i in this.searchForm.controls) {
      this.searchForm.controls[i].markAsDirty();
      this.searchForm.controls[i].updateValueAndValidity();
    }
    if (this.searchForm.valid) {
      this.searchForm.value.dateTo = moment(
        this.searchForm.value.dateTo
      ).format("YYYY-MM-DD");
      this.searchForm.value.dateFrom = moment(
        this.searchForm.value.dateFrom
      ).format("YYYY-MM-DD");
      console.log(this.searchForm.value.emp_id);
      console.log(this.searchForm.value.dateFrom);
      console.log(this.searchForm.value.dateTo);
      this._EmployeesService
        .searchAttendance(this.searchForm.value)
        .subscribe((data) => {
          this.listOfattendanceData = data;
          this.listOfData = data;
          this.listOfDisplayData = data;
          this.attendanceDataByUserId = data.filter(
            (data) => data.emp_id == this.loggedInUser.id
          );
        });
    }
  }
  selectedDuration;
  firstDayThisWeek;
  lastDayThisWeek;
  thisWeekSearchForm = new FormGroup({
    dateFrom: new FormControl(null, Validators.required),
    dateTo: new FormControl(null, Validators.required),
  });
  thisWeekSearchFormEmployee = new FormGroup({
    emp_id: new FormControl(null, Validators.required),
    dateFrom: new FormControl(null, Validators.required),
    dateTo: new FormControl(null, Validators.required),
  });

  durationSelected(ev) {
    console.log(ev);
    if (this.loggedInUser.role_id !== 2) {
      this.changeTable = true;
      if (ev == 7) {
        let todayDate = {
          dateFrom: moment().format("YYYY-MM-DD"),
          dateTo: moment().format("YYYY-MM-DD"),
        };
        console.log(todayDate);
        this._EmployeesService.searchAttendance(todayDate).subscribe((data) => {
          this.listOfDisplayData = data;
        });
      } else if (ev == 1) {
        this.changeTable = false;
        this.firstDayThisWeek = moment()
          .startOf("week")
          .add(1, "d")
          .format("YYYY-MM-DD");
        this.lastDayThisWeek = moment().endOf("week").format("YYYY-MM-DD");
        console.log(this.firstDayThisWeek);
        console.log(this.lastDayThisWeek);
        this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
        this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
        console.log(this.thisWeekSearchForm.value);
        this._EmployeesService
          .searchAttendance(this.thisWeekSearchForm.value)
          .subscribe((data) => {
            this.listOfDisplayData = data;
            console.log(this.listOfDisplayData);
          });
      } else if (ev == 2) {
        this.changeTable = false;

        this.firstDayThisWeek = moment()
          .startOf("week")
          .subtract(6, "d")
          .format("YYYY-MM-DD");
        this.lastDayThisWeek = moment()
          .endOf("week")
          .subtract(7, "d")
          .format("YYYY-MM-DD");
        console.log(this.firstDayThisWeek);
        console.log(this.lastDayThisWeek);
        this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
        this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
        console.log(this.thisWeekSearchForm.value);
        this._EmployeesService
          .searchAttendance(this.thisWeekSearchForm.value)
          .subscribe((data) => {
            this.listOfDisplayData = data;
            console.log(this.listOfDisplayData);
          });
      } else if (ev == 3) {
        this.changeTable = false;

        this.firstDayThisWeek = moment().startOf("month").format("YYYY-MM-DD");
        this.lastDayThisWeek = moment().endOf("month").format("YYYY-MM-DD");
        console.log(this.firstDayThisWeek);
        console.log(this.lastDayThisWeek);
        this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
        this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
        console.log(this.thisWeekSearchForm.value);
        this._EmployeesService
          .searchAttendance(this.thisWeekSearchForm.value)
          .subscribe((data) => {
            this.listOfDisplayData = data;
            console.log(this.listOfDisplayData);
          });
      } else if (ev == 4) {
        this.changeTable = false;

        this.firstDayThisWeek = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.lastDayThisWeek = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        console.log(this.firstDayThisWeek);
        console.log(this.lastDayThisWeek);
        this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
        this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
        console.log(this.thisWeekSearchForm.value);
        this._EmployeesService
          .searchAttendance(this.thisWeekSearchForm.value)
          .subscribe((data) => {
            this.listOfDisplayData = data;
            console.log(this.listOfDisplayData);
          });
      } else if (ev == 5) {
        this.changeTable = false;

        this.firstDayThisWeek = moment().startOf("year").format("YYYY-MM-DD");
        this.lastDayThisWeek = moment().endOf("year").format("YYYY-MM-DD");
        console.log(this.firstDayThisWeek);
        console.log(this.lastDayThisWeek);
        this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
        this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
        console.log(this.thisWeekSearchForm.value);
        this._EmployeesService
          .searchAttendance(this.thisWeekSearchForm.value)
          .subscribe((data) => {
            this.listOfDisplayData = data;
            console.log(this.listOfDisplayData);
          });
      } else if (ev == 6) {
        this.changeTable = false;

        this._EmployeesService.getAttendance().subscribe((data) => {
          this.listOfDisplayData;
          this.listOfDisplayData = data;
          console.log(this.listOfDisplayData);
        });
      } else if (ev === 7) {
        console.log(ev);
      }
    } else {
      if (ev == 1) {
        this.firstDayThisWeek = moment()
          .startOf("week")
          .add(1, "d")
          .format("YYYY-MM-DD");
        this.lastDayThisWeek = moment().endOf("week").format("YYYY-MM-DD");
        console.log(this.firstDayThisWeek);
        console.log(this.lastDayThisWeek);
        this.thisWeekSearchFormEmployee.value.emp_id = this.loggedInUser.id;
        this.thisWeekSearchFormEmployee.value.dateFrom = this.firstDayThisWeek;
        this.thisWeekSearchFormEmployee.value.dateTo = this.lastDayThisWeek;
        console.log(this.thisWeekSearchFormEmployee.value);
        this._EmployeesService
          .searchAttendance(this.thisWeekSearchFormEmployee.value)
          .subscribe((data) => {
            this.listOfDisplayData = data;
            console.log(this.listOfDisplayData);
          });
      } else if (ev == 2) {
        this.firstDayThisWeek = moment()
          .startOf("week")
          .subtract(6, "d")
          .format("YYYY-MM-DD");
        this.lastDayThisWeek = moment()
          .endOf("week")
          .subtract(7, "d")
          .format("YYYY-MM-DD");
        console.log(this.firstDayThisWeek);
        console.log(this.lastDayThisWeek);
        this.thisWeekSearchFormEmployee.value.emp_id = this.loggedInUser.id;
        this.thisWeekSearchFormEmployee.value.dateFrom = this.firstDayThisWeek;
        this.thisWeekSearchFormEmployee.value.dateTo = this.lastDayThisWeek;
        console.log(this.thisWeekSearchFormEmployee.value);
        this._EmployeesService
          .searchAttendance(this.thisWeekSearchFormEmployee.value)
          .subscribe((data) => {
            this.listOfDisplayData = data;
            console.log(this.listOfDisplayData);
          });
      } else if (ev == 3) {
        this.firstDayThisWeek = moment().startOf("month").format("YYYY-MM-DD");
        this.lastDayThisWeek = moment().endOf("month").format("YYYY-MM-DD");
        console.log(this.firstDayThisWeek);
        console.log(this.lastDayThisWeek);
        this.thisWeekSearchFormEmployee.value.emp_id = this.loggedInUser.id;
        this.thisWeekSearchFormEmployee.value.dateFrom = this.firstDayThisWeek;
        this.thisWeekSearchFormEmployee.value.dateTo = this.lastDayThisWeek;
        console.log(this.thisWeekSearchFormEmployee.value);
        this._EmployeesService
          .searchAttendance(this.thisWeekSearchFormEmployee.value)
          .subscribe((data) => {
            this.listOfDisplayData = data;
            console.log(this.listOfDisplayData);
          });
      } else if (ev == 4) {
        this.firstDayThisWeek = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.lastDayThisWeek = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        console.log(this.firstDayThisWeek);
        console.log(this.lastDayThisWeek);
        this.thisWeekSearchFormEmployee.value.emp_id = this.loggedInUser.id;
        this.thisWeekSearchFormEmployee.value.dateFrom = this.firstDayThisWeek;
        this.thisWeekSearchFormEmployee.value.dateTo = this.lastDayThisWeek;
        console.log(this.thisWeekSearchFormEmployee.value);
        this._EmployeesService
          .searchAttendance(this.thisWeekSearchFormEmployee.value)
          .subscribe((data) => {
            this.listOfDisplayData = data;
            console.log(this.listOfDisplayData);
          });
      } else if (ev == 5) {
        this.firstDayThisWeek = moment().startOf("year").format("YYYY-MM-DD");
        this.lastDayThisWeek = moment().endOf("year").format("YYYY-MM-DD");
        console.log(this.firstDayThisWeek);
        console.log(this.lastDayThisWeek);
        this.thisWeekSearchFormEmployee.value.emp_id = this.loggedInUser.id;
        this.thisWeekSearchFormEmployee.value.dateFrom = this.firstDayThisWeek;
        this.thisWeekSearchFormEmployee.value.dateTo = this.lastDayThisWeek;
        console.log(this.thisWeekSearchFormEmployee.value);
        this._EmployeesService
          .searchAttendance(this.thisWeekSearchFormEmployee.value)
          .subscribe((data) => {
            this.listOfDisplayData = data;
            console.log(this.listOfDisplayData);
          });
      } else if (ev == 6) {
        this._EmployeesService
          .getAttendanceById(this.loggedInUser.id)
          .subscribe((data) => {
            this.listOfDisplayData;
            this.listOfDisplayData = data;
            console.log(this.listOfDisplayData);
          });
      }
    }
  }
  resetSearch(): void {
    this.searchForm.reset();
    this._EmployeesService.getAttendance().subscribe((data) => {
      this.listOfattendanceData = data;
      this.listOfData = data;
      this.listOfDisplayData = data;
    });
  }
  open(): void {
    this.attendanceForm.reset();
    this.visible = true;
  }
  close(): void {
    this.visible = false;
  }
  openEdit(id): void {
    this.visibleEdit = true;
    let data = this.listOfattendanceData.find((data) => data.id == id);
    this.employeeName =
      this.listOfEmployeesData.find((employee) => employee.id == data.emp_id)
        .fname +
      " " +
      this.listOfEmployeesData.find((employee) => employee.id == data.emp_id)
        .lname;
    this.attendanceEditForm.patchValue({
      id: id,
      emp_id: data.emp_id,
      date: new Date(data.date),
      clock_in: moment(data.clock_in, "H:mm a"),
      clock_out: moment(data.clock_out, "H:mm a"),
      work_from_home: data.status == "Work From Home" ? true : false,
      work_from_home_total: data.work_from_home_total,
    });
  }
  closeEdit(): void {
    this.visibleEdit = false;
  }

  //Delete Modal
  showDeleteModal(id): void {
    this.visibleDelete = true;
    this.attendanceToDeleteId = id;
  }

  closeDeleteModal(): void {
    this.visibleDelete = false;
  }

  addAttendance(): void {
    for (const i in this.attendanceForm.controls) {
      this.attendanceForm.controls[i].markAsDirty();
      this.attendanceForm.controls[i].updateValueAndValidity();
    }
    let selectedDate = new Date(this.attendanceForm.value.date);
    this.attendanceForm.value.date = moment(selectedDate).format("YYYY-MM-DD");
    this.attendanceForm.value.clock_in = moment(
      this.attendanceForm.value.clock_in
    ).format("H:mm");
    this.attendanceForm.value.clock_out = moment(
      this.attendanceForm.value.clock_out
    ).format("H:mm");
    if (this.attendanceForm.valid) {
      this._EmployeesService
        .createAttendance(this.attendanceForm.value)
        .subscribe((response) => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if (responseObj.message == "already-exists") {
            alert("Already Exisits");
          } else if (responseObj.message == "failed") {
            alert("Faild");
          } else if (responseObj.message == "success") {
            this._EmployeesService.getAttendance().subscribe(
              (data) => {
                this.listOfattendanceData = data;
                this.listOfData = data;
                this.listOfDisplayData = data;
              },
              (error) => (this.errorMsg = error)
            );
            this.visible = false;
          } else {
            alert(JSON.stringify(response));
          }
        });
    }
  }

  editAttendance(): void {
    for (const i in this.attendanceEditForm.controls) {
      this.attendanceEditForm.controls[i].markAsDirty();
      this.attendanceEditForm.controls[i].updateValueAndValidity();
    }
    let selectedDate = new Date(this.attendanceEditForm.value.date);
    this.attendanceEditForm.value.date =
      moment(selectedDate).format("YYYY-MM-DD");
    this.attendanceEditForm.value.clock_in = moment(
      this.attendanceEditForm.value.clock_in
    ).format("H:mm");
    this.attendanceEditForm.value.clock_out = moment(
      this.attendanceEditForm.value.clock_out
    ).format("H:mm");
    if (this.attendanceEditForm.valid) {
      this._EmployeesService
        .editAttendance(this.attendanceEditForm.value)
        .subscribe((response) => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if (responseObj.message == "already-exists") {
            alert("Already Exisits");
          } else if (responseObj.message == "failed") {
            alert("Faild");
          } else if (responseObj.message == "success") {
            this._EmployeesService.getAttendance().subscribe(
              (data) => {
                this.listOfattendanceData = data;
                this.listOfData = data;
                this.listOfDisplayData = data;
              },
              (error) => (this.errorMsg = error)
            );
            this.visibleEdit = false;
          } else {
            alert(JSON.stringify(response));
          }
        });
    }
  }
  deleteAttendance(id): void {
    this.isOkLoading = true;
    this._EmployeesService.deleteAttendance(id).subscribe((response) => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if (responseObj.message == "success") {
        this._EmployeesService.getAttendance().subscribe(
          (data) => {
            this.listOfattendanceData = data;
            this.listOfData = data;
            this.listOfDisplayData = data;
          },
          (error) => (this.errorMsg = error)
        );

        this.notification.create(
          "success",
          "Attendance",
          "Successfully updated"
        );
      } else if (responseObj.message == "failed") {
        alert("Faild");
      } else {
        alert(JSON.stringify(response));
      }
    });
    this.isOkLoading = false;
    this.visibleDelete = false;
  }

  getTimeFromMins(mins) {
    var duration = moment.duration(mins, "minutes");
    var totalHours = duration.hours();
    if (duration.months() > 0) {
      totalHours += duration.months() * (duration.days() * 24);
    }
    if (duration.days() > 0) {
      totalHours += duration.days() * 24;
    }
    var totalMinutes = duration.minutes();
    if (totalHours !== 0) {
      return totalHours + "h " + totalMinutes + "m";
    } else {
      return totalMinutes + "m";
    }
  }

  // Sync Attendance data
  syncAttendance() {
    this.syncAttendanceLoading = true;
    this._EmployeesService.syncAttendanceFromMachine();
    setTimeout(() => {
      this.syncAttendanceLoading = false;
      this.notification.create(
        "success",
        "Attendance Synced",
        "Attendance Syncing Completed Successfully"
      );
    }, 3000);
  }
}
