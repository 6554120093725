import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { SupportticketsService } from 'src/app/services/supporttickets.service';

@Component({
  selector: 'app-edit-department',
  templateUrl: './edit-department.component.html',
  styleUrls: ['./edit-department.component.scss']
})
export class EditDepartmentComponent implements OnInit {

  loggedInUser = this.storage.get('loggedInUser');
  loading = true;
  dateFormat = 'MMM dd, yyyy';
  departmentData;
  departmentLoading = true;


  constructor(private router: Router,
    @Inject(DOCUMENT) private document: Document,
  private route: ActivatedRoute,
  private notification: NzNotificationService,
    private _supportTicketsService: SupportticketsService
    , @Inject(LOCAL_STORAGE) private storage: StorageService) { }

    public errorMsg;
   
   
  isOkLoading = false;
   

  ngOnInit(): void {
    
  let id = this.route.snapshot.paramMap.get('id');
  this._supportTicketsService.getDepartment().subscribe(dt => {
    this.departmentData = dt;
    this.departmentLoading = false;
    let data = this.departmentData.find(data => data.id == id);
      this.departmentForm.patchValue({
        id: id, 
        name: data.name,
      });
    });
  }

  departmentForm = new FormGroup({
    id: new FormControl(null),
    name: new FormControl(null),
    
  });

   

  addDepartment(): void {
    for (const i in this.departmentForm.controls) {
      this.departmentForm.controls[i].markAsDirty();
      this.departmentForm.controls[i].updateValueAndValidity();
    }

    if(this.departmentForm.valid)
    {
     
      this._supportTicketsService.updateDepartment(this.departmentForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            this.notification.create(
              'error',
              'Department',
              'Already Exists'
              );
          }else if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Department',
              'Unable to save changes'
              );
          }else if(responseObj.message == 'success'){
            this.router.navigate(['/department'])
            this.notification.create(
              'success',
              'Department',
              'Successfully updated'
              );
          }else{
            this.router.navigate(['/department'])
            this.notification.create(
              'success',
              'Department',
              'Successfully updated'
              );
          }
        }
      )
    }
  }

  
}
