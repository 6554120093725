import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { JobsService } from 'src/app/services/jobs.service';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-edit-job',
  templateUrl: './edit-job.component.html',
  styleUrls: ['./edit-job.component.scss']
})
export class EditJobComponent implements OnInit {

  load = false;
  listOfJobsData;
  jobsLoading = true;
  id;
  jobs;
  dateFormat = 'MMM dd, yyyy';

  drawerVisible = false;
  
  fileToUpload: File;
  validUpload = false;
  loggedInUser = this.storage.get('loggedInUser');
  
  loading = true;
  
  jobForm = new FormGroup({
    company_id: new FormControl(null),
    job_title: new FormControl(null),
    industry: new FormControl(null),
    no_position: new FormControl(null),
    job_location: new FormControl(null),
    tax_terms: new FormControl(null),
    visa_status: new FormControl(null),
    contract_duration: new FormControl(null),
    total_experience: new FormControl(null),
    related_experience: new FormControl(null),
    remote_job: new FormControl(null),
    job_type: new FormControl(null),
    job_level: new FormControl(null),
    skills: new FormControl(null),
    additional_skills: new FormControl(null),
    age: new FormControl(null),
    min_salary: new FormControl(null),
    max_salary: new FormControl(null),
    description: new FormControl(null),
    post_date: new FormControl(null),
    apply_date: new FormControl(null),
    close_date: new FormControl(null),
    status: new FormControl(null),
    reponsibilities: new FormControl(null)
  });
  

  
  constructor(private _jobsService: JobsService, 
    private route: ActivatedRoute,
    private notification: NzNotificationService,
    @Inject(LOCAL_STORAGE) private storage: StorageService, private fb: FormBuilder) { }
  public errorMsg;

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.id = id;
    this._jobsService.getJobs().subscribe(dt => {
      this.listOfJobsData = dt;
      this.jobsLoading = false;
      this.load = true;
      let data = this.listOfJobsData.find(data => data.id == id);
        this.jobForm.patchValue({
          id: id, 
          company_id: data.company_id,
          job_title: data.job_title,
          industry: data.industry,
          no_position: data.no_position,
          job_location: data.job_location,
          tax_terms: data.tax_terms,
          visa_status: data.visa_status,
          contract_duration: data.contract_duration,
          total_experience: data.total_experience,
          related_experience: data.related_experience,
          remote_job: data.remote_job,
          job_type: data.job_type,
          job_level: data.job_level,
          skills: data.skills,
          additional_skills: data.additional_skills,
          age: data.age,
          min_salary: data.min_salary,
          max_salary: data.max_salary,
          description: data.description,
          post_date: data.post_date,
          apply_date: data.apply_date,
          close_date: data.close_date,
          status: data.status,
          reponsibilities: data.reponsibilities 
  
        });
        
      });
    }
    // this._jobsService.getJobs().subscribe(
    //   data=> { 
    //     this.listOfJobsData = data;
    //     this.jobsLoading = false;
    //   },
    //   error => this.errorMsg = error);
      
 
 

  removeAttachment( element){
    this.fileToUpload = null;
    element.value = "";
  }
 

  onUpload(files: FileList): void{
    this.validUpload = false;
    if(files.length > 0){
      this.fileToUpload = files.item(0);

      var acceptedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.docx|\.doc)$/i;

      if(!acceptedExtensions.exec(this.fileToUpload.name)){
        // this.msg.error(`Supported Formats: .JPG, .JPEG, .PDF, .DOC, DOCX`);
        this.validUpload = false;
      }
      else{
        this.validUpload = true;
      }
    }
  }

  addJob(): void{
    for (const i in this.jobForm.controls) {
      this.jobForm.controls[i].markAsDirty();
      this.jobForm.controls[i].updateValueAndValidity();
    }
      this.jobForm.value.post_date    = moment(this.jobForm.value.post_date).format('YYYY-MM-DD');
      this.jobForm.value.apply_date   = moment(this.jobForm.value.post_date).format('YYYY-MM-DD');
      this.jobForm.value.close_date   = moment(this.jobForm.value.post_date).format('YYYY-MM-DD');

      if(this.jobForm.valid){
        
        this._jobsService.editJob(this.id,this.jobForm.value).subscribe(//create job should be remove by update job
          response => {
            let responseObj = JSON.parse(JSON.stringify(response));
            if(responseObj.message == 'already-exists'){
              alert('Already Exisits');
            }else if(responseObj.message == 'failed'){
              alert('Faild');
            }else if(responseObj.message == 'success'){
    
              this._jobsService.getJobs().subscribe(
                data=> { 
                  this.listOfJobsData = data;
                  this.jobsLoading = false;
                },
                error => this.errorMsg = error);          
 
              
            }else{
              alert(JSON.stringify(response));
            }
          }
        )
      }
    }

}
