import { Component,OnInit, ViewChild } from '@angular/core';
 
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexGrid,
  ApexMarkers
} from "ng-apexcharts";
import { transpileModule } from 'typescript';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  grid: ApexGrid;
  markers: ApexMarkers;

};

@Component({
  selector: 'app-radar-chart',
  templateUrl: './radar-chart.component.html',
  styleUrls: ['./radar-chart.component.scss']
})
export class RadarChartComponent implements OnInit {

  @ViewChild("chart6", null)
  chart: ChartComponent = new ChartComponent;
  public chartOptions: Partial<any>;
    constructor() {
      
      this.chartOptions = {
        series: [
          {
            name: "Series Blue",
            data: [80, 50, 30, 40, 100, 20]
          },
          {
            name: "Series Green",
            data: [44, 76, 78, 13, 43, 10]
          }
        ],
        chart: {
          height: 250,
          type: "radar",
          redrawOnParentResize: true,
          toolbar: { tools: { download: false } },
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          }
  
        },
        legend: {
          show: false
        },

         
  
        plotOptions: {
          radar: {
            polygons: {
              strokeColors: '#e8e8e8',
              strokeWidth: 1,
              connectorColors: '#e8e8e8',
              fill: {
                colors: '#0f0f0f'
              }
            }
          }
           
        },
        yaxis: {
          show: false,
          axisBorder: {
            show: false
          }
        },
        stroke: {
          width: 0,
          curve:'smooth'
          
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          show: true,      // you can either change hear to disable all grids
          xaxis: {
            lines: {
              show: false //or just here to disable only x axis grids
             }
           },  
          yaxis: {
            lines: { 
              show: true  //or just here to disable only y axis
             }
           }   
        },
        fill: {
          
          colors: ['#9885f9', '#fd9619']
        },
        markers: {
          size: 0
        },
         
        xaxis: {
          categories: ["One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight"]
        }
      };
    }


  ngOnInit(): void {
  }

}
