import {
  NgModule
} from '@angular/core';
import {
  PreloadAllModules
} from '@angular/router';
import {
  Routes,
  RouterModule
} from '@angular/router';

import {
  AuthGuardService
} from './services/auth-guard.service';
import {
  WelcomeComponent
} from './pages/welcome/welcome.component';
import {
  PublicComponent
} from './layouts/public/public.component';
import {
  PrivateComponent
} from './layouts/private/private.component';
import {
  LoginComponent
} from './components/login/login.component';
import {
  SetForgotPasswordComponent
} from './components/login/set-forgot-password/set-forgot-password.component';
import {
  ForgotPasswordEmailComponent
} from './components/login/forgot-password-email/forgot-password-email.component';

// Timesheet
import {
  AddTaskByTimesheetComponent
} from './components/HRMS/timesheet/add-task-by-timesheet/add-task-by-timesheet.component';
import {
  ConfirmTimesheetComponent
} from './components/HRMS/timesheet/confirm-timesheet/confirm-timesheet.component';
import {
  TimesheetComponent
} from './components/HRMS/timesheet/timesheet.component';
import {
  AddTimesheetComponent
} from './components/HRMS/timesheet/add-timesheet/add-timesheet/add-timesheet.component';
import {
  EditTimesheetComponent
} from './components/HRMS/timesheet/edit-timesheet/edit-timesheet/edit-timesheet.component';

// HRMS
import {
  DashboardComponent
} from './components/HRMS/dashboard/dashboard.component';
import {
  EmployeesComponent
} from './components/HRMS/employees/employees.component';
import {
  ProfileComponent
} from './components/HRMS/employees/profile/profile.component';
import {
  AttendanceComponent
} from './components/HRMS/attendance/attendance.component';
import {
  AddAttendanceComponent
} from './components/HRMS/attendance/add-attendance/add-attendance/add-attendance.component';
import {
  HolidayComponent
} from './components/HRMS/holiday/holiday.component';
import {
  AddHolidayComponent
} from './components/HRMS/holiday/add-holiday/add-holiday/add-holiday.component';
import {
  NoticeBoardComponent
} from './components/HRMS/notice-board/notice-board.component';
import {
  AddNoticeBoardComponent
} from './components/HRMS/notice-board/add-notice-board/add-notice-board/add-notice-board.component';
import {
  EditNoticeBoardComponent
} from './components/HRMS/notice-board/edit-notice-board/edit-notice-board/edit-notice-board.component';
import {
  ExpensesComponent
} from './components/HRMS/expenses/expenses.component';
import {
  AddExpensesComponent
} from './components/HRMS/expenses/add-expenses/add-expenses/add-expenses.component';
import {
  EditExpensesComponent
} from './components/HRMS/expenses/edit-expenses/edit-expenses/edit-expenses.component';
import {
  NotificationsComponent
} from './components/HRMS/notifications/notifications.component';
import {
  EmployeeLeaveComponent
} from './components/HRMS/employee-leave/employee-leave.component';
import {
  AddLeaveComponent
} from './components/HRMS/employee-leave/add-leave/add-leave/add-leave.component';
import {
  EmployeeAwardsComponent
} from './components/HRMS/employee-awards/employee-awards.component';
import {
  AddAwardsComponent
} from './components/HRMS/employee-awards/add-awards/add-awards/add-awards.component';
import {
  SalaryListComponent
} from './components/HRMS/salary-list/salary-list.component';
import {
  EditSalaryComponent
} from './components/HRMS/salary-list/edit-salary/edit-salary/edit-salary.component';
import {
  BonusesComponent
} from './components/HRMS/bonuses/bonuses.component';
import {
  EditBonusComponent
} from './components/HRMS/bonuses/edit-bonus/edit-bonus/edit-bonus.component';
import {
  MakePaymentComponent
} from './components/HRMS/make-payment/make-payment.component';
import {
  CreatePaymentComponent
} from './components/HRMS/make-payment/create-payment/create-payment/create-payment.component';
import {
  MakePaymentSlipComponent
} from './components/HRMS/make-payment/make-payment-slip/make-payment-slip.component';
import {
  GeneratePayslipComponent
} from './components/HRMS/generate-payslip/generate-payslip.component';
import {
  ProvidentFundComponent
} from './components/HRMS/provident-fund/provident-fund.component';
import {
  AddFundComponent
} from './components/HRMS/provident-fund/add-fund/add-fund/add-fund.component';
import {
  EditFundComponent
} from './components/HRMS/provident-fund/edit-fund/edit-fund/edit-fund.component';
import {
  LoanComponent
} from './components/HRMS/loan/loan.component';
import {
  AddLoanComponent
} from './components/HRMS/loan/add-loan/add-loan/add-loan.component';
import {
  EditLoanComponent
} from './components/HRMS/loan/edit-loan/edit-loan/edit-loan.component';
import {
  IncrementsComponent
} from './components/HRMS/increments/increments.component';
import {
  EditIncrementsComponent
} from './components/HRMS/increments/edit-increments/edit-increments/edit-increments.component';
import {
  IncrementLogsComponent
} from './components/HRMS/increments/increment-logs/increment-logs.component';
import {
  BonusLogsComponent
} from './components/HRMS/bonuses/bonus-logs/bonus-logs.component';
import {
  OnboardingComponent
} from './components/HRMS/employees/onboarding/onboarding.component';
import {
  AddOnboardingComponent
} from './components/HRMS/employees/onboarding/add-onboarding/add-onboarding/add-onboarding.component';
import {
  EmployeeRolesComponent
} from './components/HRMS/employees/employee-roles/employee-roles.component';
import {
  SetPermissionsComponent
} from './components/HRMS/employees/employee-roles/set-permissions/set-permissions.component';
import {
  NewEmployeeComponent
} from './components/HRMS/employees/add-employee/new-employee/new-employee.component';
import {
  AddRoleComponent
} from './components/HRMS/employees/employee-roles/add-role/add-role/add-role.component';
import {
  EditRoleComponent
} from './components/HRMS/employees/employee-roles/edit-role/edit-role/edit-role.component';
import {
  EditAttendanceComponent
} from './components/HRMS/attendance/edit-attendance/edit-attendance/edit-attendance.component';
import {
  EditLeaveComponent
} from './components/HRMS/employee-leave/edit-leave/edit-leave/edit-leave.component';
import {
  EditHolidayComponent
} from './components/HRMS/holiday/edit-holiday/edit-holiday/edit-holiday.component';
import {
  ViewPayslipComponent
} from './components/HRMS/generate-payslip/view-payslip/view-payslip.component';

// Jobs
import {
  JobsGridComponent
} from './components/HRMS/jobs/jobs-grid/jobs-grid.component';
import {
  JobsComponent
} from './components/HRMS/jobs/jobs.component';
import {
  AddJobComponent
} from './components/HRMS/jobs/add-job/add-job/add-job.component';
import {
  JobDashboardComponent
} from './components/HRMS/jobs/job-dashboard/job-dashboard.component';
import {
  EditJobComponent
} from './components/HRMS/jobs/edit-job/edit-job.component';
import {
  ApplicantsComponent
} from './components/HRMS/jobs/applicants/applicants.component';

// import { ViewPayslipComponent } from './components/HRMS/generate-payslip/view-payslip/view-payslip.component';
// import { HrmsDashboardComponent } from './components/hrms/hrms-dashboard/hrms-dashboard.component';

// Projects
import {
  ProjectsComponent
} from './components/projects/projects.component';
import {
  AddProjectComponent
} from './components/projects/add-project/add-project/add-project.component';
import {
  EditProjectComponent
} from './components/projects/edit-project/edit-project/edit-project.component';
import {
  SingleProjectComponent
} from './components/projects/single-project/single-project.component';
import {
  AddTaskComponent
} from './components/projects/single-project/add-task/add-task/add-task.component';
import {
  SingleTaskComponent
} from './components/projects/single-task/single-task.component';
import {
  EditTaskComponent
} from './components/projects/single-task/edit-task/edit-task.component';
import {
  ProjectDashboardComponent
} from './components/projects/project-dashboard/project-dashboard.component';

// Support Tickets
import {
  DepartmentComponent
} from './components/support-tickets/department/department/department.component';
import {
  AddDepartmentComponent
} from './components/support-tickets/department/add-department/add-department.component';
import {
  EditDepartmentComponent
} from './components/support-tickets/department/edit-department/edit-department.component';
import {
  SupportTicketsComponent
} from './components/support-tickets/support-tickets.component';
import {
  AddSupportTicketComponent
} from './components/support-tickets/add-support-ticket/add-support-ticket.component';
import {
  ViewSupportTicketComponent
} from './components/support-tickets/view-support-ticket/view-support-ticket.component';
// import { AddSupportTicketComponent } from './components/support-tickets/add-support-ticket/add-support-ticket.component';
// import { ViewSupportTicketComponent } from './components/support-tickets/view-support-ticket/view-support-ticket.component';

// Tax
import {
  TaxModuleComponent
} from './components/tax-module/tax-module.component';
import {
  TaxViewComponent
} from './components/tax-module/tax-view/tax-view.component';
import {
  TaxRuleComponent
} from './components/tax-module/tax-rule/tax-rule.component';
import {
  SetRuleComponent
} from './components/tax-module/tax-rule/set-rule/set-rule.component';
import {
  AddTaxRuleComponent
} from './components/tax-module/tax-rule/add-tax-rule/add-tax-rule.component';
import {
  EditTaxRuleComponent
} from './components/tax-module/tax-rule/edit-tax-rule/edit-tax-rule.component';

// Store
import {
  StoreComponent
} from './components/store/store.component';
import {
  DetailComponent
} from './components/store/detail/detail.component';
import {
  ConfigureComponent
} from './components/store/configure/configure.component';
import {
  UserMappingComponent
} from './components/store/configure/user-mapping/user-mapping.component';

// Slack
import {
  SlackComponent
} from './components/slack/slack.component';
import {
  SlackChannelComponent
} from './components/slack/slack-channel/slack-channel.component';

// Organization
import {
  OrganizationHierarchyComponent
} from './components/organization-hierarchy/organization-hierarchy.component';
import {
  AddOrganizationHierarchyComponent
} from './components/organization-hierarchy/add-organization-hierarchy/add-organization-hierarchy.component';
import {
  EditOrganizationHierarchyComponent
} from './components/organization-hierarchy/edit-organization-hierarchy/edit-organization-hierarchy.component';

// Settings
import {
  SettingsComponent
} from './components/settings/settings.component';

import {
  TrainingGoalsComponent
} from './components/training-goals/training-goals.component';
import {
  EmailTemplateComponent
} from './components/email-template/email-template.component';
import { OrganizationComponent } from './components/organization-hierarchy/organization/organization.component';
import { AllTasksComponent } from './components/HRMS/timesheet/all-tasks/all-tasks.component';
 

// import { JobsComponent } from './components/HRMS/jobs/jobs.component';
// import { AddJobComponent } from './components/HRMS/jobs/add-job/add-job/add-job.component';
// import { JobDashboardComponent } from './components/HRMS/jobs/job-dashboard/job-dashboard.component';
// import { ProjectsComponent } from './components/projects/projects.component';
// import { AddProjectComponent } from './components/projects/add-project/add-project/add-project.component';
// import { EditProjectComponent } from './components/projects/edit-project/edit-project/edit-project.component';
// import { SingleProjectComponent } from './components/projects/single-project/single-project.component';
// import { SingleTaskComponent } from './components/projects/single-task/single-task.component';
// import { EditTaskComponent } from './components/projects/single-task/edit-task/edit-task.component';
// import { SupportTicketsComponent } from './components/support-tickets/support-tickets.component';
// import { SettingsComponent } from './components/settings/settings.component';
// import { EmployeeAwardsComponent } from './components/HRMS/employee-awards/employee-awards.component';
// import { AddAwardsComponent } from './components/HRMS/employee-awards/add-awards/add-awards/add-awards.component';
// import { MakePaymentComponent } from './components/HRMS/make-payment/make-payment.component';
// import { CreatePaymentComponent } from './components/HRMS/make-payment/create-payment/create-payment/create-payment.component';
// import { MakePaymentSlipComponent } from './components/HRMS/make-payment/make-payment-slip/make-payment-slip.component';
// import { GeneratePayslipComponent } from './components/HRMS/generate-payslip/generate-payslip.component';
// import { TrainingGoalsComponent } from './components/training-goals/training-goals.component';
// import { StoreComponent } from './components/store/store.component';
// import { DetailComponent } from './components/store/detail/detail.component';
// import { ConfigureComponent } from './components/store/configure/configure.component';
// import { UserMappingComponent } from './components/store/configure/user-mapping/user-mapping.component';
// import { ItemComponent } from './components/inventory/item/item.component';
// import { ItemGroupComponent } from './components/inventory/item-group/item-group.component';
// import { CompositeItemComponent } from './components/inventory/composite-item/composite-item.component';
// import { InvAdjustmentsComponent } from './components/inventory/inv-adjustments/inv-adjustments.component';
// import { CustomerComponent } from './components/inventory/customer/customer.component';
// import { RetainerInvoicesComponent } from './components/inventory/retainer-invoices/retainer-invoices.component';
// import { CrmDashboardComponent } from './components/CRM/crm-dashboard/crm-dashboard.component';
// import { FinanceDashboardComponent } from './components/accounts/finance-dashboard/finance-dashboard.component';
// import { FileManagerDashboardComponent } from './components/document-manager/file-manager-dashboard/file-manager-dashboard.component';
// import { InventoryDashboardComponent } from './components/inventory/inventory-dashboard/inventory-dashboard.component';
// import { RetainerInvoiceViewComponent } from './components/inventory/retainer-invoices/retainer-invoice-view/retainer-invoice-view.component';
// import { SalesOrderComponent } from './components/inventory/sales-order/sales-order.component';
// import { InvoicesComponent } from './components/inventory/invoices/invoices.component';
// import { SalesReturnsComponent } from './components/inventory/sales-returns/sales-returns.component';
// import { PaymentReceivedComponent } from './components/inventory/payment-received/payment-received.component';
// import { PaymentReceivedViewComponent } from './components/inventory/payment-received/payment-received-view/payment-received-view.component';
// import { CreditNoteViewComponent } from './components/inventory/credit-notes/credit-note-view/credit-note-view.component';
// import { CreditNotesComponent } from './components/inventory/credit-notes/credit-notes.component';
// import { VendorComponent } from './components/inventory/vendor/vendor.component';
// import { PurchaseOrderComponent } from './components/inventory/purchase-order/purchase-order.component';
// import { BillsComponent } from './components/inventory/bills/bills.component';
// import { PaymentMadeComponent } from './components/inventory/payment-made/payment-made.component';
// import { PaymentMadeViewComponent } from './components/inventory/payment-made/payment-made-view/payment-made-view.component';
// import { VendorCreditsComponent } from './components/inventory/vendor-credits/vendor-credits.component';
// import { VendorCreditsViewComponent } from './components/inventory/vendor-credits/vendor-credits-view/vendor-credits-view.component';
// import { MannualJournalComponent } from './components/accounts/mannual-journal/mannual-journal.component';
// import { ChartOfAccountComponent } from './components/accounts/chart-of-account/chart-of-account.component';
// import { BudgetsComponent } from './components/accounts/budgets/budgets.component';
// import { ViewBudgetComponent } from './components/accounts/budgets/view-budget/view-budget.component';
// import { AddBudgetComponent } from './components/accounts/budgets/add-budget/add-budget.component';
// import { FilesListComponent } from './components/document-manager/files-list/files-list.component';
// import { FilesListViewComponent } from './components/document-manager/files-list/files-list-view/files-list-view.component';
// import { TagsComponent } from './components/document-manager/tags/tags.component';
// import { CustomFieldsComponent } from './components/document-manager/custom-fields/custom-fields.component';
// import { FileTypeComponent } from './components/document-manager/file-type/file-type.component';
// import { DocumentDetailsComponent } from './components/document-manager/document-details/document-details.component';
// import { FilesSettingComponent } from './components/document-manager/files-setting/files-setting.component';
// import { PackagesComponent } from './components/inventory/packages/packages.component';
// import { LeadsComponent } from './components/CRM/leads/leads.component';
// import { ContactsComponent } from './components/CRM/contacts/contacts.component';
// import { AccountsComponent } from './components/CRM/accounts/accounts.component';
// import { DealsComponent } from './components/CRM/deals/deals.component';
// import { SalesOrderViewComponent } from './components/inventory/sales-order/sales-order-view/sales-order-view.component';
// import { PurchaseOrderViewComponent } from './components/inventory/purchase-order/purchase-order-view/purchase-order-view.component';
// import { BillViewComponent } from './components/inventory/bills/bill-view/bill-view.component';
// import { InvoiceViewComponent } from './components/inventory/invoices/invoice-view/invoice-view.component';
// import { CustomerViewComponent } from './components/inventory/customer/customer-view/customer-view.component';
// import { SalesReportsComponent } from './components/reports/sales-reports/sales-reports.component';
// import { InventoryReportsComponent } from './components/reports/inventory-reports/inventory-reports.component';
// import { ReceivablesReportsComponent } from './components/reports/receivables-reports/receivables-reports.component';
// import { PaymentReceivedReportComponent } from './components/reports/payment-received-report/payment-received-report.component';
// import { PayablesReportsComponent } from './components/reports/payables-reports/payables-reports.component';
// import { PurchasesExpensesReportsComponent } from './components/reports/purchases-expenses-reports/purchases-expenses-reports.component';
// import { InventorySummaryComponent } from './components/reports/inventory-reports/inventory-summary/inventory-summary.component';
// import { ProjectReportsComponent } from './components/reports/project-reports/project-reports.component';
// import { ProjectDetailsReportsComponent } from './components/reports/project-reports/project-details-reports/project-details-reports.component';
// import { SalesByCustomerComponent } from './components/reports/sales-reports/sales-by-customer/sales-by-customer.component';
// import { CustomerAnalysisComponent } from './components/reports/customer-analysis/customer-analysis.component';
// import { InventoryAnalysisComponent } from './components/reports/inventory-analysis/inventory-analysis.component';
// import { InvAdjustmentsViewComponent } from './components/inventory/inv-adjustments/inv-adjustments-view/inv-adjustments-view.component';
// import { PriceListComponent } from './components/inventory/price-list/price-list.component';
// import { SalesReturnsViewComponent  } from './components/inventory/sales-returns/sales-returns-view/sales-returns-view.component';
// import { VendorViewComponent } from './components/inventory/vendor/vendor-view/vendor-view.component';
// import { ItemViewComponent } from './components/inventory/item/item-view/item-view.component';
// import { ItemGroupViewComponent } from './components/inventory/item-group/item-group-view/item-group-view.component';
// import { PackageViewComponent } from './components/inventory/packages/package-view/package-view.component';
// import { CompositeItemViewComponent } from './components/inventory/composite-item/composite-item-view/composite-item-view.component';
// import { ManualJournalViewComponent } from './components/accounts/mannual-journal/manual-journal-view/manual-journal-view.component';
// import { RecurringJournalsComponent } from './components/accounts/recurring-journals/recurring-journals.component';
// import { RecurringJournalsViewComponent } from './components/accounts/recurring-journals/recurring-journals-view/recurring-journals-view.component';
// import { LeadViewComponent } from './components/CRM/leads/lead-view/lead-view.component';
// import { AccountViewComponent } from './components/CRM/accounts/account-view/account-view.component';
// import { DealsViewComponent } from './components/CRM/deals/deals-view/deals-view.component';
// import { ContactsViewComponent } from './components/CRM/contacts/contacts-view/contacts-view.component';
// import { FinanceDashboardComponent } from './components/accounts/finance-dashboard/finance-dashboard.component';

// // import { HrmsDashboardComponent } from './components/hrms/hrms-dashboard/hrms-dashboard.component';

// import {InvoiceGeneratorComponent} from './components/inventory/invoices/invoice-generator/invoice-generator.component';
// import { AddAccountComponent } from './components/CRM/accounts/add-account/add-account.component';
// import { AddContactComponent } from './components/CRM/contacts/add-contact/add-contact.component';
// import { AddLeadComponent } from './components/CRM/leads/add-lead/add-lead.component';
// import { AddDealComponent } from './components/CRM/deals/add-deal/add-deal.component';
// import { EditLeadComponent } from './components/CRM/leads/edit-lead/edit-lead.component';
// import { EditContactComponent } from './components/CRM/contacts/edit-contact/edit-contact.component';
// import { EditAccountComponent } from './components/CRM/accounts/edit-account/edit-account.component';
// import { EditDealComponent } from './components/CRM/deals/edit-deal/edit-deal.component';
// import { AddItemComponent } from './components/inventory/item/add-item/add-item.component';
// import { EditItemComponent } from './components/inventory/item/edit-item/edit-item.component';
// import { EditItemGroupComponent } from './components/inventory/item-group/edit-item-group/edit-item-group.component';
// import { AddItemGroupComponent } from './components/inventory/item-group/add-item-group/add-item-group.component';
// import { AddCompositeItemComponent } from './components/inventory/composite-item/add-composite-item/add-composite-item.component';
// import { EditCompositeItemComponent } from './components/inventory/composite-item/edit-composite-item/edit-composite-item.component';
// import { AddPriceComponent } from './components/inventory/price-list/add-price/add-price.component';
// import { EditPriceComponent } from './components/inventory/price-list/edit-price/edit-price.component';
// import { AddInvAdjustmentComponent } from './components/inventory/inv-adjustments/add-inv-adjustment/add-inv-adjustment.component';
// import { EditInvAdjustmentComponent } from './components/inventory/inv-adjustments/edit-inv-adjustment/edit-inv-adjustment.component';
// import { AddCustomerComponent } from './components/inventory/customer/add-customer/add-customer.component';
// import { EditRetainerInvoicesComponent } from './components/inventory/retainer-invoices/edit-retainer-invoices/edit-retainer-invoices.component';
// import { AddRetainerInvoicesComponent } from './components/inventory/retainer-invoices/add-retainer-invoices/add-retainer-invoices.component';
// import { EditSalesOrderComponent } from './components/inventory/sales-order/edit-sales-order/edit-sales-order.component';
// import { AddSalesOrderComponent } from './components/inventory/sales-order/add-sales-order/add-sales-order.component';
// import { AddPaymentsReceivedComponent } from './components/inventory/payment-received/add-payments-received/add-payments-received.component';
// import { EditPaymentReceivedComponent } from './components/inventory/payment-received/edit-payment-received/edit-payment-received.component';
// import { EditVendorComponent } from './components/inventory/vendor/edit-vendor/edit-vendor.component';
// import { AddVendorComponent } from './components/inventory/vendor/add-vendor/add-vendor.component';
// import { AddPurchaseOrderComponent } from './components/inventory/purchase-order/add-purchase-order/add-purchase-order.component';
// import { EditPurchaseOrderComponent } from './components/inventory/purchase-order/edit-purchase-order/edit-purchase-order.component';
// import { AddBillComponent } from './components/inventory/bills/add-bill/add-bill.component';
// import { EditBillComponent } from './components/inventory/bills/edit-bill/edit-bill.component';
// import { AddPaymentMadeComponent } from './components/inventory/payment-made/add-payment-made/add-payment-made.component';
// import { EditPaymentMadeComponent } from './components/inventory/payment-made/edit-payment-made/edit-payment-made.component';
// import { AddVendorCreditsComponent } from './components/inventory/vendor-credits/add-vendor-credits/add-vendor-credits.component';
// import { EditVendorCreditsComponent } from './components/inventory/vendor-credits/edit-vendor-credits/edit-vendor-credits.component';
// import { EditCreditNotesComponent } from './components/inventory/credit-notes/edit-credit-notes/edit-credit-notes.component';
// import { AddCreditNotesComponent } from './components/inventory/credit-notes/add-credit-notes/add-credit-notes.component';
// import { ViewPriceListComponent } from './components/inventory/price-list/view-price-list/view-price-list.component';
// import { EditCustomerComponent } from './components/inventory/customer/edit-customer/edit-customer.component';
// import { ApplicantsComponent } from './components/HRMS/jobs/applicants/applicants.component';
// import { DepartmentComponent } from './components/support-tickets/department/department/department.component';
// import { AddDepartmentComponent } from './components/support-tickets/department/add-department/add-department.component';
// import { EditDepartmentComponent } from './components/support-tickets/department/edit-department/edit-department.component';
// import { OrganizationHierarchyComponent } from './components/organization-hierarchy/organization-hierarchy.component';
// import { AddOrganizationHierarchyComponent } from './components/organization-hierarchy/add-organization-hierarchy/add-organization-hierarchy.component';
// import { EditOrganizationHierarchyComponent } from './components/organization-hierarchy/edit-organization-hierarchy/edit-organization-hierarchy.component';
// import { NotificationsComponent } from './components/HRMS/notifications/notifications.component';

const routes: Routes = [{
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full'
  },
  {
      path: 'login',
      component: LoginComponent
  },
  {
      path: 'forgot-password',
      component: ForgotPasswordEmailComponent
  },
  {
      path: 'set-password',
      component: SetForgotPasswordComponent
  },
  {
      path: 'dashboard',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: DashboardComponent
      }]
  },
  {
      path: 'slack/auth-callback',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: SlackComponent
      }]
  },
  {
      path: 'slack/channel/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: SlackChannelComponent
      }]
  },
 
  {
      path: 'new-employee',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: NewEmployeeComponent
      }]
  },
  {
      path: 'employee-roles',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EmployeeRolesComponent
      }]
  },
  {
      path: 'add-role',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddRoleComponent
      }]
  },
  {
      path: 'edit-role/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditRoleComponent
      }]
  },
  {
      path: 'set-permission/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: SetPermissionsComponent
      }]
  },
  {
      path: 'on-boarding',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: OnboardingComponent
      }]
  },
  {
      path: 'add-onboarding',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddOnboardingComponent
      }]
  },
  {
      path: 'employees/profile/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: ProfileComponent
      }]
  },
  {
      path: 'employees',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EmployeesComponent
      }]
  },
  {
      path: 'attendance',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AttendanceComponent
      }]
  },
  {
      path: 'add-attendance',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddAttendanceComponent
      }]
  },
  {
      path: 'edit-attendance/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditAttendanceComponent
      }]
  },
  {
      path: 'jobs',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: JobsComponent
      }]
  },
  {
      path: 'add-jobs',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddJobComponent
      }]
  },
  {
      path: 'jobs-applicants/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: ApplicantsComponent
      }]
  },
  {
      path: 'edit-job/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditJobComponent
      }]
  },
  {
      path: 'jobs/dashboard/:id',
      component: JobDashboardComponent
  },
  {
      path: 'jobs-grid',
      component: JobsGridComponent
  },
  {
      path: 'holiday',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: HolidayComponent
      }]
  },
  {
      path: 'add-holiday',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddHolidayComponent
      }]
  },
  {
      path: 'edit-holiday/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditHolidayComponent
      }]
  },
  {
      path: 'notice-board',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: NoticeBoardComponent
      }]
  },
  {
      path: 'add-notice-board',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddNoticeBoardComponent
      }]
  },
  {
      path: 'edit-notice-board/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditNoticeBoardComponent
      }]
  },
  {
      path: 'support-tickets',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: SupportTicketsComponent
      }]
  }, 
  {
      path: 'add-support-ticket',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddSupportTicketComponent
      }]
  }, 
  {
      path: 'department',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: DepartmentComponent
      }]
  }, 
  {
      path: 'add-department',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddDepartmentComponent
      }]
  }, 
  {
      path: 'edit-department/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditDepartmentComponent
      }]
  }, 
  {
      path: 'view-support-ticket/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: ViewSupportTicketComponent
      }]
  },
  {
      path: 'leave',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EmployeeLeaveComponent
      }]
  },
  {
      path: 'add-leave',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddLeaveComponent
      }]
  },
  {
      path: 'edit-leave/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditLeaveComponent
      }]
  },
  {
      path: 'award',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EmployeeAwardsComponent
      }]
  },
  {
      path: 'add-award',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddAwardsComponent
      }]
  },
  {
      path: 'expenses',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: ExpensesComponent
      }]
  },
  {
      path: 'add-expenses',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddExpensesComponent
      }]
  }, 
  {
      path: 'edit-expenses/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditExpensesComponent
      }]
  },
  {
      path: 'salary-list',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: SalaryListComponent
      }]
  }, 
  {
      path: 'edit-salary/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditSalaryComponent
      }]
  },
  {
      path: 'increment',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: IncrementsComponent
      }]
  }, 
  {
      path: 'edit-increment/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditIncrementsComponent
      }]
  }, 
  {
      path: 'increment-logs',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: IncrementLogsComponent
      }]
  },
  {
      path: 'bonus',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: BonusesComponent
      }]
  }, 
  {
      path: 'edit-bonus/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditBonusComponent
      }]
  }, 
  {
      path: 'bonus-logs',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: BonusLogsComponent
      }]
  },
  {
      path: 'make-payment',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: MakePaymentComponent
      }]
  }, 
  {
      path: 'create-payment/:id/:date',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: CreatePaymentComponent
      }]
  }, 
  {
      path: 'make-payment-slip',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: MakePaymentSlipComponent
      }]
  },
  {
      path: 'generate-payslip',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: GeneratePayslipComponent
      }]
  }, 
  {
      path: 'view-payslip/:id/:datepay',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: ViewPayslipComponent
      }]
  },
  {
      path: 'provident-fund',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: ProvidentFundComponent
      }]
  }, 
  {
      path: 'add-fund',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddFundComponent
      }]
  }, 
  {
      path: 'edit-fund/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditFundComponent
      }]
  },
  {
      path: 'loan',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: LoanComponent
      }]
  }, 
  {
      path: 'add-loan',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddLoanComponent
      }]
  }, 
  {
      path: 'edit-loan/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditLoanComponent
      }]
  },
  {
      path: 'timesheets',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: TimesheetComponent
      }]
  }, 
  {
      path: 'timesheets/:employeeId/:selectedWeek',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: TimesheetComponent
      }]
  }, 
  {
      path: 'add-task',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddTaskByTimesheetComponent
      }]
  }, 
  {
    path: 'all-tasks',
    component: PrivateComponent,
    canActivate: [AuthGuardService],
    children: [{
        path: '',
        component: AllTasksComponent
    }]
}, 
  {
      path: 'add-timesheet/:employeeid/:id/:date',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddTimesheetComponent
      }]
  }, 
  {
      path: 'edit-timesheet/:employeeid/:id/:date',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditTimesheetComponent
      }]
  }, 
  {
      path: 'submit-timesheet/:employeeId/:selectedWeek',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: ConfirmTimesheetComponent
      }]
  },
  {
      path: 'notification',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: NotificationsComponent
      }]
  },
  {
      path: 'organization-hierarchy',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: OrganizationHierarchyComponent
      }]
  },
  {
    path: 'organization',
    component: PrivateComponent,
    canActivate: [AuthGuardService],
    children: [{
        path: '',
        component: OrganizationComponent
    }]
},
  {
      path: 'add-organization-hierarchy',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddOrganizationHierarchyComponent
      }]
  },
  {
      path: 'edit-organization-hierarchy/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditOrganizationHierarchyComponent
      }]
  },
  {
      path: 'training',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: TrainingGoalsComponent
      }]
  }, 
  {
      path: 'projects',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: ProjectsComponent
      }]
  }, 
  {
      path: 'add-projects',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddProjectComponent
      }]
  }, 
  {
      path: 'edit-projects/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditProjectComponent
      }]
  }, 
  {
      path: 'projects/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: SingleProjectComponent
      }]
  },
  {
      path: 'add-single-task/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddTaskComponent
      }]
  }, 
  {
      path: 'projects/task/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: SingleTaskComponent
      }]
  }, 
  {
      path: 'edit-single-task/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditTaskComponent
      }]
  }, 
  {
      path: 'project-main-dashboard',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: ProjectDashboardComponent
      }]
  }, 
  {
      path: 'tax-module',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: TaxModuleComponent
      }]
  }, 
  {
      path: 'tax-view',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: TaxViewComponent
      }]
  }, 
  {
      path: 'settings',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: SettingsComponent
      }]
  }, 
  {
      path: 'tax-rule',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: TaxRuleComponent
      }]
  }, 
  {
      path: 'set-rule/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: SetRuleComponent
      }]
  }, 
  {
      path: 'add-tax-rule',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: AddTaxRuleComponent
      }]
  }, 
  {
      path: 'edit-tax-rule/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: EditTaxRuleComponent
      }]
  }, 
  {
      path: 'store',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: StoreComponent
      }]
  }, 
  {
      path: 'store/details/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: DetailComponent
      }]
  }, 
  {
      path: 'settings/store/configure/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: ConfigureComponent
      }]
  }, 
  {
      path: 'settings/store/configure/user-mapping/:id',
      component: PrivateComponent,
      canActivate: [AuthGuardService],
      children: [{
          path: '',
          component: UserMappingComponent
      }]
  },

  // {
  //     path: 'crm-dashboard',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: CrmDashboardComponent
  //     }]
  // }, {
  //     path: 'finance-dashboard',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: FinanceDashboardComponent
  //     }]
  // }, {
  //     path: 'file-manager-dashboard',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: FileManagerDashboardComponent
  //     }]
  // }, {
  //     path: 'documents-details/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: DocumentDetailsComponent
  //     }]
  // }, {
  //     path: 'tags',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: TagsComponent
  //     }]
  // }, {
  //     path: 'custom-fields',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: CustomFieldsComponent
  //     }]
  // }, {
  //     path: 'file-type',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: FileTypeComponent
  //     }]
  // }, {
  //     path: 'file-setting',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: FilesSettingComponent
  //     }]
  // }, {
  //     path: 'inventory-dashboard',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: InventoryDashboardComponent
  //     }]
  // }, {
  //     path: 'sales-reports',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: SalesReportsComponent
  //     }]
  // }, {
  //     path: 'sales-by-customer',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: SalesByCustomerComponent
  //     }]
  // }, {
  //     path: 'inv-reports',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: InventoryReportsComponent
  //     }]
  // }, {
  //     path: 'inv-summary-report',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: InventorySummaryComponent
  //     }]
  // }, {
  //     path: 'receivables-reports',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: ReceivablesReportsComponent
  //     }]
  // }, {
  //     path: 'payment-reports',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: PaymentReceivedReportComponent
  //     }]
  // }, {
  //     path: 'payable-reports',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: PayablesReportsComponent
  //     }]
  // }, {
  //     path: 'purchase-reports',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: PurchasesExpensesReportsComponent
  //     }]
  // }, {
  //     path: 'project-reports',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: ProjectReportsComponent
  //     }]
  // }, {
  //     path: 'Customer-Analysis-reports',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: CustomerAnalysisComponent
  //     }]
  // }, {
  //     path: 'inventory-analysis-report',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: InventoryAnalysisComponent
  //     }]
  // }, {
  //     path: 'project-details-report',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: ProjectDetailsReportsComponent
  //     }]
  // }, 
  // {
  //     path: 'item',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: ItemComponent
  //     }]
  // }, {
  //     path: 'add-item',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddItemComponent
  //     }]
  // }, {
  //     path: 'edit-item/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditItemComponent
  //     }]
  // }, {
  //     path: 'item-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: ItemViewComponent
  //     }]
  // }, {
  //     path: 'item-group',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: ItemGroupComponent
  //     }]
  // }, {
  //     path: 'add-item-group',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddItemGroupComponent
  //     }]
  // }, {
  //     path: 'edit-item-group/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditItemGroupComponent
  //     }]
  // }, {
  //     path: 'item-group-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: ItemGroupViewComponent
  //     }]
  // }, {
  //     path: 'composite-item',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: CompositeItemComponent
  //     }]
  // }, {
  //     path: 'add-composite-item',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddCompositeItemComponent
  //     }]
  // }, {
  //     path: 'edit-composite-item/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditCompositeItemComponent
  //     }]
  // }, {
  //     path: 'composite-item-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: CompositeItemViewComponent
  //     }]
  // },
  // {
  //     path: 'inv-adjustments',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: InvAdjustmentsComponent
  //     }]
  // },
  // {
  //     path: 'inv-adjustments-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: InvAdjustmentsViewComponent
  //     }]
  // },
  // {
  //     path: 'add-inv-adjustment',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddInvAdjustmentComponent
  //     }]
  // },
  // {
  //     path: 'edit-inv-adjustment/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditInvAdjustmentComponent
  //     }]
  // }, {
  //     path: 'price-list',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: PriceListComponent
  //     }]
  // }, {
  //     path: 'add-price',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddPriceComponent
  //     }]
  // }, {
  //     path: 'edit-price/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditPriceComponent
  //     }]
  // }, {
  //     path: 'view-price-list/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: ViewPriceListComponent
  //     }]
  // }, {
  //     path: 'customer',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: CustomerComponent
  //     }]
  // }, {
  //     path: 'add-customer',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddCustomerComponent
  //     }]
  // }, {
  //     path: 'edit-customer/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditCustomerComponent
  //     }]
  // },
  // {
  //     path: 'customer-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: CustomerViewComponent
  //     }]
  // }, {
  //     path: 'ret-invoices',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: RetainerInvoicesComponent
  //     }]
  // }, {
  //     path: 'add-ret-invoices',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddRetainerInvoicesComponent
  //     }]
  // }, {
  //     path: 'edit-ret-invoices/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditRetainerInvoicesComponent
  //     }]
  // }, {
  //     path: 'ret-invoices-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: RetainerInvoiceViewComponent
  //     }]
  // }, {
  //     path: 'sales-orders',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: SalesOrderComponent
  //     }]
  // }, {
  //     path: 'add-sales-orders',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddSalesOrderComponent
  //     }]
  // }, {
  //     path: 'edit-sales-orders/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditSalesOrderComponent
  //     }]
  // }, {
  //     path: 'sales-orders-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: SalesOrderViewComponent
  //     }]
  // },
  // {
  //     path: 'invoices',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: InvoicesComponent
  //     }]
  // },
  // {
  //     path: 'invoice-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: InvoiceViewComponent
  //     }]
  // },
  // {
  //     path: 'sales-returns',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: SalesReturnsComponent
  //     }]
  // },
  // {
  //     path: 'sales-returns-view',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: SalesReturnsViewComponent
  //     }]
  // }, {
  //     path: 'payment-received',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: PaymentReceivedComponent
  //     }]
  // }, {
  //     path: 'add-payment-received',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddPaymentsReceivedComponent
  //     }]
  // }, {
  //     path: 'edit-payment-received/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditPaymentReceivedComponent
  //     }]
  // }, {
  //     path: 'payment-received-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: PaymentReceivedViewComponent
  //     }]
  // },
  // {
  //     path: 'credit-notes-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: CreditNoteViewComponent
  //     }]
  // }, {
  //     path: 'credit-notes',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: CreditNotesComponent
  //     }]
  // }, {
  //     path: 'add-credit-notes',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddCreditNotesComponent
  //     }]
  // }, {
  //     path: 'edit-credit-notes/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditCreditNotesComponent
  //     }]
  // }, {
  //     path: 'vendor',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: VendorComponent
  //     }]
  // }, {
  //     path: 'add-vendor',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddVendorComponent
  //     }]
  // }, {
  //     path: 'edit-vendor/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditVendorComponent
  //     }]
  // }, {
  //     path: 'vendor-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: VendorViewComponent
  //     }]
  // }, {
  //     path: 'purchase-order',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: PurchaseOrderComponent
  //     }]
  // }, {
  //     path: 'add-purchase-order',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddPurchaseOrderComponent
  //     }]
  // }, {
  //     path: 'edit-purchase-order/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditPurchaseOrderComponent
  //     }]
  // }, {
  //     path: 'purchase-order-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: PurchaseOrderViewComponent
  //     }]
  // }, {
  //     path: 'bills',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: BillsComponent
  //     }]
  // }, {
  //     path: 'add-bill',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddBillComponent
  //     }]
  // }, {
  //     path: 'edit-bill/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditBillComponent
  //     }]
  // }, {
  //     path: 'bill-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: BillViewComponent
  //     }]
  // }, {
  //     path: 'payment-made',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: PaymentMadeComponent
  //     }]
  // }, {
  //     path: 'add-payment-made',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddPaymentMadeComponent
  //     }]
  // }, {
  //     path: 'edit-payment-made/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditPaymentMadeComponent
  //     }]
  // }, {
  //     path: 'payment-made-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: PaymentMadeViewComponent
  //     }]
  // }, {
  //     path: 'vendor-credits',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: VendorCreditsComponent
  //     }]
  // }, {
  //     path: 'add-vendor-credits',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddVendorCreditsComponent
  //     }]
  // }, {
  //     path: 'edit-vendor-credits/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditVendorCreditsComponent
  //     }]
  // }, {
  //     path: 'vendor-credits-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: VendorCreditsViewComponent
  //     }]
  // }, {
  //     path: 'manual-journal',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: MannualJournalComponent
  //     }]
  // }, {
  //     path: 'manual-journal-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: ManualJournalViewComponent
  //     }]
  // }, {
  //     path: 'recurring-journal',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: RecurringJournalsComponent
  //     }]
  // },
  // {
  //     path: 'recurring-journal-view',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: RecurringJournalsViewComponent
  //     }]
  // },
  // {
  //     path: 'chart-of-accounts',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: ChartOfAccountComponent
  //     }]
  // },
  // {
  //     path: 'budgets',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: BudgetsComponent
  //     }]
  // }, {
  //     path: 'add-budget',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddBudgetComponent
  //     }]
  // }, {
  //     path: 'view-budget',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: ViewBudgetComponent
  //     }]
  // }, {
  //     path: 'documents',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: FilesListComponent
  //     }]
  // }, {
  //     path: 'documents-view',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: FilesListViewComponent
  //     }]
  // }, {
  //     path: 'packages',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: PackagesComponent
  //     }]
  // }, {
  //     path: 'packages-view',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: PackageViewComponent
  //     }]
  // }, {
  //     path: 'leads',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: LeadsComponent
  //     }]
  // }, {
  //     path: 'add-lead',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddLeadComponent
  //     }]
  // }, {
  //     path: 'edit-lead/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditLeadComponent
  //     }]
  // }, {
  //     path: 'lead-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: LeadViewComponent
  //     }]
  // },
  // {
  //     path: 'contacts',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: ContactsComponent
  //     }]
  // }, {
  //     path: 'add-contact',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddContactComponent
  //     }]
  // }, {
  //     path: 'edit-contact/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditContactComponent
  //     }]
  // }, {
  //     path: 'contacts-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: ContactsViewComponent
  //     }]
  // }, {
  //     path: 'crm-accounts',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AccountsComponent
  //     }]
  // }, {
  //     path: 'add-account',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddAccountComponent
  //     }]
  // }, {
  //     path: 'edit-account/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditAccountComponent
  //     }]
  // }, {
  //     path: 'crm-accounts-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AccountViewComponent
  //     }]
  // }, {
  //     path: 'deals',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: DealsComponent
  //     }]
  // }, {
  //     path: 'add-deal',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: AddDealComponent
  //     }]
  // }, {
  //     path: 'edit-deal/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EditDealComponent
  //     }]
  // }, {
  //     path: 'deals-view/:id',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: DealsViewComponent
  //     }]
  // },
  // {
  //     path: 'email-template',
  //     component: PrivateComponent,
  //     canActivate: [AuthGuardService],
  //     children: [{
  //         path: '',
  //         component: EmailTemplateComponent
  //     }]
  // },
  // {
  //   path: 'invoice-generator',
  //   component: PrivateComponent,
  //   canActivate: [AuthGuardService],
  //   children: [{
  //     path: '',
  //     component: InvoiceGeneratorComponent
  //   }]
  // },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}