import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-increment-logs',
  templateUrl: './increment-logs.component.html',
  styleUrls: ['./increment-logs.component.scss']
})
export class IncrementLogsComponent implements OnInit {
  selectedValue;
  public errorMsg;

  listOfDisplayData;
  listOfAllData;
  load = false;
  constructor(private _EmployeesService: EmployeesService) { }

    ngOnInit() {
      this._EmployeesService.getLogs(0).subscribe(data => {
        this.listOfAllData = data;
        this.load = true;
        this.listOfDisplayData = this.listOfAllData;
      });
  }
  currentPageDataChange($event){}

  
  selectedPayment;
listOfAllPreData;
    paymentSelected(ev){
      this.listOfAllPreData=[];
      console.log(ev);
      this.listOfAllData=null;
    if(ev == 1){
     
      this._EmployeesService.getLogs(0).subscribe(data => {
        data.map((increment) => {
          if (increment.payment_type == "Hourly" ) {
            this.listOfAllPreData.push(increment);
            // console.log(increment);
          }
        });
        this.listOfAllData = this.listOfAllPreData;
        // console.log(this.listOfAllData);
      });
     
    }else if (ev == 2 ){
       
      this._EmployeesService.getLogs(0).subscribe(data => {

        data.map((salary) => {
          if (salary.payment_type == "Monthly" ) {
            this.listOfAllPreData.push(salary);
            // console.log(salary);
          }
        });
         this.listOfAllData = this.listOfAllPreData;
      //  console.log(this.listOfAllData);
      });
    }else if (ev == 3 ){
       
      this._EmployeesService.getLogs(0).subscribe(data => {
        this.listOfAllData = data;
   
      });
    }
  }
}
