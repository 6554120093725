import { Component, Input, OnInit } from '@angular/core';
import { AzureService } from 'src/app/services/azure.service';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as moment from 'moment';
import { EmployeesService } from 'src/app/services/employees.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd';
import { SettingsService } from 'src/app/services/settings.service';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectsService } from 'src/app/services/projects.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  selectedValue = '6 Months';
  // loggedInUser = this.storage.get('loggedInUser');
  loggedInUser = this.storage.get('loggedInUser');
  allStats;
  noOfEmployees = 0;
  noOfDepartment = 0;
  listOfEmployees;
  absent = 0;
  onTime  = 0;
  onLeave = 0;
  late = 0;
  load = false;
  listOfDepartmentData;
  newListOfDepartmentData: any[];
  cardOneLoading = false;
  cardTwoLoading = false;
  firstDayThisWeek;
  lastDayThisWeek;
  presentDaysList;
  countPresentDays;
  listOfNoticeBoardData;
  noticeboardLoading;
  tasksByEmployeeId;
  role_id;
  visibleId;
  selectedEmployeeId = this.loggedInUser.id;
  formatOne = (percent: number): string => `${Math.round(percent) } %`;
   
  
  thisWeekSearchFormEmployee = new FormGroup({
    emp_id: new FormControl(null, Validators.required),
    dateFrom: new FormControl(null, Validators.required),
    dateTo: new FormControl(null , Validators.required),
     
  });


  constructor(
    public _EmployeesService: EmployeesService,
    private _SettingsService: SettingsService,
    private _ProjectsService: ProjectsService,
    private _azureService: AzureService,
    @Inject(LOCAL_STORAGE) private storage: StorageService) {this.newListOfDepartmentData= []; 
    this.cardOneLoading = true;
    this.cardTwoLoading = true;}
   


  ngOnInit(): void {
   this.role_id = localStorage.getItem("loggedInUser")
   const adminId = JSON.parse(this.role_id);
   if(adminId.role_id == 3 || adminId.role_id == 6){
    this.visibleId = true;
   }
    this.stats();
this.load = false;
    this.cardOneLoading = true;
    this.cardTwoLoading = true;
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfEmployees = data;
      this.noOfEmployees = this.listOfEmployees.length;
      
   
      console.log(this.noOfEmployees);
    });
    this.getRecentTask();  
    this._azureService.getAccessToken().subscribe((data)=>{
      console.log(data);
    },error => console.log(error));
    
    this.presentInCurrentMonth();
    this.getNoticesOnBoard();
  }

  getNoticesOnBoard(){
    this._SettingsService.getNotices().subscribe(data => {
      this.listOfNoticeBoardData = data;
    });
  }
  getRecentTask(){
    this._ProjectsService.getTasksByEmployeeId(this.selectedEmployeeId).subscribe(
      data=>{
       this.tasksByEmployeeId = data;
      
       console.log(this.tasksByEmployeeId);
     });
  }
 
  presentInCurrentMonth(){
    this.firstDayThisWeek = moment( ).startOf('month').format("YYYY-MM-DD");
    this.lastDayThisWeek = moment( ).endOf('month').format("YYYY-MM-DD");
     
    this.thisWeekSearchFormEmployee.value.emp_id = this.loggedInUser.id;
    this.thisWeekSearchFormEmployee.value.dateFrom = this.firstDayThisWeek;
    this.thisWeekSearchFormEmployee.value.dateTo = this.lastDayThisWeek;
    console.log(this.thisWeekSearchFormEmployee.value);
    this._EmployeesService.searchAttendance(this.thisWeekSearchFormEmployee.value).subscribe(data => {
       
      this.presentDaysList = data
      this.countPresentDays = this.presentDaysList.length ;
      console.log("Gelo",this.presentDaysList);
      console.log(this.countPresentDays);
    });
  }

  stats(){
    
    this._EmployeesService.getHrDashboardStats().subscribe(data=> { 
      this.allStats = data;
      
          this.absent =  Math.round(this.allStats.absent * 100  / 29) ;
        
          this.late = Math.round(this.allStats.late * 100 / 29);
           
          this.onLeave = Math.round(this.allStats.onleave * 100 / 29);
          
          this.onTime = Math.round(this.allStats.ontime * 100 / 29);
          console.log(this.allStats);
console.log(this.absent )
      
        });
        this._SettingsService.getDepartments().subscribe(data=> {
          this.listOfDepartmentData = data;
          this.noOfDepartment = this.listOfDepartmentData.length;
          this.listOfDepartmentData.map((msgs)=>{ 
            this.newListOfDepartmentData.push({ 
            id: msgs.id , employeeInDeptPercentage:  Math.round(msgs.employees.length * 100 / 29), department: msgs.department , employees: msgs.employees, 
 
            });
            this.load = true;
          });
          
          
          console.log(this.newListOfDepartmentData);
          console.log(this.listOfDepartmentData);
        });
        
       
  }
}
