import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NzMessageService, NzNotificationService } from 'ng-zorro-antd';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { EmployeesService } from 'src/app/services/employees.service';
import { ProjectsService } from 'src/app/services/projects.service';


interface ItemData {
  id: number;
  description:string;
  picture1:string;
  picture2:string;
  picture3:string;
  picture4:string;
  badgePointerColor:string;
  badge:string;
}

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})
export class ProjectDashboardComponent  implements OnInit {
  listOfProjectsData;
  listOfEmployees;
  listOfAllData;
  projectsLoading = true;
  constructor(
    private _ProjectsService: ProjectsService,
    private msg: NzMessageService
    , @Inject(LOCAL_STORAGE) private storage: StorageService
    ,private notification: NzNotificationService,
    private _EmployeesService: EmployeesService) { }

    
  ngOnInit(): void {
    this._EmployeesService.getEmployees().subscribe(employees=> { 
      this.listOfEmployees = employees;
    this._ProjectsService.getProjects().subscribe(data => {
      this.listOfProjectsData = data;
      this.listOfAllData = data;
      this.listOfAllData.map((dt) => {
        dt.manager = this.listOfEmployees.find(o => o.id == dt.manager_id);
        let employee_ids = dt.employees.split(',');
        dt.employees_list = [];
        employee_ids.map((emp_id)=>{
          let emp = this.listOfEmployees.find(o => o.id == emp_id);
          dt.employees_list.push(emp);
        });
      });

      // console.log(this.listOfAllData);
    },
    error => console.log(error.message));

});
}

  
  dataList=[{id:1, description:'Add Fluid Layout A Vertical', picture1:'../../../../assets/images/avatar-2.png', picture2:'../../../../assets/images/avatar-4.png', picture3:'../../../../assets/images/avatar-4.png', picture4:'../../../../assets/images/emma williams.png',  badgePointerColor:'pointer-purple', badge:'in progress'}, {id:2, description:'Add Fluid Layout A Vertical...', picture1:'../../../../assets/images/avatar-3.png', picture2:'../../../../assets/images/emma williams.png', picture3:'../../../../assets/images/avatar-6.png', picture4:'../../../../assets/images/emma williams.png',  badgePointerColor:'pointer-green', badge:'completed'}, {id:3, description:'Add Fluid Layout A Vertical', picture1:'../../../../assets/images/avatar-3.png', picture2:'../../../../assets/images/avatar-7.png', picture3:'../../../../assets/images/avatar-2.png', picture4:'../../../../assets/images/emma williams.png',  badgePointerColor:'pointer-red', badge:'due'}, {id:4, description:'Add Fluid Layout', picture1:'../../../../assets/images/avatar-4.png', picture2:'../../../../assets/images/avatar-5.png', picture3:'../../../../assets/images/avatar-7.png', picture4:'../../../../assets/images/emma williams.png',  badgePointerColor:'pointer-yellow', badge:'assign'}, {id:5, description:'Add Fluid Layout', picture1:'../../../../assets/images/emma williams.png', picture2:'../../../../assets/images/avatar-2.png', picture3:'../../../../assets/images/avatar-3.png', picture4:'../../../../assets/images/emma williams.png',  badgePointerColor:'pointer-yellow', badge:'assign'}, {id:6, description:'Add Fluid ', picture1:'../../../../assets/images/avatar-4.png', picture2:'../../../../assets/images/avatar-6.png', picture3:'../../../../assets/images/emma williams.png', picture4:'../../../../assets/images/emma williams.png',  badgePointerColor:'pointer-green', badge:'completed'}]
 

}
