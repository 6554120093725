import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeesService } from 'src/app/services/employees.service'; 
import { FileManagerService } from 'src/app/services/file-manager.service'; 
import { SettingsService } from 'src/app/services/settings.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import * as moment from 'moment';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {  
  file: any;
  document:any;
  load = false;
  loading = true;
  loggedInUser = this.storage.get('loggedInUser');
  data; 
  visibleEmployeeDelete= false;
  employeeToDeleteId;
  visibleDelete = false;
  showPassError:boolean = false;
  profile = {
    id: '',
    avatar: '',
    fname: '',
    lname: '',
    employee_code: '',
    username: '',
    email: '',
    password: '',
    tax_id: 1,
    IM: '',
    department: '',
    department_name: '',
    designation: '',
    employee_type: '',
    employee_work_space: '',
    role_id: '',
    work_from_home: '',
    date_of_join: '',
    date_of_birth: '',
    date_of_leave: '',
    status: '',
    father_name: '',
    mother_name: '',
    permanent_address: '',
    present_address: '',
    phone: '',
    phone2: '',
  };


  listOfAllEmployees;
  listOfDepartments;
  listOfDesignations;
  listOfAllBanks;
  listOfAllEmployeeDocuments;
  listOfRoles;
  errorMsg;
  id;

 

  bank_name;
  branch_name;
  account_name;
  account_number;
  ifsc_code;
  pan_no;
  isOkLoading;
  bankToDeleteId
  editBankVisible;
  editbank_id;
  editbank_name;
  editbranch_name;
  editaccount_name;
  editaccount_number;
  editifsc_code;
  editpan_no;

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService, 
  private route: ActivatedRoute,
  private router: Router,
  public _EmployeesService: EmployeesService,
  private _SettingsService: SettingsService,
  private notification: NzNotificationService,
  private _filemanager: FileManagerService) { }
  profileForm;
  addBankForm = new FormGroup({
    emp_id: new FormControl(this.route.snapshot.paramMap.get('id')),
    bank_name: new FormControl(null),
    branch_name: new FormControl(null),
    account_name: new FormControl(null),
    account_number: new FormControl(null),
    ifsc_code: new FormControl(null),
    pan_no: new FormControl(null)
  });
  editBankForm = new FormGroup({
    bank_id: new FormControl(null),
    bank_name: new FormControl(null),
    branch_name: new FormControl(null),
    account_name: new FormControl(null),
    account_number: new FormControl(null),
    ifsc_code: new FormControl(null),
    pan_no: new FormControl(null)
  });
  ngOnInit() {
    
    if(this.loggedInUser.role_id !== 2){
      this.profileForm = new FormGroup({
        id: new FormControl(this.route.snapshot.paramMap.get('id')),
        fname: new FormControl(null),
        lname: new FormControl(null),
        employee_code: new FormControl(null),
        username: new FormControl(null),
        email: new FormControl(null),
        password: new FormControl(null),
        tax_id: new FormControl(0),
        IM: new FormControl(null),
        department: new FormControl(null),
        designation: new FormControl(null),
        employee_type: new FormControl(null),
        employee_work_space: new FormControl(null),
        role_id: new FormControl(null),
        work_from_home: new FormControl(null),
        date_of_join: new FormControl(new Date()),
        date_of_birth: new FormControl(null),
        date_of_leave: new FormControl(null),
        status: new FormControl(null),
        father_name: new FormControl(null),
        mother_name: new FormControl(null),
        permanent_address: new FormControl(null),
        present_address: new FormControl(null),
        phone: new FormControl(null),
        phone2: new FormControl(null)
      });
    }else{
      this.profileForm = new FormGroup({
        id: new FormControl(this.route.snapshot.paramMap.get('id')),
        fname: new FormControl(null),
        lname: new FormControl(null),
        employee_code: new FormControl(null),
        email: new FormControl(null),
        password: new FormControl(null),
        IM: new FormControl(null),
        date_of_birth: new FormControl(null),
        permanent_address: new FormControl(null),
        present_address: new FormControl(null),
        phone: new FormControl(null),
        phone2: new FormControl(null)
      });
    
    }
  

    let id = this.route.snapshot.paramMap.get('id');
    this.id = id;
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfAllEmployees = data;
      this.load = true;
      this.data = this.listOfAllEmployees.find(o => o.id == id);
      if(this.data){

        this.profile.id = this.data.id;
        this.profile.avatar = this.data.avatar || 'http://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png';
        this.profile.fname = this.data.fname;
        this.profile.lname = this.data.lname;
        this.profile.employee_code = this.data.employee_code;
        this.profile.username = this.data.username;
        this.profile.email = this.data.email;
        this.profile.password = null;
        this.profile.tax_id = this.data.tax_id;
        this.profile.IM = this.data.IM;
        this.profile.department_name = this.data.department_name.department;
        this.profile.department = this.data.department;
        this.profile.designation = this.data.designation;
        this.profile.employee_type = this.data.employee_type;
        this.profile.employee_work_space = this.data.employee_work_space;
        this.profile.role_id = this.data.role_id;
        this.profile.work_from_home = this.data.work_from_home;
        this.profile.date_of_join = this.data.date_of_join;
        this.profile.date_of_birth = this.data.date_of_birth;
        this.profile.date_of_leave = this.data.date_of_leave;
        this.profile.status= this.data.status;
        this.profile.father_name = this.data.father_name;
        this.profile.mother_name = this.data.mother_name;
        this.profile.permanent_address = this.data.permanent_address;
        this.profile.present_address = this.data.present_address;
        this.profile.phone = this.data.phone;
        this.profile.phone2 = this.data.phone2;
        
        // get banks
        this._EmployeesService.getBankAccounts(id).subscribe(data=> { 
          this.listOfAllBanks = data;
          // console.log(this.data);
        });
      }
      // console.log(this.data);
    });

    this._EmployeesService.getEmployeeRoles().subscribe(data=> { 
      this.listOfRoles = data;
    });    
    this._SettingsService.getDepartments().subscribe(data=> this.listOfDepartments = data,
      error => this.errorMsg = error);
    this._SettingsService.getDesignations().subscribe(data=> this.listOfDesignations = data,
      error => this.errorMsg = error);
    
    this._EmployeesService.getEmployeeDocuments(this.id).subscribe(data=> { 
        this.listOfAllEmployeeDocuments = data;
      });
  }  

  uploadForm= new FormGroup({
    profile: new FormControl(null)
  });
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
    }
  }
  onSubmit() {
    const formData = new FormData();
    formData.append('file', this.file, this.file.name);
    this._filemanager.uploadFile(formData).subscribe(
      (res) => {
        let responseObj = JSON.parse(JSON.stringify(res));
        if(responseObj.path){
          let data = {
            'avatar': responseObj.path,
            'id': this.id
          };
          this._EmployeesService.updateEmployeeAvatar(data).subscribe((data=>{
            this._EmployeesService.getEmployees().subscribe(data=> { 
              this.listOfAllEmployees = data;
              this.data = this.listOfAllEmployees.find(o => o.id == this.id);
              this.profile.avatar = this.data.avatar;
            });
            this.notification.create(
              'success',
              'Employee Profile',
              'Successfully updated'
              );
          }
          ));
        }else if(responseObj.message == 'failed'){
          this.notification.create(
            'error',
            'Employee Profile',
            'Unable to save changes'
            );            
        }else{
          this.notification.create(
            'error',
            'Employee Profile',
            'somethings went wrong'
            );
        }
      },
      (err) => {
        console.log(err);
      }
    );

  }
  uploadDocumentForm = new FormGroup({
    file_title: new FormControl(null),
    file: new FormControl(null)
  });
  onDocumentSelect(event) {
    if (event.target.files.length > 0) {
      this.document = event.target.files[0];
    }
  }
  onDocumentSubmit() {
    const formData = new FormData();
    formData.append('file', this.document, this.document.name);
    this._filemanager.uploadFile(formData).subscribe(
      (res) => {
        let responseObj = JSON.parse(JSON.stringify(res));
        if(responseObj.path){
          let data = {
            'emp_id': this.id,
            'file_title': this.uploadDocumentForm.value.file_title,
            'file': responseObj.path
          };
          this._EmployeesService.addEmployeeDocument(data).subscribe((data=>{
            this._EmployeesService.getEmployeeDocuments(this.id).subscribe(data=> { 
              this.listOfAllEmployeeDocuments = data;
            });
            this.notification.create(
              'success',
              'Employee Profile',
              'Successfully updated'
              );
          }
          ));
        }else if(responseObj.message == 'failed'){
          this.notification.create(
            'error',
            'Employee Profile',
            'Unable to save changes'
            );            
        }else{
          this.notification.create(
            'error',
            'Employee Profile',
            'somethings went wrong'
            );
        }
      },
      (err) => {
        console.log(err);
      }
    );

  }
  

  updateProfile() {
    for (const i in this.profileForm.controls) {
      this.profileForm.controls[i].markAsDirty();
      this.profileForm.controls[i].updateValueAndValidity();
    }
    if(this.profileForm.valid){

      this.profileForm.value.date_of_birth = moment().format('YYYY-MM-DD');
      this.profileForm.value.date_of_join = moment().format('YYYY-MM-DD');
      this.profileForm.value.date_of_leave = moment().format('YYYY-MM-DD');

      this._EmployeesService.updateEmployee(this.profileForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success'){
            this._EmployeesService.getEmployees().subscribe(data=> { 
              this.listOfAllEmployees = data;
              this.data = this.listOfAllEmployees.find(o => o.id == this.id);
            });
            this.notification.create(
              'success',
              'Employee Profile',
              'Successfully updated'
              );
          }
          else if(responseObj.message == 'failed')
          {
            this.notification.create(
              'error',
              'Employee Profile',
              'Unable to save changes'
              );            
          }
          else
          {
            this.notification.create(
              'error',
              'Employee Profile',
              'somethings went wrong'
              );
          }
        }
      )
    }
  }
  addBank(): void {
    for (const i in this.addBankForm.controls) {
      this.addBankForm.controls[i].markAsDirty();
      this.addBankForm.controls[i].updateValueAndValidity();
    }
    if(this.addBankForm.valid){
      this._EmployeesService.addBank(this.addBankForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success'){
            // get banks
            this.addBankForm.reset();
            this._EmployeesService.getBankAccounts(this.id).subscribe(data=> { 
              this.listOfAllBanks = data;
              // console.log(this.data);
            });
            this.notification.create(
              'success',
              'Employee Profile',
              'Successfully updated'
              );
          }
          else if(responseObj.message == 'failed')
          {
            
            this.notification.create(
              'error',
              'Employee Profile',
              'Unable to save changes'
              );            
          }
          else
          {
            this.notification.create(
              'error',
              'Employee Profile',
              'somethings went wrong'
              );
          }
        }
      )
    }
  }
  //Delete Modal
  showDeleteModal(id): void{
    this.visibleEmployeeDelete = true;
    this.id = id;
  }
  closeDeleteModal(): void{
    this.visibleEmployeeDelete = false;
  }
  deleteEmployee(id): void {
    this._EmployeesService.deleteEmployee(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success'){
        
        this.router.navigate(['/employees'])
        this.notification.create(
          'success',
          'Employee Profile',
          'Delete Successfully.'
          );

      }else if(responseObj.message == 'failed'){

        // this.router.navigate(['/employees'])
        this.notification.create(
          'error',
          'Employee Profile',
          'Unable to save changes'
          );            
      }else{

        this.router.navigate(['/employees'])
        this.notification.create(
          'success',
          'Employee Profile',
          'Deleted Successfully.'
          );
      }
    }
    );
    this.isOkLoading = false;
    this.visibleEmployeeDelete = false;  
  }
  //Delete Modal
  showBankDeleteModal(id): void{
    this.visibleDelete = true;
    this.bankToDeleteId = id;
  }
  closeBankDeleteModal(): void{
    this.visibleDelete = false;
  }
  deleteBank(id): void {
    this.isOkLoading = true;
    this._EmployeesService.deleteBank(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success'){
        
        this._EmployeesService.getBankAccounts(this.id).subscribe(data=> { 
          this.listOfAllBanks = data;
          // console.log(this.data);
        });
        this.notification.create(
          'success',
          'Employee Bank Info',
          'Successfully deleted'
          );

      }else if(responseObj.message == 'failed'){
        this.notification.create(
          'error',
          'Employee Bank Info',
          'Unable to save changes'
          );            
      }else{
        this.notification.create(
          'error',
          'Employee Bank Info',
          'somethings went wrong'
          );
      }
    }
  );
  this.isOkLoading = false;
  this.visibleDelete = false;
  }  
  openEditBank(id){
    this.editBankVisible = true;
    let data = this.listOfAllBanks.find(o => o.id == id);  
    this.editbank_id = id;
    this.editbank_name= data.bank_name;
    this.editbranch_name= data.branch_name;
    this.editaccount_name= data.account_name;
    this.editaccount_number= data.account_number;
    this.editifsc_code= data.ifsc_code;
    this.editpan_no= data.pan_no;    
  }
  editBank(): void{
    for (const i in this.editBankForm.controls) {
      this.editBankForm.controls[i].markAsDirty();
      this.editBankForm.controls[i].updateValueAndValidity();
    }
    if(this.editBankForm.valid){
      this._EmployeesService.editBank(this.editBankForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success'){
            // get banks
            this.editBankForm.reset();
            this._EmployeesService.getBankAccounts(this.id).subscribe(data=> { 
              this.listOfAllBanks = data;
              // console.log(this.data);
            });
            this.notification.create(
              'success',
              'Employee Profile',
              'Successfully updated'
              );
          }
          else if(responseObj.message == 'failed')
          {
            this.notification.create(
              'error',
              'Employee Profile',
              'Unable to save changes'
              );            
          }
          else
          {
            this.notification.create(
              'error',
              'Employee Profile',
              'somethings went wrong'
              );
          }
        }
      )
    }
  }

  tab1 = true;
  activeTab1(){ this.tab1 = true; this.tab2 = false; this.tab3 = false; this.tab4 = false; }
  tab2 = false;
  activeTab2(){ this.tab1 = false; this.tab2 = true; this.tab3 = false; this.tab4 = false; }
  tab3 = false;
  activeTab3(){ this.tab1 = false; this.tab2 = false; this.tab3 = true; this.tab4 = false; }
  tab4 = false;
  activeTab4(){ this.tab1 = false; this.tab2 = false; this.tab3 = false; this.tab4 = true; }  
  
 

}
