import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service'
import { Router } from "@angular/router";
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { DOCUMENT } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';


@Component({
  selector: 'app-forgot-password-email',
  templateUrl: './forgot-password-email.component.html',
  styleUrls: ['./forgot-password-email.component.scss']
})
export class ForgotPasswordEmailComponent implements OnInit {
  errorMessage:any;
  isError = false;
  loading: boolean;
  constructor(private authService: AuthService,private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private notification: NzNotificationService,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
  }
  forgotForm = new FormGroup({
    email: new FormControl(null),
    
  });
  onSubmit(){
    
    if(this.loading !== true){
      let data = {
        "email": this.forgotForm.value.email
      }
      this.loading = true;
      this.authService.forgot(data).subscribe(
        data => {
          if(data.error == true){
            this.isError = true;
            this.errorMessage = data.message;
            this.loading = false;
            this.notification.create(
              'error',
              'Forgot Password',
                this.errorMessage
              );
            
          }else{
            this.errorMessage = "";
            this.isError = false;
            this.notification.create(
              'success',
              'Forgot Password',
                data.msg
              );
              
            this.router.navigate(['/login']);
            // this.document.location.href = '/login';
            this.loading = false;
          }
        },
        error => {
          console.log('error')
          this.loading = false;
        },
        () => {
          // 'onCompleted' callback.
          // No errors, route to new page here
        }
      );
    }
  }
}
