import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { SettingsService } from 'src/app/services/settings.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import * as moment from 'moment'
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-employee-leave',
  templateUrl: './employee-leave.component.html',
  styleUrls: ['./employee-leave.component.scss']
})

export class EmployeeLeaveComponent implements OnInit {
  loggedInUser = this.storage.get('loggedInUser');
  title = "Leave";
  load = false;
  applyLeaveForm = false;
  dateFrom;
  date;
  dateFormat = 'MMM dd, yyyy';
  leaveTypes;
  employeeLeaveData;
  leavesLoading = true;
  holidayLoading = true;
  holidayData;

  applyLeaveFormOpen(select):void{
    console.log(select);
    this.applyLeaveForm = true;
    this.dateFrom = select
    console.log(select);
  }
  applyLeaveFormClose() : void{
    this.applyLeaveForm = false;
  }
  leavesRequested: EmployeeLeave[];
  //
  onChange(ev){
    console.log(ev);
  }
  //Copid from employee type component ts
  public errorMsg;
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  leaveToDeleteId = 0;
  isOkLoading = false;
  selectedEmployee;
  selectedLeaveType;
  selectedStatus;
  selectedValue;
  inputValue;

  listDataMap = {
    eight: [
      { type: 'warning', content: 'This is warning event.' },
      { type: 'success', content: 'This is usual event.' }
    ],
  };

  listOfEmployeesData;
  employeeDataLoading = true;

  constructor(
    private _EmployeesService: EmployeesService
    , @Inject(LOCAL_STORAGE) private storage: StorageService
    ,private notification: NzNotificationService
    ,private _SettingsService: SettingsService) { }


sickLeaveTypeId =0;
sickLeaveQuota = 0;
sickLeaveMonthlyAllowed = 0;
sickLeavesAvailed = 0;
sickLeavesAvailedThisMonth = 0;
halfDayLeaveTypeId =0;
halfDayLeaveQuota = 0;
halfDayLeaveMonthlyAllowed = 0;
halfDayLeavesAvailed = 0;
halfDayLeavesAvailedThisMonth = 0;
vacationsLeaveTypeId= 0;
vacationsLeaveQuota = 0;
vacationsLeaveMonthlyAllowed = 0;
vacationsLeavesAvailed = 0;
vacationsLeavesAvailedThisMonth = 0;
upcomingHolidaysThisMonth = 0;

departments;
designations;

searchForm = new FormGroup({
  emp_id: new FormControl(null),
  dateFrom: new FormControl(null, Validators.required),
  dateTo: new FormControl(null, Validators.required),
  department: new FormControl(null),
  designation: new FormControl(null)
});

  ngOnInit() {
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfEmployeesData = data;
      this.employeeDataLoading = false;
    },
    error => console.log(error.message));  
    // get leaves list
    this._SettingsService.getLeaves().subscribe(data => {
      this.leaveTypes = data;
      data.map((leaveType)=>{
        if(leaveType.leave == 'Half Day Leave'){
          this.halfDayLeaveTypeId = leaveType.id;
          this.halfDayLeaveQuota = leaveType.leave_quota;
          this.halfDayLeaveMonthlyAllowed = leaveType.max_leave;
        }
        if(leaveType.leave == 'Sick Leave'){
          this.sickLeaveTypeId = leaveType.id;
          this.sickLeaveQuota = leaveType.leave_quota;
          this.sickLeaveMonthlyAllowed = leaveType.max_leave;
        }
        if(leaveType.leave == 'Vacations'){
          this.vacationsLeaveTypeId = leaveType.id;
          this.vacationsLeaveQuota = leaveType.leave_quota;
          this.vacationsLeaveMonthlyAllowed = leaveType.max_leave;
        }
      });
      this.load = true;
    });  
    if(this.loggedInUser.role_id !== 2){
      this._EmployeesService.getEmployeeLeave().subscribe(data => {
        this.employeeLeaveData = data;
        this.leavesLoading = false;
      });


    }else{
      this._EmployeesService.getEmployeeLeavesById(this.loggedInUser.id).subscribe(data => {
        this.employeeLeaveData = data;
        this.leavesLoading = false;
      });
      this._EmployeesService.getEmployeeLeaveThisYear(this.loggedInUser.id).subscribe(data=>{
        this.sickLeavesAvailed = data.sickLeave;
        this.halfDayLeavesAvailed = data.halfDay;
        this.vacationsLeavesAvailed = data.vacations;
      });
      this._EmployeesService.getEmployeeLeaveThisMonth(this.loggedInUser.id).subscribe(data=>{
        this.sickLeavesAvailedThisMonth = data.sickLeave;
        this.halfDayLeavesAvailedThisMonth = data.halfDay;
        this.vacationsLeavesAvailedThisMonth = data.vacations;
      });
    }
    this._SettingsService.getHolidays().subscribe(data => {
      this.holidayData = data;
      console.log(this.holidayData);
      this.holidayLoading = false;
    });

    this.employeeLeaveFormForAdmin.patchValue({
      emp_id : this.loggedInUser.id,
      status : 'Pending'
    });


    this._SettingsService.getDepartments().subscribe(data => this.departments = data);
   
    this._SettingsService.getDesignations().subscribe(data => this.designations = data);
  }

  changeDepartment(ev): void{
    if(ev){
      this._SettingsService.getDesignationsByDepartmentId(ev).subscribe(
        data => { 
          this.designations = data;
        }
      );
      this._EmployeesService.getEmployeesByDepartmentId(ev).subscribe(data=> { 
        this.listOfEmployeesData = data;
      },
      error => console.log(error.message));
    }
  }
  changeDesignation(ev): void{
    if(ev){
      this._EmployeesService.getEmployeesByDesignationId(ev).subscribe(data=> { 
        this.listOfEmployeesData = data;
      },
      error => console.log(error.message));
    }
  }
  

  searchLeave(): void{
    // this.leavesLoading = true;
    for (const i in this.searchForm.controls) {
      this.searchForm.controls[i].markAsDirty();
      this.searchForm.controls[i].updateValueAndValidity();
    }
    if(this.searchForm.valid){    
      this.searchForm.value.dateTo =moment(this.searchForm.value.dateTo).format('YYYY-MM-DD');
      this.searchForm.value.dateFrom =moment(this.searchForm.value.dateFrom).format('YYYY-MM-DD');
      console.log(this.searchForm.value.emp_id);
      console.log(this.searchForm.value.dateFrom);
      console.log(this.searchForm.value.dateTo);
      this._EmployeesService.searchEmployeeLeave(this.searchForm.value).subscribe(data => {
        this.employeeLeaveData = data;
        // this.leavesLoading = false;
      });
    }
  }
  selectedDuration;
  firstDayThisWeek;
      lastDayThisWeek;
      thisWeekSearchForm = new FormGroup({
       
       dateFrom: new FormControl(null, Validators.required),
       dateTo: new FormControl(null , Validators.required),
        
     });
     thisWeekSearchFormEmployee = new FormGroup({
      emp_id: new FormControl(null, Validators.required),
      dateFrom: new FormControl(null, Validators.required),
      dateTo: new FormControl(null , Validators.required),
       
    });
   durationSelected(ev){
    if(this.loggedInUser.role_id !== 2){
     if(ev == 1){
       this.firstDayThisWeek = moment( ).startOf('week').add(1, 'd').format("YYYY-MM-DD");
       this.lastDayThisWeek = moment( ).endOf('week').format("YYYY-MM-DD");
       console.log(this.firstDayThisWeek);
       console.log(this.lastDayThisWeek);
       this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
       this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
       console.log(this.thisWeekSearchForm.value);
       this._EmployeesService.searchEmployeeLeave(this.thisWeekSearchForm.value).subscribe(data => {
          
         this.employeeLeaveData = data
         console.log(this.employeeLeaveData);
       });
     }else if (ev == 2 ){
       this.firstDayThisWeek = moment( ).startOf('week').subtract(6, 'd').format("YYYY-MM-DD");
       this.lastDayThisWeek = moment( ).endOf('week').subtract(7, 'd').format("YYYY-MM-DD");
       console.log(this.firstDayThisWeek);
       console.log(this.lastDayThisWeek);
       this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
       this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
       console.log(this.thisWeekSearchForm.value);
       this._EmployeesService.searchEmployeeLeave(this.thisWeekSearchForm.value).subscribe(data => {
          
         this.employeeLeaveData = data
         console.log(this.employeeLeaveData);
       });
     }else if (ev == 3 ){
       this.firstDayThisWeek = moment( ).startOf('month').format("YYYY-MM-DD");
       this.lastDayThisWeek = moment( ).endOf('month').format("YYYY-MM-DD");
       console.log(this.firstDayThisWeek);
       console.log(this.lastDayThisWeek);
       this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
       this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
       console.log(this.thisWeekSearchForm.value);
       this._EmployeesService.searchEmployeeLeave(this.thisWeekSearchForm.value).subscribe(data => {
          
         this.employeeLeaveData = data
         console.log(this.employeeLeaveData);
       });
     }else if (ev == 4 ){
       this.firstDayThisWeek = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
       this.lastDayThisWeek = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
       console.log(this.firstDayThisWeek);
       console.log(this.lastDayThisWeek);
       this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
       this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
       console.log(this.thisWeekSearchForm.value);
       this._EmployeesService.searchEmployeeLeave(this.thisWeekSearchForm.value).subscribe(data => {
          
         this.employeeLeaveData = data
         console.log(this.employeeLeaveData);
       });
     }else if (ev == 5 ){
       this.firstDayThisWeek = moment( ).startOf('year').format("YYYY-MM-DD");
       this.lastDayThisWeek = moment( ).endOf('year').format("YYYY-MM-DD");
       console.log(this.firstDayThisWeek);
       console.log(this.lastDayThisWeek);
       this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
       this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
       console.log(this.thisWeekSearchForm.value);
       this._EmployeesService.searchEmployeeLeave(this.thisWeekSearchForm.value).subscribe(data => {
          
         this.employeeLeaveData = data
         console.log(this.employeeLeaveData);
       });
     }else if (ev == 6 ){
      this._EmployeesService.getEmployeeLeave().subscribe(data => {
        this.employeeLeaveData
        this.employeeLeaveData = data
        console.log(this.employeeLeaveData);
      });
    }
  }else{
    if(ev == 1){
      this.firstDayThisWeek = moment( ).startOf('week').add(1, 'd').format("YYYY-MM-DD");
      this.lastDayThisWeek = moment( ).endOf('week').format("YYYY-MM-DD");
      console.log(this.firstDayThisWeek);
      console.log(this.lastDayThisWeek);
      this.thisWeekSearchFormEmployee.value.emp_id = this.loggedInUser.id;
      this.thisWeekSearchFormEmployee.value.dateFrom = this.firstDayThisWeek;
      this.thisWeekSearchFormEmployee.value.dateTo = this.lastDayThisWeek;
      console.log(this.thisWeekSearchFormEmployee.value);
      this._EmployeesService.searchEmployeeLeave(this.thisWeekSearchFormEmployee.value).subscribe(data => {
         
        this.employeeLeaveData = data
        console.log(this.employeeLeaveData);
      });
    }else if (ev == 2 ){
      this.firstDayThisWeek = moment( ).startOf('week').subtract(6, 'd').format("YYYY-MM-DD");
      this.lastDayThisWeek = moment( ).endOf('week').subtract(7, 'd').format("YYYY-MM-DD");
      console.log(this.firstDayThisWeek);
      console.log(this.lastDayThisWeek);
      this.thisWeekSearchFormEmployee.value.emp_id = this.loggedInUser.id;
      this.thisWeekSearchFormEmployee.value.dateFrom = this.firstDayThisWeek;
      this.thisWeekSearchFormEmployee.value.dateTo = this.lastDayThisWeek;
      console.log(this.thisWeekSearchFormEmployee.value);
      this._EmployeesService.searchEmployeeLeave(this.thisWeekSearchFormEmployee.value).subscribe(data => {
         
        this.employeeLeaveData = data
        console.log(this.employeeLeaveData);
      });
    }else if (ev == 3 ){
      this.firstDayThisWeek = moment( ).startOf('month').format("YYYY-MM-DD");
      this.lastDayThisWeek = moment( ).endOf('month').format("YYYY-MM-DD");
      console.log(this.firstDayThisWeek);
      console.log(this.lastDayThisWeek);
      this.thisWeekSearchFormEmployee.value.emp_id = this.loggedInUser.id;
      this.thisWeekSearchFormEmployee.value.dateFrom = this.firstDayThisWeek;
      this.thisWeekSearchFormEmployee.value.dateTo = this.lastDayThisWeek;
      console.log(this.thisWeekSearchFormEmployee.value);
      this._EmployeesService.searchEmployeeLeave(this.thisWeekSearchFormEmployee.value).subscribe(data => {
         
        this.employeeLeaveData = data
        console.log(this.employeeLeaveData);
      });
    }else if (ev == 4 ){
      this.firstDayThisWeek = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
      this.lastDayThisWeek = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
      console.log(this.firstDayThisWeek);
      console.log(this.lastDayThisWeek);
      this.thisWeekSearchFormEmployee.value.emp_id = this.loggedInUser.id;
      this.thisWeekSearchFormEmployee.value.dateFrom = this.firstDayThisWeek;
      this.thisWeekSearchFormEmployee.value.dateTo = this.lastDayThisWeek;
      console.log(this.thisWeekSearchFormEmployee.value);
      this._EmployeesService.searchEmployeeLeave(this.thisWeekSearchFormEmployee.value).subscribe(data => {
         
        this.employeeLeaveData = data
        console.log(this.employeeLeaveData);
      });
    }else if (ev == 5 ){
      this.firstDayThisWeek = moment( ).startOf('year').format("YYYY-MM-DD");
      this.lastDayThisWeek = moment( ).endOf('year').format("YYYY-MM-DD");
      console.log(this.firstDayThisWeek);
      console.log(this.lastDayThisWeek);
      this.thisWeekSearchFormEmployee.value.emp_id = this.loggedInUser.id;
      this.thisWeekSearchFormEmployee.value.dateFrom = this.firstDayThisWeek;
      this.thisWeekSearchFormEmployee.value.dateTo = this.lastDayThisWeek;
      console.log(this.thisWeekSearchFormEmployee.value);
      this._EmployeesService.searchEmployeeLeave(this.thisWeekSearchFormEmployee.value).subscribe(data => {
         
        this.employeeLeaveData = data
        console.log(this.employeeLeaveData);
      });
    }else if (ev == 6 ){
      this._EmployeesService.getEmployeeLeavesById(this.loggedInUser.id).subscribe(data => {
       this.employeeLeaveData
       this.employeeLeaveData = data
       console.log(this.employeeLeaveData);
     });
   }
  }
   }

  resetSearch(): void{
    this.leavesLoading = true;
    this.searchForm.reset();
    this._EmployeesService.getEmployeeLeave().subscribe(data => {
      this.employeeLeaveData = data;
      this.leavesLoading = false;
    });
  }

  employeeLeaveForm = new FormGroup({
    emp_id: new FormControl(this.loggedInUser.id),
    ltype_id: new FormControl(null, Validators.required),
    leave_from: new FormControl(null, Validators.required),
    leave_to: new FormControl(null, Validators.required),
    leave_reason: new FormControl(null, Validators.required),
    status: new FormControl('pending')
  });

  employeeLeaveFormForAdmin = new FormGroup({
    emp_id: new FormControl(null, Validators.required),
    ltype_id: new FormControl(null, Validators.required),
    leave_from: new FormControl(null, Validators.required),
    leave_to: new FormControl(null, Validators.required),
    leave_reason: new FormControl(null, Validators.required),
    status: new FormControl(null, Validators.required)
  });

  employeeLeaveEditForm = new FormGroup({
    id: new FormControl(),
    emp_id: new FormControl(null, Validators.required),
    ltype_id: new FormControl(null, Validators.required),
    leave_from: new FormControl(null, Validators.required),
    leave_to: new FormControl(null, Validators.required),
    leave_reason: new FormControl(null, Validators.required),
    status: new FormControl(null, Validators.required)
  });

  open(): void{
    this.visible = true;
  }

  close(): void{
    this.visible = false;
  }

  openEdit(id): void {
    this.visibleEdit = true;

    let data = this.employeeLeaveData.find(data => data.id == id);

    this.selectedEmployee = data.emp_id;
    this.selectedLeaveType = data.ltype_id;
    this.selectedStatus = data.status;

    this.employeeLeaveEditForm.patchValue({
      id: id,
      leave_from: data.leave_from,
      leave_to: data.leave_to,
      leave_reason: data.leave_reason
    });
  }
  closeEdit(): void {
    this.visibleEdit = false;
  }

  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.leaveToDeleteId = id;
  }
  closeDeleteModal(): void{
    this.visibleDelete = false;
  }

  addLeaveEmployee(): void {
    for (const i in this.employeeLeaveForm.controls){
      this.employeeLeaveForm.controls[i].markAsDirty();
      this.employeeLeaveForm.controls[i].updateValueAndValidity();
    }
    if(this.employeeLeaveForm.valid){
    this.employeeLeaveForm.value.leave_from = moment(this.employeeLeaveForm.value.leave_from).format('YYYY-MM-DD');
    this.employeeLeaveForm.value.leave_to = moment(this.employeeLeaveForm.value.leave_to).format('YYYY-MM-DD');
      this._EmployeesService.createEmployeeLeave(this.employeeLeaveForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.error == true)
          {
            this.notification.create(
              'error',
              'Request Leave',
              responseObj.message
              );            
          }
          else if(responseObj.error == false)
          {
            this.applyLeaveForm = false;
            this.notification.create(
              'success',
              'Add Leave',
              'Successfully Added Leave'
              );
              // update data
              
              if(this.loggedInUser.role_id !== 2){
                this._EmployeesService.getEmployeeLeave().subscribe(data => {
                  this.employeeLeaveData = data;
                  this.leavesLoading = false;
                });
              }else{
                this._EmployeesService.getEmployeeLeavesById(this.loggedInUser.id).subscribe(data => {
                  this.employeeLeaveData = data;
                  this.leavesLoading = false;
                });
              }
          }
          else{
            this.notification.create(
              'error',
              'Add Leave',
              'Somethings Wrong'
              );            
          }
        }
      );
    }
  }
  addLeave(): void {
    for (const i in this.employeeLeaveFormForAdmin.controls){
      this.employeeLeaveFormForAdmin.controls[i].markAsDirty();
      this.employeeLeaveFormForAdmin.controls[i].updateValueAndValidity();
    }
    if(this.employeeLeaveFormForAdmin.valid){
    this.employeeLeaveFormForAdmin.value.leave_from = moment(this.employeeLeaveFormForAdmin.value.leave_from).format('YYYY-MM-DD');
    this.employeeLeaveFormForAdmin.value.leave_to = moment(this.employeeLeaveFormForAdmin.value.leave_to).format('YYYY-MM-DD');
      this._EmployeesService.createEmployeeLeave(this.employeeLeaveFormForAdmin.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists')
          {
            this.notification.create(
              'error',
              'Add Leave',
              'Leave Already Exists'
              );            
          }
          else if(responseObj.message == 'failed')
          {
            this.notification.create(
              'error',
              'Add Leave',
              'Cant Add Leave'
              );            
          }
          else if(responseObj.message == 'success')
          {
            this.visible = false;
            this.notification.create(
              'success',
              'Add Leave',
              'Successfully Added Leave'
              );
              // update data
              
              if(this.loggedInUser.role_id !== 2){
                this._EmployeesService.getEmployeeLeave().subscribe(data => {
                  this.employeeLeaveData = data;
                  this.leavesLoading = false;
                });
              }else{
                this._EmployeesService.getEmployeeLeavesById(this.loggedInUser.id).subscribe(data => {
                  this.employeeLeaveData = data;
                  this.leavesLoading = false;
                });
              }
          }
          else{
            this.notification.create(
              'error',
              'Add Leave',
              'Somethings Wrong'
              );            
          }
        }
      );
    }
  }
  editEmployeeLeave(): void {
    for (const i in this.employeeLeaveEditForm.controls) {
      this.employeeLeaveEditForm.controls[i].markAsDirty();
      this.employeeLeaveEditForm.controls[i].updateValueAndValidity();
    }

    if(this.employeeLeaveEditForm.valid)
    {
      this.employeeLeaveEditForm.value.leave_from = moment(this.employeeLeaveEditForm.value.leave_from).format('YYYY-MM-DD');
      this.employeeLeaveEditForm.value.leave_to = moment(this.employeeLeaveEditForm.value.leave_to).format('YYYY-MM-DD');                                                          
      this._EmployeesService.editEmployeeLeave(this.employeeLeaveEditForm.value).subscribe(
        response => {
          console.log("response: ",response);
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success')
          {
            
            if(this.loggedInUser.role_id !== 2){
              this._EmployeesService.getEmployeeLeave().subscribe(data => {
                this.employeeLeaveData = data;
                this.leavesLoading = false;
              });
            }else{
              this._EmployeesService.getEmployeeLeavesById(this.loggedInUser.id).subscribe(data => {
                this.employeeLeaveData = data;
                this.leavesLoading = false;
              });
            }
            this.notification.create(
              'success',
              'Edit Leave',
              'Successfully Edit Leave'
              );
          }
          else if(responseObj.message == 'failed')
          {
            this.notification.create(
              'error',
              'Edit Leave',
              'Cant Edit Leave'
              );            
          }
          else
          {
            this.notification.create(
              'error',
              'Edit Leave',
              'Somethings Wrong'
              );
          }
        }
      )
    }
  }
  deleteEmployeeLeave(id): void {
    this.isOkLoading = true;
    this._EmployeesService.deleteEmployeeLeave(id).subscribe(
      response => {
        let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'success')
        {
          if(this.loggedInUser.role_id !== 2){
            this._EmployeesService.getEmployeeLeave().subscribe(data => {
              this.employeeLeaveData = data;
              this.leavesLoading = false;
            });
          }else{
            this._EmployeesService.getEmployeeLeavesById(this.loggedInUser.id).subscribe(data => {
              this.employeeLeaveData = data;
              this.leavesLoading = false;
            });
          }
          this.notification.create(
            'success',
            'Delete Leave',
            'Successfully Deleted Leave'
            );
        }
        else if(responseObj.message == 'failed')
        {
          this.notification.create(
            'error',
            'Delete Leave',
            'Cant Delete Leave'
            );          
        }
        else
        {
          this.notification.create(
            'error',
            'Delete Leave',
            'Somethings Wrong'
            );          
        }
      }
    );
    this.isOkLoading = false;
    this.visibleDelete = false;
  }
}
