import { Component, OnInit } from '@angular/core';
import { JobsService } from 'src/app/services/jobs.service'; 
import { Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import * as moment from 'moment';
import { StoreService } from 'src/app/services/store.service';
import { AtsServiceService } from 'src/app/services/ats-service.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-add-job',
  templateUrl: './add-job.component.html',
  styleUrls: ['./add-job.component.scss']
})
export class AddJobComponent implements OnInit {
  stroreEnableDisable;
  skills;
  skillsarr;
  listOfAtsJobsData;
  listOfStoreData;
  listOfJobsData;
  jobsLoading = true;
  jobs;
  dateFormat = 'MMM dd, yyyy';
  load = false;
  drawerVisible = false;
  
  fileToUpload: File;
  validUpload = false;
  loggedInUser = this.storage.get('loggedInUser');
  
  loading = true;

   
  
  jobForm = this.fb.group({
    // company_id: new FormControl(null),
    job_title: new FormControl(null ),
    // industry: new FormControl(null),
    no_position: new FormControl(null ),
    job_location: new FormControl(null ),
    // tax_terms: new FormControl(null),
    // visa_status: new FormControl(null),
    // contract_duration: new FormControl(null),
    total_experience: new FormControl(null),
    related_experience: new FormControl(null),
    remote_job: new FormControl(null),
    job_type: new FormControl(null ),
    job_level: new FormControl(null ),
    skills: new FormControl(null ),
    additional_skills: new FormControl(null ),
    age: new FormControl(null ),
    min_salary: new FormControl(null ),
    max_salary: new FormControl(null ),
    short_desc: new FormControl(null ),
    post_date: new FormControl(null ),
    apply_date: new FormControl(null ),
    close_date: new FormControl(null ),
    status: new FormControl(null ),
    responsibilities: new FormControl(null ),
    
  });
   
  jobOneForm = this.fb.group({
    company_id: new FormControl(null ),
    job_title: new FormControl(null ),
    industry: new FormControl(null ),
    no_position: new FormControl(null ),
    job_location: new FormControl(null ),
    tax_terms: new FormControl(null ),
    visa_status: new FormControl(null ),
    contract_duration: new FormControl(null),
    total_experience: new FormControl(null),
    related_experience: new FormControl(null),
    remote_job: new FormControl(null),
    job_type: new FormControl(null ),
    job_level: new FormControl(null ),
    skills: new FormControl(null ),
    additional_skills: new FormControl(null ),
    age: new FormControl(null ),
    min_salary: new FormControl(null ),
    max_salary: new FormControl(null ),
    description: new FormControl(null ),
    post_date: new FormControl(null ),
    apply_date: new FormControl(null ),
    close_date: new FormControl(null ),
    status: new FormControl(null ),
    reponsibilities: new FormControl(null ),
    
  });
  

  
  constructor(private _jobsService: JobsService, 
    private router: Router,
    private notification: NzNotificationService,
    private _storeService: StoreService,
    private _atsService : AtsServiceService,
    @Inject(LOCAL_STORAGE) private storage: StorageService, private fb: FormBuilder) { }
  public errorMsg;

  ngOnInit(): void {
    this._storeService.getStoreItemView(4).subscribe(
      data=> {  
        this.listOfStoreData = data;
        this.stroreEnableDisable = this.listOfStoreData.enable;

    this._jobsService.getJobs().subscribe(
      data=> { 
        this.listOfJobsData = data;
        this.jobsLoading = false;
        this.load = true;
        this._atsService.getJobsByClientId().subscribe(
          data=> { 
            this.listOfAtsJobsData = data;
            this.jobsLoading = false;
          },
          error => this.errorMsg = error);
      },
      error => this.errorMsg = error);
    },
    error => this.errorMsg = error);
  }

  visible = false;
  open(): void {
    this.visible = true;
  }
  close(): void {
    this.visible = false;
  }  

  removeAttachment(  element){
    this.fileToUpload = null;
    element.value = "";
  }

  //Add Job Below.
  openAddJobDrawer(): void {
    this.jobForm.reset();
    this.drawerVisible = true;
  }

  closeDrawer(): void {
    this.drawerVisible = false;
  }

  onUpload(files: FileList): void{
    this.validUpload = false;
    if(files.length > 0){
      this.fileToUpload = files.item(0);

      var acceptedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.docx|\.doc)$/i;

      if(!acceptedExtensions.exec(this.fileToUpload.name)){
        // this.msg.error(`Supported Formats: .JPG, .JPEG, .PDF, .DOC, DOCX`);
        this.validUpload = false;
      }
      else{
        this.validUpload = true;
      }
    }
  }

  addJob(): void{
    for (const i in this.jobForm.controls) {
    
console.log(this.jobForm.value.skills);

      this.jobForm.controls[i].markAsDirty();
      this.jobForm.controls[i].updateValueAndValidity();
    }
      this.jobForm.value.post_date    = moment(this.jobForm.value.post_date).format('YYYY-MM-DD');
      this.jobForm.value.apply_date   = moment(this.jobForm.value.post_date).format('YYYY-MM-DD');
      this.jobForm.value.close_date   = moment(this.jobForm.value.post_date).format('YYYY-MM-DD');

      if(this.jobForm.valid){
     this._atsService.createJob(this.jobForm.value).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'already-exists'){
        this.notification.create(
          'error',
          'Jobs',
          'Already Exists'
          );
      }else if(responseObj.message == 'failed'){
        this.notification.create(
          'error',
          'Jobs',
          'Unable to save changes'
          );
      }else if(responseObj.message == 'success'){

        this.router.navigate(['/jobs'])
        this.visible = false;

        this.notification.create(
          'success',
          'Jobs',
          'Successfully updated'
          );
      }else{
        this.router.navigate(['/jobs'])

        this.notification.create(
          'success',
          'Jobs',
          'Successfully updated'
          );
      }
    }
  ) 
      }
    }

    addJobOne(): void{
      for (const i in this.jobOneForm.controls) {
       
  console.log(this.jobOneForm.value.skills);
  
   
        this.jobOneForm.controls[i].markAsDirty();
        this.jobOneForm.controls[i].updateValueAndValidity();
      }
        this.jobOneForm.value.post_date    = moment(this.jobOneForm.value.post_date).format('YYYY-MM-DD');
        this.jobOneForm.value.apply_date   = moment(this.jobOneForm.value.post_date).format('YYYY-MM-DD');
        this.jobOneForm.value.close_date   = moment(this.jobOneForm.value.post_date).format('YYYY-MM-DD');
  
        if(this.jobForm.valid){
          
   
    this._jobsService.createJob(this.jobOneForm.value).subscribe(
      response => {
        let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'already-exists'){
          this.notification.create(
            'error',
            'Jobs',
            'Already Exists'
            );
        }else if(responseObj.message == 'failed'){
          this.notification.create(
            'error',
            'Jobs',
            'Unable to save changes'
            );
        }else if(responseObj.message == 'success'){
  
          this.router.navigate(['/jobs'])
          this.visible = false;
  
          this.notification.create(
            'success',
            'Jobs',
            'Successfully updated'
            );
        }else{
          this.router.navigate(['/jobs'])
  
                    this.notification.create(
                      'success',
                      'Jobs',
                      'Successfully updated'
                      );
        }
      }
    )
 
    
  
          
        }else{
          this.notification.create(
            'error',
            'Add Jobs',
            'unable to validate data'
            );
        }
      }
}
