import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
//for catch:
import { catchError } from 'rxjs/operators';
//for throw:
import { Observable, throwError } from 'rxjs';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private apiUrl = environment.stepApiUrl + '/api';
  constructor(private http: HttpClient,@Inject(LOCAL_STORAGE) private storage: StorageService) { }
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.storage.get('auth-token').accessToken,
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json'
  })
  getSettings(){
    return this.http.get(this.apiUrl + '/settings',{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getOfficeTime(){
    return this.http.get(this.apiUrl + '/office-time',{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }

  getDepartments() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/departments' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createDepartment(data: any) {
    return this.http.post(this.apiUrl+'/create-department', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  updateDepartment(data: any) {
    return this.http.post(this.apiUrl + '/update-department', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteDepartment(id: number) {
    return this.http.get(this.apiUrl + '/delete-department/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getDesignations() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/designations' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getDesignationsByDepartmentId(id:any) : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl + '/designations-by-department-id/'+id, { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createDesignation(data: any) {
    return this.http.post(this.apiUrl+'/create-designation', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  updateDesignation(data: any) {
    return this.http.post(this.apiUrl + '/update-designation', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteDesignation(id: number) {
    return this.http.get(this.apiUrl + '/delete-designation/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getExpenses() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/expense-type' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createExpense(data: any){
    return this.http.post(this.apiUrl + '/create-expense-type', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editExpense(data: any){
    return this.http.post(this.apiUrl + '/edit-expense-type', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteExpense(id: number){
    return this.http.get(this.apiUrl + '/delete-expense-type/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getLeaves() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/leave-type' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createLeave(data: any){
    return this.http.post(this.apiUrl + '/create-leave-type', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editLeave(data: any){
    return this.http.post(this.apiUrl + '/edit-leave-type', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteLeave(id: number){
    return this.http.get(this.apiUrl + '/delete-leave-type/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getAwards() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/award' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createAward(data: any){
    return this.http.post(this.apiUrl + '/create-award', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editAward(data: any){
    return this.http.post(this.apiUrl + '/edit-award', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteAward(id: number){
    return this.http.get(this.apiUrl + '/delete-award/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getCurrency() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/currency' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createCurrency(data: any){
    return this.http.post(this.apiUrl + '/create-currency', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editCurrency(data: any){
    return this.http.post(this.apiUrl + '/edit-currency', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteCurrency(id: number){
    return this.http.get(this.apiUrl + '/delete-currency/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getAccountType() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/account-type' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createAccountType(data: any){
    return this.http.post(this.apiUrl + '/create-account-type', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editAccountType(data: any){
    return this.http.post(this.apiUrl + '/edit-account-type', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteAccountType(id: number){
    return this.http.get(this.apiUrl + '/delete-account-type/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getHolidays() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/holidays' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getUpcomingHolidays() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/upcoming-holidays' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createHoliday(data: any){
    return this.http.post(this.apiUrl + '/create-holiday', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editHoliday(data: any){
    return this.http.post(this.apiUrl + '/edit-holiday', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteHoliday(id: number){
    return this.http.get(this.apiUrl + '/delete-holiday/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getNotices(): Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/notices' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createNotice(data: any){
    return this.http.post<any>( this.apiUrl +'/create-notice', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editNotice(data: any){
    return this.http.post<any>( this.apiUrl +'/edit-notice', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteNotice(id:any){
    return this.http.get( this.apiUrl +'/delete-notice/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getStoreItems() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/store' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getStoreItemById(id:any){
    return this.http.get( this.apiUrl + '/store/item/' + id, {headers : this.headers} )
    .pipe(catchError(this.erroHandler));
  }
  installStoreItemById(id:any){
    return this.http.get( this.apiUrl + '/store/enable/' + id, { headers: this.headers } )
    .pipe(catchError(this.erroHandler));
  }
  uninstallStoreItemById(id:any){
    return this.http.get( this.apiUrl + '/store/disable/' + id, { headers: this.headers } )
    .pipe(catchError(this.erroHandler));
  }
  getEnabledStoreItems(){
    return this.http.get( this.apiUrl + '/store/enabled-list' , { headers: this.headers } )
    .pipe(catchError(this.erroHandler));
  }

  getTaxes(): Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/taxes' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createTax(data: any){
    return this.http.post<any>( this.apiUrl +'/create-tax', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editTax(data: any){
    return this.http.post<any>( this.apiUrl +'/edit-tax', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteTax(id:any){
    return this.http.get( this.apiUrl +'/delete-tax/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getTaxRules(id:any){
    return this.http.get( this.apiUrl + '/tax-by-id/' + id, {headers: this.headers})
    .pipe(catchError(this.erroHandler));
  }
 
  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }
}
