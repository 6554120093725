import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { SettingsService } from 'src/app/services/settings.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import * as moment from 'moment'
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from '@angular/common';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-edit-leave',
  templateUrl: './edit-leave.component.html',
  styleUrls: ['./edit-leave.component.scss']
})
export class EditLeaveComponent implements OnInit {

  applyLeaveForm = false;
  dateFrom;
  date;
  dateFormat = 'MMM dd, yyyy';
  leaveTypes;
  employeeLeaveData;
  leavesLoading = true;
  holidayLoading = true;
  holidayData;
  load = false;
  dateIn= null;
  dateOut= null;
  error = false;
  applyLeaveFormOpen(select: Date):void{
    this.applyLeaveForm = true;
    this.dateFrom = select
  }
  applyLeaveFormClose() : void{
    this.applyLeaveForm = false;
  }
  loggedInUser = this.storage.get('loggedInUser');
  casualLeavesAvailed = 0;
  
  sickLeaves = 0;
  casualLeaves = 0;
  sickLeaveTypeId;
  casualLeavesRemaining = 0;
  sickLeavesRemaining = 0;
  thisMonthSickLeavesAvailed = 0;
  thisMonthVacationsLeavesAvailed = 0;
  thisMonthHalfdayLeavesAvailed = 0;
  
  //Copid from employee type component ts
  public errorMsg;

  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  leaveToDeleteId = 0;
  isOkLoading = false;

  listOfEmployeesData;
  employeeDataLoading = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private _EmployeesService: EmployeesService
    , @Inject(LOCAL_STORAGE) private storage: StorageService
    ,private notification: NzNotificationService
    ,private _SettingsService: SettingsService) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    
    this._EmployeesService.getEmployees().subscribe(data=> { 
    
      this.listOfEmployeesData = data;
      this.employeeDataLoading = false;
    },
    error => console.log(error.message));  

    // get leaves list
    this._SettingsService.getLeaves().subscribe(data => {
      console.log(data)
      this.leaveTypes = data;
  
    });  

      if(this.loggedInUser.role_id !== 2){
        this._EmployeesService.getEmployeeLeave().subscribe(dt => {
          this.employeeLeaveData = dt;


      let id = this.route.snapshot.paramMap.get('id');
      let data = this.employeeLeaveData.find(data => data.id == id);
      this.employeeLeaveEditForm.patchValue({
        id: id,
        emp_id: data.emp_id,
        leave_from: data.leave_from,
        leave_to: data.leave_to,
        leave_reason: data.leave_reason,
        remark: data.remark,
        ltype_id: data.ltype_id.toString(),
        status: data.status
      });
      let datas = this.employeeLeaveData.find(datas => datas.id == id);
      this.dateIn = datas.leave_from;
      this.dateOut = datas.leave_to
      this.load = true;
          this.leavesLoading = false;
        });
      }else{
        this._EmployeesService.getEmployeeLeavesById(this.loggedInUser.id).subscribe(data => {
          this.employeeLeaveData = data;
          this.load = true;
          this.leavesLoading = false;
        });
      }

      this._SettingsService.getHolidays().subscribe(data => {
        this.holidayData = data;
        this.holidayLoading = false;
      });
  }

  employeeLeaveEditForm = new FormGroup({
    id: new FormControl(),
    emp_id: new FormControl(null, Validators.required),
    ltype_id: new FormControl(null, Validators.required),
    leave_from: new FormControl(null, Validators.required),
    leave_to: new FormControl(null, Validators.required),
    leave_reason: new FormControl(null, Validators.required),
    remark: new FormControl(null),
    status: new FormControl(null, Validators.required)
  });

  open(): void{
    this.visible = true;
  }

  close(): void{
    this.visible = false;
  }

  editEmployeeLeave(): void {
    for (const i in this.employeeLeaveEditForm.controls) {
      this.employeeLeaveEditForm.controls[i].markAsDirty();
      this.employeeLeaveEditForm.controls[i].updateValueAndValidity();
    }
    this.employeeLeaveEditForm.patchValue({
      // date: moment(selectedDate).format('YYYY-MM-DD'),
      leave_from: this.dateIn,
      leave_to: this.dateOut})
      
    if(this.employeeLeaveEditForm.valid)
    {
      
      console.log(this.employeeLeaveEditForm.value);
      
      this.employeeLeaveEditForm.value.leave_from = moment(this.employeeLeaveEditForm.value.leave_from).format('YYYY-MM-DD');
      this.employeeLeaveEditForm.value.leave_to = moment(this.employeeLeaveEditForm.value.leave_to).format('YYYY-MM-DD');                                                          
      console.log('from',this.employeeLeaveEditForm.value.leave_from)
      console.log('to',this.employeeLeaveEditForm.value.leave_to)
      this._EmployeesService.editEmployeeLeave(this.employeeLeaveEditForm.value).subscribe(
        response => {
          console.log("Response: ",response);
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists')
          {
            this.notification.create(
              'error',
              'Edit Leave',
              'Leave Already Exists'
              );            
          }
          else if(responseObj.message == 'failed')
          {
            this.notification.create(
              'error',
              'Edit Leave',
              'Cant Add Leave'
              );            
          }
          else if(responseObj.message == 'success')
          {
            this.visible = false;
            this.notification.create(
              'success',
              'Add Leave',
              'Successfully Updated Leave'
              );
              // update data
              

            this.router.navigate(['/leave'])
            // this.document.location.href = '/leave';
          }
          else{
            this.notification.create(
              'error',
              'Edit Leave',
              'Somethings Wrong'
              );            

            this.router.navigate(['/leave'])
            // this.document.location.href = '/leave';
          }
        }
      )
    }
  }

  onDateChange(ev){
    if(ev !== null && this.dateOut != null && this.dateIn != null){
      const dateIn = moment.duration(this.dateIn).asDays();
      const dateOut = moment.duration(this.dateOut).asDays();
      console.log(dateIn);
      console.log(dateOut);
      if(dateOut <= dateIn){
        this.error = true
        console.log(this.dateIn);
        console.log(this.dateOut);
      }else{
        this.error = false;
        console.log(this.dateIn);
        console.log(this.dateOut);
      
      }
    }
  }
}
