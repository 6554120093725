import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
//for catch:
import { catchError } from 'rxjs/operators';
//for throw:
import { Observable, throwError } from 'rxjs';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  private apiUrl = environment.stepApiUrl + '/api';
  constructor(private http: HttpClient,@Inject(LOCAL_STORAGE) private storage: StorageService) { }
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.storage.get('auth-token').accessToken,
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json'
  })

  getProjects(): Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/projects' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getProjectById(id:any): Observable<any>{
    return this.http.get<any>( this.apiUrl +'/project-by-id/' +id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getProjectByEmployeeId(id:any): Observable<any>{
    return this.http.get<any>( this.apiUrl +'/get-project-by-employee-id/' +id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createProject(data: any){
    return this.http.post(this.apiUrl + '/create-project', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editProject(data: any){
    return this.http.post(this.apiUrl + '/edit-project', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteProject(id: number){
    return this.http.get(this.apiUrl + '/delete-project/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getAllTasks(): Observable<Task[]>{
    return this.http.get<Task[]>( this.apiUrl +'/tasks',{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getTasks(projectId:any): Observable<Task[]>{
    return this.http.get<Task[]>( this.apiUrl +'/tasks/' + projectId ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getTask(taskId:any): Observable<Task>{
    return this.http.get<Task>( this.apiUrl +'/task/' + taskId ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getTasksByEmployeeId(employeeId:any): Observable<Task[]>{
    return this.http.get<Task[]>( this.apiUrl +'/all-tasks/' + employeeId,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getTasksByEmployeeIdStatus(employeeId:any, status?:any): Observable<Task[]>{
    return this.http.get<Task[]>( this.apiUrl +'/all-tasks/' + employeeId+'/'+ status,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  searchTask(filters: any): Observable<any[]>{
    return this.http.post<any[]>( this.apiUrl +'/search-tasks', JSON.stringify(filters),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getTasksByEmployeeIdAndProjectId(employeeId:any, projectId): Observable<Task[]>{
    return this.http.get<Task[]>( this.apiUrl +'/all-tasks-by-project/' + employeeId+'/'+projectId,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createTask(task: Task){
    return this.http.post(this.apiUrl + '/create-task', JSON.stringify(task),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editTask(task: Task){
    return this.http.post(this.apiUrl + '/edit-task', JSON.stringify(task),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  updateTaskStatus(data: any){
    return this.http.post(this.apiUrl + '/update-task-status', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteTask(id: number){
    return this.http.get(this.apiUrl + '/delete-task/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }

  getFiles(taskId:any): Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/task/files/' + taskId,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createFile(data: any){
    return this.http.post(this.apiUrl + '/task/create-file', data,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editFile(data: {id: number; title: string; size: string;}){
    return this.http.post(this.apiUrl + '/task/edit-file', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteFile(id: number){
    return this.http.get(this.apiUrl + '/task/delete-file/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  downloadFile(id: number){
    return this.http.get(this.apiUrl + '/task/download-file/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getComments(taskId:any): Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/task/comments/' + taskId,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createComment(data: {comment: string; emp_id: number; task_id: number}){
    return this.http.post(this.apiUrl + '/task/create-comment', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editComment(data: {id: number; comment: string}){
    return this.http.post(this.apiUrl + '/task/edit-comment', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteComment(id: number){
    return this.http.get(this.apiUrl + '/task/delete-comment/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getWeeklyTimesheetByEmployeeIdAndTaskId(employeeId:any, taskId: any): Observable<{
    monday:number,
    tuesday:number,
    wednesday:number,
    thursday:number,
    friday:number,
    saturday:number
  }>{
    return this.http.get<{
      monday:number,
      tuesday:number,
      wednesday:number,
      thursday:number,
      friday:number,
      saturday:number
    }>( this.apiUrl + '/get-timesheet-weekly-by-employee-id/' + employeeId + '/' + taskId, { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getCustomTimesheetByEmployeeIdAndDateRange(employeeId:any, data:any) : Observable<any>{
    return this.http.post<any>( this.apiUrl + '/get-custom-timesheet-by-employee-id/' + employeeId, data, { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getLastWeekTimesheetByEmployeeIdAndTaskId(employeeId:any, taskId:any): Observable<{
    monday:number,
    tuesday:number,
    wednesday:number,
    thursday:number,
    friday:number,
    saturday:number
  }>{
    return this.http.get<{
      monday:number,
      tuesday:number,
      wednesday:number,
      thursday:number,
      friday:number,
      saturday:number
    }>( this.apiUrl + '/get-timesheet-last-week-by-employee-id/' + employeeId + '/' + taskId, { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getAllTimesheetsByIdAndDateBetween(employeeId:any, date:any, enddate:any): Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/timesheets-by-id-and-date-between/' + employeeId + '/' + date + '/' + enddate,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getAllTimesheetsByDateBetween(date:any,enddate:any): Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/timesheets-by-date-between/' + date + '/' + enddate,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getOngoingTimesheet(id:any): Observable<any>{
    return this.http.get<any>(this.apiUrl +'/timesheet/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  addTimesheet(data:any){
    return this.http.post(this.apiUrl + '/add-timesheet', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createTimesheet(data: any){
    return this.http.post(this.apiUrl + '/create-timesheet', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getListForEditTimesheet(employeeId:any, taskId:any, date:any){
    return this.http.get(this.apiUrl + '/get-list-for-edit-timesheet/'+employeeId+'/'+taskId+'/'+date,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));  
  }
  editTimesheet(data: any){
    return this.http.post(this.apiUrl + '/edit-timesheet', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteTimesheet(id: number){
    return this.http.get(this.apiUrl + '/delete-timesheet/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  changeTimsheetTime(timesheet_id:number, clockIn:any, clockOut:any){
    return this.http.get(this.apiUrl + '/change-timesheet-time/' + timesheet_id + '/' + clockIn + '/' + clockOut,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  submitTimesheet(data: any){
    return this.http.post(this.apiUrl + '/submit-timesheet', JSON.stringify(data), { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  approveTimesheet(id:any, reason:any){
    return this.http.post(this.apiUrl + '/approve-timesheet', JSON.stringify({
      timesheet_id: id,
      reason: reason
    }), { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  rejectTimesheet(id:any, reason:any){
    return this.http.post(this.apiUrl + '/reject-timesheet', JSON.stringify({
      timesheet_id: id,
      reason: reason
    }), { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getSubmittedTimesheet(employeeId: 0, data:any){
    return this.http.post(this.apiUrl + '/get-submitted-timesheet/' + employeeId, JSON.stringify(data), { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getSubmittedTimesheetRecords(employeeId: 0, data:any){
    return this.http.post(this.apiUrl + '/get-submitted-timesheet-records/' + employeeId, JSON.stringify(data), { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }
}
