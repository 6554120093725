import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { SupportticketsService } from 'src/app/services/supporttickets.service';

@Component({
  selector: 'app-view-support-ticket',
  templateUrl: './view-support-ticket.component.html',
  styleUrls: ['./view-support-ticket.component.scss']
})
export class ViewSupportTicketComponent implements OnInit {

  listOfViewSupportTicketData: ViewSupportTicket[];
  supportTicketLoading = true;
  ticket;
  dateFormat = 'MMM dd, yyyy';
  listOfDisplayData: ViewSupportTicket[];
  SupportTicketData: ViewSupportTicket = {
    id:  null,
    did: null,
    emp_id: null,
    name: null,
    email: null,
    date: null,
    subject: null,
    message: null,
    admin: null,
    reply_by:  null,
    closed_by:  null,
    status:  null
  };
 
  loggedInUser = this.storage.get('loggedInUser');
  
  loading = true;
  
  

  
  constructor(
    private _supportTicketService: SupportticketsService, 
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService, private fb: FormBuilder) { }
  public errorMsg;

  ngOnInit(): void {
    let id:any = this.route.snapshot.paramMap.get('id');
    this._supportTicketService.getViewSupportTicket(id).subscribe(
      data=> { 
        console.log(data); 
         this.ticket = data;
         
         
      },
      error => this.errorMsg = error);
      
  }


}
