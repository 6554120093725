import { Component, OnInit } from '@angular/core';
import { ProjectsService } from 'src/app/services/projects.service';
import { Router, ActivatedRoute } from "@angular/router";
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import * as moment from 'moment';
import { EmployeesService } from 'src/app/services/employees.service';
import { Validators, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-add-timesheet',
  templateUrl: './add-timesheet.component.html',
  styleUrls: ['./add-timesheet.component.scss']
})
export class AddTimesheetComponent implements OnInit {
  disableHour;
  checkInModel = new Date();
  loggedInUser = this.storage.get('loggedInUser');
  dateFormat = 'MMM dd, yyyy';
  listOfEmployeesData;
  listOfAllEmployees;
  selectedEmployee: Employee;
  selectedEmployeeId: any;
  selectedTaskId: any;
  timesheets = []
  taskId;
  taskObject;
  
  public errorMsg;
  addField(){
    this.timesheets.push({
      employee_id:this.selectedEmployeeId,
      task_id: this.route.snapshot.paramMap.get('id'),
      date: this.route.snapshot.paramMap.get('date'),
      clockIn: null,
      clock_in: null,
      clockOut: null,
      clock_out: null,
      error: false
    })
  }
  removeField(ev){
    this.timesheets.splice(ev);
  }

  constructor(private _ProjectsService: ProjectsService,
              private _EmployeesService: EmployeesService, 
              @Inject(LOCAL_STORAGE) private storage: StorageService,
              private notification: NzNotificationService,
              private router: Router,
              private route: ActivatedRoute) {}

  ngOnInit() {
    this.selectedEmployeeId = this.route.snapshot.paramMap.get('employeeid');
    this.taskId = this.route.snapshot.params.id;
    this._ProjectsService.getTask(this.taskId).subscribe(
      data => {
        this.taskObject = data;
        console.log(this.taskObject);
      });
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfAllEmployees = data;
      this.selectedEmployee = this.listOfAllEmployees.find(o => o.id == this.selectedEmployeeId);
      this.listOfEmployeesData = this.listOfAllEmployees;
      }, error => console.log(error.message));

      let task_id = this.route.snapshot.paramMap.get('id');
      this.selectedTaskId = task_id;
      let date = this.route.snapshot.paramMap.get('date');
      this.selectedDate = date;

      this.timesheets.push({
        employee_id:this.selectedEmployeeId,
        task_id: this.route.snapshot.paramMap.get('id'),
        date: this.route.snapshot.paramMap.get('date'),
        clockIn: null,
        clock_in: null,
        clockOut: null,
        clock_out: null,
        error: false
      })
  }
 
  visible = false;
  selectedDate;
  errorRequiredcheckout =false;
errorRequiredcheckin = false;
  saveTimesheet():void{
   
    console.log(this.timesheets);
    let error = false;
    this.timesheets.map((timesheet)=>{
      if (timesheet.clockOut == null ){
        this.errorRequiredcheckout = true;
        console.log("checkout true");
      }else{
      this.errorRequiredcheckout = false;
      console.log("checkout false");}
      if (timesheet.clockIn == null ){
        this.errorRequiredcheckin = true;
        console.log("checkin true");
      }else{
      this.errorRequiredcheckin = false;
      console.log("checkin false");
      }
      if(timesheet.clockIn == null || timesheet.clockOut == null || timesheet.date == null || timesheet.error){
        console.log('nulled');
        error = true;
      }else{
          timesheet.date = moment(timesheet.date, ['MM-DD-YYYY']).format('YYYY-MM-DD');
          timesheet.clock_in = timesheet.clockIn;
          // moment(timesheet.clockIn).format('H:mm');
          timesheet.clock_out = timesheet.clockOut;
          // moment(timesheet.clockOut).format('H:mm');
      }
    });
    if(error == false){
      this._ProjectsService.addTimesheet(this.timesheets).subscribe(response=>{
        let responseObj = JSON.parse(JSON.stringify(response));
            if(responseObj.message == 'already-exists'){
              alert('Already Exisits');
            }
            else if(responseObj.message == 'failed'){
              alert('Faild');
            }else if(responseObj.message == 'success'){
                this.notification.create(
                'success',
                'Success!',
                "Timesheet Updated on "+ moment()
                );
            }else{
              alert(JSON.stringify(response));
            }
          this.router.navigate(['/timesheets'])
      }, error => console.log(error));
    }
  }
  
  onTimeChange(ev, i){
    if(ev !== null){
      const clockIn = moment.duration(this.timesheets[i].clockIn).asMinutes();
      const clockOut = moment.duration(ev).asMinutes();
      if(clockOut <= clockIn){
        this.timesheets[i].error = true
      }else{
        this.timesheets[i].error = false
      }
    }
  }
}
