import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-employee-roles',
  templateUrl: './employee-roles.component.html',
  styleUrls: ['./employee-roles.component.scss']
})
export class EmployeeRolesComponent implements OnInit {
  selectedValue;
  employeeRoles;
  title: 'Employee Roles';
  load = false;
  selectedRole = 1;
  loggedInUser = this.storage.get('loggedInUser');
  visibleaddRole = false;
  visibleeditRole = false;
  visibleDelete = false;
  roleToDeleteId;
  isOkLoading;

  addRoleForm = new FormGroup({
    role_name: new FormControl(null, Validators.required)
  });
  editRoleForm = new FormGroup({
    id: new FormControl(null),
    role_name: new FormControl(null, Validators.required),
  });

  constructor(private notification: NzNotificationService, private _employeeService: EmployeesService, @Inject(LOCAL_STORAGE) private storage: StorageService) { }
  listOfSelection = [
    {
      text: 'Select All Row',
      onSelect: () => {
        this.checkAll(true);
      }
    },
    {
      text: 'Select Odd Row',
      onSelect: () => {
        this.listOfDisplayData.forEach((data, index) => (this.mapOfCheckedId[data.Id] = index % 2 !== 0));
        this.refreshStatus();
      }
    },
    {
      text: 'Select Even Row',
      onSelect: () => {
        this.listOfDisplayData.forEach((data, index) => (this.mapOfCheckedId[data.Id] = index % 2 === 0));
        this.refreshStatus();
      }
    }
  ];
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfDisplayData;
  listOfAllData;
  mapOfCheckedId: { [key: string]: boolean } = {};
  
  currentPageDataChange($event: []): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }
  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item.Id]);
    this.isIndeterminate =
      this.listOfDisplayData.some(item => this.mapOfCheckedId[item.Id]) && !this.isAllDisplayDataChecked;
  }
  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.Id] = value));
    this.refreshStatus();
  }

  ngOnInit() {
    this._employeeService.getEmployeeRoles().subscribe(
      data => {
        this.listOfAllData = data;
        this.listOfDisplayData = this.listOfAllData;
        this.load = true;
      },
      error => console.log(error)
    );
  }
  visible = false;
  open(id:number):void{
    this.selectedRole = id;
    this.visible = true;
  }
  close():void{
    this.visible = false;
  }


  openaddRole(): void{
    this.visibleaddRole = true;
  }
  closeaddRole(): void{
    this.visibleaddRole = false;
  }
  openeditRole(id): void {
    this.visibleeditRole = true;
    let data = this.listOfAllData.find(data => data.id == id);
    this.editRoleForm.patchValue({
      id: id,
      role_name: data.role_name,
    });
  }
  closeEdit(): void {
    this.visibleeditRole = false;
  }
  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.roleToDeleteId = id;
  }
  closeDeleteModal(): void{
    this.visibleDelete = false;
  }
  
  addRole(): void {
    for (const i in this.addRoleForm.controls) {
      this.addRoleForm.controls[i].markAsDirty();
      this.addRoleForm.controls[i].updateValueAndValidity();
    }
    if(this.addRoleForm.valid){
      this._employeeService.addRole(this.addRoleForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            alert('Already Exisits');
          }else if(responseObj.message == 'failed'){
            alert('Faild');
          }else if(responseObj.message == 'success'){
            this._employeeService.getEmployeeRoles().subscribe(
              data => {
                this.listOfAllData = data;
                this.listOfDisplayData = this.listOfAllData;
              },
              error => console.log(error)
            );
            this.addRoleForm.reset();
            this.visibleaddRole = false;
          }else{
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }

  editRole(): void {
    for (const i in this.editRoleForm.controls) {
      this.editRoleForm.controls[i].markAsDirty();
      this.editRoleForm.controls[i].updateValueAndValidity();
    }
    if(this.editRoleForm.valid){
      this._employeeService.editRole(this.editRoleForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success'){
            this._employeeService.getEmployeeRoles().subscribe(
              data => {
                this.listOfAllData = data;
                this.listOfDisplayData = this.listOfAllData;
              },
              error => console.log(error)
            );
            this.editRoleForm.reset();
            this.visibleeditRole = false;
          }else if(responseObj.message == 'failed'){
            alert('Faild');
          }else{
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }

  deleteRole(id): void {
    this.isOkLoading = true;
    this._employeeService.deleteRole(id).subscribe(
      response => {
        let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'success'){
          this._employeeService.getEmployeeRoles().subscribe(
            data => {
              this.listOfAllData = data;
              this.listOfDisplayData = this.listOfAllData;
              this.notification.create(
                'success',
                'User Roles',
                'updated successfully..'
                );            
            },
            error => console.log(error)
          );
        }else if(responseObj.message == 'failed'){
          alert('Faild');
        }else{
          // alert(JSON.stringify(response));
          this.notification.create(
            'success',
            'User Roles',
            'updated successfully..'
            );            
        }
      }
    );

    this.isOkLoading = false;
    this.visibleDelete = false;
  }

}
