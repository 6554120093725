import { Component,OnInit, ViewChild } from '@angular/core';
 
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexGrid
} from "ng-apexcharts";
import { transpileModule } from 'typescript';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  grid: ApexGrid;
};
 

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {

  @ViewChild("chart5", null)
  chart: ChartComponent = new ChartComponent;
  public chartOptions: Partial<any>;

  constructor() {

    this.chartOptions = {
      colors: ['#52459d', '#84e8f5'],
      series: [
        {
          name: "Desktops",
          data: [62, 50, 68, 51, 49, 62, 69, 91, 148, 62, 69, 91]
        },
        {
          name: "Desktops",
          data: [58, 54, 65, 51, 49, 63, 69, 9, 148 , 41, 5, 51]
        }
      ],
      chart: {
        height: 190,
        redrawOnParentResize: true,
         
        type: "line",
        zoom: {
          enabled: false
        },
        toolbar: { tools: { download: false } }
      }, 
      fill: {
        colors: ['#52459d', '#84e8f5']
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth",
        width: "2"
      },
      yaxis: {
        show: false,
      },
      legend: {
        show: false
      },
      grid: {
        show: true,      // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: true //or just here to disable only x axis grids
           }
         },  
        yaxis: {
          lines: { 
            show: false  //or just here to disable only y axis
           }
         }   
      },
      xaxis: {
        labels:{
          style:{
            fontWeight: 600,
            colors:['#d5d8db','#d5d8db','#d5d8db','#d5d8db','#d5d8db','#d5d8db','#d5d8db','#d5d8db','#d5d8db','#d5d8db','#d5d8db','#d5d8db']
          }
        },
        axisBorder: {
          show: false
        },
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ]
      }
    };
   }


  ngOnInit(): void {
  }

}
