import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import * as moment from 'moment'
import { Router } from "@angular/router";
import { DOCUMENT } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-add-holiday',
  templateUrl: './add-holiday.component.html',
  styleUrls: ['./add-holiday.component.scss']
})
export class AddHolidayComponent implements OnInit {

  
  loggedInUser = this.storage.get('loggedInUser');
  loading = true;
  dateFormat = 'MMM dd, yyyy';
  holidayData;
  holidayLoading = true;
load = false;

  constructor(private router: Router,

  private notification: NzNotificationService,
    @Inject(DOCUMENT) private document: Document,
    private _SettingsService: SettingsService
    , @Inject(LOCAL_STORAGE) private storage: StorageService) { }

    public errorMsg;
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  holidayToDeleteId = 0;
  isOkLoading = false;
  holidays: Date[] = [];
  holidayss = [new Date('2020-02-12'), new Date('2020-02-13')];

  ngOnInit(): void {
    
  this._SettingsService.getHolidays().subscribe(data => {
    this.holidayData = data;
    this.holidayLoading = false;
    this.load = true;
    });
  }

  holidayForm = new FormGroup({
    holiday: new FormControl(null, Validators.required),
    occasion: new FormControl(null, Validators.required)
  });

  open(): void {
    this.visible = true;
  }
  close(): void {
    this.visible = false;
  }


  addHoliday(): void {
    for (const i in this.holidayForm.controls) {
      this.holidayForm.controls[i].markAsDirty();
      this.holidayForm.controls[i].updateValueAndValidity();
    }

    if(this.holidayForm.valid)
    {
      this.holidayForm.value.holiday = moment(this.holidayForm.value.holiday).format('YYYY-MM-DD');
      this._SettingsService.createHoliday(this.holidayForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            this.notification.create(
              'error',
              'Holidays',
              'Already Exists'
              );
          }else if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Holidays',
              'Unable to save changes'
              );
          }else if(responseObj.message == 'success'){
            this.router.navigate(['/holiday'])
            this.notification.create(
              'success',
              'Holidays',
              'Successfully published'
              );
          }else{
            this.router.navigate(['/holiday'])
            this.notification.create(
              'success',
              'Holidays',
              'Successfully published'
              );
          }
        }
      )
    }
  }

}
