import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { AccountsService } from 'src/app/services/accounts.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileManagerService } from 'src/app/services/file-manager.service'; 
import { SettingsService } from 'src/app/services/settings.service';
import * as moment from 'moment'
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent implements OnInit {
  selectedValue;
  dateFormat = 'MMM dd, yyyy';
//Copied from employee type component ts
public errorMsg;
loggedInUser = this.storage.get('loggedInUser');

listOfDisplayData: Expense[];
listOfAllData: Expense[];
//
load = false;
visible = false;
visibleEdit = false;
visibleDelete = false;
expenseToDeleteId = 0;
isOkLoading = false;

employeeList;
expenseTypes;

selectedExpenseType;
selectedEmployee;
selectedStatus;
document;

constructor(private _employeeService: EmployeesService, 
  private _SettingsService: SettingsService, 
  private _AccountsService: AccountsService, 
  private notification: NzNotificationService,
  private _filemanager: FileManagerService
  , @Inject(LOCAL_STORAGE) private storage: StorageService) { }

ngOnInit() {
  this._AccountsService.getExpenses().subscribe(data => {
    this.listOfAllData = data;
    this.listOfDisplayData = this.listOfAllData;
    this.load = true;
  });

  this._SettingsService.getExpenses().subscribe(data => {
    this.expenseTypes = data;
  })

  this._employeeService.getEmployees().subscribe(data => {
      this.employeeList = data;
  });
}
// filters
selectedDuration;
firstDayThisWeek;
    lastDayThisWeek;
    thisWeekSearchForm = new FormGroup({
     
     dateFrom: new FormControl(null, Validators.required),
     dateTo: new FormControl(null , Validators.required),
      
   });
 durationSelected(ev){
  this.listOfDisplayData = null;
   if(ev == 1){
     this.firstDayThisWeek = moment( ).startOf('week').add(1, 'd').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment( ).endOf('week').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._employeeService.searchExpense(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.listOfAllData = data
       console.log(this.listOfAllData);
     });
   }else if (ev == 2 ){
     this.firstDayThisWeek = moment( ).startOf('week').subtract(6, 'd').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment( ).endOf('week').subtract(7, 'd').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._employeeService.searchExpense(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.listOfAllData = data
       console.log(this.listOfAllData);
     });
   }else if (ev == 3 ){
     this.firstDayThisWeek = moment( ).startOf('month').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment( ).endOf('month').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._employeeService.searchExpense(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.listOfAllData = data
       console.log(this.listOfAllData);
     });
   }else if (ev == 4 ){
     this.firstDayThisWeek = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._employeeService.searchExpense(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.listOfAllData = data
       console.log(this.listOfAllData);
     });
   }else if (ev == 5 ){
     this.firstDayThisWeek = moment( ).startOf('year').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment( ).endOf('year').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._employeeService.searchExpense(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.listOfAllData = data
       console.log(this.listOfAllData);
     });
   }else if (ev == 6 ){
     this._AccountsService.getExpenses().subscribe(data => {
       this.listOfDisplayData
       this.listOfAllData = data
       console.log(this.listOfDisplayData);
     });
   }
 }



onFileSelect(event) {
  if (event.target.files.length > 0) {
    this.document = event.target.files[0];
  }
}

expenseForm = new FormGroup({
  purchase_by: new FormControl(this.loggedInUser.id),
  expense_title_id: new FormControl(null, Validators.required),
  purchase_from: new FormControl(null, Validators.required),
  purchase_date: new FormControl(null, Validators.required),
  amount: new FormControl(null, Validators.required),
  bill_copy: new FormControl(null, Validators.required)
});

expenseEditForm = new FormGroup({
  id: new FormControl(null),
  purchase_by: new FormControl(this.loggedInUser.id),
  expense_title_id: new FormControl(null, Validators.required),
  purchase_from: new FormControl(null, Validators.required),
  purchase_date: new FormControl(null, Validators.required),
  amount: new FormControl(null, Validators.required),
  bill_copy: new FormControl(null)
});

  open(): void{
    this.visible = true;
  }

  close(): void{
    this.visible = false;
  }

  openEdit(id): void {
    this.visibleEdit = true;

    let data = this.listOfAllData.find(data => data.id == id);

    this.selectedExpenseType = data.expense_title_id;

    this.selectedEmployee = data.purchase_by;

    this.expenseEditForm.patchValue({
      id: id,
      purchase_from: data.purchase_from,
      purchase_date: new Date(data.purchase_date),
      amount: data.amount,
    });
  }
  closeEdit(): void {
    this.visibleEdit = false;
  }

  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.expenseToDeleteId = id;
  }

  closeDeleteModal(): void{
    this.visibleDelete = false;
  }

  addExpense(): void {
    if(this.expenseForm.valid){
      const formData = new FormData();
      formData.append('file', this.document, this.document.name);
      this._filemanager.uploadFile(formData).subscribe(
      (res) => {
        let responseObj = JSON.parse(JSON.stringify(res));
        if(responseObj.path){
          for (const i in this.expenseForm.controls) {
            this.expenseForm.controls[i].markAsDirty();
            this.expenseForm.controls[i].updateValueAndValidity();
          }
            this.expenseForm.value.bill_copy = responseObj.path;
            this.expenseForm.value.purchase_date = moment(this.expenseForm.value.purchase_date).format('YYYY-MM-DD');
            this._AccountsService.createExpense(this.expenseForm.value).subscribe(
              response => {
                let responseObj = JSON.parse(JSON.stringify(response));
                if(responseObj.message == 'already-exists'){
                  alert('Already Exisits');
                }else if(responseObj.message == 'failed'){
                  alert('Faild');
                }else if(responseObj.message == 'success'){
                  this._AccountsService.getExpenses().subscribe(data => this.listOfAllData = data,
                    error => this.errorMsg = error);
                  this.listOfDisplayData = this.listOfAllData;
                  this.visible = false;

                  this.notification.create(
                    'success',
                    'Employee Profile',
                    'Successfully updated'
                    );
                }else{
                  alert(JSON.stringify(response));
                }
              }
            )

        }else if(responseObj.message == 'failed'){
          this.notification.create(
            'error',
            'Employee Profile',
            'Unable to save changes'
            );            
        }else{
          this.notification.create(
            'error',
            'Employee Profile',
            'somethings went wrong'
            );
        }
      });
    }
  }

  editExpense(): void {
    for (const i in this.expenseEditForm.controls) {
      this.expenseEditForm.controls[i].markAsDirty();
      this.expenseEditForm.controls[i].updateValueAndValidity();
    }
    if(this.expenseEditForm.valid){
      // this.expenseEditForm.value.file_to_upload = this.documentToUpload;
      this.expenseEditForm.value.purchase_date = moment(this.expenseEditForm.value.purchase_date).format('YYYY-MM-DD');
      this._AccountsService.editExpense(this.expenseEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success'){
            this._AccountsService.getExpenses().subscribe(data => this.listOfAllData = data,
              error => this.errorMsg = error);
            this.listOfDisplayData = this.listOfAllData;
            this.visibleEdit = false;
          }else if(responseObj.message == 'failed'){
            alert('Faild');
          }else{
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }

  deleteExpense(id): void {
    this.isOkLoading = true;
    this._AccountsService.deleteExpense(id).subscribe(
      response => {
        let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'success')
        {
          this._AccountsService.getExpenses().subscribe(data => this.listOfAllData = data,
            error => this.errorMsg = error);
          this.listOfDisplayData = this.listOfAllData;
        }
        else if(responseObj.message == 'failed')
        {
          this.notification.create(
            'success',
            'Expenses',
            'Deleted successfully'
            );
        }
        else
        {
          this.notification.create(
            'success',
            'Expenses',
            'Deleted successfully'
            );
        }
      }
    );

    this.isOkLoading = false;
    this.visibleDelete = false;
  }

}
