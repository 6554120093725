import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';

@Component({
  selector: 'app-notification-sidebar',
  templateUrl: './notification-sidebar.component.html',
  styleUrls: ['./notification-sidebar.component.scss']
})
export class NotificationSidebarComponent implements OnInit {

  notifications;
  constructor(
    private _EmployeesService: EmployeesService
  ) { }

  ngOnInit() {
    this._EmployeesService.getNotifications().subscribe(data=>{
      this.notifications = data;
    },error=>console.log(error));

  }

}
