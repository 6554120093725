import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd';
import { EmployeesService } from 'src/app/services/employees.service';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.scss']
})
export class HolidayComponent implements OnInit {
  currentPageDataChange($event){
    
  }
  selectedValue;
  loggedInUser = this.storage.get('loggedInUser');
  loading = true;
  dateFormat = 'MMM dd, yyyy';
  holidayData;
  holidayLoading = true;
  load = false;

  constructor(
    private _EmployeesService: EmployeesService,
    private notification: NzNotificationService,
    private _SettingsService: SettingsService
    , @Inject(LOCAL_STORAGE) private storage: StorageService) { }

    public errorMsg;
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  holidayToDeleteId = 0;
  isOkLoading = false;
  holidays: Date[] = [];
  holidayss = [new Date('2020-02-12'), new Date('2020-02-13')];

  ngOnInit(): void {
    this.firstDayThisWeek = moment( ).startOf('year').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment( ).endOf('year').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._EmployeesService.searchHoliday(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.holidayData = data
       this.holidayLoading = false;
       this.load = true;
       console.log(this.holidayData);
     });
  // this._SettingsService.getHolidays().subscribe(data => {
  //   this.holidayData = data;
  //   this.holidayLoading = false;
  //   });
  }
// filters
selectedDuration;
firstDayThisWeek;
    lastDayThisWeek;
    thisWeekSearchForm = new FormGroup({
     
     dateFrom: new FormControl(null, Validators.required),
     dateTo: new FormControl(null , Validators.required),
      
   });
 durationSelected(ev){
   if(ev == 1){
     this.firstDayThisWeek = moment( ).startOf('week').add(1, 'd').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment( ).endOf('week').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._EmployeesService.searchHoliday(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.holidayData = data
       console.log(this.holidayData);
     });
   }else if (ev == 2 ){
     this.firstDayThisWeek = moment( ).startOf('week').subtract(6, 'd').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment( ).endOf('week').subtract(7, 'd').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._EmployeesService.searchHoliday(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.holidayData = data
       console.log(this.holidayData);
     });
   }else if (ev == 3 ){
     this.firstDayThisWeek = moment( ).startOf('month').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment( ).endOf('month').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._EmployeesService.searchHoliday(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.holidayData = data
       console.log(this.holidayData);
     });
   }else if (ev == 4 ){
     this.firstDayThisWeek = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._EmployeesService.searchHoliday(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.holidayData = data
       console.log(this.holidayData);
     });
   }else if (ev == 5 ){
     this.firstDayThisWeek = moment( ).startOf('year').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment( ).endOf('year').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._EmployeesService.searchHoliday(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.holidayData = data
       console.log(this.holidayData);
     });
   }else if (ev == 6 ){
     this._SettingsService.getHolidays().subscribe(data => {
        
       this.holidayData = data
       console.log(this.holidayData);
     });
   }
 }




  holidayForm = new FormGroup({
    holiday: new FormControl(null, Validators.required),
    occasion: new FormControl(null, Validators.required)
  });

  holidayEditForm = new FormGroup({
    id: new FormControl(null),
    holiday: new FormControl(null, Validators.required),
    occasion: new FormControl(null, Validators.required)
  });

  open(): void {
    this.visible = true;
  }
  close(): void {
    this.visible = false;
  }

  openEdit(id): void {
    this.visibleEdit = true;

    let data = this.holidayData.find(data => data.id == id);
    this.holidayEditForm.patchValue({
      id: id,
      holiday: data.holiday,
      occasion: data.occasion,
    });
  }
  closeEdit(): void {
    this.visibleEdit = false;
  }

  showDeleteModal(id){
    this.visibleDelete = true;
    this.holidayToDeleteId = id;
  }

  closeDeleteModal(){
    this.visibleDelete = false;
  }

  addHoliday(): void {
    for (const i in this.holidayForm.controls) {
      this.holidayForm.controls[i].markAsDirty();
      this.holidayForm.controls[i].updateValueAndValidity();
    }

    if(this.holidayForm.valid)
    {
      this.holidayForm.value.holiday = moment(this.holidayForm.value.holiday).format('YYYY-MM-DD');
      this._SettingsService.createHoliday(this.holidayForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists')
          {
            alert('Already Exisits');
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else if(responseObj.message == 'success')
          {
            this._SettingsService.getHolidays().subscribe(data => {
              this.holidayData = data
            },
              error => this.errorMsg = error);
            this.visible = false;
          }
          else
          {
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }

  editHoliday(): void{
    for (const i in this.holidayEditForm.controls) {
      this.holidayEditForm.controls[i].markAsDirty();
      this.holidayEditForm.controls[i].updateValueAndValidity();
    }

    if(this.holidayEditForm.valid)
    {
      this.holidayEditForm.value.holiday = moment(this.holidayEditForm.value.holiday).format('YYYY-MM-DD');
      this._SettingsService.editHoliday(this.holidayEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists')
          {
            alert('Already Exisits');
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else if(responseObj.message == 'success')
          {
            this._SettingsService.getHolidays().subscribe(data => 
              this.holidayData = data              ,
              error => this.errorMsg = error);

            this.visibleEdit = false;
          }
          else
          {
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }

  deleteHoliday(id): void {
    this.isOkLoading = true;
    this._SettingsService.deleteHoliday(id).subscribe(
      response => {
        let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'success')
        {
          this._SettingsService.getHolidays().subscribe(data => 
            {
              this.holidayData = data
            },
            error => this.errorMsg = error);
            this.notification.create(
              'success',
              'Holidays',
              'Deleted Successfully'
              );
        }
        else if(responseObj.message == 'failed')
        {
          this.notification.create(
            'error',
            'Holidays',
            'Unabled to save changes'
            );
        }
        else
        {
          this.notification.create(
            'success',
            'Holidays',
            'Deleted Successfully'
            );
        }
      }
    );

    this.isOkLoading = false;
    this.visibleDelete = false;
  }

}
