import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service'
import { Router } from "@angular/router";
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { DOCUMENT } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  errorMessage:any;
  isError = false;
  loading: boolean;
  loginForm = new FormGroup({
    email: new FormControl(null, Validators.required),
    password: new FormControl(null, Validators.required),
  });
  constructor(private authService: AuthService,private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private notification: NzNotificationService,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    
    // console.log('ok');
    if(this.authService.isLoggedIn()) {
      this.router.navigate(['/dashboard'])
    }
  }
  onSubmit(){
    if(this.loading !== true){
      let data = {
        "email": this.loginForm.value.email,
        "password": this.loginForm.value.password
      }
      this.loading = true;
      this.authService.login(data).subscribe(
        data => {
          if(data.message){
            this.isError = true;
            this.errorMessage = data.message;
            this.loading = false;
            this.notification.create(
              'error',
              'Loging In',
              this.errorMessage
              );
            
          }else{
            this.errorMessage = "";
            this.isError = false;
            this.notification.create(
              'success',
              'Login',
              'Login Successful'
              );
            console.log(data.token.accessToken);
            this.authService.getClaims(data.token.accessToken).subscribe(usr=>{
            usr.avatar = usr.avatar || 'http://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png';
            this.storage.set('loggedInUser',usr);
            this.storage.set('auth-token',data.token);
            this.router.navigate(['/dashboard'])
            this.document.location.href = '/dashboard';
            this.loading = false;
            });
          }
        },
        error => {
          console.log('error')
          this.loading = false;
        },
        () => {
          // 'onCompleted' callback.
          // No errors, route to new page here
        }
      );
    }
  }

}
