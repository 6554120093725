import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
//for catch:
import { catchError } from 'rxjs/operators';
//for throw:
import { Observable, throwError } from 'rxjs';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  private apiUrl = environment.stepApiUrl + '/api';
  constructor(private http: HttpClient,@Inject(LOCAL_STORAGE) private storage: StorageService) { }
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.storage.get('auth-token').accessToken,
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json'
  })

  
  getJobs() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/jobs' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createJob(job: any[]){
    return this.http.post(this.apiUrl + '/jobs/create', JSON.stringify(job),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editJob(id: number, data: any){
    return this.http.post(this.apiUrl + '/jobs/edit/' + id, JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteJob(id: number){
    return this.http.get(this.apiUrl + '/jobs/delete/'+id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
 
  getApplicants(id:number) : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/jobs/get-applicants-by-job/'+id ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  
  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }


}