import { Component, OnInit } from '@angular/core';
import { JobsService } from 'src/app/services/jobs.service'; 
import { Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import * as moment from 'moment';
import { AtsServiceService } from 'src/app/services/ats-service.service';
import { StoreService } from 'src/app/services/store.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  selectedValue;
  stroreEnableDisable;
  visibleDelete = false;
  jobToDeleteId = 0;
  atsJobToDeleteId = 0;
  isOkLoading = false;
  listOfExternalJobsData;
  listOfinternalJobsData;
  listOfStoreData;
  listOfAtsJobsData;
  listOfJobsData;
  jobsLoading = true;
  jobs;
  dateFormat = 'MMM dd, yyyy';
  load = true;
  drawerVisible = false;
  
  fileToUpload: File;
  validUpload = false;
  loggedInUser = this.storage.get('loggedInUser');
  
  loading = true;
 
  jobForm = this.fb.group({
    company_id: new FormControl(null),
    job_title: new FormControl(null),
    industry: new FormControl(null),
    no_position: new FormControl(null),
    job_location: new FormControl(null),
    tax_terms: new FormControl(null),
    visa_status: new FormControl(null),
    contract_duration: new FormControl(null),
    total_experience: new FormControl(null),
    related_experience: new FormControl(null),
    remote_job: new FormControl(null),
    job_type: new FormControl(null),
    job_level: new FormControl(null),
    skills: new FormControl(null),
    additional_skills: new FormControl(null),
    age: new FormControl(null),
    min_salary: new FormControl(null),
    max_salary: new FormControl(null),
    description: new FormControl(null),
    post_date: new FormControl(null),
    apply_date: new FormControl(null),
    close_date: new FormControl(null),
    status: new FormControl(null),
    reponsibilities: new FormControl(null)
  });
 


  
  constructor(private _jobsService: JobsService, 
    private _atsService : AtsServiceService,
    private _storeService: StoreService,
    private notification: NzNotificationService,
    @Inject(LOCAL_STORAGE) private storage: StorageService, private fb: FormBuilder) { }
  public errorMsg;

  ngOnInit(): void {
    this._storeService.getStoreItemView(4).subscribe(
      data=> {  
        this.listOfStoreData = data;
        this.stroreEnableDisable = this.listOfStoreData.enable;

    this._jobsService.getJobs().subscribe(
      data=> { 
        this.listOfinternalJobsData = data;
        this.jobsLoading = false;

        this._atsService.getJobsByClientId().subscribe(
          data=> { 
            this.listOfAtsJobsData = data;
            this.listOfExternalJobsData = this.listOfAtsJobsData.jobs;
           
            console.log(this.listOfAtsJobsData.jobs);
            console.log(this.listOfinternalJobsData);
            console.log(this.listOfStoreData);
            if (this.listOfStoreData.enable == 0){
              this.listOfJobsData = this.listOfinternalJobsData;
              this.load = true;
            }else{
              this.listOfJobsData = this.listOfExternalJobsData;
              this.load = true;
            }


          },
          error => this.errorMsg = error);
      },
      error => this.errorMsg = error);
    },
    error => this.errorMsg = error);
    
  }

  visible = false;
  open(): void {
    this.visible = true;
  }
  close(): void {
    this.visible = false;
  }  

  removeAttachment(tag, element){
    this.fileToUpload = null;
    element.value = "";
  }

  //Add Job Below.
  openAddJobDrawer(): void {
    this.jobForm.reset();
    this.drawerVisible = true;
  }

  closeDrawer(): void {
    this.drawerVisible = false;
  }

  onUpload(files: FileList): void{
    this.validUpload = false;
    if(files.length > 0){
      this.fileToUpload = files.item(0);

      var acceptedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf|\.docx|\.doc)$/i;

      if(!acceptedExtensions.exec(this.fileToUpload.name)){
        // this.msg.error(`Supported Formats: .JPG, .JPEG, .PDF, .DOC, DOCX`);
        this.validUpload = false;
      }
      else{
        this.validUpload = true;
      }
    }
  }
  

  addJob(): void{
    for (const i in this.jobForm.controls) {
      this.jobForm.controls[i].markAsDirty();
      this.jobForm.controls[i].updateValueAndValidity();
    }
      this.jobForm.value.post_date    = moment(this.jobForm.value.post_date).format('YYYY-MM-DD');
      this.jobForm.value.apply_date   = moment(this.jobForm.value.post_date).format('YYYY-MM-DD');
      this.jobForm.value.close_date   = moment(this.jobForm.value.post_date).format('YYYY-MM-DD');

      if(this.jobForm.valid){
        
        this._jobsService.createJob(this.jobForm.value).subscribe(
          response => {
            let responseObj = JSON.parse(JSON.stringify(response));
            if(responseObj.message == 'already-exists'){
              alert('Already Exisits');
            }else if(responseObj.message == 'failed'){
              alert('Faild');
            }else if(responseObj.message == 'success'){
    
              this._jobsService.getJobs().subscribe(
                data=> { 
                  this.listOfJobsData = data;
                  this.jobsLoading = false;
                },
                error => this.errorMsg = error);          
 
              this.visible = false;
            }else{
              alert(JSON.stringify(response));
            }
          }
        )
      }
    }
    showDeleteModal(id): void{
      this.visibleDelete = true;
      this.jobToDeleteId = id;
      console.log(id);
    }
  
    closeDeleteModal(): void{
      this.visibleDelete = false;
    }
  
    deleteJob(id): void {
      this.isOkLoading = true;
      this._jobsService.deleteJob(id).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success')
          {
            this._jobsService.getJobs().subscribe(data => this.listOfinternalJobsData = data,
              error => this.errorMsg = error);
            this.listOfinternalJobsData = this.listOfJobsData;
          }
          else if(responseObj.message == 'failed')
          {
            this.notification.create(
              'success',
              'Job',
              'Deleted successfully'
              );
          }
          else
          {
            this.notification.create(
              'success',
              'Job',
              'Deleted successfully'
              );
          }
        }
      );
  
      this.isOkLoading = false;
      this.visibleDelete = false;
    }
    showDeleteModalAts(id): void{
      this.visibleDelete = true;
      this.atsJobToDeleteId = id;
      console.log(id);
    }
  
    closeDeleteModalAts(): void{
      this.visibleDelete = false;
    }
  
    deleteAtsJob(id): void {
      this.isOkLoading = true;
      this._atsService.deleteJob(id).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success')
          {
            this._atsService.getJobsByClientId().subscribe(data => this.listOfExternalJobsData = data,
              error => this.errorMsg = error);
            this.listOfExternalJobsData = this.listOfJobsData;
          }
          else if(responseObj.message == 'failed')
          {
            this.notification.create(
              'success',
              'Job',
              'Deleted successfully'
              );
          }
          else
          {
            this.notification.create(
              'success',
              'Job',
              'Deleted successfully'
              );
          }
        }
      );
  
      this.isOkLoading = false;
      this.visibleDelete = false;
    }
}
