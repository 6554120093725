import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from "@angular/router";
import { DOCUMENT } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import * as moment from 'moment';
import { ProjectsService } from 'src/app/services/projects.service';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { EmployeesService } from 'src/app/services/employees.service';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss']
})


export class PrivateComponent implements OnInit {
  notifications;
  noOfNotifications = 0;
  tasks;
  selectedEmployeeId;
  started: boolean=false;
  selectDisabled: boolean=false;
  interval;
  duration;
  timesheet;
  selectedTask;
  buttonDisabled = false;
  onTaskChange(): void{
    if(this.selectedTask){
      this.buttonDisabled = false;
    }else{
      this.buttonDisabled = true;
    }
  }
  timesheetForm = new FormGroup({
    employee_id: new FormControl(null, Validators.required),
    task_id: new FormControl(null, Validators.required)
  });
  constructor(private titleService: Title,
    private _EmployeesService: EmployeesService,
    @Inject(DOCUMENT) private document: Document, 
    private router: Router, 
    private _ProjectsService: ProjectsService,
    private authService: AuthService, 
    private notification: NzNotificationService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
    ) { }
  
  loggedInUser = this.storage.get('loggedInUser');
  perms = this.storage.get('permissions');
  ngOnInit(): void {

    this.selectedEmployeeId = this.loggedInUser.id;
    this.timesheetForm.patchValue({
      employee_id: this.loggedInUser.id
    })
    this.authService.getClaims(this.storage.get('auth-token').accessToken).subscribe(response=>{
      this.storage.set('loggedInUser', response);
    }, error=> {
      this.storage.remove('loggedInUser');
      this.storage.remove('auth-token');
      this.router.navigate(['/login']);
      this.document.location.href = '/login';
    })
    this.authService.getPermissions(this.loggedInUser.role_id).subscribe(response=>{
      let data:any=[];
      response.forEach((element) => {
        data.push(element.perm_id);
      });
      this.storage.set('permissions', data);
      this.perms = this.storage.get('permissions');
    });
    this._ProjectsService.getTasksByEmployeeId(this.selectedEmployeeId).subscribe(
      data=>{
        if(data.length > 0){

          this.tasks = data;
          console.log(data);
          
          // for current running timesheet
          this._ProjectsService.getOngoingTimesheet(this.loggedInUser.id).subscribe(data =>{
            this.timesheet = data;
            if(this.timesheet != null){
              this.selectedTask = this.timesheet.task_id;
              let clock_in = moment(this.timesheet.clockIn, ["YYYY-MM-DD H:mm:ss"]).format("DD/MM/YYYY HH:mm:ss");
              let clock_now = moment().format("DD/MM/YYYY HH:mm:ss");
              let diff = moment.utc(moment(clock_now,"DD/MM/YYYY HH:mm:ss").diff(moment(clock_in,"DD/MM/YYYY HH:mm:ss"))).format("H:mm:ss");
              this.duration = diff;
              this.startCounter();
            }else{
              this.stopCounter();
            }
          }, error=> {
            this.notification.create(
              'error',
              'Error!',
              error.message
              );
          });
          // 
        }
      },error=> {
        this.notification.create(
          'error',
          'Error!',
          error.message
        );
    });

    this._EmployeesService.getNotifications().subscribe(data=>{
      this.notifications = data;
      this.noOfNotifications = this.notifications.length;
      console.log(this.noOfNotifications);
      if(this.noOfNotifications == 0){
        this.titleService.setTitle(" STEP - Stratesfy Inc");
      }else{
        this.titleService.setTitle("(" + this.noOfNotifications + ")" + " STEP - Stratesfy Inc");
      }
      
    });
  }

  isCollapsed = false;
  offsetTop = 0;
  nzOffsetBottom = 0;
  IsHidden= true;
  onSelect(){
   this.IsHidden= !this.IsHidden;
  }
  setOffsetTop(): void {
    this.offsetTop += 10;
  }
  setOffsetBottom(): void {
    this.nzOffsetBottom += 10;
  }
  startCounter(): void{ 
    this.started = true;
    this.selectDisabled = true;
    clearInterval(this.interval);
    let duration = moment(this.duration, ['H:mm:ss']);
    this.interval = setInterval(() => {
      duration.add(1, 'second');
      this.duration = duration.format('H:mm:ss');
    }, 1000);
  }
  stopCounter(): void{  
    clearInterval(this.interval);
    this.started = false;
    this.selectDisabled = false;
    this.duration = '00:00:00';
    this.timesheet = null;
  }

  timerTrigger(playOrStop){
    if(!this.timesheetForm.value.task_id){
    this.notification.create(
      'error',
      'Error!',
      'Select a task'
      );
    }
    if(this.timesheetForm.valid){
      this._ProjectsService.createTimesheet(this.timesheetForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            this.notification.create(
              'error',
              'Error!',
              'Already Exists'
              );  
          }
          else if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Error!',
              'Failed '
              );
          }
          else if(responseObj.message == 'success'){
            // for current running timesheet
            this._ProjectsService.getOngoingTimesheet(this.loggedInUser.id).subscribe(data =>{
              this.timesheet = data;
              if(this.timesheet != null){
                this.selectedTask = this.timesheet.task_id;
                let clock_in = moment(this.timesheet.clockIn, ["YYYY-MM-DD H:mm:ss"]).format("DD/MM/YYYY HH:mm:ss");
                let clock_now = moment().format("DD/MM/YYYY HH:mm:ss");
                let diff = moment.utc(moment(clock_now,"DD/MM/YYYY HH:mm:ss").diff(moment(clock_in,"DD/MM/YYYY HH:mm:ss"))).format("H:mm:ss");
                this.duration = diff;
                this.startCounter();
              }else{
                this.stopCounter();
              }
            }, error=> {
              this.notification.create(
                'error',
                'Error!',
                error.message
                );
            });
            if(playOrStop == "play"){
              this.notification.create(
                'success',
                'Play Task',
                'Successfully Played Task'
              )
            }else{
              this.notification.create(
                'success',
                'Stop Task',
                'Successfully Stopped Task'
              )
            }
          }else{
            alert(JSON.stringify(response));
          }
        }
      );
    }
  }
  logout(){
    this.storage.remove('loggedInUser');
    this.storage.remove('auth-token');
    // this.router.navigate(['/login'])
    this.document.location.href = '/login';
  }
}
