import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Router } from "@angular/router";
import { DOCUMENT } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {
  employeeRoles;
  title: 'Employee Roles';
  selectedRole = 1;
  loggedInUser = this.storage.get('loggedInUser');
  visibleaddRole = false;
  visibleeditRole = false;
  visibleDelete = false;
  roleToDeleteId;
  isOkLoading;
  load = false;
  addRoleForm = new FormGroup({
    role_name: new FormControl(null, Validators.required)
  });

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,private notification: NzNotificationService,
    private _employeeService: EmployeesService, @Inject(LOCAL_STORAGE) private storage: StorageService) { }
  listOfSelection = [
    {
      text: 'Select All Row',
      onSelect: () => {
        this.checkAll(true);
      }
    },
    {
      text: 'Select Odd Row',
      onSelect: () => {
        this.listOfDisplayData.forEach((data, index) => (this.mapOfCheckedId[data.Id] = index % 2 !== 0));
        this.refreshStatus();
      }
    },
    {
      text: 'Select Even Row',
      onSelect: () => {
        this.listOfDisplayData.forEach((data, index) => (this.mapOfCheckedId[data.Id] = index % 2 === 0));
        this.refreshStatus();
      }
    }
  ];
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfDisplayData;
  listOfAllData;
  mapOfCheckedId: { [key: string]: boolean } = {};
  
  currentPageDataChange($event: []): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }
  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item.Id]);
    this.isIndeterminate =
      this.listOfDisplayData.some(item => this.mapOfCheckedId[item.Id]) && !this.isAllDisplayDataChecked;
  }
  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.Id] = value));
    this.refreshStatus();
  }

  ngOnInit() {
    this._employeeService.getEmployeeRoles().subscribe(
      data => {
        this.listOfAllData = data;
        this.listOfDisplayData = this.listOfAllData;
        this.load = true;
      },
      error => console.log(error)
    );
  }
  visible = false;
  open(id:number):void{
    this.selectedRole = id;
    this.visible = true;
  }
  close():void{
    this.visible = false;
  }


  openaddRole(): void{
    this.visibleaddRole = true;
  }
  closeaddRole(): void{
    this.visibleaddRole = false;
  }
  
  addRole(): void {
    for (const i in this.addRoleForm.controls) {
      this.addRoleForm.controls[i].markAsDirty();
      this.addRoleForm.controls[i].updateValueAndValidity();
    }
    if(this.addRoleForm.valid){
      this._employeeService.addRole(this.addRoleForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            alert('Already Exisits');
          }else if(responseObj.message == 'failed'){
            alert('Faild');
          }else if(responseObj.message == 'success'){
            this.notification.create(
              'success',
              'User Roles',
              'updated successfully..'
              );            
              this.router.navigate(['/employee-roles'])
          }else{
            this.notification.create(
              'success',
              'User Roles',
              'updated successfully..'
              );            
              this.router.navigate(['/employee-roles'])
          }
        }
      )
    }
  }


}
