import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from 'src/app/services/projects.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmployeesService } from 'src/app/services/employees.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UploadChangeParam } from 'ng-zorro-antd/upload';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@Component({
  selector: 'app-single-project',
  templateUrl: './single-project.component.html',
  styleUrls: ['./single-project.component.scss']
})
export class SingleProjectComponent implements OnInit {
  selectedValue;
  projectId;
  project;
  tasks;
  loggedInUser = this.storage.get('loggedInUser');
  visible = false;
  load = false;
  visibleCreate = false;
  dateFormat = 'MMM dd, yyyy';
  open(): void{
    this.visible = true;
  }

  close(): void{
    this.visible = false;
  }

  openCreate(): void{
    this.visibleCreate = true;
  }

  closeCreate(): void{
    this.visibleCreate = false;
  }

  isOkLoading;
  visibleProject = false;
  visibleAddTask = false;
  visibleTaskDelete = false;
  taskToDeleteId = 0;
  selectedEmployees;
  listOfAllEmployees;
  selectedStatus;
  errorMsg;

  dateSearch;
  listOfDisplayData;
  listOfAllData;

  startValue: Date | null = null;
  endValue: Date | null = null;
  endOpen = false;

  filteredTasks;
  filterChecked = false;
  assignedTo= [];

  onCollapseChange(event): void{
    this.dateSearch = "";
    this.listOfDisplayData = this.listOfAllData;
  }
  
  constructor(private route: ActivatedRoute
    ,private _ProjectsService: ProjectsService
    ,public _EmployeesService: EmployeesService
    ,private msg: NzMessageService
    , @Inject(LOCAL_STORAGE) private storage: StorageService
    ,private notification: NzNotificationService) { }

  ngOnInit() {
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfAllEmployees = data;
      this.selectedEmployees = this.listOfAllEmployees.find(o => o.id == 1);
      
      this.route.paramMap.subscribe(params => {
        this.projectId =this.route.snapshot.params.id;
      });
      this._ProjectsService.getProjectById(this.projectId).subscribe(data=> {
        this.project = data;
        console.log(this.project);
        console.log(data);
        
        const list = data.employees.split(",").map(Number);
        list.forEach(element => {
          const emp = this.listOfAllEmployees.find(o => o.id == element);
          this.assignedTo.push(
            { name: emp.fname, img: emp.avatar });
        });
          console.log(this.project);
          this.load = true;
      })
      console.log(this.project);

      this._ProjectsService.getTasks(this.projectId).subscribe(data => {
        
        console.log(data);
        this.filteredTasks = data;
      });
    },
    error => console.log(error.message));
  }
  taskForm = new FormGroup({
    project_id: new FormControl(null),
    task: new FormControl(null, Validators.required),
    employee_ids: new FormControl(null, Validators.required),
    start_date: new FormControl(null, Validators.required),
    due_date: new FormControl(null, Validators.required),
    estimated_hour: new FormControl(null, Validators.required),
    progress: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    status: new FormControl(null, Validators.required)
  });
  openAddTaskDrawer(): void{
    this.taskForm.reset();
    this.taskForm.patchValue({
      project_id: this.project.id
    });
    this.visibleAddTask = true;
  }
  closeAddTaskDrawer(): void{
    this.visibleAddTask = false;
  }
  showDeleteTaskModal(id): void{
    this.visibleTaskDelete = true;
    this.taskToDeleteId = id;
  }
  closeDeleteTaskModal(): void{
    this.visibleTaskDelete = false;
  }
  handleChange(info: UploadChangeParam): void {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      this.msg.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      this.msg.error(`${info.file.name} file upload failed.`);
    }
  }
  //Task CRUD below
  addTask(): void {
    if(this.taskForm.valid){
      this.taskForm.value.start_date = moment(this.taskForm.value.start_date).format('YYYY-MM-DD');
      this.taskForm.value.due_date = moment(this.taskForm.value.due_date).format('YYYY-MM-DD');
      this._ProjectsService.createTask(this.taskForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            this.notification.create(
              'error',
              'Add Task',
              'Task Already Exists'
              );            
          }else if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Add Task',
              'Cant Add Task'
              );            
          }else if(responseObj.message == 'success'){
            if(this.loggedInUser.role_id !== 2){
              this._ProjectsService.getTasks(this.projectId).subscribe(data => {
                this.tasks = data;
                console.log('thisIsTaks',this.tasks);
                this.filteredTasks = this.tasks;
              });
            }else{
              this._ProjectsService.getTasksByEmployeeIdAndProjectId(this.loggedInUser.id, this.projectId).subscribe(data=>{
                this.tasks = data;
                this.filteredTasks = this.tasks;
              })
            }
              this.notification.create(
                'success',
                'Add Task',
                'Successfully Added Task'
                ); 
          }else{
            this.notification.create(
              'error',
              'Add Task',
              'Somethings Wrong'
              );             
          }
        }
      )
    }
  }
  deleteTask(id): void {
    this.isOkLoading = true;
    this._ProjectsService.deleteTask(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success'){
        if(this.loggedInUser.role_id !== 2){
          this._ProjectsService.getTasks(this.projectId).subscribe(data => {
            this.tasks = data;
            console.log('thisIsTaks',this.tasks);
            this.filteredTasks = this.tasks;
          });
        }else{
          this._ProjectsService.getTasksByEmployeeIdAndProjectId(this.loggedInUser.id, this.projectId).subscribe(data=>{
            this.tasks = data;
            this.filteredTasks = this.tasks;
          })
        }
          this.notification.create(
            'success',
            'Delete Task',
            'Successfully Deleted Task'
            ); 
      }else if(responseObj.message == 'failed'){
        this.notification.create(
          'error',
          'Delete Task',
          'Sucessfully Deleted Task'
          );         
      }else{
        this.notification.create(
          'error',
          'Delete Task',
          'Somethings Wrong'
          ); 
      }
    }
  );
  this.isOkLoading = false;
  this.visibleTaskDelete = false;
  }
  deleteTimesheet(id): void {
    this.isOkLoading = true;
    this._ProjectsService.deleteTimesheet(id).subscribe(
      response => {
        let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'success'){
        }
        else if(responseObj.message == 'failed')
        {
          this.notification.create(
            'error',
            'Delete Timesheet',
            'Cant Delete Timesheet'
            );           
        }
        else
        {
          this.notification.create(
            'error',
            'Delete Timesheet',
            'Somethings Wrong'
            );           
        }
      }
    );

    this.isOkLoading = false;
    this.visibleDelete = false;
  }
  visibleDelete = false;
  timesheetToDeleteId = 0;
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.timesheetToDeleteId = id;
  }
  closeDeleteModal(): void{
    this.visibleDelete = false;
  }

  onStartChange(date: Date): void {
    this.startValue = date;
  }

  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }    
  }

  handleEndOpenChange(open: boolean): void {
    console.log(open);
    this.endOpen = open;
  }

  searchTask()
  {

    if((this.startValue == null && this.endValue == null) || this.filterChecked==true)
    {
      if((this.startValue == null && this.endValue == null))
      {

      }
      else
      {
      this.filteredTasks = this.tasks;
      this.filterChecked = !this.filterChecked;      
      }
    }
    else{
      this.filterChecked = !this.filterChecked;
      let startValue = formatDate(this.startValue,'yyyy-MM-dd','en-PK');
      let endValue = formatDate(this.endValue,'yyyy-MM-dd','en-PK');
      let startTime = formatDate(this.startValue,'hh:mm:ss a','en-PK');
      let endTime = formatDate(this.startValue,'hh:mm:ss a','en-PK');  
      console.log(startValue);
      this.filteredTasks = this.tasks.filter(function(data) { return ((data.start_date >= startValue && data.start_date <= endValue))})                             
    }
  }

  resetSearch()
  {
    this.filteredTasks = this.tasks;    
  }
}


