import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-set-forgot-password',
  templateUrl: './set-forgot-password.component.html',
  styleUrls: ['./set-forgot-password.component.scss']
})
export class SetForgotPasswordComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  forgotForm = new FormGroup({
    password: new FormControl(null, Validators.required),
    confirm: new FormControl(null, Validators.required),
    
  });
  onSubmit(){
    
  }

}
