import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-make-payment-slip',
  templateUrl: './make-payment-slip.component.html',
  styleUrls: ['./make-payment-slip.component.scss']
})
export class MakePaymentSlipComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
