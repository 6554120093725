import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SlackService {
  private apiUrl = environment.stepApiUrl + '/api';
  constructor(private http: HttpClient,@Inject(LOCAL_STORAGE) private storage: StorageService) { }
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.storage.get('auth-token').accessToken,
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json'
  });

  getCredentials(){
    return this.http.get(this.apiUrl + '/get-api-credentials/5', { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getAccessToken( baseUrl, ClientID, clientSecret, code, RedirectURI ) {
      return this.http.get(baseUrl + 
        '/oauth.access?client_id='+ ClientID+
        '&client_secret='+clientSecret+
        '&code='+code+
        '&redirect_uri='+RedirectURI+
        '&tracked=1',{ headers: new HttpHeaders({}) })
      .pipe(catchError(this.erroHandler));
  }
  refreshAccessToken( baseUrl, ClientID, clientSecret, RefreshToken ){
    var formdata = new FormData();
    formdata.append("client_id", ClientID);
    formdata.append("client_secret", clientSecret);
    formdata.append("grant_type", 'refresh_token');
    formdata.append("refresh_token", RefreshToken);
    
    return this.http.post(baseUrl + '/oauth.v2.access', formdata ,{ headers: new HttpHeaders({}) })
    .pipe(catchError(this.erroHandler));
  }
  saveAccessToken(empId, access_token, refresh_token, expiry, realm_id, code){
    return this.http.get(this.apiUrl + '/save-access-token/slack/'+ empId + '/' + access_token + '/' + refresh_token + '/' + expiry + '/' + realm_id + '/' + code, { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getSavedAccessToken(empId){
    return this.http.get(this.apiUrl + '/get-access-token/slack/'+empId, { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  
  getChannels(token) {
    var formdata = new FormData();
    formdata.append("token", token);
    formdata.append("types", "public_channel, private_channel, mpim, im");

    return this.http.post('https://slack.com/api/conversations.list', formdata,{ headers: new HttpHeaders({}) })
    .pipe(catchError(this.erroHandler));
  }
  getChannelMessages(token, channelId) {
    var formdata = new FormData();
    formdata.append("token", token);
    formdata.append("channel", channelId);

    return this.http.post('https://slack.com/api/conversations.history', formdata,{ headers: new HttpHeaders({}) })
    .pipe(catchError(this.erroHandler));
  }
  getUserProfile(token, userId){
    var formdata = new FormData();
    formdata.append("token", token);
    formdata.append("user", userId);

    return this.http.post('https://slack.com/api/users.profile.get', formdata,{ headers: new HttpHeaders({}) })
    .pipe(catchError(this.erroHandler));

  }
  createMessage(token, channelId, text){
    var formdata = new FormData();
    formdata.append("token", token);
    formdata.append("channel", channelId);
    formdata.append("text", text);
    return this.http.post('https://slack.com/api/chat.postMessage', formdata, { headers: new HttpHeaders({}) })
    .pipe(catchError(this.erroHandler));
  }
  
  // emojiList(token){
  //   return this.http.get('https://slack.com/api/emoji.list/'+ token , { headers: new HttpHeaders({}) })
  //   .pipe(catchError(this.erroHandler));
  // }

  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }
}
