import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmployeesService } from 'src/app/services/employees.service';

import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

import { NzNotificationService } from 'ng-zorro-antd/notification';

import * as moment from 'moment';
import { ProjectsService } from 'src/app/services/projects.service';

import { Router } from "@angular/router";

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss']
})
export class AddProjectComponent implements OnInit {

  
  loggedInUser = this.storage.get('loggedInUser');

  
  public errorMsg;
  listOfProjectsData;
  projectsLoading = true;
  tasks;
  selectedEmployees;
  listOfAllEmployees;
  
  //
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  isOkLoading = false;
  selectedStatus;
  project;
  projectToDeleteId = 0;
  constructor( 
private router: Router,
    private _ProjectsService: ProjectsService,
              private msg: NzMessageService
              ,public _EmployeesService: EmployeesService
              , @Inject(LOCAL_STORAGE) private storage: StorageService
              ,private notification: NzNotificationService) { }

  ngOnInit() {

    this._ProjectsService.getProjects().subscribe(data => {
      // console.log(data);
      this.listOfProjectsData = data;
      this.projectsLoading = false;
    });
    

    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfAllEmployees = data;
      this.selectedEmployees = this.listOfAllEmployees.find(o => o.id == 1);
    },
    error => console.log(error.message));

    this.selectedStatus = 'pending';
    this.project = {
      id: null,
      project: null,
      description: null,
      status: null
    }
  }
  projectForm = new FormGroup({
    project: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    employees: new FormControl(null, Validators.required),
    project_deadline: new FormControl(null, Validators.required),
    status: new FormControl(null, Validators.required)
  });
  
  listOfJiraProjects = [];
  listOfJiraTasks = [];
  selectedProject;
  selectedTasks= [];

  open(): void{
    this.projectForm.reset();
    this.visible = true;
  }
  close(): void{
    this.visible = false;
  }
  
  //Projects CRUD below
  addProject(): void {
    for (const i in this.projectForm.controls) {
      this.projectForm.controls[i].markAsDirty();
      this.projectForm.controls[i].updateValueAndValidity();
    }
    if(this.projectForm.valid)
    {
      
      this.projectForm.value.project_deadline = moment(this.projectForm.value.project_deadline).format('YYYY-MM-DD');
      this._ProjectsService.createProject(this.projectForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists')
          {
            this.notification.create(
              'error',
              'Add Project',
              'Cant Add Project'
              );            
          }
          else if(responseObj.message == 'failed')
          {
            this.notification.create(
              'error',
              'Add Project',
              'Cant Add Project'
              );            
          }
          else if(responseObj.message == 'success')
          {
            
            this.notification.create(
              'success',
              'Projects',
              'Successfully updated'
              );
              this.router.navigate(['/projects'])
          }
          else
          {
            this.notification.create(
              'success',
              'Projects',
              'Successfully updated'
              );
              this.router.navigate(['/projects'])
          }
        }
      )
    }
  }

}
