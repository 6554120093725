import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sort-label',
  templateUrl: './sort-label.component.html',
  styleUrls: ['./sort-label.component.scss']
})
export class SortLabelComponent implements OnInit {
  selectedValue = '6 Months';
  constructor() { }

  ngOnInit(): void {
  }

}
