import { Component,OnInit, Input, ViewChild } from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexGrid
} from "ng-apexcharts";
import { toNumber } from 'ng-zorro-antd';
import { ProjectsService } from 'src/app/services/projects.service';
// import { ProjectsService } from 'src/app/services/projects.service';
import { transpileModule } from 'typescript';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
  grid: ApexGrid;
};

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {


  listOfProjectsData;
  projectsId;
   totals = [];
   ngOnInit(): void {
    this._ProjectsService.getProjects().subscribe(data => {
      let subData = data;

      this.projectsId = subData.map(item => {
        return item.id;
    });
    this._ProjectsService.getAllTasks().subscribe(data => {
      console.log(data);
      data.forEach(x => {
        const obj = this.totals.find(o => o.project_id === x.project_id);
        if (obj) {
          let cloneObj = {...obj}
          obj.progress = parseInt(cloneObj.progress)
         obj.progress = parseInt(obj.progress) + parseInt(x.progress)
          
          // console.log("Value",totalValueChart);
        } else {
          this.totals.push(x);
        }
      });
      this.chartOptions.series.map((series) => {
        this.totals.forEach(element => {
          series.data.push(element.progress / 100)
          console.log(series.data)
        });
      })   
      console.log(this.totals)
       });


    
    
    });

  }




  @ViewChild("chart2", null)
  chart: ChartComponent = new ChartComponent;
  public chartOptions: Partial<any>;
  constructor(private _ProjectsService: ProjectsService, ) { 

    this.chartOptions = {
      series: [
        {
          name: " Review",
          data: []
        } 
      ],
      chart: {
        height: 320,
        fontFamily: 'Roboto, sans-serif',
        redrawOnParentResize: true,
        type: "bar",
        toolbar: { tools: { download: false } }
      },
      
      xaxis: {
        labels:{
          style:{
            fontWeight: 200,
            colors:['#8A8A8A','#8A8A8A','#8A8A8A','#8A8A8A','#8A8A8A','#8A8A8A','#8A8A8A']
          }
        },
        axisBorder: {
          show: false
        },
        categories: ["251", "017",  "695",  "846",  "748",  "951", "133", "017",  "695",  "846", ]
      },
      dataLabels: {
        enabled: true,
        formatter: function(val: string) {
          return val ;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#51459E"]
        }
      },
      
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          formatter: function(val: string) {
            return val + "%";
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "17%",
          borderRadius: 2,
          endingShape: "rounded",
          dataLabels: {
            position: "top" , 
             
          }
          
        }
      }, 
      stroke: {
        show: true,
        width: 0.8,
        colors: ["transparent"]
      }, 
      fill: {
        colors: ['#51459E']
      },
      grid: {
        show: true,      // you can either change hear to disable all grids
        xaxis: {
          lines: {
            show: false //or just here to disable only x axis grids
           }
         },  
        yaxis: {
          lines: { 
            show: false  //or just here to disable only y axis
           }
         }   
      },
     
      legend: {
        show: false
      },
      tooltip: {
        y: {
          formatter: function(val: string) {
            return "$ " + val + " thousands";
          }
        }
      }
    };

  }



 

}
function sum(progress: any) {
  throw new Error('Function not implemented.');
}

