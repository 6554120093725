import { Component, Inject, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { SettingsService } from 'src/app/services/settings.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { DOCUMENT } from '@angular/common';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-salary-list',
  templateUrl: './salary-list.component.html',
  styleUrls: ['./salary-list.component.scss']
})
export class SalaryListComponent implements OnInit {
  loggedInUser = this.storage.get('loggedInUser');
  selectedValue;
  //Copid from employee leave component ts
  public errorMsg;
  salaryLoading = true;
  listOfDisplayData;
  listOfAllData;
  departments;
  designations;
  load = false;
  listOfEmployeesData;
  //
  // visibleEdit = false;
  // selectedPaymentType='Monthly';
  constructor(private _EmployeesService: EmployeesService,
    @Inject(DOCUMENT) private document: Document, 
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _SettingsService: SettingsService) { }
  ngOnInit() {
    this._EmployeesService.getSalaryList().subscribe(data => {
      this.listOfAllData = data;
      this.listOfDisplayData = this.listOfAllData;
      this.load = true;
      console.log(data);
    });
    this._SettingsService.getDepartments().subscribe(data => this.departments = data);
    this._SettingsService.getDesignations().subscribe(data => this.designations = data);
    this._EmployeesService.getEmployees().subscribe(data=>  this.listOfEmployeesData = data);
  }

  changeDepartment(ev): void{
    if(ev){
      this._SettingsService.getDesignationsByDepartmentId(ev).subscribe(
        data => { 
          this.designations = data;
        }
      );
      this._EmployeesService.getEmployeesByDepartmentId(ev).subscribe(data=> { 
        this.listOfEmployeesData = data;
      },
      error => console.log(error.message));
    }
  }
  changeDesignation(ev): void{
    if(ev){
      this._EmployeesService.getEmployeesByDesignationId(ev).subscribe(data=> { 
        this.listOfEmployeesData = data;
      },
      error => console.log(error.message));
    }
  }
  
// filters
selectedPayment;
listOfAllPreData;

paymentSelected(ev){
  this.listOfAllPreData=[];
      console.log(ev);
    if(ev == 1){
      this.listOfAllData=null;
      this._EmployeesService.getSalaryList().subscribe(data => {
        data.map((salary) => {
          if (salary.payment_type == "Hourly" ) {
            this.listOfAllPreData.push(salary);
            // console.log(salary);
          }
        });
        this.listOfAllData = this.listOfAllPreData;
        // console.log(this.listOfAllData);
      });
     
    }else if (ev == 2 ){
      this.listOfAllData=null;
      this._EmployeesService.getSalaryList().subscribe(data => {

        data.map((salary) => {
          if (salary.payment_type == "Monthly" ) {
            this.listOfAllPreData.push(salary);
            // console.log(salary);
          }
        });
         this.listOfAllData = this.listOfAllPreData;
      //  console.log(this.listOfAllData);
      });
    }else if (ev == 3 ){
      this.listOfAllData=null;
      this._EmployeesService.getSalaryList().subscribe(data => {
        this.listOfAllData = data;
   
      });
    }
  }

  searchForm = new FormGroup({
    emp_id: new FormControl(null),
 
    department: new FormControl(null),
    designation: new FormControl(null)
  });


  searchSalary(): void{
    // this.leavesLoading = true;
    for (const i in this.searchForm.controls) {
      this.searchForm.controls[i].markAsDirty();
      this.searchForm.controls[i].updateValueAndValidity();
    }
    if(this.searchForm.valid){    
       
      this._EmployeesService.searchSalaryList(this.searchForm.value).subscribe(data => {
        this.listOfAllData = data;
        // this.leavesLoading = false;
      });
    }
  }



  
  resetSearch(): void{
    this.salaryLoading = true;
    this.searchForm.reset();
    this._EmployeesService.getSalaryList().subscribe(data => {
      this.listOfAllData = data;
      this.salaryLoading = false;
    });
  }


  // salaryEditForm = new FormGroup({
  //   id: new FormControl(),
  //   payment_type: new FormControl(null, Validators.required),
  //   basic_salary: new FormControl(null),
  //   overtime_salary: new FormControl(null),
  //   working_hourly_rate: new FormControl(null),
  //   overtime_hourly_rate: new FormControl(null),
  // });

  // onPaymentTypeChange(): void{
  //   if(this.selectedPaymentType == 'Monthly'){
  //     this.salaryEditForm.get('basic_salary').setValidators([Validators.required]);
  //     this.salaryEditForm.get('overtime_salary').setValidators([Validators.required]);
  //     this.salaryEditForm.get('working_hourly_rate').setValidators([Validators.nullValidator]);
  //     this.salaryEditForm.get('overtime_hourly_rate').setValidators([Validators.nullValidator]);
  //   }else{
  //     this.salaryEditForm.get('working_hourly_rate').setValidators([Validators.required]);
  //     this.salaryEditForm.get('overtime_hourly_rate').setValidators([Validators.required]);
  //     this.salaryEditForm.get('basic_salary').setValidators([Validators.nullValidator]);
  //     this.salaryEditForm.get('overtime_salary').setValidators([Validators.nullValidator]);
  //   }
  //   this.salaryEditForm.get('working_hourly_rate').updateValueAndValidity();
  //   this.salaryEditForm.get('overtime_hourly_rate').updateValueAndValidity();
  //   this.salaryEditForm.get('basic_salary').updateValueAndValidity();
  //   this.salaryEditForm.get('overtime_salary').updateValueAndValidity();
  // }
  // openEdit(id): void {
  //   this.visibleEdit = true;
  //   let data = this.listOfAllData.find(data => data.id == id);
  //   this.selectedPaymentType = data.payment_type;
  //   this.salaryEditForm.patchValue({
  //     id: data.id,
  //     payment_type: data.payment_type,
  //     basic_salary: data.basic_salary,
  //     overtime_salary: data.overtime_salary,
  //     working_hourly_rate: data.working_hourly_rate,
  //     overtime_hourly_rate: data.overtime_hourly_rate
  //   });
  // }
  // closeEdit(): void {
  //   this.visibleEdit = false;
  // }

  // editSalary(): void {
  //   for (const i in this.salaryEditForm.controls) {
  //     this.salaryEditForm.controls[i].markAsDirty();
  //     this.salaryEditForm.controls[i].updateValueAndValidity();
  //   }

  //   if(this.salaryEditForm.valid)
  //   {
  //     this._EmployeesService.editSalary(this.salaryEditForm.value).subscribe(
  //       response => {
  //         let responseObj = JSON.parse(JSON.stringify(response));
  //         if(responseObj.message == 'success')
  //         {
  //           this._EmployeesService.getSalaryList().subscribe(data => this.listOfAllData = data,
  //             error => this.errorMsg = error);
  //           this.listOfDisplayData = this.listOfAllData;

  //           this.visibleEdit = false;
  //         }
  //         else if(responseObj.message == 'failed')
  //         {
  //           alert('Faild');
  //         }
  //         else
  //         {
  //           alert(JSON.stringify(response));
  //         }
  //       }
  //     )
  //   }
  // }

}
