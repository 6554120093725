import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

import { NzNotificationService } from 'ng-zorro-antd/notification';

import * as moment from 'moment';
import { ProjectsService } from 'src/app/services/projects.service';

import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  selectedValue;
  loggedInUser = this.storage.get('loggedInUser');

  load = false;
  public errorMsg;
  listOfProjectsData;
  projectsLoading = true;
  tasks;
  
  //
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  isOkLoading = false;
  selectedStatus;
  project;
  projectToDeleteId = 0;
  constructor(
              private _ProjectsService: ProjectsService,
              private msg: NzMessageService
              , @Inject(LOCAL_STORAGE) private storage: StorageService
              ,private notification: NzNotificationService) { }

  ngOnInit() {

    this._ProjectsService.getProjects().subscribe(data => {
      // console.log(data);
      this.listOfProjectsData = data;
      this.projectsLoading = false;
      this.load = true;
    });
    
    this.selectedStatus = 'pending';
    this.project = {
      id: null,
      project: null,
      description: null,
      status: null
    }
  }
  projectForm = new FormGroup({
    project: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    status: new FormControl(null, Validators.required)
  });
  projectEditForm = new FormGroup({
    id: new FormControl(null),
    project: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    status: new FormControl(null, Validators.required)
  });
  
  listOfJiraProjects = [];
  listOfJiraTasks = [];
  selectedProject;
  selectedTasks= [];

  open(): void{
    this.projectForm.reset();
    this.visible = true;
  }
  close(): void{
    this.visible = false;
  }
  openEdit(id): void {
    this.visibleEdit = true;
    let data = this.listOfProjectsData.find(data => data.id == id);
    this.selectedStatus = data.status;
    
    this.projectEditForm.patchValue({
      id: id,
      project: data.project,
      description: data.description,
    });
  }
  closeEdit(): void {
    this.visibleEdit = false;
  }  
  
  
  //Projects CRUD below
  addProject(): void {
    if(this.projectForm.valid)
    {
      this._ProjectsService.createProject(this.projectForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists')
          {
            this.notification.create(
              'error',
              'Add Project',
              'Cant Add Project'
              );            
          }
          else if(responseObj.message == 'failed')
          {
            this.notification.create(
              'error',
              'Add Project',
              'Cant Add Project'
              );            
          }
          else if(responseObj.message == 'success')
          {
            this._ProjectsService.getProjects().subscribe(data => this.listOfProjectsData = data,
              error => this.errorMsg = error);
          }
          else
          {
            this.notification.create(
              'error',
              'Add Project',
              'Successfully Added Project'
              );            
          }
        }
      )
    }
  }
  editProject(): void {
    if(this.projectEditForm.valid)
    {
      this._ProjectsService.editProject(this.projectEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success')
          {
            this._ProjectsService.getProjects().subscribe(data => this.listOfProjectsData = data,
              error => this.errorMsg = error);
            this.notification.create(
              'success',
              'Edit Project',
              'Successfully Edited Project'
              );
          }
          else if(responseObj.message == 'failed')
          {
            this.notification.create(
              'error',
              'Edit Project',
              'Cant Edit Project'
              );            
          }
          else
          {
            this.notification.create(
              'error',
              'Edit Project',
              'Somethings Wrong'
              );            
          }
        }
      )
    }
  }

  deleteProject(id): void {
    this.isOkLoading = true;
    this._ProjectsService.deleteProject(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success')
      {
        this._ProjectsService.getProjects().subscribe(data => this.listOfProjectsData = data,
          error => this.errorMsg = error);
          this.notification.create(
            'success',
            'Delete Project',
            'Successfully Deleted Project'
            ); 
      }
      else if(responseObj.message == 'failed')
      {
        this.notification.create(
          'error',
          'Delete Project',
          'Cant Delete Project'
          );        
      }
      else
      {
        this.notification.create(
          'error',
          'Delete Project',
          'Somethings Wrong'
          );        
      }
    }
  );
  this.isOkLoading = false;
  this.visibleDelete = false;
  }

  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.projectToDeleteId = id;
  }

  closeDeleteModal(): void{
    this.visibleDelete = false;
  }
}
