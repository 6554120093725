import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  status;
   
  listOfStoreItemViewData: StoreItemView[];
  storeItemLoading = true;
  store;
  dateFormat = 'MMM dd, yyyy';
  listOfDisplayData: StoreItemView[];
  StoreData: StoreItemView;
 
  loggedInUser = this.storage.get('loggedInUser');
  
  loading = true;
  storeEnable;
  storeDisable;
  id:any;

  
  constructor(
    private _storeService: StoreService, 
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService, private fb: FormBuilder) { }
  public errorMsg;

  ngOnInit(): void {
      this.id = this.route.snapshot.paramMap.get('id');
    this._storeService.getStoreItemView(this.id).subscribe(
      data=> { 
         this.store = data;
         console.log(this.store);
        
         
      },
      error => this.errorMsg = error);
  }
  statusChanged(event){
    if (event == 1){
      this.status == 1;  
      this._storeService.getStoreEnableId(this.id).subscribe(
        data=> {
          this.storeEnable = data;
          console.log(this.storeEnable)
        }
      )
    }else{
      this.status == 0; 
      this._storeService.getStoreDisableId(this.id).subscribe(
        data=> {
          this.storeDisable = data;
          console.log(this.storeDisable)
        }
      ) 
    }
  }
  

  
}
