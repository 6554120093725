import { Component, Inject, OnInit } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ProjectsService } from 'src/app/services/projects.service';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'app-all-tasks',
  templateUrl: './all-tasks.component.html',
  styleUrls: ['./all-tasks.component.scss']
})
export class AllTasksComponent implements OnInit {
  loggedInUser = this.storage.get('loggedInUser');
  listOfAllData;
  load = false;
  taskToDeleteId = 0;
  filteredTasks;
  tasks;
  visibleTaskDelete = false;
  selectedEmployeeId = this.loggedInUser.id;
  constructor(private _ProjectsService: ProjectsService,
    private notification: NzNotificationService,
    @Inject(LOCAL_STORAGE) private storage: StorageService) { }

  ngOnInit() {
    this._ProjectsService.getTasksByEmployeeId(this.selectedEmployeeId).subscribe(
      // this._ProjectsService.getAllTasks().subscribe( 
      data=>{
        this.tasks = data;
        this.filteredTasks = this.tasks;
        this.load = true;
        console.log(this.tasks);
      });
  }
  searchForm = new FormGroup({
    
    // dateFrom: new FormControl(null,),
    // dateTo: new FormControl(null,),
    task: new FormControl(null),
    project: new FormControl(null)
  });
  searchTask(): void{
    for (const i in this.searchForm.controls) {
      this.searchForm.controls[i].markAsDirty();
      this.searchForm.controls[i].updateValueAndValidity();
    }
    if(this.searchForm.valid){    
      // this.searchForm.value.dateTo =moment(this.searchForm.value.dateTo).format('YYYY-MM-DD');
      // this.searchForm.value.dateFrom =moment(this.searchForm.value.dateFrom).format('YYYY-MM-DD');
      console.log(this.searchForm.value.task);
      console.log(this.searchForm.value.project);
     
      this._ProjectsService.searchTask(this.searchForm.value).subscribe(data => {
        this.filteredTasks = data;
                 
      });
    }
  }
  showDeleteTaskModal(id): void{
    this.visibleTaskDelete = true;
    this.taskToDeleteId = id;
  }
  closeDeleteTaskModal(): void{
    this.visibleTaskDelete = false;
  }

  deleteTask(id): void {
 
    this._ProjectsService.deleteTask(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success'){
        if(this.loggedInUser.role_id !== 2){
          this._ProjectsService.getTasksByEmployeeId(this.selectedEmployeeId).subscribe(data => {
            this.tasks = data;
            console.log('thisIsTaks',this.tasks);
            this.filteredTasks = this.tasks;
          });
        }else{
          this._ProjectsService.getTasksByEmployeeId(this.selectedEmployeeId).subscribe(data=>{
            this.tasks = data;
            this.filteredTasks = this.tasks;
          })
        }
          this.notification.create(
            'success',
            'Delete Task',
            'Successfully Deleted Task'
            ); 
      }else if(responseObj.message == 'failed'){
        this.notification.create(
          'error',
          'Delete Task',
          'Error Delete Task'
          );         
      }else{
        this.notification.create(
          'error',
          'Delete Task',
          'Error Delete Task'
          ); 
      }
    }
  );
  
  this.visibleTaskDelete = false;
  }
}
