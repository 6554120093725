import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
//for catch:
import { catchError } from 'rxjs/operators';
//for throw:
import { Observable, throwError } from 'rxjs';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportticketsService {
  private apiUrl = environment.stepApiUrl + '/api';
  constructor(private http: HttpClient,@Inject(LOCAL_STORAGE) private storage: StorageService) { }
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.storage.get('auth-token').accessToken,
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json'
  })

  // getSupportTickets() : Observable<any[]>{
  //   return this.http.get<any[]>( this.apiUrl +'/Issue/GetIssueByAgentId/GOO000100' ,{ headers: this.headers })
  //   .pipe(catchError(this.erroHandler));
  // }
  // getAllSupportTicket() {
  //   return this.http.get<any[]>( this.apiUrl +'/Issue/GetIssueByCompanyId/GOO000100' ,{ headers: this.headers })
  //   .pipe(catchError(this.erroHandler));
  // }
  // createSupportTicket(data:any) {
  //   return this.http.post( this.apiUrl +'/Issue/SaveIssue/',JSON.stringify(data) ,{ headers: this.headers })
  //   .pipe(catchError(this.erroHandler));
  // }

  getSupportTicket():Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/support-tickets' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createSupportTicket(ticket: any[]){
    return this.http.post(this.apiUrl + '/support-tickets/create', JSON.stringify(ticket),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getViewSupportTicket(id:number):Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/support-ticket-by-id/'+id  ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  // department

  getDepartment():Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/support-tickets/departments' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
   
  createDepartment(ticket: any[]){
    return this.http.post(this.apiUrl + '/support-tickets/departments/create', JSON.stringify(ticket),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  updateDepartment(data: any) {
    return this.http.post(this.apiUrl + '/support-tickets/departments/edit', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteDepartment(id: number) {
    return this.http.post(this.apiUrl + '/support-tickets/departments/delete/'+id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  
}
