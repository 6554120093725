import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { EmployeesService } from 'src/app/services/employees.service';

import { NzNotificationService } from 'ng-zorro-antd/notification';

import * as moment from 'moment';
import { ProjectsService } from 'src/app/services/projects.service';
import { ActivatedRoute,Router } from '@angular/router';


@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.scss']
})
export class EditProjectComponent implements OnInit {
  loggedInUser = this.storage.get('loggedInUser');
  public errorMsg;
  listOfProjectsData;
  projectsLoading = true;
  tasks;

  selectedEmployees:[];
  listOfAllEmployees;
  listOfSelectedValue:[];
  //
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  isOkLoading = false;
  selectedStatus;
  project;
  projectToDeleteId = 0;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public _EmployeesService: EmployeesService,
    private _ProjectsService: ProjectsService,
    private msg: NzMessageService
    , @Inject(LOCAL_STORAGE) private storage: StorageService
    ,private notification: NzNotificationService) { }

  ngOnInit() {
    let id;
    this.route.paramMap.subscribe(params => {
      id =this.route.snapshot.params.id;
    });
    this._ProjectsService.getProjects().subscribe(dt => {
      // console.log(dt);
      this.listOfProjectsData = dt;
      this.projectsLoading = false;
      let data = this.listOfProjectsData.find(data => data.id == id);
      this.selectedStatus = data.status;
      this.selectedEmployees = data.employee_ids;
      if(data.employees !== null){
        this.listOfSelectedValue = data.employees.split(",").map(Number);
        console.log(this.listOfSelectedValue);
      }
      this.projectEditForm.patchValue({
        id: id,
        project: data.project,
        description: data.description,
        status: data.status
      });

      if(data.project_deadline !== null){
        this.projectEditForm.patchValue({
          project_deadline: data.project_deadline,
        });
      }
    });
    
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfAllEmployees = data;
    },
    error => console.log(error.message));    

    this.selectedStatus = 'pending';
    this.project = {
      id: null,
      project: null,
      description: null,
      status: null
    }
  }
  projectEditForm = new FormGroup({
    id: new FormControl(null),
    project: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
    employees: new FormControl(null, Validators.required),
    project_deadline: new FormControl(null, Validators.required),
    status: new FormControl(null, Validators.required)
  });
  
  listOfJiraProjects = [];
  listOfJiraTasks = [];
  selectedProject;
  selectedTasks= [];
  editProject(): void {
    if(this.projectEditForm.valid)
    {

      this.projectEditForm.value.project_deadline = moment(this.projectEditForm.value.project_deadline).format('YYYY-MM-DD');
      this._ProjectsService.editProject(this.projectEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success')
          {
            this._ProjectsService.getProjects().subscribe(data => this.listOfProjectsData = data,
              error => this.errorMsg = error);
            this.notification.create(
              'success',
              'Edit Project',
              'Successfully Edited Project'
              );
              this.router.navigate(['/projects'])
          }
          else if(responseObj.message == 'failed')
          {
            this.notification.create(
              'error',
              'Edit Project',
              'Cant Edit Project'
              );            
          }
          else
          {
            this.notification.create(
              'success',
              'Edit Project',
              'Successfully Edited Project'
              );
              this.router.navigate(['/projects'])
          }
        }
      )
    }
  }


}
