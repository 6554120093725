import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Router } from "@angular/router";
import { DOCUMENT } from '@angular/common';
import { EmployeesService } from 'src/app/services/employees.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {
  loggedInUser = this.storage.get('loggedInUser');
  notifications;

  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private router: Router,
    private _EmployeesService: EmployeesService,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    ) { }
noOfNotifications;
  ngOnInit() {
    console.log(this.loggedInUser)
    
    this._EmployeesService.getNotifications().subscribe(data=>{
      this.notifications = data;
      this.noOfNotifications = this.notifications.length;
    },error=>console.log(error));
  }


  logout(){
    this.storage.remove('loggedInUser');
    this.storage.remove('auth-token');
    // this.router.navigate(['/login'])
    this.document.location.href = '/login';
  }

}
