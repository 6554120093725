import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { EmployeesService } from 'src/app/services/employees.service';
import { TeamsService } from 'src/app/services/teams.service';

@Component({
  selector: 'app-organization-hierarchy',
  templateUrl: './organization-hierarchy.component.html',
  styleUrls: ['./organization-hierarchy.component.scss']
})
export class OrganizationHierarchyComponent implements OnInit {
  listOfAllData;
  listOfEmployees;
  employees_id;
  constructor(
    private notification: NzNotificationService,
        
        private router: Router,
            @Inject(LOCAL_STORAGE) private storage: StorageService,
            private _TeamsService: TeamsService,
            private _EmployeesService: EmployeesService
             
  ) { }

  ngOnInit() {

    this._EmployeesService.getEmployees().subscribe(employees=> { 
      this.listOfEmployees = employees;
  
        this._TeamsService.getTeams().subscribe(data=> { 
          this.listOfAllData = data;
          this.listOfAllData.map((dt) => {
            dt.manager = this.listOfEmployees.find(o => o.id == dt.manager_id);
            let employee_ids = dt.employees.split(',');
            dt.employees_list = [];
            employee_ids.map((emp_id)=>{
              let emp = this.listOfEmployees.find(o => o.id == emp_id);
              dt.employees_list.push(emp);
            });
          });
          console.log(this.listOfAllData);
        },
        error => console.log(error.message));

    });

  }
}
