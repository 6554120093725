import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ChartComponent,
  ApexPlotOptions,
  ApexFill,
  ApexDataLabels,
  ApexLegend,
  ApexStroke
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  labels: any;
  fill: ApexFill;
  stroke: ApexStroke;
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
};

@Component({
  selector: 'app-donutchar-gap',
  templateUrl: './donutchar-gap.component.html',
  styleUrls: ['./donutchar-gap.component.scss']
})
export class DonutcharGapComponent implements OnInit {
  @ViewChild("chart-line", null) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  constructor() { 

    this.chartOptions = {
      series: [60, 20, 20],
      chart: {
        type: "donut",
        width: 225,
        fontFamily: 'Roboto, sans-serif',
        background: 'transparent'
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '80%',
            labels: {
              show: false,
              total: {
                show: false,
                label: '365 Days',
                fontSize: '30',
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 600,
                color: '#8A8A8A',
              }
            }
          }
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        lineCap: "round",
        curve: 'smooth',
        colors: ['#edf0f8'],
        width: 8,
        dashArray: 3,    
      },
      fill: {
        // type: "gradient"
        colors: ['#51459E', '#FF7F5C', '#FF7F5C'],
      },
      legend: {
        show: false,
         
      },
      labels: ["Team A", "Team B", "Team C"],
      

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              show:false
            }
          }
        }
      ]
    };

  }

  ngOnInit() {
  }

}
