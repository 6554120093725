import { Component, Inject, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { NzMessageService } from 'ng-zorro-antd';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { DOCUMENT } from '@angular/common';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-bonuses',
  templateUrl: './bonuses.component.html',
  styleUrls: ['./bonuses.component.scss']
})
export class BonusesComponent implements OnInit {
  loggedInUser = this.storage.get('loggedInUser');
  selectedValue;
  public errorMsg;
  load = false;
  listOfDisplayData;
  listOfAllData;
  departments;
  designations;
  listOfEmployeesData;
  visibleEdit = false;
  selectedPaymentType = 'Monthly';
  constructor(private _EmployeesService: EmployeesService,
    private _SettingsService: SettingsService,
    @Inject(DOCUMENT) private document: Document, 
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private msg: NzMessageService) { }

  ngOnInit() {
    this._EmployeesService.getBonus().subscribe(data => {
      this.listOfAllData = data;
      this.load = true;
      this.listOfDisplayData = this.listOfAllData;
    });
    this._SettingsService.getDepartments().subscribe(data => this.departments = data);
    this._SettingsService.getDesignations().subscribe(data => this.designations = data);
    this._EmployeesService.getEmployees().subscribe(data=>  this.listOfEmployeesData = data);
}
changeDepartment(ev): void{
  if(ev){
    this._SettingsService.getDesignationsByDepartmentId(ev).subscribe(
      data => { 
        this.designations = data;
      }
    );
    this._EmployeesService.getEmployeesByDepartmentId(ev).subscribe(data=> { 
      this.listOfEmployeesData = data;
    },
    error => console.log(error.message));
  }
}
changeDesignation(ev): void{
  if(ev){
    this._EmployeesService.getEmployeesByDesignationId(ev).subscribe(data=> { 
      this.listOfEmployeesData = data;
    },
    error => console.log(error.message));
  }
}

// filters
selectedPayment;
listOfAllPreData;

paymentSelected(ev){
  this.listOfAllPreData=[];
      console.log(ev);
    if(ev == 1){
      this.listOfAllData=null;
      this._EmployeesService.getBonus().subscribe(data => {
        data.map((salary) => {
          if (salary.payment_type == "Hourly" ) {
            this.listOfAllPreData.push(salary);
            // console.log(salary);
          }
        });
        this.listOfAllData = this.listOfAllPreData;
        // console.log(this.listOfAllData);
      });
     
    }else if (ev == 2 ){
      this.listOfAllData=null;
      this._EmployeesService.getBonus().subscribe(data => {

        data.map((salary) => {
          if (salary.payment_type == "Monthly" ) {
            this.listOfAllPreData.push(salary);
            // console.log(salary);
          }
        });
         this.listOfAllData = this.listOfAllPreData;
      //  console.log(this.listOfAllData);
      });
    }else if (ev == 3 ){
      this.listOfAllData=null;
      this._EmployeesService.getBonus().subscribe(data => {
        this.listOfAllData = data;
   
      });
    }
  }
  resetSearch(): void{
    this.searchForm.reset();
    this._EmployeesService.getBonus().subscribe(data => {
      this.listOfAllData = data;
    });
  }
  searchForm = new FormGroup({
    emp_id: new FormControl(null),
 
    department: new FormControl(null),
    designation: new FormControl(null)
  });
  
  searchBonus(): void{
    // this.leavesLoading = true;
    for (const i in this.searchForm.controls) {
      this.searchForm.controls[i].markAsDirty();
      this.searchForm.controls[i].updateValueAndValidity();
    }
    if(this.searchForm.valid){    
       
      this._EmployeesService.searchBonuses(this.searchForm.value).subscribe(data => {
        this.listOfAllData = data;
        // this.leavesLoading = false;
      });
    }
  }

  onPaymentTypeChange(): void{
    if(this.selectedPaymentType == 'Monthly')
    {

    }
    else
    {

    }
  }

  bonusEditForm = new FormGroup({
    id: new FormControl(),
    payment_type: new FormControl(null, Validators.required),
    basic_salary_bonus: new FormControl(null),
    overtime_salary_bonus: new FormControl(null),
    working_hourly_bonus: new FormControl(null),
    overtime_hourly_bonus: new FormControl(null),
  });


  openEdit(id): void {
    this.visibleEdit = true;

    let data = this.listOfAllData.find(data => data.id == id);

    this.selectedPaymentType = data.payment_type;

    this.bonusEditForm.patchValue({
      id: data.id,
      payment_type: data.payment_type,
      basic_salary_bonus: data.basic_salary_bonus,
      overtime_salary_bonus: data.overtime_salary_bonus,
      working_hourly_bonus: data.working_hourly_bonus,
      overtime_hourly_bonus: data.overtime_hourly_bonus
    });
  }
  closeEdit(): void {
    this.visibleEdit = false;
  }

  editBonus(): void {
    if(this.bonusEditForm.valid)
    {
      this._EmployeesService.editBonus(this.bonusEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success')
          {
            this._EmployeesService.getBonus().subscribe(data => this.listOfAllData = data,
              error => this.errorMsg = error);
            this.listOfDisplayData = this.listOfAllData;

            this.msg.success("Salary Bonus Added Successfully.");
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else
          {
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }

  currentPageDataChange($event){}
}
