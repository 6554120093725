import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectsService } from 'src/app/services/projects.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmployeesService } from 'src/app/services/employees.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import * as moment from 'moment';
import { DOCUMENT } from '@angular/common';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@Component({
  selector: 'app-add-task-by-timesheet',
  templateUrl: './add-task-by-timesheet.component.html',
  styleUrls: ['./add-task-by-timesheet.component.scss']
})
export class AddTaskByTimesheetComponent implements OnInit {
  projectId=0;
  load = false;
  listOfAllProjects;
  loggedInUser = this.storage.get('loggedInUser');
  dateFormat = 'MMM dd, yyyy';
  empOfSelectedProject;
  selectedEmployees;
  listOfAllEmployees;
  errorMsg;
  selectedProject;
  assignedTo: any[];

  
  constructor(private route: ActivatedRoute
    ,private _ProjectsService: ProjectsService
    ,public _EmployeesService: EmployeesService
    ,private msg: NzMessageService
    , @Inject(LOCAL_STORAGE) private storage: StorageService
    ,private notification: NzNotificationService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
    ) {  this.assignedTo= [];}
    listOfEmployees;
  ngOnInit() {
    this.load = true;
    if(this.loggedInUser.role_id !== 2 ){
    this._ProjectsService.getProjects().subscribe(data=>{
      this.listOfAllProjects = data; 
      
       
      console.log(this.listOfAllProjects);
    });
  }else{
    this._ProjectsService.getProjectByEmployeeId(this.loggedInUser.id).subscribe(data=>{
      this.listOfAllProjects = data;
      
      console.log(this.listOfAllProjects);
    });
  }
console.log(this.loggedInUser);
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfAllEmployees = data;
      this.assignedTo = data;
      this.selectedEmployees = this.listOfAllEmployees.find(o => o.id == 1);
    },
    error => console.log(error.message));
    //  ==----------------------
      
  }
assignee(ev){
  this.assignedTo= [];
  this._ProjectsService.getProjectById(ev).subscribe(data=> {
    this.selectedProject = data;
    const list = data.employees.split(",").map(Number);
      list.forEach(element => {
        const emp = this.listOfAllEmployees.find(o => o.id == element);
        this.assignedTo.push(
          { fname: emp.fname, img: emp.avatar, id: emp.id});
      });
   });
   console.log(this.assignedTo);
}
progresspercent;
progressError = false;
changeStatus(ev){
  this.progresspercent = ev.substring(0, ev.length - 1);
  console.log(this.progresspercent);
  if(this.progresspercent <= 0){
    this.progressError = false;
    this.taskForm.patchValue({
      status: "pending",
       
    });
     
  }else if(this.progresspercent > 0 && this.progresspercent < 99 ){
    this.progressError = false;
    this.taskForm.patchValue({
      status: "started",
       
    });
     
  }else if(this.progresspercent > 99 && this.progresspercent < 101){
    this.progressError = false;
  this.taskForm.patchValue({
    status: "completed",
     
  });
   
}else if(this.progresspercent > 100 ){
  this.progressError = true;
  this.taskForm.patchValue({
    status: " ",
     
  });
  
}else{
  this.progressError = true;
  this.taskForm.patchValue({
    status: "",
     
  });
  
}

}

  taskForm = new FormGroup({
    project_id: new FormControl(null),
    task: new FormControl(null, Validators.required),
    employee_ids: new FormControl(null, Validators.required),
    start_date: new FormControl(null, Validators.required),
    due_date: new FormControl(null, Validators.required),
    estimated_hour: new FormControl(null, Validators.required),
    progress: new FormControl(null, Validators.required),
    description: new FormControl(null),
    status: new FormControl(null, Validators.required),
    
    assigned_by: new FormControl(null, Validators.required)
  });
  error =false;
  startDate;
  endDate;
  onDateChange(ev){
    if(ev !== null && this.endDate != null && this.startDate != null){
      const dateIn = moment.duration(this.startDate).asDays();
      const dateOut = moment.duration(this.endDate).asDays();
      console.log(dateIn);
      console.log(dateOut);
      if(dateOut <= dateIn){
        this.error = true
        console.log(this.startDate);
        console.log(this.endDate);
      }else{
        this.error = false;
        console.log(this.startDate);
        console.log(this.endDate);
      
      }
      
    }else{
      this.error = false;
    }
    
  }
  //Task CRUD below
  addTask(): void {
    for (const i in this.taskForm.controls) {
      this.taskForm.controls[i].markAsDirty();
      this.taskForm.controls[i].updateValueAndValidity();
    }
    if(this.taskForm.valid){
      this.taskForm.value.project_id = this.projectId;
      this.taskForm.value.start_date = moment(this.taskForm.value.start_date).format('YYYY-MM-DD');
      this.taskForm.value.due_date = moment(this.taskForm.value.due_date).format('YYYY-MM-DD');
      this.taskForm.value.task_deadline = moment(this.taskForm.value.task_deadline).format('YYYY-MM-DD');
      this._ProjectsService.createTask(this.taskForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            this.notification.create(
              'error',
              'Add Task',
              'Task Already Exists'
              );            
          }else if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Add Task',
              'Cant Add Task'
              );            
          }else if(responseObj.message == 'success'){
              this.notification.create(
                'success',
                'Add Task',
                'Successfully Added Task'
                ); 

            this.router.navigate(['/timesheets'])
          }else{
            this.notification.create(
              'success',
              'Add Task',
              'Successfully Added Task'
              );     

            this.router.navigate(['/timesheets'])
          }
        }
      )
    }
  }
}
