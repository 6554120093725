import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { SettingsService } from 'src/app/services/settings.service';
import { SupportticketsService } from 'src/app/services/supporttickets.service';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss']
})
export class DepartmentComponent implements OnInit {
  selectedValue;
  visible = false;
  visibleDelete = false;
  departmentToDeleteId: any;
  listOfdepartmentData;
  departmenLoading = true;
  isOkLoading = false;
  dateFormat = 'MMM dd, yyyy';

  drawerVisible = false;
  
  fileToUpload: File;
  validUpload = false;
  loggedInUser = this.storage.get('loggedInUser');
  loading = true;
  listOfDisplayData;
  listOfAllData;
  constructor(private _supportTicketService: SupportticketsService, 
    private notification: NzNotificationService,
    @Inject(LOCAL_STORAGE) private storage: StorageService, private fb: FormBuilder) { }
  public errorMsg;

  ngOnInit(): void {
    this._supportTicketService.getDepartment().subscribe(
      data=> { 
        console.log(data);

        this.listOfdepartmentData = data;
        this.departmenLoading = false;
      },
      error => this.errorMsg = error);
  }
 
  
     
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.departmentToDeleteId = id;
    console.log(id);
  }

  closeDeleteModal(): void{
    this.visibleDelete = false;
  }

  deleteDepartment(id): void {
    this.isOkLoading = true;
    this._supportTicketService.deleteDepartment(id).subscribe(
      response => {
        let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'success')
        {
          this._supportTicketService.getDepartment().subscribe(data => this.listOfdepartmentData = data,
            error => this.errorMsg = error);
          this.listOfDisplayData = this.listOfAllData;
        }
        else if(responseObj.message == 'failed')
        {
          this.notification.create(
            'success',
            'Department',
            'Deleted successfully'
            );
        }
        else
        {
          this.notification.create(
            'success',
            'Department',
            'Deleted successfully'
            );
        }
      }
    );

    this.isOkLoading = false;
    this.visibleDelete = false;
  }

  }

 

   
  

   
  
 
   


  
 
  
 
