import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { NzNotificationService  } from 'ng-zorro-antd';
import { Router,ActivatedRoute } from "@angular/router";
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-edit-fund',
  templateUrl: './edit-fund.component.html',
  styleUrls: ['./edit-fund.component.scss']
})
export class EditFundComponent implements OnInit {

  loggedInUser = this.storage.get('loggedInUser');
  public errorMsg;

  listOfDisplayData;
  listOfAllData;
  //
  load = false;
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  providentFundToDeleteId = 0;
  isOkLoading = false;

  providentFund: ProvidentFund[];

  selectedEmployee;
  selectedProvidentFundType;
  employeeShare;
  organizationShare;

  selectedEditProvidentFundType;
  employeeShareEdit;
  organizationShareEdit;

  employeeShareAmountEdit;
  organizationShareAmountEdit;
  listOfEmployees;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
private route: ActivatedRoute,
private notification: NzNotificationService,
private router: Router,
    private _EmployeesService: EmployeesService, private nzError: NzMessageService) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');

    this.selectedProvidentFundType = 'Fixed Amount';
    this.employeeShare = 0;
    this.organizationShare = 0;

    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfEmployees = data;
      
      this._EmployeesService.getProvidentFund().subscribe(dt => {
        this.listOfAllData = dt;
        this.load = true;
        this.listOfDisplayData = this.listOfAllData;

        let data = this.listOfAllData.find(data => data.id == id);
        this.selectedProvidentFundType = data.provident_fund_type;
        this.providentFundEditForm.patchValue({
          id: id,
          emp_id: data.emp_id,
          provident_fund_type: data.provident_fund_type,
          employee_share: data.employee_share,
          organization_share: data.organization_share,
          description: data.description,
          status: data.status
        });
      });
    },
    error => console.log(error.message));
  }

  providentFundEditForm = new FormGroup({
    id: new FormControl(null),
    emp_id: new FormControl(null, Validators.required),
    provident_fund_type: new FormControl(null, Validators.required),
    employee_share: new FormControl(null),
    organization_share: new FormControl(null),
    description: new FormControl(null),
    status: new FormControl(null),
  });

  open(): void{
  this.visible = true;
  }

  close(): void{
  this.visible = false;
  }


  editProvidentFund(): void {
    if(this.providentFundEditForm.valid){
      this._EmployeesService.editProvidentFund(this.providentFundEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success')
          {
            this._EmployeesService.getProvidentFund().subscribe(data => this.listOfAllData = data,
              error => this.errorMsg = error);
            this.listOfDisplayData = this.listOfAllData;
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else
          {
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }

  deleteProvidentFund(id): void {
    this.isOkLoading = true;
    this._EmployeesService.deleteProvidentFund(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success')
      {
        this._EmployeesService.getProvidentFund().subscribe(data => this.listOfAllData = data,
          error => this.errorMsg = error);
          this.listOfDisplayData = this.listOfAllData;
      }
      else if(responseObj.message == 'failed')
      {
        alert('Faild');
      }
      else
      {
        alert(JSON.stringify(response));
      }
    }
  );

  this.isOkLoading = false;
  this.visibleDelete = false;
  }


}
