import { Component, Inject, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { EmployeesService } from 'src/app/services/employees.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  listOfAllNotifications;
  constructor(
    
    private _EmployeesService: EmployeesService
    ,@Inject(LOCAL_STORAGE) private storage: StorageService
    ,private notification: NzNotificationService) { }

  ngOnInit() {
    this._EmployeesService.getNotifications().subscribe(data=>{
      this.listOfAllNotifications = data;
      console.log(this.listOfAllNotifications);
    },error=>console.log(error));
  }

}
