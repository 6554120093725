import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from '@angular/common';

import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@Component({
  selector: 'app-edit-notice-board',
  templateUrl: './edit-notice-board.component.html',
  styleUrls: ['./edit-notice-board.component.scss']
})
export class EditNoticeBoardComponent implements OnInit {

  
  loggedInUser = this.storage.get('loggedInUser');
  loading = true;
  listOfNoticeBoardData;
  noticeboardLoading = true;
  data;
  selectedStatus;
  load = false;
  constructor(private router: Router,
  private route: ActivatedRoute,
  private notification: NzNotificationService,
    @Inject(DOCUMENT) private document: Document,private _SettingsService: SettingsService
    , @Inject(LOCAL_STORAGE) private storage: StorageService) { }
  public errorMsg;
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  noticeToDeleteId = 0;
  isOkLoading = false;
  ngOnInit(): void {

    let id = this.route.snapshot.paramMap.get('id');
    this._SettingsService.getNotices().subscribe(dt => {
      this.listOfNoticeBoardData = dt;
      this.noticeboardLoading = false;
      this.load = true;
      let data = this.listOfNoticeBoardData.find(data => data.id == id);
      this.selectedStatus = data.status;
      this.noticeForm.patchValue({
        id: id,
        title: data.title,
        description: data.description,
        status: data.status
      });
    },
    error => this.errorMsg = error
    );
  }
  noticeForm = new FormGroup({
    id: new FormControl(),
    title: new FormControl(null, Validators.required),
    status: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
  });
  open(): void{
  this.visible = true;
  }
  close(): void{
  this.visible = false;
  }
  addNotice(): void {
    for (const i in this.noticeForm.controls) {
      this.noticeForm.controls[i].markAsDirty();
      this.noticeForm.controls[i].updateValueAndValidity();
    }
    if(this.noticeForm.valid){
      this._SettingsService.editNotice(this.noticeForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            this.notification.create(
              'error',
              'Noticeboard',
              'Already Exists'
              );
          }else if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Noticeboard',
              'Unable to save changes'
              );
          }else if(responseObj.message == 'success'){
            this.router.navigate(['/notice-board'])
            this.notification.create(
              'success',
              'Noticeboard',
              'Successfully updated'
              );
          }else{
            this.router.navigate(['/notice-board'])
            this.notification.create(
              'success',
              'Noticeboard',
              'Successfully updated'
              );
          }
        }
      )
    }
  }

}
