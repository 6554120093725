import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AtsServiceService {

  private apiUrl = 'http://localhost/RMS/source/api';
  constructor(private http: HttpClient,@Inject(LOCAL_STORAGE) private storage: StorageService) { }
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    // 'Authorization': 'Bearer ' + this.storage.get('auth-token').accessToken,
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json'
  })


  getJobsByClientId() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl + '/get-jobs-by-client-id/'+environment.atsClientId, { headers:this.headers })
    .pipe(catchError(this.erroHandler));
    console.log()
  }
  createJob(job: any[]) {
    return this.http.post(this.apiUrl + '/request-job-application/'+environment.atsClientId, (job),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteJob(id: number){
    return this.http.get(this.apiUrl + '/job/delete/'+id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  // applicants
  getApplicantByJobId(id: number) {
    return this.http.get<any[]>( this.apiUrl + '/job/get-applicants-by-id/'+ id, { headers:this.headers })
    .pipe(catchError(this.erroHandler));
    console.log()
  }
}
