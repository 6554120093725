import { Component, OnInit ,ElementRef, ViewChild} from '@angular/core';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { EmployeesService } from 'src/app/services/employees.service';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ProjectsService } from 'src/app/services/projects.service';
import 'src/app/model/Employee';
import { findLastNotEmptyNode } from 'ng-zorro-antd';
import { TeamsService } from 'src/app/services/teams.service';
import { ActivatedRoute } from '@angular/router';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss']
})

export class TimesheetComponent implements OnInit {
  generatePdf(divName) {
    const originalContents = document.body.innerHTML;
    const printContents = document.getElementById("timesheet").innerHTML;
    document.body.innerHTML = originalContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.close()

}

  load = false;
  isCustomView = false;
  startDate;
  showStartDate;
  endDate;
  showEndDate;
  customTimesheet = [];
  isSaturdayOpen = false;
  isShow = true;
  toggleDisplay() {
    this.isShow = !this.isShow;
  }
  loggedInUser = this.storage.get('loggedInUser');

  tasks; // for timescheduler
  employeeid; // for timescheduler

  listOfAllEmployees;
  listOfFilteredEmployees;
  selectedEmployee;
  selectedEmployeeId;
  selectedProject;
  listOfProjectsData;

  // for weekly data
  tasksForWeek;
  selectedWeek;
  selectedWeekModel=0;
  total = 20;
  total_time_thisweek:string = "0h 0m";
  listOfAllDataForWeek;
  timesheetLoading = true;
  monday;
  tuesday;
  wednesday;
  thursday;
  friday;
  saturday;
  mondayTotal=0;
  tuesdayTotal=0;
  wednesdayTotal=0;
  thursdayTotal=0;
  fridayTotal=0;
  saturdayTotal=0;
  mondayTimesheetsByTask =[];
  tuesdayTimesheetsByTask =[];
  wednesdayTimesheetsByTask =[];
  thursdayTimesheetsByTask =[];
  fridayTimesheetsByTask =[];
  saturdayTimesheetsByTask =[];
  search_employeeId;
  JSON;

  isLocked=true;

  // for the purpose of checking correspondence with attendance
  attclockin;
  attclockout;
  // variable "c" before variable means it use to convert 
  cClockIn;
  cClockOut;
  // "att" is to show final attendace time 
  attClockIn;
  attClockOut;
  selectedDate;
 submitError = true;
 chkMonday = false;
chkTuesday = false;
chkWednesday = false;
chkThursday = false;
chkFriday = false;
chkSaturday = false;

// Accessing Child Element Fot Timesheet PDF.

  constructor(
    private _ProjectsService: ProjectsService,
    private _EmployeesService: EmployeesService,
    private _TeamsService: TeamsService,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private notification: NzNotificationService) {
  this.JSON = JSON;
  }
  // for timesheet
  timesheetForm = new FormGroup({
    employee_id: new FormControl(this.loggedInUser.id),
    task_id: new FormControl(null, Validators.required)
  });
  getTimeFromMins(mins) {
    var duration = moment.duration(mins, 'minutes');
    var totalHours = duration.hours();
    if(duration.months() > 0){
      totalHours += (duration.months() * (duration.days() * 24));
    }
    if(duration.days() > 0){
      totalHours += (duration.days() * 24);
    }
    var totalMinutes = duration.minutes();
    if(totalHours !== 0){
      return totalHours+"h "+totalMinutes+"m";
    }else{
      return totalMinutes+"m";
    }
  }

  // edit
  openEdit = false;
  timeshets;

  listOfDisplayData;
  searchForm = new FormGroup({
    emp_id: new FormControl(null),
    dateFrom: new FormControl(null ),
    dateTo: new FormControl(null ), 
  });
  getDataEdit(selectedEmployeeId, taskid, day ){
    console.log( selectedEmployeeId , taskid, day);
    this.EmpIdForUpdate = selectedEmployeeId;
    this.taskIdForUpdate = taskid;
    this.dayForUpdate  = day;
     console.log(this.dayForUpdate);
    this.openAdd = false;
  this.openEdit = true;
  // for task task name and description
  this._ProjectsService.getTask(this.taskIdForUpdate).subscribe(
    data => {
      this.taskObject = data;
      console.log(this.taskObject);
    });
    
    // 
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfAllEmployeesUpdate = data;
      this.selectedEmployeeUpdate = this.listOfAllEmployeesUpdate.find(o => o.id == this.EmpIdForUpdate);
      this.listOfEmployeesDataUpdate = this.listOfAllEmployeesUpdate ;
      }, error => console.log(error.message));
      this._ProjectsService.getListForEditTimesheet(
        this.EmpIdForUpdate, this.taskIdForUpdate, this.dayForUpdate
      ).subscribe((data)=>{
        if(data){
          this.timeshets = data;
          this.timeshets.map((timesheet)=>{
            timesheet.date = moment(timesheet.clockIn, 'YYYY-MM-DD').format('MM-DD-YYYY');
            timesheet.clockIn = moment(timesheet.clockIn,'YYYY-MM-DD H:mm:ss').format('H:mm');
            timesheet.clockOut = moment(timesheet.clockOut,'YYYY-MM-DD H:mm:ss').format('H:mm');
            timesheet.error = false;
            timesheet.errorRequiredcheckout = false;
            timesheet.errorRequiredcheckin = false;
          })
        }
      },error => console.log(error));
      for (const i in this.searchForm.controls) {
        this.searchForm.controls[i].markAsDirty();
        this.searchForm.controls[i].updateValueAndValidity();
      }
      this.searchForm.value.emp_id = this.selectedEmployeeId;
      this.searchForm.value.dateFrom = moment(this.dayForUpdate, 'DD-MM-YYYY').format('YYYY-MM-DD');
      this.searchForm.value.dateTo = moment(this.dayForUpdate, 'DD-MM-YYYY').format('YYYY-MM-DD');
      this._EmployeesService.searchAttendance(this.searchForm.value).subscribe(data => {
        this.listOfDisplayData = data;
       
         console.log(this.listOfDisplayData);
      });
  }
  addFields(){
    this.timeshets.push({
      date:  moment(this.dayForUpdate, 'DD-MM-YYYY').format('MM-DD-YYYY'),
      employee_id:this.EmpIdForUpdate,
      task_id: this.taskIdForUpdate,
      clockIn: null,
      clockOut: null,
      error : false,
      errorRequiredcheckout :false,
      errorRequiredcheckin : false
    });
    console.log(this.timeshets);
  }
  removeFields(ev){
    this.timeshets.splice(ev);
    console.log(this.timeshets);
  }
  visible = false;
   
  saveTimesheets():void{
    this.attclockin = this.listOfDisplayData[0].clock_in;
    if(this.listOfDisplayData[0].clock_out != null){
        
      this.attclockout = this.listOfDisplayData[0].clock_out;
      
    }else{
      this.attclockout = moment().format('H:mm:ss A');
    }
   
  
    console.log(this.attclockin, this.attclockout);
    this.cClockIn = moment(this.attclockin, ['H:mm:ss A']).format('H:mm');
    this.cClockOut = moment(this.attclockout, ['H:mm:ss A']).format('H:mm');
    this.attClockIn = moment.duration(this.cClockIn).asMinutes();
    this.attClockOut = moment.duration(this.cClockOut).asMinutes();
  this.timeshets.map((timesheet, i)=>{
    let clockIn = moment.duration(timesheet.clockIn).asMinutes();
    let clockOut = moment.duration(timesheet.clockOut).asMinutes();
    if(this.attClockIn == 0){
      this.notification.create(
        'error',
        'Error!',
        "Selected time is not within the valid attendance range" 
        );
        this.submitError = true;
    }else if(this.attClockOut == 0){
      if(clockIn >= this.attClockIn && clockOut > this.attClockIn  && clockOut < this.attClockOut){
        this.submitError = false;
      }else{
        this.notification.create(
          'error',
          'Error!',
          "Selected time is not within the valid attendance range" 
          );
          this.submitError = true;
      }
    }else{
      if( clockIn >= this.attClockIn && clockIn < this.attClockOut && clockOut > this.attClockIn && clockOut <= this.attClockOut){
        console.log( clockIn , clockOut , this.attClockIn , this.attClockOut)
       this.submitError = false;
       }else{
         this.notification.create(
           'error',
           'Error!',
           "Selected time is not within the valid attendance range" 
           );
           this.submitError = true;
      }
    }
  });



  if(this.submitError == false){
    console.log(this.timeshets);
    let error = false;
    this.timeshets.map((timesheet, i)=>{
      if (timesheet.clockOut == null ){
        this.timeshets[i].errorRequiredcheckout = true;
        
        console.log("checkout true");
      }else{
      this.timeshets[i].errorRequiredcheckout = false;
      console.log("checkout false");}
      if (timesheet.clockIn == null ){
        this.timeshets[i].errorRequiredcheckin = true;
        console.log("checkin true");
      }else{
      this.timeshets[i].errorRequiredcheckin = false;
      console.log("checkin false");
      }
      if(timesheet.clockIn == null || timesheet.clockOut == null || timesheet.date == null || timesheet.error){
        console.log('nulled');
        error = true;
      }else{
          timesheet.employee_id = this.EmpIdForUpdate;
          timesheet.task_id = this.taskIdForUpdate;
          timesheet.date = moment(timesheet.date, ['MM-DD-YYYY']).format('YYYY-MM-DD');
          timesheet.clock_in = timesheet.clockIn;
          timesheet.clock_out = timesheet.clockOut;
      }
    });
    if(error == false){
      this._ProjectsService.editTimesheet(this.timeshets).subscribe(response=>{
        let responseObj = JSON.parse(JSON.stringify(response));
            if(responseObj.message == 'already-exists'){
              alert('Already Exisits');
            }
            else if(responseObj.message == 'failed'){
              alert('Faild');
            }else if(responseObj.message == 'success'){
                this.notification.create(
                'success',
                'Success!',
                "Timesheet Updated on " 
                );
                this._ProjectsService.getTasksByEmployeeId(this.selectedEmployeeId).subscribe(
                  data=>{
                    if(data.length > 0){
                      this.tasks = data;
                      this.tasksForWeek = data;
                      data.map((task:any) => {
                        this._ProjectsService.getWeeklyTimesheetByEmployeeIdAndTaskId(this.selectedEmployeeId, task.id).subscribe((data)=>{
                          this.mondayTotal += moment.duration(data.monday, 'seconds').asMinutes();
                          this.tuesdayTotal += moment.duration(data.tuesday, 'seconds').asMinutes();
                          this.wednesdayTotal += moment.duration(data.wednesday, 'seconds').asMinutes();
                          this.thursdayTotal += moment.duration(data.thursday, 'seconds').asMinutes();
                          this.fridayTotal += moment.duration(data.friday, 'seconds').asMinutes();
                          this.saturdayTotal += moment.duration(data.saturday, 'seconds').asMinutes();
                          task['timesheet'] = {
                            task_id: task.id,
                            monday: moment.duration(data.monday, 'seconds').asMinutes(),
                            tuesday: moment.duration(data.tuesday, 'seconds').asMinutes(),
                            wednesday: moment.duration(data.wednesday, 'seconds').asMinutes(),
                            thursday: moment.duration(data.thursday, 'seconds').asMinutes(),
                            friday: moment.duration(data.friday, 'seconds').asMinutes(),
                            saturday: moment.duration(data.saturday, 'seconds').asMinutes()
                          };
                          this.getTotalTimeThisWeek();
                        }, error => console.log(error))
                      });
                      this.timesheetLoading = false;
                      // for current running timesheet
                      this._ProjectsService.getOngoingTimesheet(this.loggedInUser.id).subscribe(data =>{
                        this.timesheet = data;
                        if(this.timesheet != null){
                          this.selectedTask = this.timesheet.task_id;
                          let clock_in = moment(this.timesheet.clockIn, ["YYYY-MM-DD H:mm:ss"]).format("DD/MM/YYYY HH:mm:ss");
                          let clock_now = moment().format("DD/MM/YYYY HH:mm:ss");
                          let diff = moment.utc(moment(clock_now,"DD/MM/YYYY HH:mm:ss").diff(moment(clock_in,"DD/MM/YYYY HH:mm:ss"))).format("H:mm:ss");
                          this.duration = diff;
                          this.startCounter();
                        }else{
                          this.stopCounter();
                        }
                      }, error=> {
                        this.notification.create(
                          'error',
                          'Error!',
                          error.message
                          );
                      });
                      // 
                    }else{
                      this.timesheetLoading = false;
                    }
                  },error=> {
                    this.notification.create(
                      'error',
                      'Error!',
                      error.message
                    );
                });
               this.timeshets = []; 
                this.openEdit = false;
            }else{
              alert(JSON.stringify(response));
            }
           
      }, error => console.log(error));
    }
  }
  }  
  onTimeChanges(ev, i){
    if(ev !== null){
      const clockIn = moment.duration(this.timeshets[i].clockIn).asMinutes();
      const clockOut = moment.duration(ev).asMinutes();
      if(clockOut <= clockIn){
        this.timeshets[i].error = true
      }else{
        this.timeshets[i].error = false
      }
    }
  }
  closeEdit(){
    this.openEdit= false;
  }
  // add time sheet
  EmpIdForUpdate;
  taskIdForUpdate;
  dayForUpdate;
  openAdd= false;
  
  timesheets = []
  taskObject;
  listOfAllEmployeesUpdate; 
  selectedEmployeeUpdate;
  listOfEmployeesDataUpdate;
  formatday;
  
getDataAdd(selectedEmployeeId, taskid, day ){
  console.log( selectedEmployeeId , taskid, day);
  this.EmpIdForUpdate = selectedEmployeeId;
  this.taskIdForUpdate = taskid;
  this.dayForUpdate  = day;
  console.log(this.dayForUpdate);
 this.openEdit = false;
this.openAdd = true;
// for task task name and description
this._ProjectsService.getTask(this.taskIdForUpdate).subscribe(
  data => {
    this.taskObject = data;
    console.log(this.taskObject);
  });
  // 
  this._EmployeesService.getEmployees().subscribe(data=> { 
    this.listOfAllEmployeesUpdate = data;
    this.selectedEmployeeUpdate = this.listOfAllEmployeesUpdate.find(o => o.id == this.EmpIdForUpdate);
    this.listOfEmployeesDataUpdate = this.listOfAllEmployeesUpdate ;
    }, error => console.log(error.message));
    this.timesheets.push({
      employee_id:this.selectedEmployeeId,
      task_id: this.taskIdForUpdate,
      date: this.dayForUpdate  ,
      clockIn: null,
      clock_in: null,
      clockOut: null,
      clock_out: null,
      error: false,
      errorRequiredcheckouts: false,
      errorRequiredcheckins: false
    })
    for (const i in this.searchForm.controls) {
      this.searchForm.controls[i].markAsDirty();
      this.searchForm.controls[i].updateValueAndValidity();
    }
    this.searchForm.value.emp_id = this.selectedEmployeeId;
    this.searchForm.value.dateFrom = moment(this.dayForUpdate).format('YYYY-MM-DD');
    this.searchForm.value.dateTo = moment(this.dayForUpdate).format('YYYY-MM-DD');
    this._EmployeesService.searchAttendance(this.searchForm.value).subscribe(data => {
      this.listOfDisplayData = data;
      console.log(this.listOfDisplayData);
    });  
}
removeField(ev){
  this.timesheets.splice(ev);

}
addField(){
  this.timesheets.push({
    employee_id:this.EmpIdForUpdate,
    task_id: this.taskIdForUpdate,
    date: this.dayForUpdate,
    clockIn: null,
    clock_in: null,
    clockOut: null,
    clock_out: null,
    error: false,
    errorRequiredcheckouts: false,
    errorRequiredcheckins: false
  })
}
 

saveTimesheet():void{
  this.attclockin = this.listOfDisplayData[0].clock_in;
  if(this.listOfDisplayData[0].clock_out != null){
      
    this.attclockout = this.listOfDisplayData[0].clock_out;
    
  }else{
    this.attclockout = moment().format('H:mm:ss A');
  }
 

  console.log(this.attclockin, this.attclockout);
  this.cClockIn = moment(this.attclockin, ['H:mm:ss A']).format('H:mm');
  this.cClockOut = moment(this.attclockout, ['H:mm:ss A']).format('H:mm');
  this.attClockIn = moment.duration(this.cClockIn).asMinutes();
  this.attClockOut = moment.duration(this.cClockOut).asMinutes();
  console.log
  this.timesheets.map((timesheet, i)=>{
    let clockIn = moment.duration(timesheet.clockIn).asMinutes();
    let clockOut = moment.duration(timesheet.clockOut).asMinutes();
    if(this.attClockIn == 0){
      this.notification.create(
        'error',
        'Error!',
        "Selected time is not within the valid attendance range" 
        );
        this.submitError = true;
    }else if(this.attClockOut == 0){
      if(clockIn >= this.attClockIn && clockOut > this.attClockIn  && clockOut < this.attClockOut){
        this.submitError = false;
      }else{
        this.notification.create(
          'error',
          'Error!',
          "Selected time is not within the valid attendance range" 
          );
          this.submitError = true;
      }
    }else{
      if( clockIn >= this.attClockIn && clockIn < this.attClockOut && clockOut > this.attClockIn && clockOut <= this.attClockOut){
        console.log( clockIn , clockOut , this.attClockIn , this.attClockOut)
       this.submitError = false;
       }else{
         this.notification.create(
           'error',
           'Error!',
           "Selected time is not within the valid attendance range" 
           );
           this.submitError = true;
      }
    }
  });

 if(this.submitError == false){
  console.log(this.timesheets);
  let error = false;
  this.timesheets.map((timesheet, i)=>{
    if (timesheet.clockOut == null ){
      this.timesheets[i].errorRequiredcheckouts = true;
      
      console.log("checkout true");
    }else{
    this.timesheets[i].errorRequiredcheckouts = false;
    console.log("checkout false");}
    if (timesheet.clockIn == null ){
      this.timesheets[i].errorRequiredcheckins = true;
      console.log("checkin true");
    }else{
    this.timesheets[i].errorRequiredcheckins = false;
    console.log("checkin false");
    }
    if(timesheet.clockIn == null || timesheet.clockOut == null || timesheet.date == null || timesheet.error){
      console.log('nulled');
      error = true;
    }else{
        timesheet.date = moment(timesheet.date, ['MM-DD-YYYY']).format('YYYY-MM-DD');
        timesheet.clock_in = timesheet.clockIn;
        // moment(timesheet.clockIn).format('H:mm');
        timesheet.clock_out = timesheet.clockOut;
        // moment(timesheet.clockOut).format('H:mm');
    }
  });
  if(error == false){
    this._ProjectsService.addTimesheet(this.timesheets).subscribe(response=>{
      let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            alert('Already Exisits');
          }
          else if(responseObj.message == 'failed'){
            alert('Faild');
          }else if(responseObj.message == 'success'){
              this.notification.create(
              'success',
              'Success!',
              "Timesheet Updated on " 
              );
              this._ProjectsService.getTasksByEmployeeId(this.selectedEmployeeId).subscribe(
                data=>{
                  if(data.length > 0){
                    this.tasks = data;
                    this.tasksForWeek = data;
                    data.map((task:any) => {
                      this._ProjectsService.getWeeklyTimesheetByEmployeeIdAndTaskId(this.selectedEmployeeId, task.id).subscribe((data)=>{
                        this.mondayTotal += moment.duration(data.monday, 'seconds').asMinutes();
                        this.tuesdayTotal += moment.duration(data.tuesday, 'seconds').asMinutes();
                        this.wednesdayTotal += moment.duration(data.wednesday, 'seconds').asMinutes();
                        this.thursdayTotal += moment.duration(data.thursday, 'seconds').asMinutes();
                        this.fridayTotal += moment.duration(data.friday, 'seconds').asMinutes();
                        this.saturdayTotal += moment.duration(data.saturday, 'seconds').asMinutes();
                        task['timesheet'] = {
                          task_id: task.id,
                          monday: moment.duration(data.monday, 'seconds').asMinutes(),
                          tuesday: moment.duration(data.tuesday, 'seconds').asMinutes(),
                          wednesday: moment.duration(data.wednesday, 'seconds').asMinutes(),
                          thursday: moment.duration(data.thursday, 'seconds').asMinutes(),
                          friday: moment.duration(data.friday, 'seconds').asMinutes(),
                          saturday: moment.duration(data.saturday, 'seconds').asMinutes()
                        };
                        this.getTotalTimeThisWeek();
                      }, error => console.log(error))
                    });
                    this.timesheetLoading = false;
                    // for current running timesheet
                    this._ProjectsService.getOngoingTimesheet(this.loggedInUser.id).subscribe(data =>{
                      this.timesheet = data;
                      if(this.timesheet != null){
                        this.selectedTask = this.timesheet.task_id;
                        let clock_in = moment(this.timesheet.clockIn, ["YYYY-MM-DD H:mm:ss"]).format("DD/MM/YYYY HH:mm:ss");
                        let clock_now = moment().format("DD/MM/YYYY HH:mm:ss");
                        let diff = moment.utc(moment(clock_now,"DD/MM/YYYY HH:mm:ss").diff(moment(clock_in,"DD/MM/YYYY HH:mm:ss"))).format("H:mm:ss");
                        this.duration = diff;
                        this.startCounter();
                      }else{
                        this.stopCounter();
                      }
                    }, error=> {
                      this.notification.create(
                        'error',
                        'Error!',
                        error.message
                        );
                    });
                    // 
                  }else{
                    this.timesheetLoading = false;
                  }
                },error=> {
                  this.notification.create(
                    'error',
                    'Error!',
                    error.message
                  );
              });
             this.timesheets = []; 
              this.openAdd = false;
          }else{
            alert(JSON.stringify(response));
          }
       
    }, error => console.log(error));
  }
}
}
onTimeChange(ev, i){
  if(ev !== null){
    const clockIn = moment.duration(this.timesheets[i].clockIn).asMinutes();
    const clockOut = moment.duration(ev).asMinutes(); 
      if(clockOut <= clockIn){
        this.timesheets[i].error = true
      }else{
        this.timesheets[i].error = false
      }; 
  }
}
closeAdd(){
  this.openAdd= false;
}
error = false;
onDateChange(ev){
  if(ev !== null && this.endDate != null && this.startDate != null){
    const dateIn = moment.duration(this.startDate).asDays();
    const dateOut = moment.duration(this.endDate).asDays();
    console.log(dateIn);
    console.log(dateOut);
    if(dateOut <= dateIn){
      this.error = true
      console.log(this.startDate);
      console.log(this.endDate);
    }else{
      this.error = false;
      console.log(this.startDate);
      console.log(this.endDate);
    
    }
  }
}

// ------------------------------------------------------------submit timesheet--------------------------------------------------------------------
  sstimesheetLoading = true;
  ssmondayTotal=0;
  sstuesdayTotal=0;
  sswednesdayTotal=0;
  ssthursdayTotal=0;
  ssfridayTotal=0;
  sssaturdayTotal=0;
  ssselectedWeek;
  sstasksForWeek;
  ssmonday;
  sstuesday;
  sswednesday;
  ssthursday;
  ssfriday;
  sssaturday;
  sstasks; // for timescheduler
  sstotal = 4;
  sstotal_time_thisweek:string = "0h 0m";
  ssselectedEmployee: Employee;
  start;
  end;
  listOfAllDataTeam;
  selectedTeam;
  manager = {
    fname: 'N/A', lname: '', id: 0
  };
  ssisLocked = true;
  submittedTimesheet;
  reason="";
  previousRecords;
  showSubmitTimesheet= false;


loadsubmitTimesheet( ){
   
  this.openAdd = false;
  this.openEdit = false;
    

  let selectedWeekModel:any =  this.selectedWeekModel;
  let selectedEmployeeId:any = this.selectedEmployeeId;
  console.log("emp" + selectedEmployeeId);
  console.log("week" + selectedWeekModel);
  

  this._EmployeesService.getEmployees().subscribe(data=> { 
    this.ssselectedEmployee = data.find(o => o.id == selectedEmployeeId);
    this._TeamsService.getTeams().subscribe(dt=> { 
      this.listOfAllDataTeam = dt;
      this.listOfAllDataTeam.map((team)=>{
        let members = team.employees.split(',');
        members.map((member)=>{
          if(member == selectedEmployeeId){
            this.selectedTeam = team;
            this.manager  = data.find(o => o.id == team.manager_id);
          }
        })
      });
    });
  }, error => console.log(error.message));
  
  this.sstimesheetLoading = true;
  if(selectedWeekModel == 0){ // this week
    // for weekly timesheet
    // empty data
    this.ssmondayTotal = 0;
    this.sstuesdayTotal = 0;
    this.sswednesdayTotal = 0;
    this.ssthursdayTotal = 0;
    this.ssfridayTotal = 0;
    this.sssaturdayTotal = 0;

    this.ssselectedWeek = moment();
    this.ssmonday     = moment(this.ssselectedWeek).startOf('week').add(1, 'd');
    this.start =      this.ssmonday.format('dddd - Do MMM, Y');
    this.sstuesday    = moment(this.ssselectedWeek).startOf('week').add(2, 'd');
    this.sswednesday  = moment(this.ssselectedWeek).startOf('week').add(3, 'd');
    this.ssthursday   = moment(this.ssselectedWeek).startOf('week').add(4, 'd');
    this.ssfriday     = moment(this.ssselectedWeek).startOf('week').add(5, 'd');
    this.sssaturday   = moment(this.ssselectedWeek).startOf('week').add(6, 'd');
    this.end =        this.sssaturday.format('dddd - Do MMM, Y');

    let data = {
      'from_date': this.ssmonday.format('Y-M-D'),
      'to_date': this.sssaturday.format('Y-M-D')
    };
    this._ProjectsService.getSubmittedTimesheet(selectedEmployeeId, data).subscribe(response=>{
      if(response != null){
      let responseObj = JSON.parse(JSON.stringify(response));
      this.submittedTimesheet = responseObj;
      console.log(this.submittedTimesheet);  
      if(responseObj.status == 2){
          this.ssisLocked = false;
        }else{
          this.ssisLocked = true;
        }
      }else{
        this.ssisLocked = false;
      }
    }, error=>console.log(error));


    this._ProjectsService.getSubmittedTimesheetRecords(selectedEmployeeId, data).subscribe(response=>{
      if(response != null){
      let responseObj = JSON.parse(JSON.stringify(response));
      this.previousRecords = responseObj;

      console.log(responseObj);
      console.log(this.previousRecords);
      }
      }, error=>console.log(error));
    
    this._ProjectsService.getTasksByEmployeeId(selectedEmployeeId).subscribe(
      data=>{
        if(data.length > 0){
          this.sstasks = data;
          this.sstasksForWeek = data;            
          data.map((task) => {
            this._ProjectsService.getWeeklyTimesheetByEmployeeIdAndTaskId(selectedEmployeeId, task.id).subscribe((data)=>{
              this.ssmondayTotal += moment.duration(data.monday, 'seconds').asMinutes();
              this.sstuesdayTotal += moment.duration(data.tuesday, 'seconds').asMinutes();
              this.sswednesdayTotal += moment.duration(data.wednesday, 'seconds').asMinutes();
              this.ssthursdayTotal += moment.duration(data.thursday, 'seconds').asMinutes();
              this.ssfridayTotal += moment.duration(data.friday, 'seconds').asMinutes();
              this.sssaturdayTotal += moment.duration(data.saturday, 'seconds').asMinutes();
              task['timesheet'] = {
                task_id: task.id,
                monday: moment.duration(data.monday, 'seconds').asMinutes(),
                tuesday: moment.duration(data.tuesday, 'seconds').asMinutes(),
                wednesday: moment.duration(data.wednesday, 'seconds').asMinutes(),
                thursday: moment.duration(data.thursday, 'seconds').asMinutes(),
                friday: moment.duration(data.friday, 'seconds').asMinutes(),
                saturday: moment.duration(data.saturday, 'seconds').asMinutes()
              };
              this.ssgetTotalTimeThisWeek();
            }, error => console.log(error))
          });
          this.sstimesheetLoading = false;
          // for current running timesheet
          // 
        }else{
          this.sstimesheetLoading = false;
        }
      },error=> {
        this.notification.create(
          'error',
          'Error!',
          error.message
        );
    });
  }if(selectedWeekModel == 1){ // last week
    error=> {
      this.notification.create(
        'error',
        'Error!',
        'you can not submit last month timesheet'
      )}
    // for weekly timesheet
    // empty data
    // this.ssmondayTotal = 0;
    // this.sstuesdayTotal = 0;
    // this.sswednesdayTotal = 0;
    // this.ssthursdayTotal = 0;
    // this.ssfridayTotal = 0;
    // this.sssaturdayTotal = 0;

    // this.ssselectedWeek = moment();
    // this.ssmonday     = moment(this.ssselectedWeek).startOf('week').subtract(6, 'd');
    // this.sstuesday    = moment(this.ssselectedWeek).startOf('week').subtract(5, 'd');
    // this.sswednesday  = moment(this.ssselectedWeek).startOf('week').subtract(4, 'd');
    // this.ssthursday   = moment(this.ssselectedWeek).startOf('week').subtract(3, 'd');
    // this.ssfriday     = moment(this.ssselectedWeek).startOf('week').subtract(2, 'd');
    // this.sssaturday   = moment(this.ssselectedWeek).startOf('week').subtract(1, 'd');
    // this.start =      this.ssmonday.format('dddd - Do MMM, Y');
    // this.end =        this.sssaturday.format('dddd - Do MMM, Y');
    
    // let data = {
    //   'from_date': this.ssmonday.format('Y-M-D'),
    //   'to_date': this.sssaturday.format('Y-M-D')
    // };
    // this._ProjectsService.getSubmittedTimesheet(selectedEmployeeId, data).subscribe(response=>{
    //   if(response != null){
    //   let responseObj = JSON.parse(JSON.stringify(response));
    //     if(responseObj.status == 2){
    //       this.ssisLocked = false;
    //     }else{
    //       this.ssisLocked = true;
    //     }
    //   }else{
    //     this.ssisLocked = false;
    //   }
    // }, error=>console.log(error));

    
    // this._ProjectsService.getTasksByEmployeeId(selectedEmployeeId).subscribe(
    //   data=>{
    //     if(data.length > 0){
    //       this.sstasks = data;
    //       this.sstasksForWeek = data;
    //       data.map((task) => {
    //         this._ProjectsService.getLastWeekTimesheetByEmployeeIdAndTaskId(selectedEmployeeId, task.id).subscribe((data)=>{
    //           this.ssmondayTotal += moment.duration(data.monday, 'seconds').asMinutes();
    //           this.sstuesdayTotal += moment.duration(data.tuesday, 'seconds').asMinutes();
    //           this.sswednesdayTotal += moment.duration(data.wednesday, 'seconds').asMinutes();
    //           this.ssthursdayTotal += moment.duration(data.thursday, 'seconds').asMinutes();
    //           this.ssfridayTotal += moment.duration(data.friday, 'seconds').asMinutes();
    //           this.sssaturdayTotal += moment.duration(data.saturday, 'seconds').asMinutes();
    //           task['timesheet'] = {
    //             task_id: task.id,
                 
    //             monday: moment.duration(data.monday, 'seconds').asMinutes(),
    //             tuesday: moment.duration(data.tuesday, 'seconds').asMinutes(),
    //             wednesday: moment.duration(data.wednesday, 'seconds').asMinutes(),
    //             thursday: moment.duration(data.thursday, 'seconds').asMinutes(),
    //             friday: moment.duration(data.friday, 'seconds').asMinutes(),
    //             saturday: moment.duration(data.saturday, 'seconds').asMinutes()
    //           };
    //           this.ssgetTotalTimeThisWeek();
    //         }, error => console.log(error))
    //       });
    //       this.sstimesheetLoading = false;
    //       // 
    //     }else{
    //       this.sstimesheetLoading = false;
    //     }
    //   },error=> {
    //     this.notification.create(
    //       'error',
    //       'Error!',
    //       error.message
    //     );
    // });
  }
  this.forSubmitTimesheetTrue();
}
loadsubmitTimesheetNotifiction(  ){
   
  this.openAdd = false;
  this.openEdit = false;
    

  let selectedWeekModel:any = this.route.snapshot.paramMap.get('selectedWeek');
  let selectedEmployeeId:any = this.route.snapshot.paramMap.get('employeeId');
  

  this._EmployeesService.getEmployees().subscribe(data=> { 
    this.ssselectedEmployee = data.find(o => o.id == selectedEmployeeId);
    this._TeamsService.getTeams().subscribe(dt=> { 
      this.listOfAllDataTeam = dt;
      this.listOfAllDataTeam.map((team)=>{
        let members = team.employees.split(',');
        members.map((member)=>{
          if(member == selectedEmployeeId){
            this.selectedTeam = team;
            this.manager  = data.find(o => o.id == team.manager_id);
          }
        })
      });
    });
  }, error => console.log(error.message));
  
  this.sstimesheetLoading = true;
  if(selectedWeekModel == 0){ // this week
    // for weekly timesheet
    // empty data
    this.ssmondayTotal = 0;
    this.sstuesdayTotal = 0;
    this.sswednesdayTotal = 0;
    this.ssthursdayTotal = 0;
    this.ssfridayTotal = 0;
    this.sssaturdayTotal = 0;

    this.ssselectedWeek = moment();
    this.ssmonday     = moment(this.ssselectedWeek).startOf('week').add(1, 'd');
    this.start =      this.ssmonday.format('dddd - Do MMM, Y');
    this.sstuesday    = moment(this.ssselectedWeek).startOf('week').add(2, 'd');
    this.sswednesday  = moment(this.ssselectedWeek).startOf('week').add(3, 'd');
    this.ssthursday   = moment(this.ssselectedWeek).startOf('week').add(4, 'd');
    this.ssfriday     = moment(this.ssselectedWeek).startOf('week').add(5, 'd');
    this.sssaturday   = moment(this.ssselectedWeek).startOf('week').add(6, 'd');
    this.end =        this.sssaturday.format('dddd - Do MMM, Y');

    let data = {
      'from_date': this.ssmonday.format('Y-M-D'),
      'to_date': this.sssaturday.format('Y-M-D')
    };
    this._ProjectsService.getSubmittedTimesheet(selectedEmployeeId, data).subscribe(response=>{
      if(response != null){
      let responseObj = JSON.parse(JSON.stringify(response));
      this.submittedTimesheet = responseObj;
      console.log(this.submittedTimesheet);  
      if(responseObj.status == 2){
          this.ssisLocked = false;
        }else{
          this.ssisLocked = true;
        }
      }else{
        this.ssisLocked = false;
      }
    }, error=>console.log(error));


    this._ProjectsService.getSubmittedTimesheetRecords(selectedEmployeeId, data).subscribe(response=>{
      if(response != null){
      let responseObj = JSON.parse(JSON.stringify(response));
      this.previousRecords = responseObj;
      console.log(responseObj);
      console.log(this.previousRecords);
      }
      }, error=>console.log(error));
    
    this._ProjectsService.getTasksByEmployeeId(selectedEmployeeId).subscribe(
      data=>{
        if(data.length > 0){
          this.sstasks = data;
          this.sstasksForWeek = data;            
          data.map((task) => {
            this._ProjectsService.getWeeklyTimesheetByEmployeeIdAndTaskId(selectedEmployeeId, task.id).subscribe((data)=>{
              this.ssmondayTotal += moment.duration(data.monday, 'seconds').asMinutes();
              this.sstuesdayTotal += moment.duration(data.tuesday, 'seconds').asMinutes();
              this.sswednesdayTotal += moment.duration(data.wednesday, 'seconds').asMinutes();
              this.ssthursdayTotal += moment.duration(data.thursday, 'seconds').asMinutes();
              this.ssfridayTotal += moment.duration(data.friday, 'seconds').asMinutes();
              this.sssaturdayTotal += moment.duration(data.saturday, 'seconds').asMinutes();
              task['timesheet'] = {
                task_id: task.id,
                monday: moment.duration(data.monday, 'seconds').asMinutes(),
                tuesday: moment.duration(data.tuesday, 'seconds').asMinutes(),
                wednesday: moment.duration(data.wednesday, 'seconds').asMinutes(),
                thursday: moment.duration(data.thursday, 'seconds').asMinutes(),
                friday: moment.duration(data.friday, 'seconds').asMinutes(),
                saturday: moment.duration(data.saturday, 'seconds').asMinutes()
              };
              this.ssgetTotalTimeThisWeek();
            }, error => console.log(error))
          });
          this.sstimesheetLoading = false;
          // for current running timesheet
          // 
        }else{
          this.sstimesheetLoading = false;
        }
      },error=> {
        this.notification.create(
          'error',
          'Error!',
          error.message
        );
    });
  }
  if(selectedWeekModel == 1){ 
    error=> {
      this.notification.create(
        'error',
        'Error!',
        'you should have to submit your time sheet before friday'
      )}
    
    // last week
    // for weekly timesheet
    // empty data
    // this.ssmondayTotal = 0;
    // this.sstuesdayTotal = 0;
    // this.sswednesdayTotal = 0;
    // this.ssthursdayTotal = 0;
    // this.ssfridayTotal = 0;
    // this.sssaturdayTotal = 0;

    // this.ssselectedWeek = moment();
    // this.ssmonday     = moment(this.ssselectedWeek).startOf('week').subtract(6, 'd');
    // this.sstuesday    = moment(this.ssselectedWeek).startOf('week').subtract(5, 'd');
    // this.sswednesday  = moment(this.ssselectedWeek).startOf('week').subtract(4, 'd');
    // this.ssthursday   = moment(this.ssselectedWeek).startOf('week').subtract(3, 'd');
    // this.ssfriday     = moment(this.ssselectedWeek).startOf('week').subtract(2, 'd');
    // this.sssaturday   = moment(this.ssselectedWeek).startOf('week').subtract(1, 'd');
    // this.start =      this.ssmonday.format('dddd - Do MMM, Y');
    // this.end =        this.sssaturday.format('dddd - Do MMM, Y');
    
    // let data = {
    //   'from_date': this.ssmonday.format('Y-M-D'),
    //   'to_date': this.sssaturday.format('Y-M-D')
    // };
    // this._ProjectsService.getSubmittedTimesheet(selectedEmployeeId, data).subscribe(response=>{
    //   if(response != null){
    //   let responseObj = JSON.parse(JSON.stringify(response));
    //     if(responseObj.status == 2){
    //       this.ssisLocked = false;
    //     }else{
    //       this.ssisLocked = true;
    //     }
    //   }else{
    //     this.ssisLocked = false;
    //   }
    // }, error=>console.log(error));

    
    // this._ProjectsService.getTasksByEmployeeId(selectedEmployeeId).subscribe(
    //   data=>{
    //     if(data.length > 0){
    //       this.sstasks = data;
    //       this.sstasksForWeek = data;
    //       data.map((task) => {
    //         this._ProjectsService.getLastWeekTimesheetByEmployeeIdAndTaskId(selectedEmployeeId, task.id).subscribe((data)=>{
    //           this.ssmondayTotal += moment.duration(data.monday, 'seconds').asMinutes();
    //           this.sstuesdayTotal += moment.duration(data.tuesday, 'seconds').asMinutes();
    //           this.sswednesdayTotal += moment.duration(data.wednesday, 'seconds').asMinutes();
    //           this.ssthursdayTotal += moment.duration(data.thursday, 'seconds').asMinutes();
    //           this.ssfridayTotal += moment.duration(data.friday, 'seconds').asMinutes();
    //           this.sssaturdayTotal += moment.duration(data.saturday, 'seconds').asMinutes();
    //           task['timesheet'] = {
    //             task_id: task.id,
                 
    //             monday: moment.duration(data.monday, 'seconds').asMinutes(),
    //             tuesday: moment.duration(data.tuesday, 'seconds').asMinutes(),
    //             wednesday: moment.duration(data.wednesday, 'seconds').asMinutes(),
    //             thursday: moment.duration(data.thursday, 'seconds').asMinutes(),
    //             friday: moment.duration(data.friday, 'seconds').asMinutes(),
    //             saturday: moment.duration(data.saturday, 'seconds').asMinutes()
    //           };
    //           this.ssgetTotalTimeThisWeek();
    //         }, error => console.log(error))
    //       });
    //       this.sstimesheetLoading = false;
    //       // 
    //     }else{
    //       this.sstimesheetLoading = false;
    //     }
    //   },error=> {
    //     this.notification.create(
    //       'error',
    //       'Error!',
    //       error.message
    //     );
    // });
  }
  this.forSubmitTimesheetTrue();
}

ssgetTotalTimeThisWeek(){
  console.log('total this week:');
  console.log(
    this.ssmondayTotal + 
    this.sstuesdayTotal + 
    this.sswednesdayTotal + 
    this.ssthursdayTotal + 
    this.ssfridayTotal + 
    this.sssaturdayTotal
    );
  this.sstotal_time_thisweek = this.ssgetTimeFromMins(
    this.ssmondayTotal + 
    this.sstuesdayTotal + 
    this.sswednesdayTotal + 
    this.ssthursdayTotal + 
    this.ssfridayTotal + 
    this.sssaturdayTotal
  );
}
ssgetTimeFromMins(mins) {
  var duration = moment.duration(mins, 'minutes');
  var totalHours = duration.hours();
  if(duration.months() > 0){
    totalHours += (duration.months() * (duration.days() * 24));
  }
  if(duration.days() > 0){
    totalHours += (duration.days() * 24);
  }
  var totalMinutes = duration.minutes();
  if(totalHours !== 0){
    return totalHours+"h "+totalMinutes+"m";
  }else{
    return totalMinutes+"m";
  }
}
ongoingTask;
errortask = true;
submitTimesheet(){

this._ProjectsService.getOngoingTimesheet(this.loggedInUser.id).subscribe(dt =>{
  this.ongoingTask = dt;
  console.log(this.ongoingTask);

  if(this.ongoingTask == null){
     
      this.errortask = false;
  }  else  {
    this.notification.create(
      'error',
      'Error!',
      "One of your task timer is not closed" 
      );
      this.errortask = true;
  } 


});
if( this.errortask == false){
  let data = {
    'employee_id': this.ssselectedEmployee.id, 
    'manager_id': this.manager.id,
    'from_date': this.ssmonday.format('Y-M-D'),
    'to_date': this.sssaturday.format('Y-M-D')
  }
  this._ProjectsService.submitTimesheet(data).subscribe(response=>{
    let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'already-exists'){
        alert('Already Exisits');
      }
      else if(responseObj.message == 'failed'){
        alert('Faild');
      }else if(responseObj.message == 'success'){
          this.notification.create(
          'success',
          'Success!',
          "Timesheet Submitted on "
          );
      }else{
        alert(JSON.stringify(response));
      }
        // we need to close time sheet from here
  },error=>console.log(error));

  this.showSubmitTimesheet = false;
  this.isLocked = true;
}
}

approve(){
  this._ProjectsService.approveTimesheet(this.submittedTimesheet.id, this.reason).subscribe(response=>{
    let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'already-exists'){
        alert('Already Exisits');
      }
      else if(responseObj.message == 'failed'){
        alert('Faild');
      }else if(responseObj.message == 'success'){

          let data = {
            'from_date': this.ssmonday.format('Y-M-D'),
            'to_date': this.sssaturday.format('Y-M-D')
          };
          this._ProjectsService.getSubmittedTimesheet(this.submittedTimesheet.employee_id, data).subscribe(response=>{
            if(response != null){
            let responseObj = JSON.parse(JSON.stringify(response));
            this.submittedTimesheet = responseObj;
            console.log(this.submittedTimesheet);  
            // if(responseObj.status == 2){
              //   this.isLocked = false;
              // }else{
                this.ssisLocked = true;
              // }
            }else{
              this.ssisLocked = false;
            }
          }, error=>console.log(error));
      
      
          this.notification.create(
          'success',
          'Success!',
          "Timesheet Approved on " 
          );
      }else{
        alert(JSON.stringify(response));
      }
      // this.router.navigate(['/timesheets'])
  },error=>console.log(error));
  this.showSubmitTimesheet = false;
}
reject(){
  this._ProjectsService.rejectTimesheet(this.submittedTimesheet.id, this.reason).subscribe(response=>{
    let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'already-exists'){
        alert('Already Exisits');
      }
      else if(responseObj.message == 'failed'){
        alert('Faild');
      }else if(responseObj.message == 'success'){

          let data = {
            'from_date': this.ssmonday.format('Y-M-D'),
            'to_date': this.sssaturday.format('Y-M-D')
          };
          this._ProjectsService.getSubmittedTimesheet(this.submittedTimesheet.employee_id, data).subscribe(response=>{
            if(response != null){
            let responseObj = JSON.parse(JSON.stringify(response));
            this.submittedTimesheet = responseObj;
            console.log(this.submittedTimesheet);  
            // if(responseObj.status == 2){
              //   this.isLocked = false;
              // }else{
                this.ssisLocked = true;
              // }
            }else{
              this.ssisLocked = false;
            }
          }, error=>console.log(error));

          this.notification.create(
          'success',
          'Success!',
          "Timesheet Rejected on " 
          );
      }else{
        alert(JSON.stringify(response));
      }
      // this.router.navigate(['/timesheets'])
  },error=>console.log(error));
  this.showSubmitTimesheet = false;
}

forSubmitTimesheetTrue(){
  this.showSubmitTimesheet =true;
  
}
closeSubmitTimesheet(){
  this.showSubmitTimesheet = false;
}


// ------------------------------------------------------------submit timesheet--------------------------------------------------------------------


ngOnInit() {
    
    let selectedEmployeeId:any = this.route.snapshot.paramMap.get('employeeId');
    
    if( selectedEmployeeId){
      this.loadsubmitTimesheetNotifiction();
      this.showSubmitTimesheet =true;
    }else{
      this.showSubmitTimesheet = false;
    }
    var oneDate = moment();
    console.log(oneDate);
    var dayName = oneDate.format('dddd');
    console.log(dayName);
     if(dayName == "Monday"){
      this.chkMonday = true;
      this.chkTuesday = false;
      this.chkWednesday= false;
      this.chkThursday= false;
      this.chkFriday= false;
      this.chkSaturday= false;

     }
     else if(dayName == "Tuesday"){
      this.chkMonday = true;
      this.chkTuesday = true;
      this.chkWednesday= false;
      this.chkThursday= false;
      this.chkFriday= false;
      this.chkSaturday= false;
     }
     else if(dayName == "Wednesday"){
      this.chkMonday = true;
      this.chkTuesday = true;
      this.chkWednesday= true;
      this.chkThursday= false;
      this.chkFriday= false;
      this.chkSaturday= false;
     }
     else if(dayName == "Thursday"){
      this.chkMonday = true;
      this.chkTuesday = true;
      this.chkWednesday= true;
      this.chkThursday= true;
      this.chkFriday= false;
      this.chkSaturday= false;
     }
     else if(dayName == "Friday"){
      this.chkMonday = true;
      this.chkTuesday = true;
      this.chkWednesday= true;
      this.chkThursday= true;
      this.chkFriday= true;
      this.chkSaturday= false;
     }
     else if(dayName == "Saturday"){
      this.chkMonday = true;
      this.chkTuesday = true;
      this.chkWednesday= true;
      this.chkThursday= true;
      this.chkFriday= true;
      this.chkSaturday= true;
     }else{
      this.chkMonday == true;
      this.chkTuesday = true;
      this.chkWednesday= true;
      this.chkThursday= true;
      this.chkFriday= true;
      this.chkSaturday= true;
     }

     if(this.loggedInUser.role_id !== 2 ){
      this._ProjectsService.getProjects().subscribe(data=>{
        this.listOfProjectsData = data; 
           
         
        console.log(this.listOfProjectsData);
      });
    }else{
      this._ProjectsService.getProjectByEmployeeId(this.loggedInUser.id).subscribe(data=>{
        this.listOfProjectsData = data;
        console.log(this.listOfProjectsData);
      });
    }
    
    this.selectedEmployeeId = this.loggedInUser.id;
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfAllEmployees = data;
      this.load = true;
      this.listOfFilteredEmployees = data;
      this.selectedEmployee = this.listOfAllEmployees.find(o => o.id == this.loggedInUser.id);
    }, error => console.log(error.message));
    
      // for weekly timesheet
      // empty data
      this.mondayTotal = 0;
      this.tuesdayTotal = 0;
      this.wednesdayTotal = 0;
      this.thursdayTotal = 0;
      this.fridayTotal = 0;
      this.saturdayTotal = 0;

      this.selectedWeek = moment();
      this.monday     = moment(this.selectedWeek).startOf('week').add(1, 'd');
      this.tuesday    = moment(this.selectedWeek).startOf('week').add(2, 'd');
      this.wednesday  = moment(this.selectedWeek).startOf('week').add(3, 'd');
      this.thursday   = moment(this.selectedWeek).startOf('week').add(4, 'd');
      this.friday     = moment(this.selectedWeek).startOf('week').add(5, 'd');
      this.saturday   = moment(this.selectedWeek).startOf('week').add(6, 'd');

      let data = {
        'from_date': this.monday.format('Y-M-D'),
        'to_date': this.saturday.format('Y-M-D')
      };
      this._ProjectsService.getSubmittedTimesheet(this.selectedEmployeeId, data).subscribe(response=>{
        console.log(response);
        if(response != null){

        let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.status == 2){
            this.isLocked = false;
          }else{
            this.isLocked = true;
          }
        }else{
          this.isLocked = false;
        }
      }, error=>console.log(error));
        this._ProjectsService.getTasksByEmployeeIdStatus(this.selectedEmployeeId).subscribe(
          data=>{
            if(data.length > 0){
              this.tasks = data;
              this.tasksForWeek = data;
              this.load = true;
              console.log(this.tasksForWeek);
              data.map((task:any) => {
                this._ProjectsService.getWeeklyTimesheetByEmployeeIdAndTaskId(this.selectedEmployeeId, task.id).subscribe((data)=>{
                  this.mondayTotal += moment.duration(data.monday, 'seconds').asMinutes();
                  this.tuesdayTotal += moment.duration(data.tuesday, 'seconds').asMinutes();
                  this.wednesdayTotal += moment.duration(data.wednesday, 'seconds').asMinutes();
                  this.thursdayTotal += moment.duration(data.thursday, 'seconds').asMinutes();
                  this.fridayTotal += moment.duration(data.friday, 'seconds').asMinutes();
                  this.saturdayTotal += moment.duration(data.saturday, 'seconds').asMinutes();
                  task['timesheet'] = {
                    task_id: task.id,
                    monday: moment.duration(data.monday, 'seconds').asMinutes(),
                    tuesday: moment.duration(data.tuesday, 'seconds').asMinutes(),
                    wednesday: moment.duration(data.wednesday, 'seconds').asMinutes(),
                    thursday: moment.duration(data.thursday, 'seconds').asMinutes(),
                    friday: moment.duration(data.friday, 'seconds').asMinutes(),
                    saturday: moment.duration(data.saturday, 'seconds').asMinutes()
                  };
                  this.getTotalTimeThisWeek();
                }, error => console.log(error))
              });
              this.timesheetLoading = false;
              // for current running timesheet
              this._ProjectsService.getOngoingTimesheet(this.loggedInUser.id).subscribe(data =>{
                this.timesheet = data;
                if(this.timesheet != null){
                  this.selectedTask = this.timesheet.task_id;
                  let clock_in = moment(this.timesheet.clockIn, ["YYYY-MM-DD H:mm:ss"]).format("DD/MM/YYYY HH:mm:ss");
                  let clock_now = moment().format("DD/MM/YYYY HH:mm:ss");
                  let diff = moment.utc(moment(clock_now,"DD/MM/YYYY HH:mm:ss").diff(moment(clock_in,"DD/MM/YYYY HH:mm:ss"))).format("H:mm:ss");
                  this.duration = diff;
                  this.startCounter();
                }else{
                  this.stopCounter();
                }
              }, error=> {
                this.notification.create(
                  'error',
                  'Error!',
                  error.message
                  );
              });
              // 
            }else{
              this.timesheetLoading = false;
            }
          },error=> {
            this.notification.create(
              'error',
              'Error!',
              error.message
            );
        });
  }
    
  
  selectedTask= [];
  buttonDisabled = true;
  onTaskChange(): void{
    if(this.selectedTask.length > 0){
      this.buttonDisabled = false;
    }
    else{
      this.buttonDisabled = true;
    }
  }
  interval;
  started: boolean=false;
  selectDisabled: boolean=false;
  duration;
  timesheet;
  startCounter(): void{ 
    this.started = true;
    this.selectDisabled = true;
    clearInterval(this.interval);
    let duration = moment(this.duration, ['H:mm:ss']);
    this.interval = setInterval(() => {
      duration.add(1, 'second');
      this.duration = duration.format('H:mm:ss');
    }, 1000);
  }
  stopCounter(): void{  
    clearInterval(this.interval);
    this.started = false;
    this.selectDisabled = false;
    this.duration = '00:00:00';
    this.timesheet = null;
  }
  customGetSharableServiceMethod(playOrStop){
    this.timesheetLoading = true;
    // console.log(this.timesheetForm);
    if(!this.timesheetForm.value.task_id){
    this.notification.create(
      'error',
      'Error!',
      'Select a task'
      );
    }
    if(this.timesheetForm.valid){
      this._ProjectsService.createTimesheet(this.timesheetForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            this.notification.create(
              'error',
              'Error!',
              'Already Exists'
              );  
          }
          else if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Error!',
              'Failed '
              );
          }
          else if(responseObj.message == 'success'){        

            this.selectedEmployeeId = this.loggedInUser.id;
            this._EmployeesService.getEmployees().subscribe(data=> { 
              this.listOfAllEmployees = data;
              this.selectedEmployee = this.listOfAllEmployees.find(o => o.id == this.loggedInUser.id);
            }, error => console.log(error.message));
            
            // for weekly timesheet
            // empty data
            this.mondayTotal = 0;
            this.tuesdayTotal = 0;
            this.wednesdayTotal = 0;
            this.thursdayTotal = 0;
            this.fridayTotal = 0;
            this.saturdayTotal = 0;
        
            this.selectedWeek = moment();
            this.monday     = moment(this.selectedWeek).startOf('week').add(1, 'd');
            this.tuesday    = moment(this.selectedWeek).startOf('week').add(2, 'd');
            this.wednesday  = moment(this.selectedWeek).startOf('week').add(3, 'd');
            this.thursday   = moment(this.selectedWeek).startOf('week').add(4, 'd');
            this.friday     = moment(this.selectedWeek).startOf('week').add(5, 'd');
            this.saturday   = moment(this.selectedWeek).startOf('week').add(6, 'd');
            
            this._ProjectsService.getTasksByEmployeeId(this.selectedEmployeeId).subscribe(
              data=>{
                if(data.length > 0){
                  this.tasks = data;
                  this.tasksForWeek = data;        
                  data.map((task:any) => {
                    this._ProjectsService.getWeeklyTimesheetByEmployeeIdAndTaskId(this.selectedEmployeeId, task.id).subscribe((data)=>{
                      this.mondayTotal += moment.duration(data.monday, 'seconds').asMinutes();
                      this.tuesdayTotal += moment.duration(data.tuesday, 'seconds').asMinutes();
                      this.wednesdayTotal += moment.duration(data.wednesday, 'seconds').asMinutes();
                      this.thursdayTotal += moment.duration(data.thursday, 'seconds').asMinutes();
                      this.fridayTotal += moment.duration(data.friday, 'seconds').asMinutes();
                      this.saturdayTotal += moment.duration(data.saturday, 'seconds').asMinutes();
                      task['timesheet'] = {
                        task_id: task.id,
                        monday: moment.duration(data.monday, 'seconds').asMinutes(),
                        tuesday: moment.duration(data.tuesday, 'seconds').asMinutes(),
                        wednesday: moment.duration(data.wednesday, 'seconds').asMinutes(),
                        thursday: moment.duration(data.thursday, 'seconds').asMinutes(),
                        friday: moment.duration(data.friday, 'seconds').asMinutes(),
                        saturday: moment.duration(data.saturday, 'seconds').asMinutes()
                      };
                      this.getTotalTimeThisWeek();
                    }, error => console.log(error))
                  });
                  this.timesheetLoading = false;
                  // for current running timesheet
                  this._ProjectsService.getOngoingTimesheet(this.loggedInUser.id).subscribe(data =>{
                    this.timesheet = data;
                    if(this.timesheet != null){
                      this.selectedTask = this.timesheet.task_id;
                      let clock_in = moment(this.timesheet.clockIn, ["YYYY-MM-DD H:mm:ss"]).format("DD/MM/YYYY HH:mm:ss");
                      let clock_now = moment().format("DD/MM/YYYY HH:mm:ss");
                      let diff = moment.utc(moment(clock_now,"DD/MM/YYYY HH:mm:ss").diff(moment(clock_in,"DD/MM/YYYY HH:mm:ss"))).format("H:mm:ss");
                      this.duration = diff;
                      this.startCounter();
                    }else{
                      this.stopCounter();
                    }
                  }, error=> {
                    this.notification.create(
                      'error',
                      'Error!',
                      error.message
                      );
                  });
                  // 
                }else{
                  this.timesheetLoading = false;
                }
              },error=> {
                this.notification.create(
                  'error',
                  'Error!',
                  error.message
                );
            });

            if(playOrStop == "play"){
              this.notification.create(
                'success',
                'Play Task',
                'Successfully Played Task'
              )
            }else{
              this.notification.create(
                'success',
                'Stop Task',
                'Successfully Stopped Task'
              )
            }
          }
          else{
            alert(JSON.stringify(response));
          }
        }
      );
    }
  }
  searchEmployee(eId):void{
    this.listOfFilteredEmployees = this.listOfAllEmployees.filter(function(data) {
      return (data.fname.includes(eId) ||data.designation_name.designation.includes(eId))
    })
  }
  changeWeekModel(ev){
    this.timesheetLoading = true;
    if(ev == 0){ // this week
      // for weekly timesheet
      // empty data
      this.mondayTotal = 0;
      this.tuesdayTotal = 0;
      this.wednesdayTotal = 0;
      this.thursdayTotal = 0;
      this.fridayTotal = 0;
      this.saturdayTotal = 0;

      this.selectedWeek = moment();
      this.monday     = moment(this.selectedWeek).startOf('week').add(1, 'd');
      this.tuesday    = moment(this.selectedWeek).startOf('week').add(2, 'd');
      this.wednesday  = moment(this.selectedWeek).startOf('week').add(3, 'd');
      this.thursday   = moment(this.selectedWeek).startOf('week').add(4, 'd');
      this.friday     = moment(this.selectedWeek).startOf('week').add(5, 'd');
      this.saturday   = moment(this.selectedWeek).startOf('week').add(6, 'd');
      
      this._ProjectsService.getTasksByEmployeeId(this.selectedEmployeeId).subscribe(
        data=>{
          if(data.length > 0){
            this.tasks = data;
            this.tasksForWeek = data;
            data.map((task:any) => {
              this._ProjectsService.getWeeklyTimesheetByEmployeeIdAndTaskId(this.selectedEmployeeId, task.id).subscribe((data)=>{
                this.mondayTotal += moment.duration(data.monday, 'seconds').asMinutes();
                this.tuesdayTotal += moment.duration(data.tuesday, 'seconds').asMinutes();
                this.wednesdayTotal += moment.duration(data.wednesday, 'seconds').asMinutes();
                this.thursdayTotal += moment.duration(data.thursday, 'seconds').asMinutes();
                this.fridayTotal += moment.duration(data.friday, 'seconds').asMinutes();
                this.saturdayTotal += moment.duration(data.saturday, 'seconds').asMinutes();
                task['timesheet'] = {
                  task_id: task.id,
                  monday: moment.duration(data.monday, 'seconds').asMinutes(),
                  tuesday: moment.duration(data.tuesday, 'seconds').asMinutes(),
                  wednesday: moment.duration(data.wednesday, 'seconds').asMinutes(),
                  thursday: moment.duration(data.thursday, 'seconds').asMinutes(),
                  friday: moment.duration(data.friday, 'seconds').asMinutes(),
                  saturday: moment.duration(data.saturday, 'seconds').asMinutes()
                };
                this.getTotalTimeThisWeek();
              }, error => console.log(error))
            });
            this.timesheetLoading = false;
            // for current running timesheet
            this._ProjectsService.getOngoingTimesheet(this.loggedInUser.id).subscribe(data =>{
              this.timesheet = data;
              if(this.timesheet != null){
                this.selectedTask = this.timesheet.task_id;
                let clock_in = moment(this.timesheet.clockIn, ["YYYY-MM-DD H:mm:ss"]).format("DD/MM/YYYY HH:mm:ss");
                let clock_now = moment().format("DD/MM/YYYY HH:mm:ss");
                let diff = moment.utc(moment(clock_now,"DD/MM/YYYY HH:mm:ss").diff(moment(clock_in,"DD/MM/YYYY HH:mm:ss"))).format("H:mm:ss");
                this.duration = diff;
                this.startCounter();
              }else{
                this.stopCounter();
              }
            }, error=> {
              this.notification.create(
                'error',
                'Error!',
                error.message
                );
            });
            // 
          }else{
            this.timesheetLoading = false;
          }
        },error=> {
          this.notification.create(
            'error',
            'Error!',
            error.message
          );
      });
    }if(ev == 1){ // last week
      // for weekly timesheet
      // empty data
      this.mondayTotal = 0;
      this.tuesdayTotal = 0;
      this.wednesdayTotal = 0;
      this.thursdayTotal = 0;
      this.fridayTotal = 0;
      this.saturdayTotal = 0;

      this.selectedWeek = moment();
      this.monday     = moment(this.selectedWeek).startOf('week').subtract(6, 'd');
      this.tuesday    = moment(this.selectedWeek).startOf('week').subtract(5, 'd');
      this.wednesday  = moment(this.selectedWeek).startOf('week').subtract(4, 'd');
      this.thursday   = moment(this.selectedWeek).startOf('week').subtract(3, 'd');
      this.friday     = moment(this.selectedWeek).startOf('week').subtract(2, 'd');
      console.log(this.friday);
      this.saturday   = moment(this.selectedWeek).startOf('week').subtract(1, 'd');
      console.log(this.saturday);
      
      this._ProjectsService.getTasksByEmployeeId(this.selectedEmployeeId).subscribe(
        data=>{
          if(data.length > 0){
            this.tasks = data;
            this.tasksForWeek = data;
            data.map((task:any) => {
              this._ProjectsService.getLastWeekTimesheetByEmployeeIdAndTaskId(this.selectedEmployeeId, task.id).subscribe((data)=>{
                this.mondayTotal += moment.duration(data.monday, 'seconds').asMinutes();
                this.tuesdayTotal += moment.duration(data.tuesday, 'seconds').asMinutes();
                this.wednesdayTotal += moment.duration(data.wednesday, 'seconds').asMinutes();
                this.thursdayTotal += moment.duration(data.thursday, 'seconds').asMinutes();
                this.fridayTotal += moment.duration(data.friday, 'seconds').asMinutes();
                this.saturdayTotal += moment.duration(data.saturday, 'seconds').asMinutes();
                task['timesheet'] = {
                  task_id: task.id,
                  monday: moment.duration(data.monday, 'seconds').asMinutes(),
                  tuesday: moment.duration(data.tuesday, 'seconds').asMinutes(),
                  wednesday: moment.duration(data.wednesday, 'seconds').asMinutes(),
                  thursday: moment.duration(data.thursday, 'seconds').asMinutes(),
                  friday: moment.duration(data.friday, 'seconds').asMinutes(),
                  saturday: moment.duration(data.saturday, 'seconds').asMinutes()
                };
                this.getTotalTimeThisWeek();
              }, error => console.log(error))
            });
            this.timesheetLoading = false;
            // for current running timesheet
            this._ProjectsService.getOngoingTimesheet(this.loggedInUser.id).subscribe(data =>{
              this.timesheet = data;
              if(this.timesheet != null){
                this.selectedTask = this.timesheet.task_id;
                let clock_in = moment(this.timesheet.clockIn, ["YYYY-MM-DD H:mm:ss"]).format("DD/MM/YYYY HH:mm:ss");
                let clock_now = moment().format("DD/MM/YYYY HH:mm:ss");
                let diff = moment.utc(moment(clock_now,"DD/MM/YYYY HH:mm:ss").diff(moment(clock_in,"DD/MM/YYYY HH:mm:ss"))).format("H:mm:ss");
                this.duration = diff;
                this.startCounter();
              }else{
                this.stopCounter();
              }
            }, error=> {
              this.notification.create(
                'error',
                'Error!',
                error.message
                );
            });
            // 
          }else{
            this.timesheetLoading = false;
          }
        },error=> {
          this.notification.create(
            'error',
            'Error!',
            error.message
          );
      });
    }
  }
  selectEmployee(eId):void{
    // console.log(eId);
    this.timesheetLoading = true;
    this.selectedEmployeeId = eId;
    this.selectedEmployee = this.listOfAllEmployees.find(o => o.id == eId);
    // for weekly timesheet
    // empty data
    this.mondayTotal = 0;
    this.tuesdayTotal = 0;
    this.wednesdayTotal = 0;
    this.thursdayTotal = 0;
    this.fridayTotal = 0;
    this.saturdayTotal = 0;

    this.selectedWeek = moment();
    this.monday     = moment(this.selectedWeek).startOf('week').add(1, 'd');
    this.tuesday    = moment(this.selectedWeek).startOf('week').add(2, 'd');
    this.wednesday  = moment(this.selectedWeek).startOf('week').add(3, 'd');
    this.thursday   = moment(this.selectedWeek).startOf('week').add(4, 'd');
    this.friday     = moment(this.selectedWeek).startOf('week').add(5, 'd');
    this.saturday   = moment(this.selectedWeek).startOf('week').add(6, 'd');
    
    this._ProjectsService.getTasksByEmployeeId(this.selectedEmployeeId).subscribe(
      data=>{
        if(data.length > 0){
          this.tasksForWeek = data;
          data.map((task:any) => {
            this._ProjectsService.getWeeklyTimesheetByEmployeeIdAndTaskId(this.selectedEmployeeId, task.id).subscribe((data)=>{
              this.mondayTotal += moment.duration(data.monday, 'seconds').asMinutes();
              this.tuesdayTotal += moment.duration(data.tuesday, 'seconds').asMinutes();
              this.wednesdayTotal += moment.duration(data.wednesday, 'seconds').asMinutes();
              this.thursdayTotal += moment.duration(data.thursday, 'seconds').asMinutes();
              this.fridayTotal += moment.duration(data.friday, 'seconds').asMinutes();
              this.saturdayTotal += moment.duration(data.saturday, 'seconds').asMinutes();
              task['timesheet'] = {
                task_id: task.id,
                monday: moment.duration(data.monday, 'seconds').asMinutes(),
                tuesday: moment.duration(data.tuesday, 'seconds').asMinutes(),
                wednesday: moment.duration(data.wednesday, 'seconds').asMinutes(),
                thursday: moment.duration(data.thursday, 'seconds').asMinutes(),
                friday: moment.duration(data.friday, 'seconds').asMinutes(),
                saturday: moment.duration(data.saturday, 'seconds').asMinutes()
              };
              this.getTotalTimeThisWeek();
              this.timesheetLoading = false;
            }, error => console.log(error))
          });
        }else{
          this.tasksForWeek = [];
          this.timesheetLoading = false;
        }
      },error=> {
        this.notification.create(
          'error',
          'Error!',
          error.message
        );
    });

  }
  getTotalTimeThisWeek(){
    console.log('total this week:');
    console.log(
      this.mondayTotal + 
      this.tuesdayTotal + 
      this.wednesdayTotal + 
      this.thursdayTotal + 
      this.fridayTotal + 
      this.saturdayTotal
      );
    this.total_time_thisweek = this.getTimeFromMins(
      this.mondayTotal + 
      this.tuesdayTotal + 
      this.wednesdayTotal + 
      this.thursdayTotal + 
      this.fridayTotal + 
      this.saturdayTotal
    );
  }

  searchTimesheet(){
    this.customTimesheet = [];
    if(this.startDate && this.endDate){
      let data = {
        'from_date'   : moment(this.startDate).format("MM/DD/YYYY"),
        'to_date'     : moment(this.endDate).format("MM/DD/YYYY"),
        'project_id'  : this.selectedProject,
        'employee_id' : this.selectedEmployeeId
      }   
      this.isCustomView = true;
      this._ProjectsService.getCustomTimesheetByEmployeeIdAndDateRange(this.selectedEmployeeId, data).subscribe((data)=>{
          data.map((dt)=>{
            dt.total = moment.duration(dt.total, 'seconds').asMinutes();
            dt.data.map((dtEntry)=>{
              dtEntry.duration = moment.duration(dtEntry.duration, 'seconds').asMinutes();
            })
            this.customTimesheet.push(dt);
          })
          console.log(data);
      });

      console.log(data);
    }else if(this.selectedProject){
      this.isCustomView = false;
      this.getTimesheetByProjectId(this.selectedProject);
    }else{
      console.log('ev');
      this.resetSearch();
    }
  }
  resetSearch(){
    this.startDate = null;
    this.endDate = null;
    this.selectedProject = null;
    // this.selectedEmployeeId = null;
    this.isCustomView = false;

      this.mondayTotal = 0;
      this.tuesdayTotal = 0;
      this.wednesdayTotal = 0;
      this.thursdayTotal = 0;
      this.fridayTotal = 0;
      this.saturdayTotal = 0;

      this.selectedWeek = moment();
      this.monday     = moment(this.selectedWeek).startOf('week').add(1, 'd');
      this.tuesday    = moment(this.selectedWeek).startOf('week').add(2, 'd');
      this.wednesday  = moment(this.selectedWeek).startOf('week').add(3, 'd');
      this.thursday   = moment(this.selectedWeek).startOf('week').add(4, 'd');
      this.friday     = moment(this.selectedWeek).startOf('week').add(5, 'd');
      this.saturday   = moment(this.selectedWeek).startOf('week').add(6, 'd');

      let data = {
        'from_date': this.monday.format('Y-M-D'),
        'to_date': this.saturday.format('Y-M-D')
      };
      this._ProjectsService.getSubmittedTimesheet(this.selectedEmployeeId, data).subscribe(response=>{
        if(response != null){
        let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.status == 2){
            this.isLocked = false;
          }else{
            this.isLocked = true;
          }
        }else{
          this.isLocked = false;
        }
      }, error=>console.log(error));
      this._ProjectsService.getTasksByEmployeeId(this.selectedEmployeeId).subscribe(
        data=>{
          if(data.length > 0){
            this.tasks = data;
            this.tasksForWeek = data;
            data.map((task:any) => {
              this._ProjectsService.getWeeklyTimesheetByEmployeeIdAndTaskId(this.selectedEmployeeId, task.id).subscribe((data)=>{
                this.mondayTotal += moment.duration(data.monday, 'seconds').asMinutes();
                this.tuesdayTotal += moment.duration(data.tuesday, 'seconds').asMinutes();
                this.wednesdayTotal += moment.duration(data.wednesday, 'seconds').asMinutes();
                this.thursdayTotal += moment.duration(data.thursday, 'seconds').asMinutes();
                this.fridayTotal += moment.duration(data.friday, 'seconds').asMinutes();
                this.saturdayTotal += moment.duration(data.saturday, 'seconds').asMinutes();
                task['timesheet'] = {
                  task_id: task.id,
                  monday: moment.duration(data.monday, 'seconds').asMinutes(),
                  tuesday: moment.duration(data.tuesday, 'seconds').asMinutes(),
                  wednesday: moment.duration(data.wednesday, 'seconds').asMinutes(),
                  thursday: moment.duration(data.thursday, 'seconds').asMinutes(),
                  friday: moment.duration(data.friday, 'seconds').asMinutes(),
                  saturday: moment.duration(data.saturday, 'seconds').asMinutes()
                };
                this.getTotalTimeThisWeek();
              }, error => console.log(error))
            });
            this.timesheetLoading = false;
            // for current running timesheet
            this._ProjectsService.getOngoingTimesheet(this.loggedInUser.id).subscribe(data =>{
              this.timesheet = data;
              if(this.timesheet != null){
                this.selectedTask = this.timesheet.task_id;
                let clock_in = moment(this.timesheet.clockIn, ["YYYY-MM-DD H:mm:ss"]).format("DD/MM/YYYY HH:mm:ss");
                let clock_now = moment().format("DD/MM/YYYY HH:mm:ss");
                let diff = moment.utc(moment(clock_now,"DD/MM/YYYY HH:mm:ss").diff(moment(clock_in,"DD/MM/YYYY HH:mm:ss"))).format("H:mm:ss");
                this.duration = diff;
                this.startCounter();
              }else{
                this.stopCounter();
              }
            }, error=> {
              this.notification.create(
                'error',
                'Error!',
                error.message
                );
            });
            // 
          }else{
            this.timesheetLoading = false;
          }
        },error=> {
          this.notification.create(
            'error',
            'Error!',
            error.message
          );
      });
      
  }
  getTimesheetByProjectId(ev){
      this.timesheetLoading = true;
      // for weekly timesheet
      // empty data
      this.mondayTotal = 0;
      this.tuesdayTotal = 0;
      this.wednesdayTotal = 0;
      this.thursdayTotal = 0;
      this.fridayTotal = 0;
      this.saturdayTotal = 0;

      this.selectedWeek = moment();
      this.monday     = moment(this.selectedWeek).startOf('week').add(1, 'd');
      this.tuesday    = moment(this.selectedWeek).startOf('week').add(2, 'd');
      this.wednesday  = moment(this.selectedWeek).startOf('week').add(3, 'd');
      this.thursday   = moment(this.selectedWeek).startOf('week').add(4, 'd');
      this.friday     = moment(this.selectedWeek).startOf('week').add(5, 'd');
      this.saturday   = moment(this.selectedWeek).startOf('week').add(6, 'd');
      
      this._ProjectsService.getTasksByEmployeeIdAndProjectId(this.selectedEmployeeId, ev).subscribe(
        data=>{
          if(data.length > 0){
            this.tasksForWeek = data;
              data.map((task:any) => {
                this._ProjectsService.getWeeklyTimesheetByEmployeeIdAndTaskId(this.selectedEmployeeId, task.id).subscribe((data)=>{
                  this.mondayTotal += moment.duration(data.monday, 'seconds').asMinutes();
                  this.tuesdayTotal += moment.duration(data.tuesday, 'seconds').asMinutes();
                  this.wednesdayTotal += moment.duration(data.wednesday, 'seconds').asMinutes();
                  this.thursdayTotal += moment.duration(data.thursday, 'seconds').asMinutes();
                  this.fridayTotal += moment.duration(data.friday, 'seconds').asMinutes();
                  this.saturdayTotal += moment.duration(data.saturday, 'seconds').asMinutes();
                  task['timesheet'] = {
                    task_id: task.id,
                    monday: moment.duration(data.monday, 'seconds').asMinutes(),
                    tuesday: moment.duration(data.tuesday, 'seconds').asMinutes(),
                    wednesday: moment.duration(data.wednesday, 'seconds').asMinutes(),
                    thursday: moment.duration(data.thursday, 'seconds').asMinutes(),
                    friday: moment.duration(data.friday, 'seconds').asMinutes(),
                    saturday: moment.duration(data.saturday, 'seconds').asMinutes()
                  };
                this.getTotalTimeThisWeek();
                this.timesheetLoading = false;
                }, error => console.log(error))
              });
          }else{
            this.tasksForWeek = [];
            this.timesheetLoading = false;
          }
        },error=> {
          this.notification.create(
            'error',
            'Error!',
            error.message
          );
      });
  }
}