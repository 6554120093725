import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private apiUrl = environment.stepApiUrl + '/api';
  constructor(private http: HttpClient,@Inject(LOCAL_STORAGE) private storage: StorageService) { }
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.storage.get('auth-token').accessToken,
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json'
  })
  

  getStore():Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/store' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getStoreItemView(id:number){
    return this.http.get( this.apiUrl +'/store/item/'+id  ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getStoreEnableId(id:number){
    return this.http.get( this.apiUrl +'/store/enable/'+id  ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getStoreDisableId(id:number){
    return this.http.get( this.apiUrl +'/store/disable/'+id  ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }
}
