import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { AccountsService } from 'src/app/services/accounts.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { SettingsService } from 'src/app/services/settings.service';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-view-payslip',
  templateUrl: './view-payslip.component.html',
  styleUrls: ['./view-payslip.component.scss']
})
export class ViewPayslipComponent implements OnInit {
  searchForm: any;
  printInvoice(divName) {
    const printContents = document.getElementById("invoice-pdf").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
}
  listOfDisplayData: Payslip[];
  listOfEmployees;
  listOfAllData: Payslip[];
  visible = false;
  departments;
  designations;
  payment_month ;
  netSalary;
  overtimeSalary;
  tax;
  providentFund;
  loan;
  totalSalary;
  subTotal;
  grandTotal;
  settings;
  
  slip: Payslip = {
    id: null,
    employee_code: null,
    employee_name: null,
    department: null,
    designation: null,
    payment_type: null,
    payment_month: null,
    payment_date: null,
    net_salary: null,
    tax: null,
    provident_fund: null,
    loan: null,
    overtime_salary: null,
    total_salary: null,
    payment_method: null,
    address: "Office #305, Blossom Trade Center, University Road, Gulzar-e-Hijri, Karachi, Pakistan",
    basic_salary: null
  };
  constructor(
    private route: ActivatedRoute,
    private _AccountsService: AccountsService, 
    private _EmployeesService: EmployeesService, private _SettingsService: SettingsService,  
    private msg: NzMessageService) { }

  ngOnInit() {
    let id:any = this.route.snapshot.paramMap.get('id');
    let datepay:any = this.route.snapshot.paramMap.get('datepay');
    
    this._SettingsService.getDepartments().subscribe(data => this.departments = data);
    this._SettingsService.getDesignations().subscribe( data => this.designations = data );
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfEmployees = data;
      
      this._AccountsService.searchEmployees({
        employee: id,
        date: datepay
      }).subscribe(data => {
        this.listOfAllData = data;
        this.listOfDisplayData = this.listOfAllData;
        this.slip = this.listOfAllData.find(data => data.id == id);
         this.subTotal = Number(this.slip.net_salary) + Number(this.slip.overtime_salary);
         this.grandTotal = (Number(this.slip.net_salary) + Number(this.slip.overtime_salary)) - (Number(this.slip.tax) + Number(this.slip.provident_fund) + Number(this.slip.loan));          
      });
    },
    error => console.log(error.message));


    this._SettingsService.getSettings().subscribe(
      data => {
        this.settings = data;
      }
    );
  }
  
  


}
