import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { NzNotificationService  } from 'ng-zorro-antd';
import { Router } from "@angular/router";
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-add-fund',
  templateUrl: './add-fund.component.html',
  styleUrls: ['./add-fund.component.scss']
})
export class AddFundComponent implements OnInit {

  loggedInUser = this.storage.get('loggedInUser');
  public errorMsg;

  listOfDisplayData;
  listOfAllData;
  //
  load = false;
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  providentFundToDeleteId = 0;
  isOkLoading = false;

  providentFund: ProvidentFund[];

  selectedEmployee;
  selectedProvidentFundType;
  employeeShare;
  organizationShare;

  selectedEditProvidentFundType;
  employeeShareEdit;
  organizationShareEdit;

  employeeShareAmountEdit;
  organizationShareAmountEdit;
  listOfEmployees;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
private notification: NzNotificationService,
private router: Router,
    private _EmployeesService: EmployeesService, private nzError: NzMessageService) { }

  ngOnInit() {
    this.selectedProvidentFundType = 'Fixed Amount';
    this.employeeShare = 0;
    this.organizationShare = 0;

    this._EmployeesService.getProvidentFund().subscribe(data => {
      this.listOfAllData = data;
      this.listOfDisplayData = this.listOfAllData;
      this.load = true;
    });
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfEmployees = data;
    },
    error => console.log(error.message));
  }

  providentFundForm = new FormGroup({
    emp_id: new FormControl(null, Validators.required),
    provident_fund_type: new FormControl(null, Validators.required),
    employee_share: new FormControl(null, Validators.required),
    organization_share: new FormControl(null, Validators.required),
    description: new FormControl(null),
    status: new FormControl(null, Validators.required),
  });

  providentFundEditForm = new FormGroup({
    id: new FormControl(null),
    emp_id: new FormControl(null, Validators.required),
    provident_fund_type: new FormControl(null, Validators.required),
    employee_share: new FormControl(null),
    organization_share: new FormControl(null),
    description: new FormControl(null),
    status: new FormControl(null),
  });

  open(): void{
  this.visible = true;
  }

  close(): void{
  this.visible = false;
  }

  openEdit(id): void {
    this.visibleEdit = true;
    let data = this.listOfAllData.find(data => data.id == id);
    this.selectedEmployee = data.emp_id;
    this.selectedEditProvidentFundType = data.provident_fund_type;
    this.employeeShareEdit = 0;
    this.organizationShareEdit = 0;
    this.employeeShareAmountEdit = 0;
    this.organizationShareAmountEdit = 0;
    if(data.provident_fund_type == 'Fixed Amount'){
      this.employeeShareAmountEdit = data.employee_share;
      this.organizationShareAmountEdit = data.organization_share;
    }else{
      this.employeeShareEdit = data.employee_share;
      this.organizationShareEdit = data.organization_share;
    }
    this.providentFundEditForm.patchValue({
      id: id,
      description: data.description,
      employee_share: data.employee_share,
      organization_share: data.organization_share 
    });
  }

  closeEdit(): void {
  this.visibleEdit = false;
  }
  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.providentFundToDeleteId = id;
  }

  closeDeleteModal(): void{
    this.visibleDelete = false;
  }

  addProvidentFund(): void {
    for (const i in this.providentFundForm.controls) {
      this.providentFundForm.controls[i].markAsDirty();
      this.providentFundForm.controls[i].updateValueAndValidity();
    }
    if(this.providentFundForm.valid)
    {
      this._EmployeesService.createProvidentFund(this.providentFundForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists')
          {
            alert('Already Exisits');
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else if(responseObj.message == 'success')
          {
            
              this.notification.create(
                'success',
                'Provident Fund',
                'Successfully updated'
                );

                this.router.navigate(['/provident-fund']);
          }
          else
          {
            
                this.notification.create(
                  'success',
                  'Provident Fund',
                  'Successfully updated'
                  );
                  this.router.navigate(['/provident-fund'])
          }
        }
      )
    }
  }

  editProvidentFund(): void {
    if(this.providentFundEditForm.valid){
      this._EmployeesService.editProvidentFund(this.providentFundEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success')
          {
            this._EmployeesService.getProvidentFund().subscribe(data => this.listOfAllData = data,
              error => this.errorMsg = error);
            this.listOfDisplayData = this.listOfAllData;
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else
          {
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }

  deleteProvidentFund(id): void {
    this.isOkLoading = true;
    this._EmployeesService.deleteProvidentFund(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success')
      {
        this._EmployeesService.getProvidentFund().subscribe(data => this.listOfAllData = data,
          error => this.errorMsg = error);
          this.listOfDisplayData = this.listOfAllData;
      }
      else if(responseObj.message == 'failed')
      {
        alert('Faild');
      }
      else
      {
        alert(JSON.stringify(response));
      }
    }
  );

  this.isOkLoading = false;
  this.visibleDelete = false;
  }


}
