import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray  } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-set-rule',
  templateUrl: './set-rule.component.html',
  styleUrls: ['./set-rule.component.scss']
})
export class SetRuleComponent implements OnInit {
  data;

  setRuleForm = new FormGroup({
    salary_from: this.fb.array([]),
    salary_to: this.fb.array([]),
    additional_tax_amount: this.fb.array([]),
    tax_percentage: this.fb.array([])
  });


  constructor(private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private notification: NzNotificationService,
    private _SettingsService: SettingsService) { }
  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this._SettingsService.getTaxRules(id).subscribe(dt=> {
      this.data = dt;
      console.log(dt);
    });
  }

}
