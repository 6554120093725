import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {
  

  listOfStoreData;
  StoresLoading = true;
   
  dateFormat = 'MMM dd, yyyy';

  drawerVisible = false;
  
  fileToUpload: File;
  validUpload = false;
  loggedInUser = this.storage.get('loggedInUser');
  loading = true;
  constructor(private _storeService: StoreService, 
    @Inject(LOCAL_STORAGE) private storage: StorageService, private fb: FormBuilder) { }
  public errorMsg;

  ngOnInit(): void {
    this._storeService.getStore().subscribe(
      data=> { 
        console.log(data);

        this.listOfStoreData = data;
        this.StoresLoading = false;
      },
      error => this.errorMsg = error);
  }
 
}
