import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { EmployeesService } from 'src/app/services/employees.service';
import { Inject } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Router, ActivatedRoute } from "@angular/router";
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-set-permissions',
  templateUrl: './set-permissions.component.html',
  styleUrls: ['./set-permissions.component.scss']
})
export class SetPermissionsComponent implements OnInit {
  loggedInUser = this.storage.get('loggedInUser');
  permissionsList;
  @Input() selectedRole;


  permissionsForm = this.fb.group({
    perms: this.fb.array([])
  });


  constructor(
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,private _employeesService: EmployeesService, private _auth: AuthService, @Inject(LOCAL_STORAGE) private storage: StorageService) { }

  ngOnInit() {

    this.selectedRole = this.route.snapshot.paramMap.get('id');
    this.permissionsForm = this.fb.group({ // remove all data
      perms: this.fb.array([])
    });
    this._auth.getPermissions(this.selectedRole).subscribe(data=>{
      console.log(data);
      const chkArray = < FormArray > this.permissionsForm.get('perms');
      data.forEach(element => {
      chkArray.push(new FormControl(element.perm_id));
      });
      this._auth.getPermissionsList().subscribe(data=>{
        let checkedValues = [];
        data.forEach((element, i) => {
          if(this.permissionsForm.value.perms.indexOf(i) !== -1){
            checkedValues.push({
              id: i,
              text: element,
              checked: true
            });
          }else{
            checkedValues.push({
              id: i,
              text: element,
              checked: false
            });
          }
        });
        this.permissionsList = checkedValues
      })
    });
  }

updateChkbxArray(id, isChecked, key) {
    const chkArray = < FormArray > this.permissionsForm.get(key);
    if (isChecked) {
      chkArray.push(new FormControl(id));
    } else {
      let idx = chkArray.controls.findIndex(x => x.value == id);
      chkArray.removeAt(idx);
    }
}

  updatePermissions(){
    for (const i in this.permissionsForm.controls) {
      this.permissionsForm.controls[i].markAsDirty();
      this.permissionsForm.controls[i].updateValueAndValidity();
    }
    if(this.permissionsForm.valid){
      this._employeesService.setPermissions(this.permissionsForm.value.perms, this.selectedRole).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            alert('Already Exisits');
          }else if(responseObj.message == 'failed'){
            alert('Faild');
          }else if(responseObj.message == 'success'){this.notification.create(
            'success',
            'User Roles',
            'updated successfully..'
            );            
            this.router.navigate(['/employee-roles'])
          }else{this.notification.create(
            'success',
            'User Roles',
            'updated successfully..'
            );            
            this.router.navigate(['/employee-roles'])
          }
        }
      )
    }
  }
  
}
