import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators  } from '@angular/forms';

import { EmployeesService } from 'src/app/services/employees.service';
@Component({
  selector: 'app-add-onboarding',
  templateUrl: './add-onboarding.component.html',
  styleUrls: ['./add-onboarding.component.scss']
})
export class AddOnboardingComponent implements OnInit {

 
  loading = true;
  dateFormat = 'MMM dd, yyyy';
  constructor(
    private fb: FormBuilder, private _EmployeesService: EmployeesService) { }
  public errorMsg;

  listOfSelection = [
    {
      text: 'Select All Row',
      onSelect: () => {
        this.checkAll(true);
      }
    },
    {
      text: 'Select Odd Row',
      onSelect: () => {
        this.listOfDisplayData.forEach((data, index) => (this.mapOfCheckedId[data.id] = index % 2 !== 0));
        this.refreshStatus();
      }
    },
    {
      text: 'Select Even Row',
      onSelect: () => {
        this.listOfDisplayData.forEach((data, index) => (this.mapOfCheckedId[data.id] = index % 2 === 0));
        this.refreshStatus();
      }
    }
  ];
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfDisplayData: Onboarding[];
  listOfAllData: Onboarding[];
  listOfAllEmployees;
  emp_id;
  due_date;

  mapOfCheckedId: { [key: string]: boolean } = {};
  currentPageDataChange($event: Onboarding[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }
  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfDisplayData.every(item => this.mapOfCheckedId[item.id]);
    this.isIndeterminate =
      this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;
  }
  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus();
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({});
    this.addField();
    this._EmployeesService.getOnboardings().subscribe(data=> this.listOfAllData = data,
      error => this.errorMsg = error);
    this._EmployeesService.getEmployees().subscribe(data=> this.listOfAllEmployees = data,
    error => this.errorMsg = error );

  }

  validateForm: FormGroup;
  listOfControl: Array<{ id: number; controlInstance: string }> = [];
  
  addField(e?: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }
    const id = this.listOfControl.length > 0 ? this.listOfControl[this.listOfControl.length - 1].id + 1 : 0;

    const control = {
      id,
      controlInstance: `checklistitem${id}`
    };
    const index = this.listOfControl.push(control);
    this.validateForm.addControl(
      this.listOfControl[index - 1].controlInstance,
      new FormControl(null, Validators.required)
    );
  }

  removeField(i: { id: number; controlInstance: string }, e: MouseEvent): void {
    e.preventDefault();
    if (this.listOfControl.length > 1) {
      const index = this.listOfControl.indexOf(i);
      this.listOfControl.splice(index, 1);
      this.validateForm.removeControl(i.controlInstance);
    }
  }

  visible = false;
  visibleChecklist = false;

  open(): void {
    this.visible = true;
  }
  close(): void {
    this.visible = false;
  }
  openChecklist(): void {
    this.visibleChecklist = true;
  }
  closeChecklist(): void {
    this.visibleChecklist = false;
  }  
  AddChecklist(){
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    let data = {
      emp_id: this.emp_id,
      created_by: 1,
      due_date: this.due_date,
      checklist: JSON.stringify(this.validateForm.value)
    };

    this._EmployeesService.createOnboarding(data).subscribe(data=>{
      this._EmployeesService.getOnboardings().subscribe(data=> this.listOfAllData = data,
        error => this.errorMsg = error);
        this.visible =false;

    }, err=> {
      console.log(err);
    })

  }
}
