import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import OrgChart from "@balkangraph/orgchart.js";
import { ChartComponent } from 'ng-apexcharts';
import { title } from 'process';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

  visibleSave = false;
   chart;
   data = [
    { id: 1, name: "Denny Curtis", title: "CEO", img: "https://cdn.balkan.app/shared/2.jpg" },
    { id: 2, pid: 1, name: "Ashley Barnett", title: "Sales Manager", img: "https://cdn.balkan.app/shared/3.jpg" },
    { id: 3, pid: 1, name: "Caden Ellison", title: "Dev Manager", img: "https://cdn.balkan.app/shared/4.jpg" },
    { id: 4, pid: 2, name: "Elliot Patel", title: "Sales", img: "https://cdn.balkan.app/shared/5.jpg" },
    { id: 5, pid: 2, name: "Lynn Hussain", title: "Sales", img: "https://cdn.balkan.app/shared/6.jpg" },
    { id: 6, pid: 3, name: "Tanner May", title: "Developer", img: "https://cdn.balkan.app/shared/7.jpg" },
    { id: 7, pid: 3, name: "Fran Parsons", title: "Developer", img: "https://cdn.balkan.app/shared/8.jpg" }
   ];
 
  constructor() { }
  
  ngOnInit() {
    const tree = document.getElementById('tree');
    if (tree) {
         var chart = new OrgChart(tree, {
          enableSearch: false,
            nodeBinding: {
            field_0: "name",
            img_0: "img",
            title_0: "title"
            },
            nodeMenu: {
              edit: { text: "Edit" },
              add: { text: "Add" },
              remove: { text: "Remove" }
          },
        });

         chart.load( this.data );
    }
}

add(){
  

}
showSaveModal(): void{
  this.visibleSave = true;
 
}
saveOrganization(){
  console.log(this.data);
  this.visibleSave = false;
}

closeSaveModal(): void{
  this.visibleSave = false;
}
 
}
