import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { NzMessageService } from 'ng-zorro-antd';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import * as moment from 'moment';
import { Router, ActivatedRoute } from "@angular/router";
import { DOCUMENT } from '@angular/common';
import { NzNotificationService  } from 'ng-zorro-antd';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-edit-attendance',
  templateUrl: './edit-attendance.component.html',
  styleUrls: ['./edit-attendance.component.scss']
})
export class EditAttendanceComponent implements OnInit {
  attendanceDataByUserId;
  listOfattendanceData;
  listOfEmployeesData;
  disabledDate;
  today = new Date();
  employeeDataLoading = true;
  listOfAllEmployees;
  attendanceDataLoading = true;
  load = false;
  loggedInUser = this.storage.get('loggedInUser');
  public errorMsg;
  //
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  attendanceToDeleteId = 0;
  isOkLoading = false;
  departments;
  designations;
  selectedEmployee;
  workFromHome = false;
  employeeName = "";
  clockIn= null;
  clockOut= null;
  error = false;

  constructor(
    private notification: NzNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
                private _EmployeesService: EmployeesService,
                private _SettingsService: SettingsService, private msg: NzMessageService
                , @Inject(LOCAL_STORAGE) private storage: StorageService) { }
  ngOnInit() {

    let id = this.route.snapshot.paramMap.get('id');

    // get employees list
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfAllEmployees = data;
      this.selectedEmployee = this.listOfAllEmployees.find(o => o.id == 1);
      this.listOfEmployeesData = this.listOfAllEmployees;
      this.employeeDataLoading = false;
    },
    error => console.log(error.message));
    this.disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) > 0;

    this._EmployeesService.getAttendance().subscribe(dt => {
      this.listOfattendanceData = dt;                        
      this.attendanceDataLoading = false;
      this.load = true;

        this.visibleEdit = true;
        let data = this.listOfattendanceData.find(data => data.id == id);
        
        this.attendanceEditForm.patchValue({
          id: id,
          emp_id: data.emp_id.toString(),
          date: new Date(data.date),
          clock_in: moment(data.clock_in, "H:mm a"),
          clock_out: moment(data.clock_out, "H:mm a"),
          work_from_home: data.status == "Work From Home" ? true : false,
          work_from_home_total: data.work_from_home_total,
        });
        this.clockIn = moment(data.clock_in,'H:mm a').format('H:mm');
        this.clockOut = moment(data.clock_out,'H:mm a').format('H:mm');
        console.log(this.clockIn);
        console.log(this.clockOut);
       
    });
    
    
  }
  open(): void{
    this.attendanceEditForm.reset();
    this.visible = true;
  }
  close(): void{
  this.visible = false;
  }

  attendanceEditForm = new FormGroup({
    id: new FormControl(),
    emp_id: new FormControl(null, Validators.required),
    date: new FormControl(null, Validators.required),
    clock_in: new FormControl(null, Validators.required),
    clock_out: new FormControl(null, Validators.required),
    work_from_home: new FormControl(null),
    work_from_home_total: new FormControl(null)
  });
  errorRequiredcheckout =false;
  errorRequiredcheckin = false;
  editAttendance(): void {
    
    
      if (this.clockOut == null ){
        this.errorRequiredcheckout = true;
        console.log("checkout true");
      }else{
      this.errorRequiredcheckout = false;
      console.log("checkout false");}
      if (this.clockIn == null ){
        this.errorRequiredcheckin = true;
        console.log("checkin true");
      }else{
      this.errorRequiredcheckin = false;
      console.log("checkin false");
      }
    for (const i in this.attendanceEditForm.controls) {
      this.attendanceEditForm.controls[i].markAsDirty();
      this.attendanceEditForm.controls[i].updateValueAndValidity();
    }
    // let selectedDate = new Date(this.attendanceEditForm.value.date);
    // this.attendanceEditForm.value.date = moment(selectedDate).format('YYYY-MM-DD');
    // this.attendanceForm.value.clock_in = moment(this.attendanceForm.value.clock_in).format('H:mm');
    // this.attendanceForm.value.clock_out = moment(this.attendanceForm.value.clock_out).format('H:mm');
    this.attendanceEditForm.patchValue({
        // date: moment(selectedDate).format('YYYY-MM-DD'),
       clock_in: this.clockIn,
       clock_out: this.clockOut
    });
    console.log(this.attendanceEditForm.value);
    if(this.attendanceEditForm.valid){
      this.attendanceEditForm.value.date = moment(this.attendanceEditForm.value.date).format('YYYY-MM-DD');
      this._EmployeesService.editAttendance(this.attendanceEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            alert('Already Exisits');
          }else if(responseObj.message == 'failed'){
            alert('Faild');
          }else if(responseObj.message == 'success'){
            this.notification.create(
              'success',
              'Attendance',
              'Successfully updated'
              );
            this.router.navigate(['/attendance'])
            // this.document.location.href = '/attendance';
          }else{
            this.notification.create(
              'success',
              'Attendance',
              'Successfully updated'
              );
            this.router.navigate(['/attendance'])
            // this.document.location.href = '/attendance';
          }
        }
      )
    }
  }
  
  onTimeChange(ev){
    if(ev !== null && this.clockOut != null && this.clockIn != null){
      const clockIn = moment.duration(this.clockIn).asMinutes();
      const clockOut = moment.duration(this.clockOut).asMinutes();
      if(clockOut <= clockIn){
        this.error = true
      }else{
        this.error = false;
        
      }
    }
  }

  
}
