import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { AccountsService } from 'src/app/services/accounts.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileManagerService } from 'src/app/services/file-manager.service'; 
import { SettingsService } from 'src/app/services/settings.service';
import * as moment from 'moment'
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ActivatedRoute, Router } from "@angular/router";
import { NzSpinModule } from 'ng-zorro-antd/spin';

@Component({
  selector: 'app-edit-expenses',
  templateUrl: './edit-expenses.component.html',
  styleUrls: ['./edit-expenses.component.scss']
})
export class EditExpensesComponent implements OnInit {
  fileToUpload;
 
  
  dateFormat = 'MMM dd, yyyy';
//Copied from employee type component ts
public errorMsg;
loggedInUser = this.storage.get('loggedInUser');

listOfDisplayData: Expense[];
listOfAllData: Expense[];
//
load = false;
visible = false;
visibleEdit = false;
visibleDelete = false;
expenseToDeleteId = 0;
isOkLoading = false;

employeeList;
expenseTypes;
prevAttachment;
selectedExpenseType;
selectedEmployee;
selectedStatus;
document;
expenseForm = new FormGroup({
  id: new FormControl(null),
  purchase_by: new FormControl(this.loggedInUser.id),
  expense_title_id: new FormControl(null, Validators.required),
  purchase_from: new FormControl(null, Validators.required),
  purchase_date: new FormControl(null, Validators.required),
  amount: new FormControl(null, Validators.required),
  bill_copy: new FormControl(null)
});

constructor(private router: Router, private _employeeService: EmployeesService, 
  private _SettingsService: SettingsService, 
  private _AccountsService: AccountsService, 
  private notification: NzNotificationService,
  private _filemanager: FileManagerService,
  private route: ActivatedRoute,
  @Inject(LOCAL_STORAGE) private storage: StorageService) { }

ngOnInit() {
  this._SettingsService.getExpenses().subscribe(dat => {
    this.expenseTypes = dat;

    let id:any = this.route.snapshot.paramMap.get('id');
    this._AccountsService.getExpenses().subscribe(dt => {
      this.listOfAllData = dt;
      this.load = true;
      this.listOfDisplayData = this.listOfAllData;
      let data = this.listOfDisplayData.find(data => data.id == id);
      this.prevAttachment = data.bill_copy;
      this.selectedExpenseType= this.expenseTypes.find(d=>d.id == data.expense_title_id);
      console.log(this.selectedExpenseType);

      this.expenseForm.patchValue({
        id: id,
        expense_title_id: data.expense_title_id,
        purchase_by: data.purchase_by,
        purchase_from: data.purchase_from,
        purchase_date: data.purchase_date,
        amount: data.amount
      });
    });

  })

  this._employeeService.getEmployees().subscribe(data => {
      this.employeeList = data;
  });



}


onFileSelect(event) {
  if (event.target.files.length > 0) {
    this.document = event.target.files[0];
  }
}



  open(): void{
    this.visible = true;
  }

  close(): void{
    this.visible = false;
  }


  addExpense(): void {
    if(this.expenseForm.valid){
      const formData = new FormData();
      if(this.document){
      formData.append('file', this.document, this.document.name);
      this._filemanager.uploadFile(formData).subscribe(
      (res) => {
        let responseObj = JSON.parse(JSON.stringify(res));
        if(responseObj.path){
          for (const i in this.expenseForm.controls) {
            this.expenseForm.controls[i].markAsDirty();
            this.expenseForm.controls[i].updateValueAndValidity();
          }
            this.expenseForm.value.bill_copy = responseObj.path;
            this.expenseForm.value.purchase_date = moment(this.expenseForm.value.purchase_date).format('YYYY-MM-DD');
            this._AccountsService.editExpense(this.expenseForm.value).subscribe(
              response => {
                let responseObj = JSON.parse(JSON.stringify(response));
                if(responseObj.message == 'already-exists'){
                  alert('Already Exisits');
                }else if(responseObj.message == 'failed'){
                  alert('Faild');
                }else if(responseObj.message == 'success'){

            this.router.navigate(['/expenses'])
                  this.visible = false;

                  this.notification.create(
                    'success',
                    'Expenses',
                    'Successfully updated'
                    );
                }else{
                  this.router.navigate(['/expenses'])

                  alert(JSON.stringify(response));
                }
              }
            )

        }else if(responseObj.message == 'failed'){
          this.notification.create(
            'error',
            'Employee Profile',
            'Unable to save changes'
            );            
        }else{
          this.notification.create(
            'error',
            'Employee Profile',
            'somethings went wrong'
            );
        }
      });
      }else{
        for (const i in this.expenseForm.controls) {
          this.expenseForm.controls[i].markAsDirty();
          this.expenseForm.controls[i].updateValueAndValidity();
        }
          this.expenseForm.value.bill_copy = this.prevAttachment;
          this.expenseForm.value.purchase_date = moment(this.expenseForm.value.purchase_date).format('YYYY-MM-DD');
          this._AccountsService.editExpense(this.expenseForm.value).subscribe(
            response => {
              let responseObj = JSON.parse(JSON.stringify(response));
              if(responseObj.message == 'already-exists'){
                this.notification.create(
                  'error',
                  'Expenses',
                  'Already Exists'
                  );
              }else if(responseObj.message == 'failed'){
                this.notification.create(
                  'error',
                  'Expenses',
                  'Unable to save changes'
                  );
              }else if(responseObj.message == 'success'){

              this.router.navigate(['/expenses'])
                this.visible = false;

                this.notification.create(
                  'success',
                  'Expenses',
                  'Successfully updated'
                  );
              }else{
                this.router.navigate(['/expenses'])

                this.notification.create(
                  'success',
                  'Expenses',
                  'Successfully updated'
                  );
              }
            }
          )
      }
    }

  }
  removeAttachment(  element){
    this.fileToUpload = null;
    element.value = "";
  }
}
