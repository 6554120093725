import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import * as moment from 'moment';
import { EmployeesService } from 'src/app/services/employees.service';
import { NzSpinModule } from 'ng-zorro-antd/spin';


@Component({
  selector: 'app-notice-board',
  templateUrl: './notice-board.component.html',
  styleUrls: ['./notice-board.component.scss']
})
export class NoticeBoardComponent implements OnInit {
  selectedValue;

  loggedInUser = this.storage.get('loggedInUser');
  loading = true;
  listOfNoticeBoardData;
  noticeboardLoading = true;
  load = false;
  constructor(
    private notification: NzNotificationService,private _SettingsService: SettingsService
    , @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _EmployeesService: EmployeesService) { }
  public errorMsg;
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  noticeToDeleteId = 0;
  isOkLoading = false;
  selectedStatus;

  currentPageDataChange($event){
    
  }
  ngOnInit(): void {
    this._SettingsService.getNotices().subscribe(data => {
      this.listOfNoticeBoardData = data;
      this.noticeboardLoading = false;
      this.load = true;
    },
    error => this.errorMsg = error
    );
  }
//dilters

selectedDuration;
firstDayThisWeek;
    lastDayThisWeek;
    thisWeekSearchForm = new FormGroup({
     
     dateFrom: new FormControl(null, Validators.required),
     dateTo: new FormControl(null , Validators.required),
      
   });
 durationSelected(ev){
   if(ev == 1){
     this.firstDayThisWeek = moment( ).startOf('week').add(1, 'd').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment( ).endOf('week').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._EmployeesService.searchNotice(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.listOfNoticeBoardData = data
       console.log(this.listOfNoticeBoardData);
     });
   }else if (ev == 2 ){
     this.firstDayThisWeek = moment( ).startOf('week').subtract(6, 'd').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment( ).endOf('week').subtract(7, 'd').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._EmployeesService.searchNotice(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.listOfNoticeBoardData = data
       console.log(this.listOfNoticeBoardData);
     });
   }else if (ev == 3 ){
     this.firstDayThisWeek = moment( ).startOf('month').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment( ).endOf('month').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._EmployeesService.searchNotice(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.listOfNoticeBoardData = data
       console.log(this.listOfNoticeBoardData);
     });
   }else if (ev == 4 ){
     this.firstDayThisWeek = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._EmployeesService.searchNotice(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.listOfNoticeBoardData = data
       console.log(this.listOfNoticeBoardData);
     });
   }else if (ev == 5 ){
     this.firstDayThisWeek = moment( ).startOf('year').format("YYYY-MM-DD");
     this.lastDayThisWeek = moment( ).endOf('year').format("YYYY-MM-DD");
     console.log(this.firstDayThisWeek);
     console.log(this.lastDayThisWeek);
     this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
     this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
     console.log(this.thisWeekSearchForm.value);
     this._EmployeesService.searchNotice(this.thisWeekSearchForm.value).subscribe(data => {
        
       this.listOfNoticeBoardData = data
       console.log(this.listOfNoticeBoardData);
     });
   }else if (ev == 6 ){
     this._SettingsService.getNotices().subscribe(data => {
     
       this.listOfNoticeBoardData = data
       console.log(this.listOfNoticeBoardData);
     });
   }
 }



  noticeForm = new FormGroup({
    title: new FormControl(null, Validators.required),
    status: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
  });
  noticeEditForm = new FormGroup({
    id: new FormControl(),
    title: new FormControl(null, Validators.required),
    status: new FormControl(null, Validators.required),
    description: new FormControl(null, Validators.required),
  });
  open(): void{
  this.visible = true;
  }
  close(): void{
  this.visible = false;
  }
  openEdit(id): void {
    this.visibleEdit = true;
    let data = this.listOfNoticeBoardData.find(data => data.id == id);
    this.selectedStatus = data.status;
    this.noticeEditForm.patchValue({
      id: id,
      title: data.title,
      description: data.description
    });
  }
  closeEdit(): void {
  this.visibleEdit = false;
  }
  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.noticeToDeleteId = id;
  }
  closeDeleteModal(): void{
    this.visibleDelete = false;
  }
  addNotice(): void {
    for (const i in this.noticeForm.controls) {
      this.noticeForm.controls[i].markAsDirty();
      this.noticeForm.controls[i].updateValueAndValidity();
    }
    if(this.noticeForm.valid){
      this._SettingsService.createNotice(this.noticeForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            alert('Already Exisits');
          }else if(responseObj.message == 'failed'){
            alert('Faild');
          }else if(responseObj.message == 'success'){
            this._SettingsService.getNotices().subscribe(data => 
              {
              this.listOfNoticeBoardData = data
              },
              error => this.errorMsg = error);
              this.visible = false;
          }else{
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }
  editNotice(): void {
    for (const i in this.noticeEditForm.controls) {
      this.noticeEditForm.controls[i].markAsDirty();
      this.noticeEditForm.controls[i].updateValueAndValidity();
    }
    if(this.noticeEditForm.valid){
      this._SettingsService.editNotice(this.noticeEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success'){
            this._SettingsService.getNotices().subscribe(data => {
              this.listOfNoticeBoardData = data
            },
              error => this.errorMsg = error);
            this.visibleEdit = false;
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else
          {
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }

  deleteNotice(id): void {
    this.isOkLoading = true;
    this._SettingsService.deleteNotice(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success'){
        this._SettingsService.getNotices().subscribe(data => {
            this.listOfNoticeBoardData = data
          },
          error => this.errorMsg = error);
          this.notification.create(
            'success',
            'Noticeboard',
            'Successfully deleted'
            );
      }
      else if(responseObj.message == 'failed'){
        this.notification.create(
          'error',
          'Noticeboard',
          'Unable to save changes'
          );
      }
      else{
        this.notification.create(
          'success',
          'Noticeboard',
          'Successfully deleted'
          );
      }
    }
  );
  this.isOkLoading = false;
  this.visibleDelete = false;
  }
}
