import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { AccountsService } from 'src/app/services/accounts.service';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { EmployeesService } from 'src/app/services/employees.service';
import { SettingsService } from 'src/app/services/settings.service';
import { Router, ActivatedRoute } from "@angular/router";
import { Inject } from '@angular/core';
import { NzNotificationService  } from 'ng-zorro-antd';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Component({
  selector: 'app-create-payment',
  templateUrl: './create-payment.component.html',
  styleUrls: ['./create-payment.component.scss']
})
export class CreatePaymentComponent implements OnInit {
  settings;
  printInvoice(divName) {
    const originalContents = document.body.innerHTML;
    const printContents = document.getElementById("invoice-pdf").innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.close()

}
  listOfEmployees;

  monthFormat = 'MMM yyyy';
  public errorMsg;

  listOfSelection = [
    {
      text: 'Select All Row',
      onSelect: () => {
        this.checkAll(true);
      }
    },
    {
      text: 'Select Odd Row',
      onSelect: () => {
        this.listOfDisplayData.forEach((data, index) => (this.mapOfCheckedId[data.id] = index % 2 !== 0));
        this.refreshStatus();
      }
    },
    {
      text: 'Select Even Row',
      onSelect: () => {
        this.listOfDisplayData.forEach((data, index) => (this.mapOfCheckedId[data.id] = index % 2 === 0));
        this.refreshStatus();
      }
    }
  ];

  isAtleastOneChecked = false;
  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfDisplayData: Payroll[];
  listOfAllData: Payroll[];
  mapOfCheckedId: { [key: string]: boolean } = {};

  currentPageDataChange($event: Payroll[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }
  refreshStatus():void {
    let checkedAbles = this.listOfDisplayData.filter(item => item.status == "Pay");

    for(let i = 0; i < checkedAbles.length; i++)
    {
      if(this.mapOfCheckedId[checkedAbles[i].id])
      {
        this.isAllDisplayDataChecked = true;
      }
      else
      {
        this.isAllDisplayDataChecked = false;
        break;
      }
    }

    this.isIndeterminate =
      this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;

    if(Object.values(this.mapOfCheckedId).includes(true))
    {
      this.isAtleastOneChecked = true;
    }
    else
    {
      this.isAtleastOneChecked = false;
    }
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => {
      if(item.status == 'Pay')
        this.mapOfCheckedId[item.id] = value;
    });
    this.refreshStatus();
  }

  visible = false;
  visibleSlipDrawer = false;
   isVisible = true;
   isNotVisible = false;

  departments;
  designations;

  payment_month;

  selectedEmployee;
  selectedDepartment;
  selectedDesignation;

  netSalary;
  overtimeSalary;
  tax;
  providentFund;
  loan;
  totalSalary;
  selectedPaymentType;

  payments;
   
  i = 0;

  slip: Payslip = {
    id: null,
    employee_code: null,
    employee_name: null,
    department: null,
    designation: null,
    payment_type: null,
    payment_month: null,
    payment_date: null,
    net_salary: null,
    tax: null,
    provident_fund: null,
    loan: null,
    overtime_salary: null,
    total_salary: null,
    payment_method: null,
    address: "Office #305, Blossom Trade Center, University Road, Gulzar-e-Hijri, Karachi, Pakistan",
    basic_salary: null
  };

  searchFormIsSubmitted = false;
  PaymentFormIsSubmitted = false;

  searchForm = new FormGroup({
    date: new FormControl(null, Validators.required),
    employee: new FormControl(null),
    department: new FormControl(null),
    designation: new FormControl(null),
  });
  paymentForm = new FormGroup({
    emp_id: new FormControl(null),
    net_salary: new FormControl(null, Validators.required),
    overtime_salary: new FormControl(null, Validators.required),
    tax: new FormControl(null, Validators.required),
    provident_fund: new FormControl(null),
    loan: new FormControl(null),
    total_salary: new FormControl({disabled: true}, Validators.required),
    payment_month: new FormControl(null),
    payment_type: new FormControl(null, Validators.required),
  });

  constructor(

    private notification: NzNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    public _EmployeesService: EmployeesService,
    private _AccountsService: AccountsService, private _SettingsService: SettingsService) { }

    ngOnInit() {
      this.selectedPaymentType = 'Bank Payment';

    let id = this.route.snapshot.paramMap.get('id');
    let date = this.route.snapshot.paramMap.get('date');

    this.payment_month = date;
    let formData = {
      'id': id,
      'date': date
    }
    let payment;
    this._AccountsService.generatePayment(formData).subscribe(data => {
      payment = data;
      console.log(data);
      this.paymentForm.patchValue({
        emp_id: id,
        net_salary: payment.net_salary,
        overtime_salary: payment.overtime_salary,
        provident_fund: payment.provident_deducted,
        loan: payment.loan_deducted,
        tax: payment.tax,
        total_salary: payment.total_salary,
        payment_month: this.payment_month,
        payment_type: this.selectedPaymentType
      });
      let providentFund = document.getElementById('provident_fund');
      let loan = document.getElementById('loan');
      if(!payment.provident_deducted){
        providentFund.style.display = "none";
      }else{
        providentFund.style.display = "";
      }
      if(!payment.loan_deducted){
        loan.style.display = "none";
      }else{
        loan.style.display = "";
      }
    });

    this._SettingsService.getSettings().subscribe(
      data => {
        this.settings = data;
      }
    );
     
  }


  get date() { return this.searchForm.get('date'); }



  closeSlipDrawer(): void{
    this.visibleSlipDrawer = false;
  }
   

  onAmountChange(): void{
    this.totalSalary = (this.netSalary + this.overtimeSalary) - this.tax - this.providentFund - this.loan;
  }

  onBulkAmountChange(): void{
    console.clear();
    for(let i = 0; i < this.payments.length; i++)
    {
      this.payments[i].total_salary = (this.payments[i].net_salary + this.payments[i].overtime_salary) - this.payments[i].tax - this.payments[i].provident_deducted - this.payments[i].loan_deducted;
    }
  }

  
  addPayment(): void{
    if(this.paymentForm.valid){
      this._AccountsService.createPayroll(this.paymentForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success'){
            // alert(JSON.stringify(response));
            this.notification.create(
              'success',
              'Payment',
              'Successfully updated'
              );
            this.visibleSlipDrawer = false;
             this.isVisible = false;
             this.isNotVisible = true;
            this.slip = responseObj.data;
          }
          else if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Payment',
              'Payment Already Exists.'
              );
          }else{
            // alert(JSON.stringify(response));
            this.notification.create(
              'success',
              'Payment',
              'Successfully updated'
              );
            // this.router.navigate(['/make-payment'])
          }
        }
      )
    }
  }

  printSlip(): void{
    window.print();
  }

}
