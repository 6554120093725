import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-edit-tax-rule',
  templateUrl: './edit-tax-rule.component.html',
  styleUrls: ['./edit-tax-rule.component.scss']
})
export class EditTaxRuleComponent implements OnInit {
  listOfAllData;
  errorMsg;

  editTaxForm = new FormGroup({
    id: new FormControl(null),
    tax_name: new FormControl(null),
    status: new FormControl(null)
  });
  constructor(private router: Router,
    private route: ActivatedRoute,
    private notification: NzNotificationService,
    private _SettingsService: SettingsService) { }
  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this._SettingsService.getTaxes().subscribe(dt=> {
      this.listOfAllData = dt;
      let data = this.listOfAllData.find(data => data.id == id);
      this.editTaxForm.patchValue({
        id: id,
        tax_name: data.tax_name,
        status: data.status
      });
    },
      error => this.errorMsg = error);
  }
  editTaxRule(){
    for (const i in this.editTaxForm.controls) {
      this.editTaxForm.controls[i].markAsDirty();
      this.editTaxForm.controls[i].updateValueAndValidity();
    }
    if(this.editTaxForm.valid){
      this._SettingsService.editTax(this.editTaxForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists'){
            this.notification.create(
              'error',
              'Tax Rules',
              'Already Exists'
              );
          }else if(responseObj.message == 'failed'){
            this.notification.create(
              'error',
              'Tax Rules',
              'Unable to save changes'
              );
          }else if(responseObj.message == 'success'){
            this.router.navigate(['/tax-rule'])
            this.notification.create(
              'success',
              'Tax Rules',
              'Successfully updated'
              );
          }else{
            this.router.navigate(['/tax-rule'])
            this.notification.create(
              'success',
              'Tax Rules',
              'Successfully updated'
              );
          }
        }
      )
    }
  }

}
