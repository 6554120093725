import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
//for catch:
import { catchError } from 'rxjs/operators';
//for throw:
import { Observable, throwError } from 'rxjs';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {
  private apiUrl = environment.stepApiUrl + '/api';
  constructor(private http: HttpClient,@Inject(LOCAL_STORAGE) private storage: StorageService) { }
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.storage.get('auth-token').accessToken,
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json'
  })
  getNotifications(){
    return this.http.get(this.apiUrl + '/user/notificaitons', { headers: this.headers})
    .pipe(catchError(this.erroHandler));
  }
  getHrDashboardStats(){
    return this.http.get(this.apiUrl + '/get-all-stats', { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getStats(employeeId:any){
    return this.http.get(this.apiUrl + '/get-basic-stats/' + employeeId,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getAttendanceByDateAndEmployeeId(date:any){
    return this.http.get(this.apiUrl + '/get-attendance-by-date/' + date ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getHolidayByDate(date:any){
    return this.http.get(this.apiUrl + '/get-holiday-by-date/' + date ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  punchIn(employeeId:any, timeNow:any){
    return this.http.get(this.apiUrl + '/punch-in/'+ employeeId +'/'+timeNow,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  punchOut(employeeId:any, timeNow:any){
    return this.http.get(this.apiUrl + '/punch-out/'+ employeeId+'/'+timeNow,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  pause(employeeId:any, timeNow:any){
    return this.http.get(this.apiUrl + '/pause/'+ employeeId+'/'+timeNow,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getallpauselogs(employeeId:any, date:any){
    return this.http.get(this.apiUrl + '/get-all-pause-logs/'+ employeeId+'/'+date,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  checkPaused(employeeId:any){
    return this.http.get(this.apiUrl + '/check-paused/'+ employeeId,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  checkAttendance(employeeId:any){
    return this.http.get(this.apiUrl + '/check-attendance/' + employeeId,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getAttendance() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/attendance' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getAttendanceById(employeeId:any) : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/attendance-by-id/'+employeeId ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  searchAttendance(filters: any): Observable<any[]>{
    return this.http.post<any[]>( this.apiUrl +'/search-attendance', JSON.stringify(filters),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createAttendance(data: any){
    return this.http.post(this.apiUrl + '/create-attendance', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editAttendance(data: any){
    return this.http.post(this.apiUrl + '/edit-attendance', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteAttendance(id: number){
    return this.http.get(this.apiUrl + '/delete-attendance/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getEmployees() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/employees' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getEmployeesByDepartmentId(id:any) : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl + '/employees-by-department-id/'+id, { headers:this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getEmployeesByDesignationId(id:any) : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl + '/employees-by-designation-id/'+id, { headers:this.headers })
    .pipe(catchError(this.erroHandler));
  } 
  getEmployeeRoles() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl + '/employee-roles' ,{headers: this.headers})
    .pipe(catchError(this.erroHandler));
  }
  setPermissions(perms:any, id:any){
    return this.http.post(this.apiUrl + '/set-permissions/' +id, JSON.stringify(perms), { headers: this.headers})
    .pipe(catchError(this.erroHandler));
  }
  getBankAccounts(id:any){
    return this.http.get<any[]>( this.apiUrl + '/employee-bank-accounts/'+id, {headers: this.headers})
    .pipe(catchError(this.erroHandler));
  }
  addBank(bank:any){
    return this.http.post(this.apiUrl + '/add-employee-bank', JSON.stringify(bank),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editBank(bank:any){
    return this.http.post(this.apiUrl + '/edit-employee-bank', JSON.stringify(bank),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteBank(id:any){
    return this.http.get<any>( this.apiUrl + '/delete-employee-bank/'+id, { headers: this.headers})
    .pipe(catchError(this.erroHandler));
  }
  createEmployee(employee:any){
    return this.http.post(this.apiUrl + '/create-employee', JSON.stringify(employee),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteEmployee(id: number) {
    return this.http.get(this.apiUrl + '/delete-employee/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  updateEmployee(employee:any){
    return this.http.post(this.apiUrl + '/update-employee',
    JSON.stringify(employee),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  updateEmployeeAvatar(data:any){
    return this.http.post(this.apiUrl + '/update-employee-avatar', JSON.stringify(data), { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  postImportEmployees(employees: any[]) {
    return this.http.post(this.apiUrl+'/import-employees', JSON.stringify(employees),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  addEmployeeDocument(data:any){
    return this.http.post(this.apiUrl+'/add-employee-document', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getEmployeeDocuments(id:number){
    return this.http.get(this.apiUrl + '/get-employee-documents/'+id, {headers:this.headers})
    .pipe(catchError(this.erroHandler));
  }

  addRole(role:any){
    return this.http.post(this.apiUrl + '/add-employee-role', JSON.stringify(role),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editRole(role:any){
    return this.http.post(this.apiUrl + '/edit-employee-role', JSON.stringify(role),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteRole(id: number) {
    return this.http.get(this.apiUrl + '/delete-employee-role/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
    
  getEmployeeAwards(): Observable<any[]>{
    return this.http.get<any[]>(this.apiUrl + '/employee-awards',{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createEmployeeAward(data: any): Observable<any>{
    return this.http.post<any>(this.apiUrl + '/create-employee-award', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editEmployeeAward(data: any): Observable<any>{
    return this.http.post<any>(this.apiUrl + '/edit-employee-award', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteEmployeeAward(id:any){
    return this.http.get(this.apiUrl + '/delete-employee-award/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getEmployeeLeaveThisMonth(id:any): Observable<{
    sickLeave:number,
    halfDay:number,
    vacations: number
    }>{
    return this.http.get<{
      sickLeave: number,
      halfDay: number,
      vacations: number
    }>( this.apiUrl + '/employee-leave-this-month/' + id, { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getEmployeeLeaveThisYear(id:any): Observable<{
    sickLeave:number,
    halfDay:number,
    vacations: number
    }>{
    return this.http.get<{
      sickLeave: number,
      halfDay: number,
      vacations: number
    }>( this.apiUrl + '/employee-leave-this-year/' + id, { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  searchEmployeeLeave(filters: any): Observable<any[]>{
    return this.http.post<any[]>( this.apiUrl +'/search-employee-leave', JSON.stringify(filters),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getEmployeeLeave() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/employee-leave' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getEmployeeLeavesById(id:any) : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/employee-leave-by-id/' + id ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createEmployeeLeave(data: any){
    return this.http.post(this.apiUrl + '/create-employee-leave', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editEmployeeLeave(data: any){
    return this.http.post(this.apiUrl + '/edit-employee-leave', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteEmployeeLeave(id: number){
    return this.http.get(this.apiUrl + '/delete-employee-leave/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getOnboardings() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/onboardings' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getOnboarding(id:any): Observable<{
      employee_name: string;
      created_by_name: string;
      due_date: string;
      submitted: number;
      onboarding_items: {id: number; checklist: string; notes: string; is_checked: number}[]
    }>{
    return this.http.get<{
      employee_name: string;
      created_by_name: string;
      due_date: string;
      submitted: number;
      onboarding_items: {id: number; checklist: string; notes: string; is_checked: number}[]
    }>
    ( this.apiUrl + '/onboarding/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createOnboarding(data: any){
    return this.http.post(this.apiUrl + '/create-onboarding', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editOnboarding(data: any){
    return this.http.post(this.apiUrl + '/edit-onboarding', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteOnboarding(id: number){
    return this.http.get(this.apiUrl + '/delete-onboarding/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getBonus() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/bonuses',{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editBonus(data: any) {
    return this.http.post(this.apiUrl + '/edit-bonus', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getIncrements() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/increments',{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editIncrement(data: any) {
    return this.http.post(this.apiUrl + '/edit-increment', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getSalaryList() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/salary-list',{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editSalary(data: any) {
    return this.http.post(this.apiUrl + '/edit-salary', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getLogs(type:any): Observable<any[]>{
    return this.http.get<any[]>(this.apiUrl + '/salary-records/' + type,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getLoans(): Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/loans' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getLoansByEmployeeId($id): Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl+'/get-loans-by-employee-id/'+$id, { headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createLoan(data: any){
    return this.http.post(this.apiUrl + '/create-loan', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editLoan(data: any){
    return this.http.post(this.apiUrl + '/edit-loan', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteLoan(id: number){
    return this.http.get(this.apiUrl + '/delete-loan/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getProvidentFund(): Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/provident-funds' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  // search
  searchExpense(filters: any): Observable<any[]>{
    return this.http.post<any[]>( this.apiUrl +'/search-expense', JSON.stringify(filters),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  searchHoliday(filters: any): Observable<any[]>{
    return this.http.post<any[]>( this.apiUrl +'/search-holiday', JSON.stringify(filters),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  searchNotice(filters: any): Observable<any[]>{
    return this.http.post<any[]>( this.apiUrl +'/search-notice', JSON.stringify(filters),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  searchSalaryList(filters: any): Observable<any[]>{
    return this.http.post<any[]>( this.apiUrl +'/search-salary-list', JSON.stringify(filters),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  searchIncrements(filters: any): Observable<any[]>{
    return this.http.post<any[]>( this.apiUrl +'/search-increments', JSON.stringify(filters),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  searchBonuses(filters: any): Observable<any[]>{
    return this.http.post<any[]>( this.apiUrl +'/search-bonuses', JSON.stringify(filters),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  
  searchEmployeeProvidentFund(filters: any): Observable<any[]>{
    return this.http.post<any[]>( this.apiUrl +'/search-provident-funds', JSON.stringify(filters),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  searchLoans(filters: any): Observable<any[]>{
    return this.http.post<any[]>( this.apiUrl +'/search-loans', JSON.stringify(filters),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createProvidentFund(data: any){
    return this.http.post(this.apiUrl + '/create-provident-fund', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editProvidentFund(data: any){
    return this.http.post(this.apiUrl + '/edit-provident-fund', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteProvidentFund(id: number){
    return this.http.get(this.apiUrl + '/delete-provident-fund/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

 syncAttendanceFromMachine(){
  console.log("GET Request to sync attendance from machine")
 }
}
