import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SlackService } from 'src/app/services/slack.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Component({
  selector: 'app-slack',
  templateUrl: './slack.component.html',
  styleUrls: ['./slack.component.scss']
})
export class SlackComponent implements OnInit {
  loggedInUser = this.storage.get('loggedInUser');
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private _slackService: SlackService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) { }

  ngOnInit() {
    const code = this.route.snapshot.queryParams.code;
    if(code){
      this._slackService.getCredentials().subscribe((data:any)=>{
        console.log(data);
          this._slackService.getAccessToken(data.baseUrl, data.ClientID, data.ClientSecret, code, data.RedirectURI ).subscribe((data:any)=>{
            console.log(data);  
            this._slackService.saveAccessToken(this.loggedInUser.id, data.access_token, data.refresh_token, data.expires_in, data.team_id, data.user_id).subscribe((data)=>{
              console.log('token saved');
              console.log(data);
              // this.router.navigate(['/dashboard'])
              this.document.location.href = '/dashboard';
            })
          },error=>console.log(error));   
      });
    }
  }

  authorize(){
    this._slackService.getCredentials().subscribe((data:any)=>{
      console.log(data);
      this.document.location.href = "https://slack.com/oauth/authorize?client_id="
      +data.ClientID+"&scope="
      +data.scope+"&redirect_uri="
      +data.RedirectURI+"&tracked=1";
    });
  }

  getChannelMessage(){
    this._slackService.getChannelMessages("xoxe.xoxp-1-Mi0yLTI2NjEzOTczODkzNTEtMjY3NjA4NjUwNTQ3NS0yOTE2MjAxNzYzODYxLTI5MTkxNTc2MzcyMDMtYzQxZTczZmFjOWZmZmE0MjAzOGE5NzRmYzJhYWI3YTUyOTg2NDQ3OGU0NDNhZDlhMjQzNDdkZDI2N2QwODM3Yw","C02L8KWKZH7").subscribe((data)=>{
      console.log(data);
    }, error => console.log(error))
  }

}
