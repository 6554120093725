import { Component, Input, OnInit } from '@angular/core';
import { SlackService } from 'src/app/services/slack.service';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as moment from 'moment';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Component({
  selector: 'app-slack-sidebar',
  templateUrl: './slack-sidebar.component.html',
  styleUrls: ['./slack-sidebar.component.scss']
})
export class SlackSidebarComponent implements OnInit {

  @Input() id = '';

  loggedInUser = this.storage.get('loggedInUser');
  messages;
  isSlackConnected = true;
  slackChannels =[];

  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private _slackService: SlackService,
    @Inject(LOCAL_STORAGE) private storage: StorageService
  ) { }

  ngOnInit() {
    this._slackService.getSavedAccessToken(this.loggedInUser.id).subscribe((data:any)=>{
      if(data.length == 0){
        this.isSlackConnected = false;
      }else{
        this.isSlackConnected = true;

        let startTime = data[0].created_at;
        let end = moment();
        
        var duration = moment.duration(end.diff(startTime));
        var seconds = duration.asSeconds();


        if(seconds < data[0].expiry){
        this._slackService.getChannels(data[0].access_token).subscribe(async (channels:any)=>{
          if(channels.channels.length > 0){
            await channels.channels.map((channel)=>{
              if(channel.is_im){
                this._slackService.getUserProfile(data[0].access_token, channel.user).subscribe((user:any)=>{
                  channel.userName = user.profile.real_name;
                  this.slackChannels.push(channel);
                })
              }else{
                this.slackChannels.push(channel);
              }
            });
          }
        });
        }else{
          this._slackService.getCredentials().subscribe((creds:any)=>{
            this._slackService.refreshAccessToken(creds.baseUrl, creds.ClientID, creds.ClientSecret, data.refresh_token ).subscribe((refreshToken:any)=>{
              if(refreshToken.ok){
                this.isSlackConnected = true;
                  this._slackService.saveAccessToken(this.loggedInUser.id, data.access_token, data.refresh_token, data.expires_in, data.team_id, data.user_id).subscribe((data)=>{
                    console.log('token saved');
                    console.log(data);
                  });
              }else{
                this.isSlackConnected = false;
              }
            },error=>console.log(error));
          });
        }
        }
    }, error => console.log(error));
  }


  authorize(){
    this._slackService.getCredentials().subscribe((data:any)=>{
      console.log(data);
      this.document.location.href = "https://slack.com/oauth/authorize?client_id="
      +data.ClientID+"&scope="
      +data.scope+"&redirect_uri="
      +data.RedirectURI+"&tracked=1";
    });
  }

}
