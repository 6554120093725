import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { SupportticketsService } from 'src/app/services/supporttickets.service';

@Component({
  selector: 'app-support-tickets',
  templateUrl: './support-tickets.component.html',
  styleUrls: ['./support-tickets.component.scss']
})
export class SupportTicketsComponent implements OnInit {

  selectedValue;
  listOfTicketsData;
  ticketsLoading = true;
   
  dateFormat = 'MMM dd, yyyy';

  drawerVisible = false;
  
  fileToUpload: File;
  validUpload = false;
  loggedInUser = this.storage.get('loggedInUser');
  loading = true;
  constructor(private _supportTicketService: SupportticketsService, 
    @Inject(LOCAL_STORAGE) private storage: StorageService, private fb: FormBuilder) { }
  public errorMsg;

  ngOnInit(): void {
    this._supportTicketService.getSupportTicket().subscribe(
      data=> { 
        console.log(data);

        this.listOfTicketsData = data;
        this.ticketsLoading = false;
      },
      error => this.errorMsg = error);
  }
  showDeleteModal(id): void{
   
  }
 
  
}
