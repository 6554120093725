import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import * as moment from 'moment'
import { SettingsService } from 'src/app/services/settings.service';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-loan',
  templateUrl: './loan.component.html',
  styleUrls: ['./loan.component.scss']
})
export class LoanComponent implements OnInit {
  selectedValue;
  loggedInUser = this.storage.get('loggedInUser');
  load = false;
  public errorMsg;
  dateFormat = 'MMM dd, yyyy';
  listOfDisplayData;
  listOfAllData;
  //
  listOfLoanData;
  loanDataByUserId;
  listOfData: Attendance[] = [];
  departments;
  designations;
  listOfEmployeesData;
  visible = false;
  visibleEdit = false;
  visibleDelete = false;
  loanToDeleteId = 0;
  isOkLoading = false;


  selectedEmployee;
  enablePayslip;
  selectedStatus;

  defaultEnablePayslip;
  defaultStatus;
  listOfEmployees;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _SettingsService: SettingsService,
    private _EmployeesService: EmployeesService) { }

  ngOnInit() {
    this.defaultEnablePayslip = 'yes';
    this.defaultStatus = 'pending';

    if(this.loggedInUser.role_id !== 2){
      this._EmployeesService.getLoans().subscribe(data => {
        console.log(data);
        this.load = true;
        this.listOfAllData = data;
        this.listOfDisplayData = this.listOfAllData;
      });
    }else{
      this._EmployeesService.getLoansByEmployeeId(this.loggedInUser.id).subscribe(data => {
        this.listOfAllData = data;
        this.listOfDisplayData = this.listOfAllData;
        this.load = true;
      });
    }
    
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfEmployees = data;
    },
    error => console.log(error.message));

    this.loanForm.patchValue({
      emp_id : this.loggedInUser.id,
      status : 'Pending'
    });

    this._SettingsService.getDepartments().subscribe(data => this.departments = data);
    this._SettingsService.getDesignations().subscribe(data => this.designations = data);
    this._EmployeesService.getEmployees().subscribe(data=>  this.listOfEmployeesData = data);
  }
  changeDepartment(ev): void{
    if(ev){
      this._SettingsService.getDesignationsByDepartmentId(ev).subscribe(
        data => { 
          this.designations = data;
        }
      );
      this._EmployeesService.getEmployeesByDepartmentId(ev).subscribe(data=> { 
        this.listOfEmployeesData = data;
      },
      error => console.log(error.message));
    }
  }
  changeDesignation(ev): void{
    if(ev){
      this._EmployeesService.getEmployeesByDesignationId(ev).subscribe(data=> { 
        this.listOfEmployeesData = data;
      },
      error => console.log(error.message));
    }
  }
  searchForm = new FormGroup({
    emp_id: new FormControl(null),
    dateFrom: new FormControl(null, Validators.required),
    dateTo: new FormControl(null, Validators.required),
    department: new FormControl(null),
    designation: new FormControl(null)
  });
  searchAttendance(): void{
    for (const i in this.searchForm.controls) {
      this.searchForm.controls[i].markAsDirty();
      this.searchForm.controls[i].updateValueAndValidity();
    }
    if(this.searchForm.valid){    
      this.searchForm.value.dateTo =moment(this.searchForm.value.dateTo).format('YYYY-MM-DD');
      this.searchForm.value.dateFrom =moment(this.searchForm.value.dateFrom).format('YYYY-MM-DD');
      console.log(this.searchForm.value.emp_id);
      console.log(this.searchForm.value.dateFrom);
      console.log(this.searchForm.value.dateTo);
      this._EmployeesService.searchLoans(this.searchForm.value).subscribe(data => {
        this. listOfLoanData = data;                        
        this.listOfData = data;
        this.listOfDisplayData = data;
        this.listOfAllData = data;
        this.loanDataByUserId = data.filter(data=> data.emp_id == this.loggedInUser.id);        
      });
    }
  }
  loanForm = new FormGroup({
    emp_id: new FormControl(null, Validators.required),
    title: new FormControl(null, Validators.required),
    loan_date: new FormControl(null, Validators.required),
    amount: new FormControl(null, Validators.required),
    enable_payslip: new FormControl('yes'),
    repayment_amount: new FormControl(0),
    repayment_start_date: new FormControl(null),
    status: new FormControl('pending'),
    description: new FormControl(null)
  });

 
  
  

  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.loanToDeleteId = id;
  }

  closeDeleteModal(): void{
    this.visibleDelete = false;
  }

 
  // filters
selectedDuration;
firstDayThisWeek;
    lastDayThisWeek;
    thisWeekSearchForm = new FormGroup({
     
     dateFrom: new FormControl(null, Validators.required),
     dateTo: new FormControl(null , Validators.required),
      
   });
  durationSelected(ev){
    if(ev == 1){
      this.firstDayThisWeek = moment( ).startOf('week').add(1, 'd').format("YYYY-MM-DD");
      this.lastDayThisWeek = moment( ).endOf('week').format("YYYY-MM-DD");
      console.log(this.firstDayThisWeek);
      console.log(this.lastDayThisWeek);
      this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
      this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
      console.log(this.thisWeekSearchForm.value);
      this._EmployeesService.searchLoans(this.thisWeekSearchForm.value).subscribe(data => {
         
        this.listOfAllData = data
        console.log(this.listOfAllData);
      });
    }else if (ev == 2 ){
      this.firstDayThisWeek = moment( ).startOf('week').subtract(6, 'd').format("YYYY-MM-DD");
      this.lastDayThisWeek = moment( ).endOf('week').subtract(7, 'd').format("YYYY-MM-DD");
      console.log(this.firstDayThisWeek);
      console.log(this.lastDayThisWeek);
      this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
      this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
      console.log(this.thisWeekSearchForm.value);
      this._EmployeesService.searchLoans(this.thisWeekSearchForm.value).subscribe(data => {
         
        this.listOfAllData = data
        console.log(this.listOfAllData);
      });
    }else if (ev == 3 ){
      this.firstDayThisWeek = moment( ).startOf('month').format("YYYY-MM-DD");
      this.lastDayThisWeek = moment( ).endOf('month').format("YYYY-MM-DD");
      console.log(this.firstDayThisWeek);
      console.log(this.lastDayThisWeek);
      this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
      this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
      console.log(this.thisWeekSearchForm.value);
      this._EmployeesService.searchLoans(this.thisWeekSearchForm.value).subscribe(data => {
         
        this.listOfAllData = data
        console.log(this.listOfAllData);
      });
    }else if (ev == 4 ){
      this.firstDayThisWeek = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD");
      this.lastDayThisWeek = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD");
      console.log(this.firstDayThisWeek);
      console.log(this.lastDayThisWeek);
      this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
      this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
      console.log(this.thisWeekSearchForm.value);
      this._EmployeesService.searchLoans(this.thisWeekSearchForm.value).subscribe(data => {
         
        this.listOfAllData = data
        console.log(this.listOfAllData);
      });
    }else if (ev == 5 ){
      this.firstDayThisWeek = moment( ).startOf('year').format("YYYY-MM-DD");
      this.lastDayThisWeek = moment( ).endOf('year').format("YYYY-MM-DD");
      console.log(this.firstDayThisWeek);
      console.log(this.lastDayThisWeek);
      this.thisWeekSearchForm.value.dateFrom = this.firstDayThisWeek;
      this.thisWeekSearchForm.value.dateTo = this.lastDayThisWeek;
      console.log(this.thisWeekSearchForm.value);
      this._EmployeesService.searchLoans(this.thisWeekSearchForm.value).subscribe(data => {
         
        this.listOfAllData = data
        console.log(this.listOfAllData);
      });
    }else if (ev == 6 ){
      this._EmployeesService.getLoans().subscribe(data => {
         
        this.listOfAllData = data
        console.log(this.listOfAllData);
      });
    }
  }
  resetSearch(): void{
    this.searchForm.reset();
    this._EmployeesService.getLoans().subscribe(data => {
      this.listOfAllData = data;
    });
  }
  deleteLoan(id): void {
    this.isOkLoading = true;
    this._EmployeesService.deleteLoan(id).subscribe(
    response => {
      let responseObj = JSON.parse(JSON.stringify(response));
      if(responseObj.message == 'success')
      {
          if(this.loggedInUser.role_id !== 2){
            this._EmployeesService.getLoans().subscribe(data => {
              console.log(data);
              this.listOfAllData = data;
              this.listOfDisplayData = this.listOfAllData;
            });
          }else{
            this._EmployeesService.getLoansByEmployeeId(this.loggedInUser.id).subscribe(data => {
              this.listOfAllData = data;
              this.listOfDisplayData = this.listOfAllData;
            });
          }
      }
      else if(responseObj.message == 'failed')
      {
        alert('Faild');
      }
      else
      {
        alert(JSON.stringify(response));
      }
    }
  );

  this.isOkLoading = false;
  this.visibleDelete = false;
  }

}
