import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { EmployeesService } from 'src/app/services/employees.service';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ProjectsService } from 'src/app/services/projects.service';
import { TeamsService } from 'src/app/services/teams.service';
import * as moment from 'moment'

@Component({
  selector: 'app-confirm-timesheet',
  templateUrl: './confirm-timesheet.component.html',
  styleUrls: ['./confirm-timesheet.component.scss']
})
export class ConfirmTimesheetComponent implements OnInit {
  loggedInUser = this.storage.get('loggedInUser');  
  timesheetLoading = true;
  mondayTotal=0;
  tuesdayTotal=0;
  wednesdayTotal=0;
  thursdayTotal=0;
  fridayTotal=0;
  saturdayTotal=0;
  selectedWeek;
  tasksForWeek;
  monday;
  tuesday;
  wednesday;
  thursday;
  friday;
  saturday;
  tasks; // for timescheduler
  total = 4;
  total_time_thisweek:string = "0h 0m";
  selectedEmployee: Employee;
  start;
  end;
  listOfAllDataTeam;
  selectedTeam;
  manager = {
    fname: 'N/A', lname: '', id: 0
  };
  isLocked = true;
  submittedTimesheet;
  reason="";
  previousRecords;

  constructor(private _EmployeesService: EmployeesService,
    private _ProjectsService: ProjectsService,
    private router: Router,
    private _TeamsService: TeamsService,
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService) { }
  ngOnInit() {
    let selectedEmployeeId:any = this.route.snapshot.paramMap.get('employeeId');
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.selectedEmployee = data.find(o => o.id == selectedEmployeeId);
      this._TeamsService.getTeams().subscribe(dt=> { 
        this.listOfAllDataTeam = dt;
        this.listOfAllDataTeam.map((team)=>{
          let members = team.employees.split(',');
          members.map((member)=>{
            if(member == selectedEmployeeId){
              this.selectedTeam = team;
              this.manager  = data.find(o => o.id == team.manager_id);
            }
          })
        });
      });
    }, error => console.log(error.message));
    let selectedWeekModel:any = this.route.snapshot.paramMap.get('selectedWeek');
    this.timesheetLoading = true;
    if(selectedWeekModel == 0){ // this week
      // for weekly timesheet
      // empty data
      this.mondayTotal = 0;
      this.tuesdayTotal = 0;
      this.wednesdayTotal = 0;
      this.thursdayTotal = 0;
      this.fridayTotal = 0;
      this.saturdayTotal = 0;

      this.selectedWeek = moment();
      this.monday     = moment(this.selectedWeek).startOf('week').add(1, 'd');
      this.start =      this.monday.format('dddd - Do MMM, Y');
      this.tuesday    = moment(this.selectedWeek).startOf('week').add(2, 'd');
      this.wednesday  = moment(this.selectedWeek).startOf('week').add(3, 'd');
      this.thursday   = moment(this.selectedWeek).startOf('week').add(4, 'd');
      this.friday     = moment(this.selectedWeek).startOf('week').add(5, 'd');
      this.saturday   = moment(this.selectedWeek).startOf('week').add(6, 'd');
      this.end =        this.saturday.format('dddd - Do MMM, Y');

      let data = {
        'from_date': this.monday.format('Y-M-D'),
        'to_date': this.saturday.format('Y-M-D')
      };
      this._ProjectsService.getSubmittedTimesheet(selectedEmployeeId, data).subscribe(response=>{
        if(response != null){
        let responseObj = JSON.parse(JSON.stringify(response));
        this.submittedTimesheet = responseObj;
        console.log(this.submittedTimesheet);  
        if(responseObj.status == 2){
            this.isLocked = false;
          }else{
            this.isLocked = true;
          }
        }else{
          this.isLocked = false;
        }
      }, error=>console.log(error));


      this._ProjectsService.getSubmittedTimesheetRecords(selectedEmployeeId, data).subscribe(response=>{
        if(response != null){
        let responseObj = JSON.parse(JSON.stringify(response));
        this.previousRecords = responseObj;
        }
        }, error=>console.log(error));
      
      this._ProjectsService.getTasksByEmployeeId(selectedEmployeeId).subscribe(
        data=>{
          if(data.length > 0){
            this.tasks = data;
            this.tasksForWeek = data;            
            data.map((task) => {
              this._ProjectsService.getWeeklyTimesheetByEmployeeIdAndTaskId(selectedEmployeeId, task.id).subscribe((data)=>{
                this.mondayTotal += moment.duration(data.monday, 'seconds').asMinutes();
                this.tuesdayTotal += moment.duration(data.tuesday, 'seconds').asMinutes();
                this.wednesdayTotal += moment.duration(data.wednesday, 'seconds').asMinutes();
                this.thursdayTotal += moment.duration(data.thursday, 'seconds').asMinutes();
                this.fridayTotal += moment.duration(data.friday, 'seconds').asMinutes();
                this.saturdayTotal += moment.duration(data.saturday, 'seconds').asMinutes();
                task['timesheet'] = {
                  task_id: task.id,
                  monday: moment.duration(data.monday, 'seconds').asMinutes(),
                  tuesday: moment.duration(data.tuesday, 'seconds').asMinutes(),
                  wednesday: moment.duration(data.wednesday, 'seconds').asMinutes(),
                  thursday: moment.duration(data.thursday, 'seconds').asMinutes(),
                  friday: moment.duration(data.friday, 'seconds').asMinutes(),
                  saturday: moment.duration(data.saturday, 'seconds').asMinutes()
                };
                this.getTotalTimeThisWeek();
              }, error => console.log(error))
            });
            this.timesheetLoading = false;
            // for current running timesheet
            // 
          }else{
            this.timesheetLoading = false;
          }
        },error=> {
          this.notification.create(
            'error',
            'Error!',
            error.message
          );
      });
    }if(selectedWeekModel == 1){ // last week
      // for weekly timesheet
      // empty data
      this.mondayTotal = 0;
      this.tuesdayTotal = 0;
      this.wednesdayTotal = 0;
      this.thursdayTotal = 0;
      this.fridayTotal = 0;
      this.saturdayTotal = 0;

      this.selectedWeek = moment();
      this.monday     = moment(this.selectedWeek).startOf('week').subtract(6, 'd');
      this.tuesday    = moment(this.selectedWeek).startOf('week').subtract(5, 'd');
      this.wednesday  = moment(this.selectedWeek).startOf('week').subtract(4, 'd');
      this.thursday   = moment(this.selectedWeek).startOf('week').subtract(3, 'd');
      this.friday     = moment(this.selectedWeek).startOf('week').subtract(2, 'd');
      this.saturday   = moment(this.selectedWeek).startOf('week').subtract(1, 'd');
      
      let data = {
        'from_date': this.monday.format('Y-M-D'),
        'to_date': this.saturday.format('Y-M-D')
      };
      this._ProjectsService.getSubmittedTimesheet(selectedEmployeeId, data).subscribe(response=>{
        if(response != null){
        let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.status == 2){
            this.isLocked = false;
          }else{
            this.isLocked = true;
          }
        }else{
          this.isLocked = false;
        }
      }, error=>console.log(error));

      
      this._ProjectsService.getTasksByEmployeeId(selectedEmployeeId).subscribe(
        data=>{
          if(data.length > 0){
            this.tasks = data;
            this.tasksForWeek = data;
            data.map((task) => {
              this._ProjectsService.getLastWeekTimesheetByEmployeeIdAndTaskId(selectedEmployeeId, task.id).subscribe((data)=>{
                this.mondayTotal += moment.duration(data.monday, 'seconds').asMinutes();
                this.tuesdayTotal += moment.duration(data.tuesday, 'seconds').asMinutes();
                this.wednesdayTotal += moment.duration(data.wednesday, 'seconds').asMinutes();
                this.thursdayTotal += moment.duration(data.thursday, 'seconds').asMinutes();
                this.fridayTotal += moment.duration(data.friday, 'seconds').asMinutes();
                this.saturdayTotal += moment.duration(data.saturday, 'seconds').asMinutes();
                task['timesheet'] = {
                  task_id: task.id,
                  monday: moment.duration(data.monday, 'seconds').asMinutes(),
                  tuesday: moment.duration(data.tuesday, 'seconds').asMinutes(),
                  wednesday: moment.duration(data.wednesday, 'seconds').asMinutes(),
                  thursday: moment.duration(data.thursday, 'seconds').asMinutes(),
                  friday: moment.duration(data.friday, 'seconds').asMinutes(),
                  saturday: moment.duration(data.saturday, 'seconds').asMinutes()
                };
                this.getTotalTimeThisWeek();
              }, error => console.log(error))
            });
            this.timesheetLoading = false;
            // 
          }else{
            this.timesheetLoading = false;
          }
        },error=> {
          this.notification.create(
            'error',
            'Error!',
            error.message
          );
      });
    }
  }
  getTotalTimeThisWeek(){
    console.log('total this week:');
    console.log(
      this.mondayTotal + 
      this.tuesdayTotal + 
      this.wednesdayTotal + 
      this.thursdayTotal + 
      this.fridayTotal + 
      this.saturdayTotal
      );
    this.total_time_thisweek = this.getTimeFromMins(
      this.mondayTotal + 
      this.tuesdayTotal + 
      this.wednesdayTotal + 
      this.thursdayTotal + 
      this.fridayTotal + 
      this.saturdayTotal
    );
  }
  getTimeFromMins(mins) {
    var duration = moment.duration(mins, 'minutes');
    var totalHours = duration.hours();
    if(duration.months() > 0){
      totalHours += (duration.months() * (duration.days() * 24));
    }
    if(duration.days() > 0){
      totalHours += (duration.days() * 24);
    }
    var totalMinutes = duration.minutes();
    if(totalHours !== 0){
      return totalHours+"h "+totalMinutes+"m";
    }else{
      return totalMinutes+"m";
    }
  }
  submitTimesheet(){
    let data = {
      'employee_id': this.selectedEmployee.id, 
      'manager_id': this.manager.id,
      'from_date': this.monday.format('Y-M-D'),
      'to_date': this.saturday.format('Y-M-D')
    }
    this._ProjectsService.submitTimesheet(data).subscribe(response=>{
      let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'already-exists'){
          alert('Already Exisits');
        }
        else if(responseObj.message == 'failed'){
          alert('Faild');
        }else if(responseObj.message == 'success'){
            this.notification.create(
            'success',
            'Success!',
            "Timesheet Submitted on "+ moment()
            );
        }else{
          alert(JSON.stringify(response));
        }
          this.router.navigate(['/timesheets'])
    },error=>console.log(error));
  }
  approve(){
    this._ProjectsService.approveTimesheet(this.submittedTimesheet.id, this.reason).subscribe(response=>{
      let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'already-exists'){
          alert('Already Exisits');
        }
        else if(responseObj.message == 'failed'){
          alert('Faild');
        }else if(responseObj.message == 'success'){

            let data = {
              'from_date': this.monday.format('Y-M-D'),
              'to_date': this.saturday.format('Y-M-D')
            };
            this._ProjectsService.getSubmittedTimesheet(this.submittedTimesheet.employee_id, data).subscribe(response=>{
              if(response != null){
              let responseObj = JSON.parse(JSON.stringify(response));
              this.submittedTimesheet = responseObj;
              console.log(this.submittedTimesheet);  
              // if(responseObj.status == 2){
                //   this.isLocked = false;
                // }else{
                  this.isLocked = true;
                // }
              }else{
                this.isLocked = false;
              }
            }, error=>console.log(error));
        
        
            this.notification.create(
            'success',
            'Success!',
            "Timesheet Approved on "+ moment()
            );
        }else{
          alert(JSON.stringify(response));
        }
        // this.router.navigate(['/timesheets'])
    },error=>console.log(error));
  }
  reject(){
    this._ProjectsService.rejectTimesheet(this.submittedTimesheet.id, this.reason).subscribe(response=>{
      let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'already-exists'){
          alert('Already Exisits');
        }
        else if(responseObj.message == 'failed'){
          alert('Faild');
        }else if(responseObj.message == 'success'){

            let data = {
              'from_date': this.monday.format('Y-M-D'),
              'to_date': this.saturday.format('Y-M-D')
            };
            this._ProjectsService.getSubmittedTimesheet(this.submittedTimesheet.employee_id, data).subscribe(response=>{
              if(response != null){
              let responseObj = JSON.parse(JSON.stringify(response));
              this.submittedTimesheet = responseObj;
              console.log(this.submittedTimesheet);  
              // if(responseObj.status == 2){
                //   this.isLocked = false;
                // }else{
                  this.isLocked = true;
                // }
              }else{
                this.isLocked = false;
              }
            }, error=>console.log(error));

            this.notification.create(
            'success',
            'Success!',
            "Timesheet Rejected on "+ moment()
            );
        }else{
          alert(JSON.stringify(response));
        }
        // this.router.navigate(['/timesheets'])
    },error=>console.log(error));
  }
}
