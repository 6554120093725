import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
//for catch:
import { catchError } from 'rxjs/operators';
//for throw:
import { Observable, throwError } from 'rxjs';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  private apiUrl = environment.stepApiUrl + '/api';
  constructor(private http: HttpClient,@Inject(LOCAL_STORAGE) private storage: StorageService) { }
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.storage.get('auth-token').accessToken,
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json'
  })
  
  getAccount() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/accounts' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createAccount(data: any){
    return this.http.post(this.apiUrl + '/create-account', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editAccount(data: any){
    return this.http.post(this.apiUrl + '/edit-account', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteAccount(id: number){
    return this.http.get(this.apiUrl + '/delete-account/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getJournals() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/journals' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createJournal(journal: any){
    return this.http.post(this.apiUrl + '/create-journal', JSON.stringify(journal),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editJournal(journal: any){
    return this.http.post(this.apiUrl + '/edit-journal', JSON.stringify(journal),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteJournal(id: number){
    return this.http.get(this.apiUrl + '/delete-journal/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }


  getExpenses() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/expenses' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createExpense(data: any){
    return this.http.post(this.apiUrl + '/create-expense', data,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editExpense(data: any){
    return this.http.post(this.apiUrl + '/edit-expense', data,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  deleteExpense(id: number){
    return this.http.get(this.apiUrl + '/delete-expense/' + id,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }

  searchEmployees(filters: any): Observable<any[]>{
    return this.http.post<any[]>( this.apiUrl +'/generate-payslip/search-payroll', JSON.stringify(filters),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }


  searchUnpaidEmployees(filters: any): Observable<any[]>{
    return this.http.post<any[]>( this.apiUrl +'/make-payment/search-employees', JSON.stringify(filters),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  generatePayment(formData:any){
    return this.http.post(this.apiUrl + '/make-payment/generate-payment', formData,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createPayroll(payroll: any){
    return this.http.post(this.apiUrl + '/make-payment/create-payment', JSON.stringify(payroll),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  generateBulkPayment(formData: any){
    return this.http.post(this.apiUrl + '/make-payment/generate-bulk-payment', formData,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createBukPayroll(payrolls: any[]){
    return this.http.post(this.apiUrl + '/make-payment/create-bulk-payment', JSON.stringify(payrolls),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }

  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }
}
