import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from 'moment'
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-edit-salary',
  templateUrl: './edit-salary.component.html',
  styleUrls: ['./edit-salary.component.scss']
})
export class EditSalaryComponent implements OnInit {

   //Copid from employee leave component ts
   public errorMsg;
   load = false;
   listOfDisplayData;
   listOfAllData;
   //
   visibleEdit = false;
   selectedPaymentType='Monthly';
   constructor(private _EmployeesService: EmployeesService,
    private router: Router,
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: StorageService) { }
   ngOnInit() {
    let id:any = this.route.snapshot.paramMap.get('id');
     this._EmployeesService.getSalaryList().subscribe(dt => {
       this.listOfAllData = dt;
       this.listOfDisplayData = this.listOfAllData;
       this.load = true;
        let data = this.listOfDisplayData.find(data => data.id == id);
        
        this.salaryEditForm.patchValue({
          id: id,
          payment_type : data.payment_type,
          basic_salary : data.basic_salary,
          overtime_salary : data.overtime_salary,
          working_hourly_rate : data.working_hourly_rate,
          overtime_hourly_rate : data.overtime_hourly_rate
        });
     });
   }
 
   salaryEditForm = new FormGroup({
     id: new FormControl(),
     payment_type: new FormControl(null, Validators.required),
     basic_salary: new FormControl(null),
     overtime_salary: new FormControl(null),
     working_hourly_rate: new FormControl(null),
     overtime_hourly_rate: new FormControl(null),
   });
 
   onPaymentTypeChange(): void{
     if(this.selectedPaymentType == 'Monthly'){
       this.salaryEditForm.get('basic_salary').setValidators([Validators.required]);
       this.salaryEditForm.get('overtime_salary').setValidators([Validators.required]);
       this.salaryEditForm.get('working_hourly_rate').setValidators([Validators.nullValidator]);
       this.salaryEditForm.get('overtime_hourly_rate').setValidators([Validators.nullValidator]);
     }else{
       this.salaryEditForm.get('working_hourly_rate').setValidators([Validators.required]);
       this.salaryEditForm.get('overtime_hourly_rate').setValidators([Validators.required]);
       this.salaryEditForm.get('basic_salary').setValidators([Validators.nullValidator]);
       this.salaryEditForm.get('overtime_salary').setValidators([Validators.nullValidator]);
     }
     this.salaryEditForm.get('working_hourly_rate').updateValueAndValidity();
     this.salaryEditForm.get('overtime_hourly_rate').updateValueAndValidity();
     this.salaryEditForm.get('basic_salary').updateValueAndValidity();
     this.salaryEditForm.get('overtime_salary').updateValueAndValidity();
   }
   openEdit(id): void {
     this.visibleEdit = true;
     let data = this.listOfAllData.find(data => data.id == id);
     this.selectedPaymentType = data.payment_type;
     this.salaryEditForm.patchValue({
       id: data.id,
       payment_type: data.payment_type,
       basic_salary: data.basic_salary,
       overtime_salary: data.overtime_salary,
       working_hourly_rate: data.working_hourly_rate,
       overtime_hourly_rate: data.overtime_hourly_rate
     });
   }
   closeEdit(): void {
     this.visibleEdit = false;
   }
 
   editSalary(): void {
     for (const i in this.salaryEditForm.controls) {
       this.salaryEditForm.controls[i].markAsDirty();
       this.salaryEditForm.controls[i].updateValueAndValidity();
     }
 
     if(this.salaryEditForm.valid)
     {
       this._EmployeesService.editSalary(this.salaryEditForm.value).subscribe(
         response => {
          let responseObj = JSON.parse(JSON.stringify(response));
              if(responseObj.message == 'already-exists'){
                this.notification.create(
                  'error',
                  'Edit Salary',
                  'Already Exists'
                  );
              }else if(responseObj.message == 'failed'){
                this.notification.create(
                  'error',
                  'Edit Salary',
                  'Unable to save changes'
                  );
              }else if(responseObj.message == 'success'){
              this.router.navigate(['/salary-list'])
                this.notification.create(
                  'success',
                  'Edit Salary',
                  'Successfully updated'
                  );
              }else{
                this.router.navigate(['/salary-list'])
                this.notification.create(
                  'success',
                  'Edit Salary',
                  'Successfully updated'
                  );
              }
         }
       )
     }
   }
 
}
