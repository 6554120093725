import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-user-mapping',
  templateUrl: './user-mapping.component.html',
  styleUrls: ['./user-mapping.component.scss']
})
export class UserMappingComponent implements OnInit {

  constructor(
) { }
  ngOnInit() {

  }
}
