import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-award',
  templateUrl: './award.component.html',
  styleUrls: ['./award.component.scss']
})
export class AwardComponent implements OnInit {

  loading = true;
  visibleDelete = false;
  awardId;
  isOkLoading = true;
  constructor(private _SettingsService: SettingsService) { }
  public errorMsg;
  listOfAllData;
  ngOnInit(): void {
    this._SettingsService.getAwards().subscribe(data=> this.listOfAllData = data,
      error => this.errorMsg = error);
  }

  
  
  awardForm = new FormGroup({
    award: new FormControl(null, Validators.required)
  });

  awardEditForm = new FormGroup({
    id: new FormControl(),
    award: new FormControl(null, Validators.required)
  });

  visible = false;
  open(): void {
    this.visible = true;
  }
  close(): void {
    this.visible = false;
  }

  visibleEdit = false;
  openEdit(id): void {
    this.visibleEdit = true;

    let data = this.listOfAllData.find(data => data.id == id);

    this.awardEditForm.patchValue({
      id : id,
      award: data.award
    });
  }
  closeEdit(): void {
    this.visibleEdit = false;
  }

  addAward(): void {
    if(this.awardForm.valid)
    {
      this._SettingsService.createAward(this.awardForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists')
          {
            alert('Already Exisits');
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else if(responseObj.message == 'success')
          {
            this._SettingsService.getAwards().subscribe(data => this.listOfAllData = data,
              error => this.errorMsg = error);
          }
          else
          {
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }

  editAward(): void {
    if(this.awardEditForm.valid)
    {
      this._SettingsService.editAward(this.awardEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success')
          {
            this._SettingsService.getAwards().subscribe(data => this.listOfAllData = data,
              error => this.errorMsg = error);
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else
          {
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }
  
  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.awardId = id;
  }
  closeDeleteModal(): void{
    this.visibleDelete = false;
  }
  deleteAward(id): void {
    this.isOkLoading = false;
    this._SettingsService.deleteAward(id).subscribe(
      response => {
        let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'success')
        {
          this._SettingsService.getAwards().subscribe(data => this.listOfAllData = data,
            error => this.errorMsg = error);
            this.visibleDelete = false;
        }
        else if(responseObj.message == 'failed')
        {
          alert('Faild');
        }
        else
        {
          alert(JSON.stringify(response));
        }
      }
    )
  }
  

}
