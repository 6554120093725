import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tax-view',
  templateUrl: './tax-view.component.html',
  styleUrls: ['./tax-view.component.scss']
})
export class TaxViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


}
