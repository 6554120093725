import { Component, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service'; 
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/settings.service';
import { Inject } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',  
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {
employeeView = 0;
  startValue: Date | null = null;
  endValue: Date | null = null;
  endOpen = false;
  visibleOnboarding = false;
  listOfEmployees;
  filteredEmployee;
  employeeDataLoading = true;
  listOfEmployeesData;
  listOfAllEmployees;
  selectedEmployee;
  listOfDepartments;
  listOfDesignations;
  listOfRoles;
  load = false;
  filterChecked = false;
  searchEmployeeValue = "";
  showObject;
  loggedInUser = this.storage.get('loggedInUser');
  loading = true;

employeeForm = new FormGroup({
  fname: new FormControl(null),
  lname: new FormControl(null),
  role: new FormControl(null),
  username: new FormControl(null),
  password: new FormControl(null),
  confirm: new FormControl(null),
  email: new FormControl(null),
  phone: new FormControl(null),
  phone2: new FormControl(null),
  skype: new FormControl(null),
  employee_code: new FormControl(null),
  department: new FormControl(null),
  designation: new FormControl(null),
});

  constructor(public _EmployeesService: EmployeesService, @Inject(LOCAL_STORAGE) private storage: StorageService,private notification: NzNotificationService,
  private _SettingsService: SettingsService) { }
  public errorMsg;
  openOnboarding(): void {
    this.visibleOnboarding = true;
  }
  closeOnboarding(): void {
    this.visibleOnboarding = false;
  }
  ngOnInit(): void {    
   if(this.loggedInUser.role_id == 3 || this.loggedInUser.role_id == 6){
    this._EmployeesService.getEmployees().subscribe(data=> { 
      this.listOfAllEmployees = data;
      this.selectedEmployee = this.listOfAllEmployees.find(o => o.id == 1);
      this.listOfEmployeesData = this.listOfAllEmployees;
      this.employeeDataLoading = false;
      console.log(this.listOfAllEmployees);
      const employeeId_show = JSON.parse(localStorage.getItem("loggedInUser"))
      console.log("EMployee IDD DHSAfjbdlhf",employeeId_show.id)
     this.showObject =  this.listOfAllEmployees.find(employee => employee.id === employeeId_show.id)
       console.log("Show",this.showObject)
      this.listOfEmployees = this.listOfAllEmployees;
      console.log("Employees data here",this.listOfAllEmployees)
      this.filteredEmployee = this.listOfAllEmployees.map(data=>{
        return{
          ...data,
        fname : data.fname == null? "" : data.fname,        
        lname : data.lname == null? "" : data.lname,
        designation_name:{ designation: data.designation_name.designation == null? "" : data.designation_name.designation},
        email: data.email == null? "" : data.email, 
        }
      });
      this.load = true;
  },
  error => console.log(error.message));
   }
   else{
    this._EmployeesService.getEmployees().subscribe((res) => {
      this.showObject = res.find(employee => employee.id === this.loggedInUser.id)
      console.log(this.showObject)
      this.load = true;
    })
    console.log("Hello hello")
   }

    this._EmployeesService.getEmployeeRoles().subscribe(data=> { 
      this.listOfRoles = data;
    });    
    this._SettingsService.getDepartments().subscribe(data=> this.listOfDepartments = data,
      error => this.errorMsg = error);
    this._SettingsService.getDesignations().subscribe(data=> this.listOfDesignations = data,
      error => this.errorMsg = error);
  }
  visible = false;
  open(): void {
    this.visible = true;
  }
  close(): void {
    this.visible = false;
  }  
  handleEmployeeSearch(searchValue)
  {
    this.searchEmployeeValue = searchValue;
  }
  addEmployee() :void{
    for (const i in this.employeeForm.controls) {
      this.employeeForm.controls[i].markAsDirty();
      this.employeeForm.controls[i].updateValueAndValidity();
    }
    if(this.employeeForm.valid){
      this._EmployeesService.createEmployee(this.employeeForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success'){

            this._EmployeesService.getEmployees().subscribe(data=> { 
              this.listOfAllEmployees = data;
              this.selectedEmployee = this.listOfAllEmployees.find(o => o.id == 1);
              this.listOfEmployeesData = this.listOfAllEmployees;
              this.employeeDataLoading = false;
      
              this.listOfEmployees = this.listOfAllEmployees;
              this.filteredEmployee = this.listOfAllEmployees.map(data=>{
                return{
                  ...data,
                fname : data.fname == null? "" : data.fname,        
                lname : data.lname == null? "" : data.lname,
                designation_name:{ designation: data.designation_name.designation == null? "" : data.designation_name.designation},
                email: data.email == null? "" : data.email, 
                }
              });
          },
          error => console.log(error.message));
            
            this.notification.create(
              'success',
              'Employee Registered',
              'Successfully updated'
              );
          }
          else if(responseObj.message == 'username-exists')
          {
            this.notification.create(
              'error',
              'Username Already Exists',
              'Unable to save changes'
              );            
          }else if(responseObj.message == 'email-exists')
          {
              this._EmployeesService.getEmployees().subscribe(data=> { 
                this.listOfAllEmployees = data;
                this.selectedEmployee = this.listOfAllEmployees.find(o => o.id == 1);
                this.listOfEmployeesData = this.listOfAllEmployees;
                this.employeeDataLoading = false;
        
                this.listOfEmployees = this.listOfAllEmployees;
                this.filteredEmployee = this.listOfAllEmployees.map(data=>{
                  return{
                    ...data,
                  fname : data.fname == null? "" : data.fname,        
                  lname : data.lname == null? "" : data.lname,
                  designation_name:{ designation: data.designation_name.designation == null? "" : data.designation_name.designation},
                  email: data.email == null? "" : data.email, 
                  }
                });
              });
              
              this.notification.create(
                'error',
                'Username Already Exists',
                'Unable to save changes'
                );            
          }
          else if(responseObj.message == 'password-mismatch')
          {
            this.notification.create(
              'error',
              'Password Mismatch',
              'Unable to save changes'
              );            
          }
          else
          {
            this.notification.create(
              'error',
              'Employee Created but Email Sending Failed',
              'somethings went wrong'
              );
          }
        }
      )
    }
  }

  searchEmployee()
  {
    if(this.filterChecked == true)
    {
      this.listOfEmployees = this.listOfAllEmployees;
      this.filterChecked = false;
    }
    else
    {
      let searchEmployeeValue = this.searchEmployeeValue;

      this.listOfEmployees = this.filteredEmployee.filter(function(data) {
      return       (data.fname.includes(searchEmployeeValue)|| data.lname.includes(searchEmployeeValue) || data.email.includes(searchEmployeeValue)||data.designation_name.designation.includes(searchEmployeeValue))} )
      this.filterChecked = true;
    }
  }
}
