import { Component, Inject, OnInit } from '@angular/core';
import { EmployeesService } from 'src/app/services/employees.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd';
import { SettingsService } from 'src/app/services/settings.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { DOCUMENT } from '@angular/common';
import { NzSpinModule } from 'ng-zorro-antd/spin';
@Component({
  selector: 'app-increments',
  templateUrl: './increments.component.html',
  styleUrls: ['./increments.component.scss']
})
export class IncrementsComponent implements OnInit {
  loggedInUser = this.storage.get('loggedInUser');
  selectedValue;
  public errorMsg;
  listOfDisplayData;
  listOfAllData;
  visibleEdit = false;
  departments;
  designations;
  load = false;
  listOfEmployeesData;
  selectedPaymentType= 'Monthly';
  constructor(private _EmployeesService: EmployeesService, 
    @Inject(DOCUMENT) private document: Document, 
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _SettingsService: SettingsService,
    private msg: NzMessageService) { }

  ngOnInit() {
    this._EmployeesService.getIncrements().subscribe(data => {
      this.listOfAllData = data;
      this.listOfDisplayData = this.listOfAllData;
      this.load = true;
    });

    this._SettingsService.getDepartments().subscribe(data => this.departments = data);
    this._SettingsService.getDesignations().subscribe(data => this.designations = data);
    this._EmployeesService.getEmployees().subscribe(data=>  this.listOfEmployeesData = data);
  }
  changeDepartment(ev): void{
    if(ev){
      this._SettingsService.getDesignationsByDepartmentId(ev).subscribe(
        data => { 
          this.designations = data;
        }
      );
      this._EmployeesService.getEmployeesByDepartmentId(ev).subscribe(data=> { 
        this.listOfEmployeesData = data;
      },
      error => console.log(error.message));
    }
  }
  changeDesignation(ev): void{
    if(ev){
      this._EmployeesService.getEmployeesByDesignationId(ev).subscribe(data=> { 
        this.listOfEmployeesData = data;
      },
      error => console.log(error.message));
    }
  }

  selectedPayment;
listOfAllPreData;
    paymentSelected(ev){
      this.listOfAllPreData=[];
      console.log(ev);
      this.listOfAllData=null;
    if(ev == 1){
     
      this._EmployeesService.getIncrements().subscribe(data => {
        data.map((increment) => {
          if (increment.payment_type == "Hourly" ) {
            this.listOfAllPreData.push(increment);
            // console.log(increment);
          }
        });
        this.listOfAllData = this.listOfAllPreData;
        // console.log(this.listOfAllData);
      });
     
    }else if (ev == 2 ){
       
      this._EmployeesService.getIncrements().subscribe(data => {

        data.map((salary) => {
          if (salary.payment_type == "Monthly" ) {
            this.listOfAllPreData.push(salary);
            // console.log(salary);
          }
        });
         this.listOfAllData = this.listOfAllPreData;
      //  console.log(this.listOfAllData);
      });
    }else if (ev == 3 ){
       
      this._EmployeesService.getIncrements().subscribe(data => {
        this.listOfAllData = data;
   
      });
    }
  }
  searchForm = new FormGroup({
    emp_id: new FormControl(null),
 
    department: new FormControl(null),
    designation: new FormControl(null)
  });
  searchIncrement(): void{
    // this.leavesLoading = true;
    for (const i in this.searchForm.controls) {
      this.searchForm.controls[i].markAsDirty();
      this.searchForm.controls[i].updateValueAndValidity();
    }
    if(this.searchForm.valid){    
       
      this._EmployeesService.searchIncrements(this.searchForm.value).subscribe(data => {
        this.listOfAllData = data;
        // this.leavesLoading = false;
      });
    }
  }
  resetSearch(): void{
    this.searchForm.reset();
    this._EmployeesService.getIncrements().subscribe(data => {
      this.listOfAllData = data;
    });
  }
onPaymentTypeChange(): void{
  if(this.selectedPaymentType == 'Monthly'){
    this.incrementEditForm.get('basic_salary_increment').setValidators([Validators.required]);
    this.incrementEditForm.get('overtime_salary_increment').setValidators([Validators.required]);
    this.incrementEditForm.get('working_hourly_increment_rate').setValidators([Validators.nullValidator]);
    this.incrementEditForm.get('overtime_hourly_increment_rate').setValidators([Validators.nullValidator]);
  }else{
    this.incrementEditForm.get('working_hourly_increment_rate').setValidators([Validators.required]);
    this.incrementEditForm.get('overtime_hourly_increment_rate').setValidators([Validators.required]);
    this.incrementEditForm.get('basic_salary_increment').setValidators([Validators.nullValidator]);
    this.incrementEditForm.get('overtime_salary_increment').setValidators([Validators.nullValidator]);
  }
  this.incrementEditForm.get('working_hourly_increment_rate').updateValueAndValidity();
  this.incrementEditForm.get('overtime_hourly_increment_rate').updateValueAndValidity();
  this.incrementEditForm.get('basic_salary_increment').updateValueAndValidity();
  this.incrementEditForm.get('overtime_salary_increment').updateValueAndValidity();
}

incrementEditForm = new FormGroup({
  id: new FormControl(),
  payment_type: new FormControl(null, Validators.required),
  basic_salary_increment: new FormControl(null),
  overtime_salary_increment: new FormControl(null),
  working_hourly_increment_rate: new FormControl(null),
  overtime_hourly_increment_rate: new FormControl(null),
});

  openEdit(id): void {
    this.visibleEdit = true;
    let data = this.listOfAllData.find(data => data.id == id);
    this.selectedPaymentType = data.payment_type;
    this.incrementEditForm.patchValue({
      id: data.id,
      payment_type: data.payment_type,
      basic_salary_increment: data.basic_salary_increment,
      overtime_salary_increment: data.overtime_salary_increment,
      working_hourly_increment_rate: data.working_hourly_increment_rate,
      overtime_hourly_increment_rate: data.overtime_hourly_increment_rate
    });
  }
  closeEdit(): void {
    this.visibleEdit = false;
  }

  editIncrement(): void {
    for (const i in this.incrementEditForm.controls) {
      this.incrementEditForm.controls[i].markAsDirty();
      this.incrementEditForm.controls[i].updateValueAndValidity();
    }
    if(this.incrementEditForm.valid){
      this._EmployeesService.editIncrement(this.incrementEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success'){
            this._EmployeesService.getIncrements().subscribe(data => this.listOfAllData = data,
              error => this.errorMsg = error);
            this.listOfDisplayData = this.listOfAllData;
            this.msg.success("Salary Increment Added Successfully.");
            this.visibleEdit = false;
          }else if(responseObj.message == 'failed'){
            alert('Faild');
          }else{
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }
}
