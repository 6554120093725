import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
//for catch:
import { catchError } from 'rxjs/operators';
//for throw:
import { Observable, throwError } from 'rxjs';
import { Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {
  private apiUrl = environment.stepApiUrl + '/api';
  constructor(private http: HttpClient,@Inject(LOCAL_STORAGE) private storage: StorageService) { }
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.storage.get('auth-token').accessToken,
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json'
  })
  uploadFile(data:any){
    return this.http.post(this.apiUrl + '/upload-file', data,{ headers: new HttpHeaders() })
    .pipe(catchError(this.erroHandler));
  }
  getDocuments(){
    return this.http.get<any[]>( this.apiUrl +'/documents/get-list' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  createDocument(data: any){
    return this.http.post(this.apiUrl + '/documents/create', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  // editItem(data: any){
  //   return this.http.post(this.apiUrl + '/items/edit', JSON.stringify(data),{ headers: this.headers })
    // .pipe(catchError(this.erroHandler));
  // }
  // deleteItem(id: number){
  //   return this.http.get(this.apiUrl + '/items/delete/' + id,{ headers: this.headers })
    // .pipe(catchError(this.erroHandler));
  // }
  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }
}
