import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-expense-type',
  templateUrl: './expense-type.component.html',
  styleUrls: ['./expense-type.component.scss']
})
export class ExpenseTypeComponent implements OnInit {
  isOkLoading = false;
  loading = true;
  visibleDelete = false;
  expenseToDeleteId;

  constructor(private _SettingsService: SettingsService) { }
  public errorMsg;

  listOfDisplayData;
  listOfAllData;
  ngOnInit(): void {
    this._SettingsService.getExpenses().subscribe(data => this.listOfAllData = data,
      error => this.errorMsg = error);
    this.listOfDisplayData = this.listOfAllData;
  }

  visible = false;
  visibleEdit = false;

  expenseForm = new FormGroup({
    item_name: new FormControl(null, Validators.required),
  });

  expenseEditForm = new FormGroup({
    id: new FormControl(),
    item_name: new FormControl(null, Validators.required),
  });

  open(): void {
    this.visible = true;
  }
  close(): void {
    this.visible = false;
  }

  //Delete Modal
  showDeleteModal(id): void{
    this.visibleDelete = true;
    this.expenseToDeleteId = id;
  }

  openEdit(id): void {
    this.visibleEdit = true;
    let data = this.listOfAllData.find(data => data.id == id);
    this.expenseEditForm.patchValue({
      id : id,
      item_name: data.expense
    });
  }

  closeEdit(): void {
    this.visibleEdit = false;
  }

  addExpense(): void {
    if(this.expenseForm.valid)
    {
      this._SettingsService.createExpense(this.expenseForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'already-exists')
          {
            alert('Already Exisits');
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else if(responseObj.message == 'success')
          {
            this._SettingsService.getExpenses().subscribe(data => this.listOfAllData = data,
              error => this.errorMsg = error);
            this.listOfDisplayData = this.listOfAllData;
          }
          else
          {
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }

  editExpense(): void {
    if(this.expenseEditForm.valid)
    {
      this._SettingsService.editExpense(this.expenseEditForm.value).subscribe(
        response => {
          let responseObj = JSON.parse(JSON.stringify(response));
          if(responseObj.message == 'success')
          {
            this._SettingsService.getExpenses().subscribe(data => this.listOfAllData = data,
              error => this.errorMsg = error);
            this.listOfDisplayData = this.listOfAllData;
          }
          else if(responseObj.message == 'failed')
          {
            alert('Faild');
          }
          else
          {
            alert(JSON.stringify(response));
          }
        }
      )
    }
  }  
  deleteExpense(id): void {
    this.isOkLoading = true;
    this._SettingsService.deleteExpense(id).subscribe(
      response => {
        let responseObj = JSON.parse(JSON.stringify(response));
        if(responseObj.message == 'success')
        {
          this._SettingsService.getExpenses().subscribe(data => this.listOfAllData = data,
            error => this.errorMsg = error);
        this.visibleDelete = false;
          this.listOfDisplayData = this.listOfAllData;
        }
        else if(responseObj.message == 'failed')
        {
          alert('Faild');
        }
        else
        {
          alert(JSON.stringify(response));
        }
      }
    )
    this.isOkLoading = false;
  }
  closeDeleteModal(): void{
    this.visibleDelete = false;
  }

}
