import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {
  private apiUrl = environment.stepApiUrl + '/api';
  constructor(private http: HttpClient,@Inject(LOCAL_STORAGE) private storage: StorageService) { }
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + this.storage.get('auth-token').accessToken,
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json'
  })
  createtems(data: any){
    return this.http.post(this.apiUrl + '/teams/create', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  getTeams() : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/teams/get-all' ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }

  getTeamsById(id:any) : Observable<any[]>{
    return this.http.get<any[]>( this.apiUrl +'/teams/get/'+id ,{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }
  editTeams(data: any){
    return this.http.post(this.apiUrl + '/teams/modify', JSON.stringify(data),{ headers: this.headers })
    .pipe(catchError(this.erroHandler));
  }

  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }
}
