import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { OrgData } from "angular-org-chart/src/app/modules/org-chart/orgData";
import { NzNotificationService } from "ng-zorro-antd";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { EmployeesService } from "src/app/services/employees.service";
import { TeamsService } from "src/app/services/teams.service";

@Component({
  selector: "app-edit-organization-hierarchy",
  templateUrl: "./edit-organization-hierarchy.component.html",
  styleUrls: ["./edit-organization-hierarchy.component.scss"],
})
export class EditOrganizationHierarchyComponent implements OnInit {
  listOfEmployeesToRemove = [];
  teamList = [];
  checked = false;
  listOfAllDisplayDatas = [];
  listOfNames = [];
  listOfAllDataTeam;
  listOfAllDisplayData;
  listOfManagers = [];
  // listOfAllDisplayDatas = [];
  listOfSelectedEmployees;
  loanForm = new FormGroup({
    id: new FormControl(null),
    manager_id: new FormControl(null),
    employees: new FormArray([]),
  });

  constructor(
    private notification: NzNotificationService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private _TeamsService: TeamsService,
    private _EmployeesService: EmployeesService
  ) {}

  ngOnInit() {
    let id: any = this.route.snapshot.paramMap.get("id");
    this._TeamsService.getTeams().subscribe(
      (dt) => {
        this.listOfAllDataTeam = dt;
        this.listOfAllDisplayData = this.listOfAllDataTeam;
        let data = this.listOfAllDisplayData.find((data) => data.id == id);
        this.listOfSelectedEmployees = data.employees.split(",");
        this.loanForm.patchValue({
          id: id,
          manager_id: data.manager_id,
        });

        this._EmployeesService.getEmployees().subscribe(
          (data) => {
            // data.map((employee) => {
            //   this.listOfSelectedEmployees.map((selectedItem) => {
            //     if (employee.id == selectedItem) {
            //       employee.isSelected = true;
            //       this.updateChkbxArray(employee.id, true, "employees");
            //     }
            //   });
            // });
            data.map((employee) => {
              if (employee.role_id != 2) {
                this.listOfManagers.push(employee);
                console.log(employee);
              }
            });
          },
          (error) => console.log(error.message)
        );
      },
      (error) => console.log(error.message)
    );

    this._TeamsService.getTeams().subscribe((dt) => {
      this.teamList = dt;
      console.log(this.teamList);
      this._EmployeesService.getEmployees().subscribe(
        (data) => {
          this.listOfAllDisplayDatas = data;
          this.listOfAllDisplayDatas.map(
            (item, index) => {
              // teamsList = []
              this.listOfNames.push(item.fname);

              // this.listOfNames = this.listOfAllDisplayDatas.fname

              this.teamList.map((items) => {
                if (items.id != id) {
                  let employees = items.employees.split(",");
                  var isIndexExist = employees.indexOf(item.id.toString());
                  if (isIndexExist != -1) {
                    this.listOfEmployeesToRemove.push(item);
                  }
                  if (index == this.listOfAllDisplayDatas.length - 1) {
                    this.listOfEmployeesToRemove.map((item) => {
                      var index = this.listOfAllDisplayDatas.indexOf(item);
                      this.listOfAllDisplayDatas.splice(index, 1);

                    });
                  }
                  this.listOfAllDisplayDatas = this.listOfAllDisplayDatas;
                  console.log("22" , this.listOfAllDisplayDatas);
                }
              });
              // 
              
              // 
              
            },
            (error) => console.log(error.message)
          );
          this.listOfAllDisplayDatas.map((employee) => {
            this.listOfSelectedEmployees.map((selectedItem) => {
              if (employee.id == selectedItem) {
                employee.isSelected = true;
                this.updateChkbxArray(employee.id, true, "employees");
              }
            });
          });
        },
        (error) => console.log(error.message)
      );
    });
  }

  updateChkbxArray(id, isChecked, key) {
    const chkArray = <FormArray>this.loanForm.get(key);
    if (isChecked) {
      chkArray.push(new FormControl(id));
    } else {
      let idx = chkArray.controls.findIndex((x) => x.value == id);
      chkArray.removeAt(idx);
    }
    // console.log(chkArray);
  }
  addLoan() {
    console.log(this.loanForm.value);

    if (this.loanForm.valid) {
      this._TeamsService
        .editTeams(this.loanForm.value)
        .subscribe((response) => {
          console.log("request edit");
          let responseObj = JSON.parse(JSON.stringify(response));
          if (responseObj.message == "already-exists") {
            alert("Already Exisits");
          } else if (responseObj.message == "failed") {
            alert("Faild");
          } else if (responseObj.message == "success") {
            this.notification.create(
              "success",
              "Organization Hierarchy",
              "Successfully updated"
            );
            this.router.navigate(["/organization-hierarchy"]);
          } else {
            this.notification.create(
              "success",
              "Organization Hierarchy",
              "Successfully updated"
            );
            this.router.navigate(["/organization-hierarchy"]);
          }
        });
    }
  }
}
